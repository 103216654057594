import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Loading,
  Message,
  MessageBox,
  Notification,
  Table,
  TableColumn,
  TabPane,
  Tabs,
} from 'element-ui';
import moment from 'moment';
import lang from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './sweetalert2.css';

import Rollbar from 'rollbar';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import fCrudForm from './custom/crudform/CrudForm';
import fUpload from './custom/upload';
import fAlertBlock from './custom/AlertBlock';

import fIframe from './custom/iframe';
import fFieldrow from './custom/fieldrow';
import fPanel from './custom/Panel';

// devextreme
import fGrid from './devextreme/grid';

// element ui
import './elementui/theme/index.css';
import fButton from './elementui/Button';
import fImage from './elementui/Image';
import fTextbox from './elementui/Textbox';
import fLabel from './elementui/InputLabel';
import htmlEditor from './custom/htmleditor/HtmlEditor';
import fTagbox from './elementui/Tagbox';
import fTag from './elementui/Tag';
import fNumberbox from './elementui/Numberbox';
import fLookup from './elementui/Lookup';
import fDatetimebox from './elementui/Datetimebox';
import fAlert from './elementui/Alert';
import fCombobox from './elementui/Combobox';
import fCheckbox from './elementui/Checkbox';
import fDialog from './elementui/Dialog';
import fAvatar from './elementui/Avatar';
import fDrawer from './elementui/Drawer';
import fTreeview from './elementui/Treeview';
import fTransfer from './elementui/Transfer';
import fPopover from './elementui/Popover';
import fRadioButtonGroup from './elementui/RadioButtonGroup';
import fSwitch from './elementui/Switch';
import fBadge from './elementui/Badge';
import fCard from './elementui/Card';
import fProgressbar from './elementui/ProgressBar';

// bootstrap components
import fCol from './bootstrap/grid-col';
import fRow from './bootstrap/grid-row';

import Utils from './libs/utils/util';
import Publisher from './libs/observer';
import Dataset from './libs/dataset';

import 'tailwindcss/dist/tailwind.min.css';
import './tailwind.css';
import './bootstrap.min.css';

import middlewares from './libs/middlewares';

// Componentes personalizados
import fApp from './custom/app/AppMain';
import fLink from './custom/link';
import fForm from './custom/form/Form';
import fFormRow from './custom/form/FormRow';
import fConfigForm from './custom/ConfigForm';
import cpfCnpj from './custom/CpfCnpj';
import fPreview from './custom/Preview';

// import routers
import authRouters from './apps/auth/routers';
import personRouters from './apps/person/Routers';

import I18n from './libs/i18n';

// views / pages
import NotFound from './views/404';

// Report
import AppReport from './apps/report/App';

// paginas para registrar globalmente, pois todas as aplicações utilizam esses componentes
import Person from './apps/person/Person';
import Group from './apps/auth/Group';
import User from './apps/auth/User';
import Produto from './apps/estoque/produto_servico/Produto';
import Servico from './apps/estoque/produto_servico/Servico';

window._ = require('lodash');

const Components = {
  fButton,
  fImage,
  fForm,
  fFormRow,
  fConfigForm,
  fTextbox,
  fLabel,
  htmlEditor,
  fTagbox,
  fTag,
  fNumberbox,
  fLookup,
  fCombobox,
  fCheckbox,
  fDatetimebox,
  fCrudForm,
  fUpload,
  fAlertBlock,
  fCol,
  fRow,
  fDialog,
  fAvatar,
  fDrawer,
  fTreeview,
  fTransfer,
  fPopover,
  fRadioButtonGroup,
  fSwitch,
  fBadge,
  fCard,
  fProgressbar,
  fAlert,
  fIframe,
  fFieldrow,
  fPanel,
  fGrid,
  fApp,
  fLink,
  FontAwesomeIcon,
  Person,
  Group,
  User,
  cpfCnpj,
  fPreview,
};

const registerComponents = (vue) => {
  Object.keys(Components)
    .forEach((name) => {
      vue.component(name, Components[name]);
    });

  // tabs do element-ui, o único componente que ainda nao consegui usar wrappers
  vue.use(Tabs);
  vue.use(TabPane);
  vue.use(VueSweetalert2);
  vue.component('f-tabs', Tabs);
  vue.component('f-tab-pane', TabPane);
  vue.component('f-dropdown', Dropdown);
  vue.component('f-dropdown-menu', DropdownMenu);
  vue.component('f-dropdown-item', DropdownItem);
  vue.component('f-table', Table);
  vue.component('f-table-column', TableColumn);
  vue.component('Produto', Produto);
  vue.component('Servico', Servico);
  // configura a linguagem do element-ui, no caso para o portugues brasil, o import está acima
  locale.use(lang);
};

const addMiddleware = (router) => {
  router.beforeEach(middlewares.authentication);
  router.afterEach(middlewares.clearQuery);
};

const addRoutes = (router) => {
  router.addRoutes([{
    path: '/notfound',
    name: 'Notfound',
    component: NotFound,
    meta: { requiresAuth: false },
  }]);
  router.addRoutes([{
    path: '/*.map',
    name: 'maps',
    meta: { requiresAuth: false },
  }]);
  router.addRoutes(authRouters);
  router.addRoutes(personRouters);
};

const addApp = (app, vue, router, store) => {
  if (app.name) {
    if (app.store) {
      store.registerModule(app.name, app.store);
    }
    if (app.routers && app.routers.length > 0) {
      router.addRoutes(app.routers);
    }
    if (app.registerComponents) {
      app.registerComponents(vue);
    }
  }
};

const addApps = (vue, router, store) => {
  addApp(AppReport, vue, router, store);
};

const registerDirectives = (vue) => {
  vue.use(Loading.directive);

  vue.directive('enter-as-tab', {
    bind(el) {
      el.addEventListener('keyup', (e) => {
        function findNextTabStop(el2) {
          const universe = document.querySelectorAll('input, button, select, textarea, a[href]');
          const list = Array.prototype.filter.call(universe, (item) => item.tabIndex >= '0');
          const index = list.indexOf(el2);
          return list[index + 1] || list[0];
        }

        if (e.key === 'Enter') {
          findNextTabStop(e.target).focus();
        }
      });
    },
  });
};

const addFilter = (vue) => {
  vue.filter('formatDataHora', (dataHora) => _.date().format(dataHora));
  vue.filter('formatNumero', (value) => _.number().formataNumero(value));
  vue.filter('formatDinheiro', (value, cod) => _.number().formataDinheiro(value, cod));
};

// registrar as libs globais, que sao acessadas a partir de qualquer componente
const registerGlobalLibs = (vue, store) => {
  vue.prototype.$loading = Loading.service;
  vue.prototype.$msgbox = MessageBox;
  vue.prototype.$alert = (msg, title, tipo) => {
    vue.swal({ text: msg, title: title || '', icon: tipo || 'info' });
  };
  vue.prototype.$confirm = MessageBox.confirm;
  vue.prototype.$prompt = MessageBox.prompt;
  vue.prototype.$notify = Notification;
  vue.prototype.$message = Message;

  // libs internas
  vue.prototype.$utils = Utils;
  window.$utils = Utils;
  vue.prototype.$publisher = new Publisher(); // publisher component pub/sub principle
  vue.prototype.$newDataset = (config) => new Dataset(config);
  vue.prototype.$newI18n = (dictionary) => new I18n(dictionary);

  // adiciona o rollbar para pegar os erros nao esperados somente em producao
  if (process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN && process.env.VUE_APP_MODE !== 'development') {
    vue.prototype.$rollbar = new Rollbar({
      accessToken: process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: false,
      environment: process.env.VUE_APP_MODE,
    });
  }

  vue.config.errorHandler = (err, vm) => {
    if (process.env.VUE_APP_MODE !== 'development') {
      vm.$rollbar.error(err);
    }
    throw err; // rethrow
  };

  vue.prototype.$isDev = () => process.env.VUE_APP_MODE === 'development';

  vue.prototype.$showMessage = (data) => {
    if (data.type === 'error') {
      vue.swal({ text: data.message, title: data.title || '', icon: 'error' });
      return;
    }
    store.dispatch('setMessage', {
      msg: data.message,
      type: data.type,
      time: data.time || 2000,
    });
  };

  vue.prototype.$notification = (data) => {
    Notification({
      title: data.title || '',
      message: data.message,
      type: data.type || 'info',
      duration: data.time || 3000,
    });
  };

  // retorna o host da página que está sendo exibida
  vue.prototype.$getHost = () => {
    const url = new URL(window.location.href);
    return `${url.protocol}//${url.hostname}${process.env.VUE_APP_API_PORT || ''}`;
  };

  // adiciona funcoes ao lodash
  window._.mixin({
    string: () => Utils.String,
    number: () => Utils.Number,
    date: () => ({
      diffInDays: Utils.Datetime.DiffInDays,
      format: Utils.Datetime.formatDatetime,
    }),
    moment,
    file: () => Utils.File,
    object: () => Utils.Object,
    general: () => Utils.General,
    array: () => Utils.Array,
    renderHtml: Utils.General.renderHtmlContent,
  });
};

const injectPrototypes = (vue, vuestore) => {
  // esse cabeçalho é usado por todas as instâncias dos componentes dataset por padrão
  Dataset.prototype.getHeaders = () => ({
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'access-control-allow-origin, access-control-allow-headers',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    Token: vuestore.getters.infoLogin.token,
    org: vuestore.getters.currentOrg.id,
  });

  Dataset.prototype.vue = vue;
};

// injete os estilos globais, utilizados por todas as aplicações
const globalStyles = (vue) => {
  vue.prototype.$style = {
    colorRed: '#DC2626',
    colorGreen: '#059669',
    colorDarkGreen: '#065F46',
    colorForestGreen: '#228B22',
    colorBlack: '#6B7280',
    colorBlue: '#2563EB',
    styleDesabilitado: { color: '#6B7280' },
    colorDarkBlue: '#00008B',
    colorYellow: '#FCD34D',
    colorOrange: '#F59E0B',
    colorOrangeRed: '#FF4500',
    colorDarkOrange: '#FF8C00',
    colorYellow900: '#78350F',
  };
};

/* eslint no-param-reassign: "error" */
export default {
  // o install instala tudo que é necessário e comum para as aplicações
  install(vue, store, router) {
    registerGlobalLibs(vue, store);
    registerComponents(vue, store);
    registerDirectives(vue);
    addFilter(vue);
    addRoutes(router);
    addMiddleware(router);
    injectPrototypes(vue, store);
    globalStyles(vue);
    addApps(vue, router, store);
  },
  utils: new Utils(),
  Dataset,
  fCrudForm,
};
