import Compressor from 'compressorjs';

export default class FileUtil {
  // convert image object into base64 with data url
  static getBase64WithDataFromFile(file) {
    return FileUtil.getDataUrlFromFile(file);
  }

  static getBase64OnlyFromFile(file) {
    const dataURL = FileUtil.getDataUrlFromFile(file);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  static async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static async getAsText(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static getDataUrlFromFile(file) {
    const canvas = document.createElement('canvas');
    canvas.width = file.width;
    canvas.height = file.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(file, 0, 0);
    return canvas.toDataURL('image/png');
  }

  static getBase64FromUrl(url, callback) {
    const request = new XMLHttpRequest();
    request.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(request.response);
    };
    request.open('GET', url);
    request.setRequestHeader('Access-Control-Allow-Origin', '*');
    request.setRequestHeader('Access-Control-Allow-Methods', 'GET, POST, OPTIONS');
    request.setRequestHeader('Access-Control-Allow-Headers',
      'Access-Control-Allow-Origin, Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, '
      + 'Access-Control-Request-Method, Access-Control-Request-Headers');

    request.responseType = 'blob';
    request.send();
  }

  static toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static isBase64(fileContent) {
    return fileContent.includes(';base64,');
  }

  /**
   * Diminui o tamanho de um arquivo passado como parametro
   * @param file - objecto arquivo a ser diminido o tamanho
   * @param options - opcoes para o componente, checar todas as opcoes disponíveis em
   * https://github.com/fengyuanchen/compressorjs#options
   * @returns - Objeto File com o novo tamanho ou arquivo original caso nao faca o processamento
   */
  static async compressImageFile(file, options) {
    let newFile = null;
    if (!options) {
      // eslint-disable-next-line no-param-reassign
      options = {};
    }
    // eslint-disable-next-line no-new
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality: options.quality || 0.6,
        maxWidth: options.maxWidth || 0,
        maxHeight: options.maxHeight || 0,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          newFile = result;
          newFile.localUrl = URL.createObjectURL(newFile);
          resolve(newFile);
        },
        error(err) {
          reject(err.message);
        },
      });
    });
  }

  /**
   * Verifica se um arquivo passado como parametro é uma imagem
   * @param file
   * @returns boolean - true se for uma imagem, caso contrário, false
   */
  static isImageFile(file) {
    if (!file.type) {
      return false;
    }
    return file.type.includes('image');
  }

  static downloadFile(link) {
    const element = document.createElement('a');
    element.setAttribute('href', link);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
