<template>
  <f-form ref="formValidation">
    <div
      class="flex flex-col"
      v-loading="service.loading"
      element-loading-text="Aguarde, enviando arquivos XML's..."
      element-loading-spinner="el-icon-loading"
    >
      <f-textbox
        caption='E-mail'
        type="email"
        v-model='emailEnviar'
        :validations="validations.email"
      />
      <div class="flex">
        <f-datetimebox
          caption='Data inicial'
          v-model='dataInicialEnviar'
          type='date'
          :validations="validations.dataInicial"
        />
        <f-datetimebox
          caption='Data final'
          v-model='dataFinalEnviar'
          type='date'
          :validations="validations.dastaFinal"
        />
      </div>
      <f-button
        caption="Enviar"
        type="success"
        @click="enviarXmls"
      />
    </div>
  </f-form>
</template>

<script>

import ConfigService from '../ConfigService';

export default {
  data() {
    return {
      service: null,
      emailEnviar: '',
      dataInicialEnviar: null,
      dataFinalEnviar: null,
      validations: {
        email: [
          {
            required: true,
            message: 'O e-mail é obrigatorio para usar esta função.',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 120,
            message: 'E-mail deve conter entre 8 e 120 caracteres.',
            trigger: 'blur',
          },
        ],
        dataInicial: [
          {
            required: true,
            message: 'Data inicial é obrigatoria para usar esta função.',
            trigger: 'blur',
          },
        ],
        dastaFinal: [
          {
            required: true,
            message: 'Data final é obrigatoria para usar esta função.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new ConfigService();
    this.emailEnviar = this.emailContador;
  },
  props: {
    emailContador: {
      type: String,
    },
  },
  methods: {
    enviarXmls() {
      if (!this.$refs.formValidation.validate()) {
        return;
      }
      this.$confirm('Enviar XMLs com os parametros informados?', 'Enviando XMLs', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'info',
      })
        .then((result) => {
          if (result === 'confirm') {
            this.service.enviarXmls(this.emailEnviar, this.dataInicialEnviar, this.dataFinalEnviar)
              .then(() => {
                this.$showMessage({
                  title: 'XMLs enviado!',
                  type: 'success',
                  message: 'Os XMLs foram enviados com sucesso!',
                });
                this.$emit('success', true);
              })
              .catch((err) => {
                this.$showMessage({
                  title: 'XMLs não foram enviados!',
                  type: 'error',
                  message: err,
                });
              });
          }
        });
    },
  },
  mounted() {
    this.dataInicialEnviar = _.moment().subtract(1, 'months').startOf('month').toDate();
    this.dataFinalEnviar = _.moment().subtract(1, 'months').endOf('month').toDate();
  },
};
</script>
