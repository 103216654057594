<style scoped>
</style>

<template>
  <el-drawer
    :title="title"
    :visible.sync="visible"
    :direction="direction"
    :custom-class="customClass"
    :with-header="showHeader"
    :size="size"
    @before-close="beforeClose"
  >
    <div slot="title">
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </el-drawer>
</template>

<script>
import { Drawer } from 'element-ui';

export default {
  name: 'fDrawer',
  components: {
    'el-drawer': Drawer,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'ltr',
    },
    customClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    beforeClose: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  watch: {
    value(value) {
      this.visible = value;
    },
    visible(value) {
      this.$emit('input', value);
    },
  },
};
</script>
