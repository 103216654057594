<template>
  <div>
    <f-textbox
      v-if="filter.type ==='textbox'"
      :value="value"
      :caption="filter.label"
      :width="filter.width"
      @input="$emit('input', $event)"
      @keyup="$emit('change', $event)"
    />
    <f-lookup
      v-if="filter.type ==='lookup'"
      :value="lookupValueInternal"
      :caption="filter.label"
      :width="filter.witdh"
      :component="filter.tela"
      :filters="filter.lookupFilter"
      :valueId="filter.value"
      :disableDetailPage="true"
      :readonly="filter.readonly === true"
      @value="inputLookup($event, filter)"
    />
    <f-combobox
      v-if="filter.type ==='combobox'"
      :value="value"
      :caption="filter.label"
      :options="filter.options"
      :inputStyle="{width: filter.width, marginRight : '10px'}"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />
    <f-datetimebox
      v-if="filter.type ==='datetime'"
      :value="value"
      :caption="filter.label"
      :width="filter.witdh"
      :inputStyle="{width: filter.width, marginRight : '10px'}"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />
    <f-datetimebox
      type="date"
      v-if="filter.type ==='date'"
      :value="value"
      :caption="filter.label"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />
    <f-checkbox
      v-if="filter.type ==='boolean'"
      :value="value"
      :caption="filter.label"
      @change="$emit('change', $event)"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'CrudFormFindFilters',
  data() {
    return {
      lookupValueInternal: '',
    };
  },
  mounted() {
    if (this.filter.type === 'lookup') {
      this.lookupValueInternal = this.value;
      this.$emit('lookup-value', this.value);
    }
  },
  methods: {
    inputLookup($event, filter) {
      this.lookupValueInternal = $event[filter.lookupFieldName];
      this.$emit('input', this.lookupValueInternal);
      this.$emit('lookup-value', $event.id);
    },
  },
  props: {
    value: {
      type: [String, Boolean, Date],
    },
    lookupValue: {
      type: [String, Boolean, Date],
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  watch: {
    value(newValue) {
      if (this.filter.type === 'lookup') {
        this.lookupValueInternal = newValue;
      }
    },
  },
};
</script>
