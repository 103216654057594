<template>
  <el-alert
    :title="title"
    :description="description"
    :type="type"
    :show-icon="showIcon"
    :center="center"
    :closable="closeable"
    :effect="effect"
    @close="$emit('close')"
  >
    <div class="text-sm">
      <slot></slot>
    </div>
  </el-alert>
</template>

<script>
import { Alert } from 'element-ui';

export default {
  components: {
    'el-alert': Alert,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    effect: {
      type: String,
      default: 'light',
    },
    time: {
      type: [Number, String],
      default: null,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>
