export default (org) => {
  const menus = [];
  if (org.permissoes) {
    if (org.permissoes.includes('auth.usuario')) {
      menus.push({
        text: 'Usuarios',
        description: 'Controle de Usuários',
        route: '/usuarios',
        // eslint-disable-next-line global-require
        img: require('./assets/user32x32.png'),
      });
    }

    if (org.permissoes.includes('auth.grupo')) {
      menus.push({
        text: 'Grupos',
        description: 'Grupos de Usuários',
        route: '/grupos',
        // eslint-disable-next-line global-require
        img: require('./assets/userGroup32x32.png'),
      });
    }

    if (org.permissoes.includes('auth.acesso_externo')) {
      menus.push({
        text: 'Acesso Externo',
        description: 'Gerenciamento de Tokens para acesso externo',
        route: '/tokens',
        // eslint-disable-next-line global-require
        img: require('./assets/token_access.png'),
      });
    }
    if (menus.length >= 1) {
      return {
        text: 'Segurança',
        description: 'Módulo com funções sobre seguranca',
        route: '/users',
        // eslint-disable-next-line global-require
        img: require('./assets/seguranca.png'),
        items: menus,
      };
    }
  }
  return null;
};
