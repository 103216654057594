<template>
  <f-crud-form
    formParentField="address"
    mode="child"
    :schema="service.addressSchema"
    :formParent="formParent"
    :formsValidation="[$refs.formAddress]"
  >
    <f-form ref="formAddress">
      <div>
        <f-row>
          <f-col col="12">
            <f-form ref="formAddress">
              <f-form-row>
                <f-datetimebox
                  caption='Criado em'
                  :value='service.addressSchema.record.createdAt'
                  v-show='service.addressSchema.record.createdAt'
                  :disabled="true"
                />
                <f-datetimebox
                  caption='Última Alteração'
                  :value='service.addressSchema.record.updatedAt'
                  v-show='service.addressSchema.record.updatedAt'
                  :disabled="true"
                />
              </f-form-row>
              <f-form-row>
                <Endereco
                  :cep="service.addressSchema.record.zipCode"
                  :endereco="service.addressSchema.record.address"
                  :bairro="service.addressSchema.record.neighborhood"
                  :numero="service.addressSchema.record.number"
                  :uf="service.addressSchema.record.state"
                  :cidade="service.addressSchema.record.city"
                  :cidade-codigo="service.addressSchema.record.cityCode"
                  @cep="service.addressSchema.record.zipCode = $event"
                  @endereco="service.addressSchema.record.address = $event"
                  @bairro="service.addressSchema.record.neighborhood = $event"
                  @numero="service.addressSchema.record.number = $event"
                  @uf="service.addressSchema.record.state = $event"
                  @cidade="service.addressSchema.record.city = $event"
                  @cidadeCodigo="service.addressSchema.record.cityCode = $event"
                />
              </f-form-row>
              <f-textbox
                caption="IE"
                style="width: 200px;"
                v-model='service.addressSchema.record.stateIdent'
                type="number"
              />
            </f-form>
          </f-col>
        </f-row>
      </div>
    </f-form>
  </f-crud-form>
</template>

<script>
import Utils from 'comps/custom/UtilsMixing';
import Endereco from './Endereco';

export default {
  name: 'PessoaEndereco',
  mixins: [
    Utils,
  ],
  components: {
    Endereco,
  },
  data() {
    return {
      validations: {
        address: [
          {
            min: 2,
            max: 60,
            message: 'Endereço deve conter entre 2 e 60 caracteres.',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Endereço é obrigatório.',
            trigger: 'blur',
          },
        ],
        number: [
          {
            min: 1,
            max: 60,
            message: 'Número deve conter entre 1 e 60 caracteres.',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Número é obrigatório.',
            trigger: 'blur',
          },
        ],
        zipCode: [
          {
            min: 8,
            max: 8,
            message: 'CEP deve conter 8 digitos',
            trigger: 'blur',
          },
        ],
        neighborhood: [
          {
            min: 1,
            max: 60,
            message: 'Bairro deve conter entre 1 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        city: [
          {
            min: 2,
            max: 60,
            message: 'Cidade deve conter entre 2 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        cityCode: [
          {
            min: 7,
            max: 7,
            message: 'Código da cidade deve conter exatamente 7 caracteres.',
            trigger: 'blur',
          },
        ],
        state: [
          {
            min: 2,
            max: 2,
            message: 'UF deve conter exatamente 2 caracteres.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  props: {
    service: {
      type: Object,
      default: () => {
      },
    },
    formParent: {
      type: Object,
      required: true,
    },
  },
};
</script>
