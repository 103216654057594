<template>
  <LoginArea class="login-area">
    <slot>
      <h2>{{ i18n.get('confirmationTitle') }}</h2>
    </slot>

    <f-form
      v-loading="service.loading"
      element-loading-text="Aguarde..."
      element-loading-spinner="el-icon-loading"
      class="login-form"
      ref="form"
    >
      <f-textbox
        type="email"
        v-model="model.email"
        :caption="i18n.get('fieldEmail')"
        :validations="validations.email"
      />
      <f-textbox
        :caption="i18n.get('confirmationFieldCodeCaption')"
        v-model="model.confirmationCode"
        ref="confirmationCodeInput"
        icon="fas fa-user"
        :validations="validations.confirmationCode"
      />
      <f-button :caption="i18n.get('confirmationButtonCaption')" @click="confirmation"/>
      <f-row :gutter="20" type="flex" class="row-bg" justify="space-between">
        <f-col>
          <f-link class="" @click="routeToLogin">{{ i18n.get('backLoginPage') }}</f-link>
        </f-col>
      </f-row>
    </f-form>
    <f-alert-block
      v-if="successMsg.length || errorMsg.length || infoMsg.length"
      :success="successMsg"
      :errors="errorMsg"
      :info="infoMsg"
    />
  </LoginArea>
</template>

<script>
import LoginArea from './LoginArea';

export default {
  name: 'login',
  mixins: [
    LoginArea,
  ],
  components: {
    LoginArea,
  },
  created() {
    if (this.$route.params) {
      this.model.email = this.$route.params.username;
    }
  },
  mounted() {
    this.$refs.confirmationCodeInput.focus();
  },
  data() {
    return {
      model: {
        email: '',
        confirmationCode: '',
        loading: false,
      },
    };
  },
  methods: {
    async confirmation() {
      this.resetMessages();
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.model.loading = true;
      this.service.confirmation(this.model.email, this.model.confirmationCode)
        .then((response) => {
          this.successMsg = [response.data];
          this.routeToLogin(2000);
        })
        .catch((err) => {
          if (err.code === 'ALREADY_CONFIRMED') {
            this.infoMsg = [err.message];
            this.routeToLogin(2000);
          } else {
            this.errorMsg = [err.message];
          }
        });
    },
  },
};
</script>
