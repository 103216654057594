<template>
  <div>
    <DxDataGrid
      ref="grid"
      class="w-full"
      key-expr="id"
      :data-source="data"
      :remote-operations="false"
      :allow-column-reordering="allowColumnReordering"
      :row-alternation-enabled="rowAlternationEnabled"
      :selecionSingle="selecionSingle"
      :show-borders="true"
      :columnAutoWidth="columnAutoWidth"
      :selected-row-keys="selectedRowsKeys"
      :allowSorting="allowSorting"
      :allowColumnResizing="allowColumnResizing"
      @selection-changed="onSelectionChanged"
      @saving="onSaving"
      @row-dbl-click="dblClick"
      @row-click="rowClick"
      @row-validating="rowValidating"
      @cell-prepared="onCellPrepared"
      @content-ready="contentReady"
    >
      <DxPaging :enabled="paging" :page-size="pageSize" :page-index="pageIndex"/>
      <DxPager
        :visible="paging"
      />
      <DxSearchPanel
        :visible="searchPainel"
        :width="240"
        placeholder="Procurar..."
      />
      <DxSelection
        :recursive="false"
        :mode="selecionSingle ? 'single' : 'multiple'"
      />
      <DxEditing
        :allow-updating="true"
        :allow-adding="allowEdit"
        :allow-deleting="false"
        mode="cell"
      />
      <DxStateStoring
        :enabled="id !== ''"
        type="localStorage"
        :storage-key="id"
      />
      <DxColumnChooser :enabled="columnChooser"/>
      <DxColumn
        v-for="col in gridColumns" :key="col.name"
        :alignment="col.alignment"
        :data-field="col.dataField"
        :data-type="col.type"
        :caption="col.caption"
        :visible="col.visible"
        :format="col.format"
        :allow-editing="col.allowEdit"
        :allow-sorting="col.allowSorting"
      >
      </DxColumn>
      <DxColumn
        v-if="deleteAction"
        caption="Ações"
        cell-template="cell-template-actions"
      />
      <template #cell-template-actions="{ data }">
        <div>
          <f-button caption="" @click="deleteRecord(data)">
            <FontAwesomeIcon icon="trash" />
          </f-button>
        </div>
      </template>
      <slot></slot>
    </DxDataGrid>
  </div>
</template>

<script>
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
import ptBrMessages from 'devextreme/localization/messages/pt.json';

import { locale, loadMessages } from 'devextreme/localization';

import {
  DxColumn, DxDataGrid, DxEditing, DxPager, DxPaging, DxSelection, DxStateStoring, DxColumnChooser, DxSearchPanel,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxColumn,
    DxEditing,
    DxPaging,
    DxPager,
    DxStateStoring,
    DxColumnChooser,
    DxSearchPanel,
  },
  props: {
    data: {
      type: Array,
      required: false,
      selectedRows: [],
      default() {
        return [];
      },
    },
    columns: {
      type: Array,
      required: true,
    },
    height: {
      default: '100%',
    },
    id: { // identificacao do grid
      default: '',
    },
    filterRow: {
      default: true,
    },
    selecionSingle: {
      default: false,
    },
    allowColumnReordering: {
      default: true,
    },
    allowColumnResizing: {
      default: true,
    },
    columnChooser: {
      default: false,
    },
    columnAutoWidth: {
      default: true,
    },
    allowSorting: {
      default: true,
    },
    showFilterRow: {
      default: true,
    },
    searchPainel: {
      default: false,
    },
    allowEdit: {
      default: false,
    },
    rowAlternationEnabled: {
      default: true,
    },
    styleCell: {
      type: Function,
      default: null,
    },
    styleRow: {
      type: Function,
      default: null,
    },
    deleteAction: {
      type: Function,
      default: null,
    },
    pageSize: {
      type: Number,
      default: 50,
    },
    paging: {
      default: true,
    },
  },
  methods: {
    getLocale() {
      const storageLocale = sessionStorage.getItem('locale');
      return storageLocale != null ? storageLocale : 'pt';
    },
    setLocale(savingLocale) {
      sessionStorage.setItem('locale', savingLocale);
    },
    onSelectionChanged({ selectedRowsData }) {
      this.selectedRows = selectedRowsData;
      this.$emit('change', selectedRowsData);
    },
    onSaving(e) {
      this.$emit('saving', e.changes[0]);
    },
    focus() {
      this.$refs.grid.instance.focus();
    },
    forceSave() {
      this.$forceUpdate();
    },
    selectAllRows() {
      this.dataGrid.selectAll();
    },
    unSelectRows() {
      // doc
      // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Methods/#selectRowskeys_preserve
      this.dataGrid.selectRows(null, false);
    },
    dblClick() {
      this.$emit('dblClick');
    },
    rowValidating(row) {
      this.$emit('rowValidating', row);
    },
    rowClick() {
      this.$emit('rowClick');
    },
    goToLastPage() {
      const pageCount = this.$refs.grid.instance.pageCount();
      this.pageIndex = pageCount - 1;
    },
    async deleteRecord(record) {
      const resp = await this.$confirm('Excluir Registro?', 'Pergunta', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'error',
      });
      if (resp === 'confirm') {
        this.deleteAction(record.data);
      }
    },
    onCellPrepared(options) {
      if (this.styleCell && options.data && options.cellElement) {
        const style = this.styleCell(options.column.dataField, options.data);
        if (style) {
          if (typeof (style) === 'string') {
            // eslint-disable-next-line no-param-reassign
            options.cellElement.innerHTML = style;
          } else Object.assign(options.cellElement.style, style);
        }
      }
    },
    contentReady() {
      const pageCount = this.$refs.grid.instance.pageCount();
      if (pageCount !== this.pageCount) {
        this.$emit('new-page', pageCount);
      }
    },
  },
  computed: {
    dataGrid() {
      return this.$refs.grid.instance;
    },
    gridColumns() {
      const columns = [];
      Object.keys(this.columns)
        .forEach((key) => {
          const col = this.columns[key];
          const gridColumn = {
            dataField: col.field,
            caption: col.title,
            visible: col.visible,
            allowSorting: this.allowSorting ? col.allowSorting || true : false,
            allowFiltering: col.allowFiltering || false,
            allowGrouping: col.allowGrouping || false,
            allowReordering: col.allowReordering || true,
            allowEdit: this.allowEdit ? true : col.editable || false,
            format: col.format,
            type: col.type,
            width: col.width || '',
            alignment: col.alignment,
          };
          if (col.type === 'datetime' && !col.format) {
            gridColumn.format = 'dd/MM/yyyy HH:mm:ss';
          } else if (col.type === 'date' && !col.format) {
            gridColumn.format = 'dd/MM/yyyy';
          } else if (col.type === 'currency') {
            gridColumn.alignment = 'right';
            gridColumn.type = 'currency';
            gridColumn.format = 'R$ #,##0.00';
            if (col.decimals > 2) {
              gridColumn.format = `R$ #,##0.${'0'.repeat(col.decimals)}`;
            }

            gridColumn.editorOptions = {
              format: 'R$ #,##0.##',
            };
          } else if (col.type === 'currency-no-symbol' || col.type === '%') {
            gridColumn.alignment = 'right';
            gridColumn.type = 'currency';
            gridColumn.format = '#,##0.00';
            if (col.decimals > 2) {
              gridColumn.format = `#,##0.${'0'.repeat(col.decimals)}`;
            }

            gridColumn.editorOptions = {
              format: 'R$ #,##0.##',
            };
          } else if (col.type === 'number') {
            gridColumn.alignment = 'right';
            gridColumn.format = {
              type: 'decimal',
              // precision: col.precision || 2,
            };
          }
          columns.push(gridColumn);
        });
      return columns;
    },
  },
  mounted() {
    Object.keys(this.data).forEach((key) => {
      const item = this.data[key];
      if (item.check && item.id) {
        this.selectedRowsKeys.push(item.id);
      }
    });
  },
  created() {
    loadMessages(ptBrMessages);
    locale('pt');
  },
  data() {
    return {
      locale: null,
      clickTimer: 0,
      lastRowCLickedId: {},
      selectedRowsKeys: [],
      isLastRecord: false,
      pageIndex: 0,
      pageCount: 1,
      currentRow: {
        rowIndex: 0,
        colIndex: 0,
        item: null,
      },
      selectedRows: [],
    };
  },
};
</script>
