<template>
  <el-switch
    :value="value"
    @input="$emit('input', $event)"
    :active-text="activeText"
    :inactive-text="inactiveText"
    :active-color="activeColor"
    :inactive-color="inactiveColor"
  >
  </el-switch>
</template>

<script>
import { Switch } from 'element-ui';

export default {
  components: {
    'el-switch': Switch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    activeText: {
      type: String,
      default: '',
    },
    inactiveText: {
      type: String,
      default: '',
    },
    activeColor: {
      type: String,
      default: '',
    },
    inactiveColor: {
      type: String,
      default: '',
    },
  },
  computed: {
    name() {
      return this.data;
    },
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>
