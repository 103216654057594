<template>
  <div>
    <f-row>
      <div class="flex flex-wrap items-center justify-around shadow-sm rounded border p-3 ml-4 mb-3">
        <certificadoDigital class="mr-5" />
        <f-button
          caption="Enviar XML's da empresa"
          type="success"
          @click="mostraEnviaXmls = true"
        >
          <FontAwesomeIcon icon="envelope" />
        </f-button>
      </div>
      <f-col col="12">
        <f-row>
          <f-form-row>
            <f-combobox
              caption='Regime tributario'
              v-model='service.record.dfe.regimeTributario'
              :options="regimeTributarioOpcoes"
            />
            <f-lookup
              v-model="service.record.dfe.tributacaoNome"
              caption='Tributacao padrão'
              component="Tributacao"
              @value="setTributacao"
              :value-id="service.record.dfe.tributacaoId"
            />
          </f-form-row>
        </f-row>
      </f-col>
      <f-row>
        <f-col col="12">
          <f-tabs>
            <f-tab-pane label="NF-e">
              <div class="flex">
                <f-card title="Emissão de Nota">
                  <f-form-row>
                    <f-button
                      caption="Verificar Status da NF-e direto com o SEFAZ"
                      type="success"
                      @click="mostraStatus = true; tipoStatus = 'nfe'"
                    >
                      <FontAwesomeIcon icon="sync" />
                    </f-button>
                  </f-form-row>
                  <f-form-row>
                    <f-combobox
                      caption='Ambiente NF-e'
                      v-model='service.record.dfe.nfeConfig.ambientePadrao'
                      :options="tipoAmbienteOpcoes"
                    />
                  </f-form-row>
                  <f-form-row>
                    <f-textbox
                      caption='Série padrão NF-e'
                      v-model='service.record.dfe.nfeConfig.seriePadrao'
                      :fieldStyle="{width: '120px'}"
                    />
                  </f-form-row>
                  <f-form-row>
                    <f-textbox
                      caption='Observação padrão'
                      v-model='service.record.dfe.obs'
                      type="textarea"
                      :fieldStyle="{width: '500px'}"
                    />
                  </f-form-row>
                </f-card>
                <f-card title="Nota de Entrada" class="ml-3">
                  <f-checkbox
                    caption="Baixar notas de entrada automaticamente"
                    v-model="service.record.dfe.nfeConfig.entradaBuscaAutomatica"
                  />
                  <f-textbox
                    caption='NSU'
                    type="number"
                    v-model='service.record.dfe.nfeConfig.entradaNSU'
                    :fieldStyle="{width: '120px'}"
                  />
                  <f-numberbox caption='Aliq. ICMS cálculo diferença' v-model='service.record.dfe.aliqIcmsCalcDif' />
                </f-card>
              </div>
            </f-tab-pane>
            <f-tab-pane label="NFC-e">
              <f-form-row>
                <f-button
                  caption="Verificar Status da NFC-e direto com o SEFAZ"
                  type="success"
                  @click="mostraStatus = true; tipoStatus = 'nfce'"
                >
                  <FontAwesomeIcon icon="sync" />
                </f-button>
              </f-form-row>
              <f-form-row>
                <f-combobox
                  caption='Ambiente NFC-e'
                  v-model='service.record.dfe.nfceConfig.ambientePadrao'
                  :options="tipoAmbienteOpcoes"
                />
              </f-form-row>
              <f-form-row>
                <f-textbox
                  caption='Série padrão NFC-e'
                  v-model='service.record.dfe.nfceConfig.seriePadrao'
                  :fieldStyle="{width: '130px'}"
                />
                <f-textbox
                  caption='Código CSC'
                  v-model='service.record.dfe.nfceConfig.csc'
                  :fieldStyle="{width: '400px'}"
                />
                <f-textbox
                  caption='Token'
                  type="number"
                  v-model='service.record.dfe.nfceConfig.token'
                />
              </f-form-row>
            </f-tab-pane>
            <f-tab-pane label="MDF-e">
              <!-- <f-form-row> // Comentado por enquanto ja que a consulta MDFE nao deu certo
                <f-button
                  caption="Verificar Status da MDF-e direto com o SEFAZ"
                  type="success"
                  @click="mostraStatus = true; tipoStatus = 'mdfe'"
                >
                  <FontAwesomeIcon icon="sync" />
                </f-button>
              </f-form-row> -->
              <f-form-row>
                <f-combobox
                  caption='Ambiente MDF-e'
                  v-model='service.record.dfe.mdfeConfig.ambientePadrao'
                  :options="tipoAmbienteOpcoes"
                />
              </f-form-row>
              <f-form-row>
                <f-textbox
                  caption='Série padrão MDF-e'
                  v-model='service.record.dfe.mdfeConfig.seriePadrao'
                  type='number'
                  :fieldStyle="{width: '130px'}"
                />
              </f-form-row>
            </f-tab-pane>
            <f-tab-pane label="Dados Contador">
              <f-form-row>
                <f-textbox
                  caption='Nome do contador'
                  v-model='service.record.dfe.contador.nome'
                  :fieldStyle="{width: '400px'}"
                  :validations="validations.contadorNome"
                />
                <f-textbox
                  caption='CPF do contador'
                  v-model='service.record.dfe.contador.cpfCnpj'
                  :fieldStyle="{width: '200px'}"
                  :validations="validations.contadorCpf"
                />
                <f-textbox
                  caption='CNPJ do escritório'
                  v-model='service.record.dfe.contador.cnpjEscritorio'
                  :fieldStyle="{width: '200px'}"
                  :validations="validations.contadorCnpjEscritorio"
                />
              </f-form-row>
              <f-form-row>
                <f-textbox
                  caption='CRC'
                  v-model='service.record.dfe.contador.crc'
                />
                <f-textbox
                  caption='Telefone'
                  v-model='service.record.dfe.contador.telefone'
                />
                <f-textbox
                  type="email"
                  caption='E-mail do contador'
                  :fieldStyle="{width: '400px'}"
                  v-model='service.record.dfe.contador.email'
                />
              </f-form-row>
              <Endereco
                :allRequired="false"
                :cep="service.record.dfe.contador.endCep"
                :endereco="service.record.dfe.contador.endEndereco"
                :bairro="service.record.dfe.contador.endBairro"
                :numero="service.record.dfe.contador.endNumero"
                :uf="service.record.dfe.contador.endUf"
                :cidade="service.record.dfe.contador.endCidade"
                :cidade-codigo="service.record.dfe.contador.endCidadeCodigo"
                @cep="service.record.dfe.contador.endCep = $event"
                @endereco="service.record.dfe.contador.endEndereco = $event"
                @bairro="service.record.dfe.contador.endBairro = $event"
                @numero="service.record.dfe.contador.endNumero = $event"
                @uf="service.record.dfe.contador.endUf = $event"
                @cidade="service.record.dfe.contador.endCidade = $event"
                @cidadeCodigo="service.record.dfe.contador.endCidadeCodigo = $event"
              />
            </f-tab-pane>
          </f-tabs>
        </f-col>
      </f-row>
    </f-row>
    <f-dialog
      v-model="mostraStatus"
      width="50%"
      title="Verificar Status direto com o SEFAZ"
    >
      <ConsultaStatusWs v-if="mostraStatus" :tipo="tipoStatus"/>
    </f-dialog>
    <f-dialog v-model="mostraEnviaXmls" width="370px" title="Envio de XMLs" :closeOnClickOutside="false">
      <EnviaXmls
        v-if="mostraEnviaXmls"
        :emailContador="service.record.dfe.contador.email"
        @success="mostraEnviaXmls = false"
      />
    </f-dialog>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue';
import Utils from 'comps/custom/UtilsMixing';
import Endereco from 'comps/apps/person/Endereco';
import Tributacao from '../../dfe/tributacao/Tributacao';
import CertificadoDigital from './CertificadoDigital';
import ConsultaStatusWs from './ConsultaStatusWs';
import EnviaXmls from './EnviaXmls';

library.add(faEnvelope);

Vue.component('Tributacao', Tributacao);
export default {
  components: {
    CertificadoDigital,
    ConsultaStatusWs,
    EnviaXmls,
    Endereco,
  },
  props: {
    service: {
      type: Object,
      default: () => {
      },
    },
  },
  mixins: [
    Utils,
  ],
  data() {
    return {
      mostraEnviaXmls: false,
      file: '',
      mostraStatus: false,
      tipoStatus: 'nfe',
      validations: {
        obs: [
          {
            min: 1,
            max: 2000,
            message: 'Obs deve ter entre 1 e 2000 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteCpfCnpj: [
          {
            min: 14,
            max: 18,
            message: 'CNPJ ou cpf do emissor deve conter entre 11 e 14 caracteres.',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'CNPJ do emissor é obrigatório.',
            trigger: 'blur',
          },
        ],
        ie: [
          {
            min: 6,
            max: 14,
            message: 'IE do emissor deve conter entre 6 e 14 caracteres.',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'IE do emissor é obrigatório.',
            trigger: 'blur',
          },
        ],
        tributacao: [
          {
            required: true,
            message: 'Tributacao do emissor é obrigatório.',
            trigger: 'blur',
          },
        ],
        regimeTributario: [
          {
            required: true,
            message: 'Regime Tributário é obrigatório.',
            trigger: 'blur',
          },
        ],
        emitenteNome: [
          {
            required: true,
            message: 'Nome fantasia do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 60,
            message: 'Nome fantasia deve ter entre 2 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteNome2: [
          {
            required: true,
            message: 'Razao social do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 60,
            message: 'Nome fantasia deve ter entre 2 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrZip: [
          {
            required: true,
            message: 'CEP do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 8,
            message: 'CEP deve ter exatamente 8 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrStreet: [
          {
            required: true,
            message: 'Rua do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Endereço/Rua deve conter entre 3 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrNumber: [
          {
            required: true,
            message: 'Numero residencial do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            max: 120,
            message: 'Numero residencial deve ter no maximo 120 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrNeighborhood: [
          {
            required: true,
            message: 'Bairro do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Bairro deve conter entre 3 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrCity: [
          {
            required: true,
            message: 'Cidade do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Cidade deve conter entre 3 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        emitenteAddrCityCode: [
          {
            required: true,
            message: 'Codigo da cidade do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 7,
            max: 7,
            message: 'Código da cidade deve conter exatamente 7 caracteres.',
            trigger: 'blur',
          },
        ],
        emitenteAddrState: [
          {
            required: true,
            message: 'UF do emissor é obrigatório.',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 2,
            message: 'UF deve conter exatamente 2 caracteres.',
            trigger: 'blur',
          },
        ],
        email: [
          {
            min: 3,
            max: 120,
            message: 'E-mail deve conter entre 3 e 120 caracteres.',
            trigger: 'blur',
          },
        ],
        telefone: [
          {
            min: 8,
            max: 11,
            message: 'Telefone deve conter entre 8 e 11 caracteres.',
            trigger: 'blur',
          },
        ],
        contadorNome: [
          {
            min: 3,
            max: 120,
            message: 'Nome do contador deve conter entre 3 e 120 caracteres.',
            trigger: 'blur',
          },
        ],
        contadorCpf: [
          {
            min: 8,
            max: 11,
            message: 'CPF do contador deve conter entre 8 e 11 caracteres.',
            trigger: 'blur',
          },
        ],
        contadorCnpjEscritorio: [
          {
            min: 14,
            max: 14,
            message: 'CNPJ do escritorio de contabilidade deve conter 14 dígitos.',
            trigger: 'blur',
          },
        ],
      },
      tipoAmbienteOpcoes: [
        {
          text: 'PRODUCAO',
          value: 'p',
        },
        {
          text: 'HOMOLOGAÇÃO',
          value: 'h',
        },
      ],
      regimeTributarioOpcoes: [
        {
          text: 'SIMPLES',
          value: 'SIMPLES',
        },
        {
          text: 'NORMAL',
          value: 'NORMAL',
        },
        {
          text: 'REAL',
          value: 'REAL',
        },
        {
          text: 'INDIVIDUAL',
          value: 'INDIVIDUAL',
        },
      ],
    };
  },
  computed: {
    tipo() {
      if (this.uri === 'nfeEntrada') {
        return 'nfeEntrada';
      }
      if (this.uri === 'nfce') {
        return 'nfce';
      }
      return 'nfe';
    },
  },
  methods: {
    save(record) {
      this.$store.dispatch('setConfig', record);
    },
    setTributacao(record) {
      this.service.record.dfe.tributacaoId = record.id;
      this.service.record.dfe.tributacaoNome = record.nome;
    },
  },
};

</script>
