<template>
  <f-form-row>
    <f-dialog
      width="20%"
      :value="value"
      title="CALCULO DE METRAGEM"
      @input="input"
    >
      <div class="flex flex-column">
        <div style="display: flex; flex-flow: column wrap" v-cloak v-enter-as-tab>
          <f-numberbox
            caption="Comprimento (m)"
            v-model="model.comprimento"
            ref="comprimento"
            :autofocus="true"
            @keyup="calculaComprimentoLargura"
          />
          <f-numberbox
            caption="Largura (m)"
            v-model="model.largura"
            @keyup="calculaComprimentoLargura($event,'largura')"
          />
          <f-numberbox
            caption="Medida (m)"
            :value="model.medida"
            :disabled="true"
            :inputStyle="$style.styleDesabilitado"
          />
          <f-numberbox
            caption="Valor Unitário"
            v-model="model.valorUnitarioInterno"
            decimals="5"
            @keyup="calculaComprimentoLargura"
          />
          <f-numberbox
            caption="Total"
            v-model="model.total"
            :disabled="true"
            decimals="7"
            :inputStyle="$style.styleDesabilitado"
          />
          <f-button caption="OK" @click="calculoComprimentoLarguraOK"/>
        </div>
      </div>
    </f-dialog>
  </f-form-row>
</template>

<script>
export default {
  name: 'CalculaMetragem',
  props: {
    valorUnitario: {
      type: Number,
      default: 0,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        comprimento: 0,
        largura: 0,
        medida: 0,
        total: 0,
        valorUnitarioInterno: 0,
      },
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.reset();
        this.model.valorUnitarioInterno = this.valorUnitario;
        this.$nextTick(() => {
          this.$refs.comprimento.focus();
        });
      }
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    calculaComprimentoLargura(event, componente) {
      if (componente === 'largura' && event.key === 'Enter') {
        this.calculoComprimentoLarguraOK();
      } else {
        this.model.medida = _.round(this.model.comprimento * this.model.largura, 2);
        this.model.total = this.model.valorUnitarioInterno * this.model.medida;
      }
    },
    calculoComprimentoLarguraOK() {
      this.$emit('input', false);
      this.$emit('concluido', this.model);
    },
    reset() {
      this.model = {
        comprimento: 0,
        largura: 0,
        medida: 0,
        total: 0,
        valorUnitarioInterno: 0,
      };
    },
  },
};
</script>
