<template>
  <f-row>
    <f-col
      col="12"
      v-loading="loading"
      element-loading-text="Aguarde..."
      element-loading-spinner="el-icon-loading"
    >
      <f-form ref="form" @submit="cancela">
        <p class="text-center mb-4">{{ title }}</p>
        <div class="flex content-center items-center">
          <f-textbox
            :autofocus="true"
            caption='Motivo Cancelamento'
            width="100%"
            v-model='motivoCancelamento'
            :validations="validations.cancelamentoMotivo"
          />
          <f-button caption="OK" @click="cancela" class="mt-4"/>
        </div>
      </f-form>
    </f-col>
  </f-row>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      motivoCancelamento: null,
      validations: {
        cancelamentoMotivo: [
          {
            required: true,
            message: 'Motivo cancelamento é obrigatório',
            trigger: 'blur',
          },
          {
            min: 15,
            max: 60,
            message: 'Motivo cancelamento deve ter entre 15 e 60 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  props: {
    function: {
      type: Function,
      default: function func() {
        return new Promise(() => {
          this.$emit('cancela', this.motivoCancelamento);
        });
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    async cancela() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const result = await this.$confirm('Confirma cancelamento?', 'Cancelamento', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'error',
      });
      if (result === 'confirm') {
        this.loading = true;
        this.function(this.motivoCancelamento)
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
