<template>
  <f-crud-form
    ref="formGroup"
    v-if="service"
    v-on="$listeners"
    permissao="auth.grupo"
    :schema="service"
    :enableButtonDelete="!isGrupoPadrao"
    :formsValidation="[$refs.formValidation]"
    @beforeSave="podeDispararEventoCheck = false"
    @save="setCheckedKeys"
    @loadRecord="setCheckedKeys"
    @newRecord="setCheckedKeys"
  >
    <f-row>
      <f-col col="6">
        <f-form ref="formValidation">
          <f-textbox
            caption='Name'
            v-model='service.record.name'
            :disabled="isGrupoPadrao"
            :validations="validations.name"
          />
          <f-alert
            type="warning"
            v-if="isGrupoPadrao"
            :closeable="false"
          >
            Esse é um grupo PADRÂO não pode ser alterado o nome, permissões ou excluído
          </f-alert>
        </f-form>
        <f-tabs>
          <f-tab-pane label="Usuários">
            <f-lookup
              v-model="userName"
              caption='Adiciona Usuário'
              component="User"
              :value-id="userId"
              @value="setUser"
            />
            <f-crud-form
              mode="child"
              v-if="formGroup"
              form-parent-field="users"
              :formParent="formGroup"
              :schema="service.userSchema"
            >
              <f-textbox
                caption='Name'
                v-model='service.userSchema.record.name'
                :disabled="true"
                :validations="validations.name"
              />
              <f-textbox
                caption='E-mail'
                type="email"
                v-model='service.userSchema.record.email'
                :disabled="true"
              />
            </f-crud-form>
          </f-tab-pane>
          <f-tab-pane label="Permissões">
            <f-treeview
              ref="tree"
              :data="treeViewData"
              :defaultExpandAll="true"
              :default-checked-keys="checkedKeys"
              @check-change="check"
            />
          </f-tab-pane>

        </f-tabs>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import GroupService from './GroupService';

export default {
  created() {
    this.service = new GroupService();
  },
  mounted() {
    this.formGroup = this.$refs.formGroup;
  },
  data() {
    return {
      service: null,
      userId: '',
      userName: '',
      userEmail: '',
      formGroup: null,
      podeDispararEventoCheck: false,
      checkedKeys: [],
      validations: {
        name: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    treeViewData() {
      const permissoes = [];
      const disabled = this.isGrupoPadrao;
      const cadastros = {
        label: 'Cadastros',
        children: [
          {
            label: 'Pessoas',
            children: [
              { label: 'Incluir', id: 'pessoa.cria', disabled },
              { label: 'Alterar', id: 'pessoa.altera', disabled },
              { label: 'Excluir', id: 'pessoa.exclui', disabled },
              { label: 'Registros de todos os usuários', id: 'pessoa.todos_usuarios', disabled },
              { label: 'Relatórios', id: 'pessoa.relatorio', disabled },
              { label: 'Cliente', id: 'pessoa.cliente', disabled },
              { label: 'Fornecedor', id: 'pessoa.fornecedor', disabled },
              { label: 'Vendedor', id: 'pessoa.vendedor', disabled },
              { label: 'Funcionário', id: 'pessoa.funcionario', disabled },
              { label: 'Transportador', id: 'pessoa.transportador', disabled },
              { label: 'Importação de Dados', id: 'pessoa.importacao', disabled },
            ],
          },
          {
            label: 'Grupo de Pessoas',
            children: [
              { label: 'Incluir', id: 'grupopess.cria', disabled },
              { label: 'Alterar', id: 'grupopess.altera', disabled },
              { label: 'Excluir', id: 'grupopess.exclui', disabled },
              { label: 'Consulta', id: 'grupopess.consulta', disabled },
            ],
          },
          {
            label: 'Etiquetas',
            children: [
              { label: 'Incluir', id: 'etiqueta.cria', disabled },
              { label: 'Alterar', id: 'etiqueta.altera', disabled },
              { label: 'Excluir', id: 'etiqueta.exclui', disabled },
            ],
          },
          {
            label: 'Serviços',
            children: [
              { label: 'Incluir', id: 'servico.cria', disabled },
              { label: 'Alterar', id: 'servico.altera', disabled },
              { label: 'Excluir', id: 'servico.exclui', disabled },
              { label: 'Relatórios', id: 'servico.relatorio', disabled },
            ],
          },
        ],
      };
      const Estoque = {
        label: 'Estoque',
        children: [
          {
            label: 'Produtos',
            children: [
              { label: 'Incluir', id: 'estoque.produto.cria', disabled },
              { label: 'Alterar', id: 'estoque.produto.altera', disabled },
              { label: 'Excluir', id: 'estoque.produto.exclui', disabled },
              { label: 'Consulta', id: 'estoque.produto.consulta', disabled },
              { label: 'Relatórios', id: 'estoque.produto.relatorio', disabled },
              { label: 'Movimento de Estoque', id: 'estoque.produto.movimentoestoque', disabled },
            ],
          },
          {
            label: 'Categoria',
            children: [
              { label: 'Incluir', id: 'estoque.categoria.cria', disabled },
              { label: 'Alterar', id: 'estoque.categoria.altera', disabled },
              { label: 'Excluir', id: 'estoque.categoria.exclui', disabled },
              { label: 'Consulta', id: 'estoque.categoria.consulta', disabled },
            ],
          },
          {
            label: 'Fabricante',
            children: [
              { label: 'Incluir', id: 'estoque.fabricante.cria', disabled },
              { label: 'Alterar', id: 'estoque.fabricante.altera', disabled },
              { label: 'Excluir', id: 'estoque.fabricante.exclui', disabled },
              { label: 'Consulta', id: 'estoque.fabricante.consulta', disabled },
            ],
          },
          {
            label: 'Entrada Avulsa',
            children: [
              { label: 'Incluir', id: 'estoque.entradaavulsa.cria', disabled },
              { label: 'Alterar', id: 'estoque.entradaavulsa.altera', disabled },
              { label: 'Excluir', id: 'estoque.entradaavulsa.exclui', disabled },
              { label: 'Consultar', id: 'estoque.entradaavulsa.consulta', disabled },
              { label: 'Adiciona Produto', id: 'estoque.entradaavulsa.add_produto', disabled },
              { label: 'Editar Produto', id: 'estoque.entradaavulsa.update_produto', disabled },
              { label: 'Remover Produto', id: 'estoque.entradaavulsa.delete_produto', disabled },
              { label: 'Finalizar', id: 'estoque.entradaavulsa.finaliza', disabled },
              { label: 'Cancelar', id: 'estoque.entradaavulsa.cancela', disabled },
            ],
          },
          {
            label: 'Ajuste de Estoque',
            children: [
              { label: 'Consultar', id: 'estoque.ajusteestoque.consulta', disabled },
              { label: 'Criar', id: 'estoque.ajusteestoque.cria', disabled },
              { label: 'Alterar', id: 'estoque.ajusteestoque.altera', disabled },
              { label: 'Incluir Produtos', id: 'estoque.ajusteestoque.produto_add', disabled },
              { label: 'Alterar Produtos', id: 'estoque.ajusteestoque.produto_altera', disabled },
            ],
          },
        ],
      };

      const Financeiro = {
        label: 'Financeiro',
        children: [
          {
            label: 'Dashboard',
            children: [
              { label: 'Consulta', id: 'financeiro.dashboard.consulta', disabled },
            ],
          },
          {
            label: 'Caixa',
            children: [
              { label: 'Incluir', id: 'financeiro.caixa.cria', disabled },
              { label: 'Alterar', id: 'financeiro.caixa.altera', disabled },
              { label: 'Excluir', id: 'financeiro.caixa.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.caixa.consulta', disabled },
            ],
          },
          {
            label: 'Movimento Caixa',
            children: [
              { label: 'Incluir', id: 'financeiro.movimentocaixa.cria', disabled },
              { label: 'Alterar', id: 'financeiro.movimentocaixa.altera', disabled },
              { label: 'Consulta', id: 'financeiro.movimentocaixa.consulta', disabled },
              { label: 'Relatórios', id: 'financeiro.movimentocaixa.relatorio', disabled },
            ],
          },
          {
            label: 'Fechamento Caixa',
            children: [
              { label: 'Incluir', id: 'financeiro.fechamentocaixa.cria', disabled },
              { label: 'Alterar', id: 'financeiro.fechamentocaixa.altera', disabled },
              { label: 'Excluir', id: 'financeiro.fechamentocaixa.exclui', disabled },
              { label: 'Aprovar', id: 'financeiro.fechamentocaixa.aceita', disabled },
              { label: 'Consulta', id: 'financeiro.fechamentocaixa.consulta', disabled },
              { label: 'Relatórios', id: 'financeiro.fechamentocaixa.relatorio', disabled },
            ],
          },
          {
            label: 'Contas a Pagar',
            children: [
              { label: 'Incluir', id: 'financeiro.contas_pagar.cria', disabled },
              { label: 'Alterar', id: 'financeiro.contas_pagar.altera', disabled },
              { label: 'Excluir', id: 'financeiro.contas_pagar.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.contas_pagar.consulta', disabled },
              { label: 'Relatórios', id: 'financeiro.contas_pagar.relatorio', disabled },
            ],
          },
          {
            label: 'Contas a Receber',
            children: [
              { label: 'Incluir', id: 'financeiro.contas_receber.cria', disabled },
              { label: 'Alterar', id: 'financeiro.contas_receber.altera', disabled },
              { label: 'Excluir', id: 'financeiro.contas_receber.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.contas_receber.consulta', disabled },
              { label: 'Relatórios', id: 'financeiro.contas_receber.relatorio', disabled },
            ],
          },
          {
            label: 'Pagamentos Online',
            children: [
              { label: 'Consulta', id: 'vendas.pedido.pagamento_online_consulta', disabled },
            ],
          },
          {
            label: 'Recibo',
            children: [
              { label: 'Incluir', id: 'financeiro.recibo.cria', disabled },
              { label: 'Alterar', id: 'financeiro.recibo.altera', disabled },
              { label: 'Excluir', id: 'financeiro.recibo.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.recibo.consulta', disabled },
            ],
          },
          {
            label: 'Plano de conta',
            children: [
              { label: 'Incluir', id: 'financeiro.planoconta.cria', disabled },
              { label: 'Alterar', id: 'financeiro.planoconta.altera', disabled },
              { label: 'Excluir', id: 'financeiro.planoconta.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.planoconta.consulta', disabled },
            ],
          },
          {
            label: 'Centro de Custo',
            children: [
              { label: 'Incluir', id: 'financeiro.centrocusto.cria', disabled },
              { label: 'Alterar', id: 'financeiro.centrocusto.altera', disabled },
              { label: 'Excluir', id: 'financeiro.centrocusto.exclui', disabled },
              { label: 'Consulta', id: 'financeiro.centrocusto.consulta', disabled },
            ],
          },
        ],
      };

      const Fiscal = {
        label: 'Fiscal',
        children: [
          {
            label: 'Sped Fiscal',
            children: [
              { label: 'Incluir', id: 'fiscal.sped.cria', disabled },
              { label: 'Alterar', id: 'fiscal.sped.altera', disabled },
              { label: 'Excluir', id: 'fiscal.sped.exclui', disabled },
              { label: 'Consulta', id: 'fiscal.sped.consulta', disabled },
              { label: 'Gerar', id: 'fiscal.sped.gerar', disabled },
            ],
          },
        ],
      };

      const dfe = {
        label: 'DFe',
        children: [
          {
            label: 'Dashboard',
            children: [
              { label: 'Consulta', id: 'dfe.dashboard.consulta', disabled },
            ],
          },
          {
            label: 'NF-E(NOTA FISCAL ELETRÔNICA)',
            id: 'dfe.nfe',
            children: [
              { label: 'Incluir', id: 'dfe.nfe.cria', disabled },
              { label: 'Alterar', id: 'dfe.nfe.altera', disabled },
              { label: 'Excluir', id: 'dfe.nfe.exclui', disabled },
              { label: 'Consulta', id: 'dfe.nfe.consulta', disabled },
              { label: 'Cancelar', id: 'dfe.nfe.cancela', disabled },
              { label: 'Imprimir', id: 'dfe.nfe.imprimir', disabled },
              { label: 'Enviar por email', id: 'dfe.nfe.envia_por_email', disabled },
              { label: 'Downlaod XML', id: 'dfe.nfe.download_xml', disabled },
              { label: 'Relatórios', id: 'dfe.nfe.relatorio', disabled },
            ],
          },
          {
            label: 'NFC-E(NOTA FISCAL CONSUMIDOR ELETRÔNICA)',
            id: 'dfe.nfce',
            children: [
              { label: 'Incluir', id: 'dfe.nfce.cria', disabled },
              { label: 'Alterar', id: 'dfe.nfce.altera', disabled },
              { label: 'Excluir', id: 'dfe.nfce.exclui', disabled },
              { label: 'Consulta', id: 'dfe.nfce.consulta', disabled },
              { label: 'Cancelar', id: 'dfe.nfce.cancela', disabled },
              { label: 'Imprimir', id: 'dfe.nfce.imprimir', disabled },
              { label: 'Enviar por email', id: 'dfe.nfce.envia_por_email', disabled },
              { label: 'Enviar/Autoriza', id: 'dfe.nfce.envia', disabled },
              { label: 'Downlaod XML', id: 'nfce_download_xml', disabled },
              { label: 'Relatórios', id: 'dfe.nfce.relatorio', disabled },
            ],
          },
          {
            label: 'NFe de Entrada',
            id: 'dfe.nfeentrada',
            children: [
              { label: 'Incluir', id: 'dfe.entrada.cria', disabled },
              { label: 'Alterar', id: 'dfe.entrada.altera', disabled },
              { label: 'Excluir', id: 'dfe.entrada.exclui', disabled },
              { label: 'Consulta', id: 'dfe.entrada.consulta', disabled },
              { label: 'Aceita Nota', id: 'dfe.entrada.aceita', disabled },
              { label: 'Rejeita Nota', id: 'dfe.entrada.rejeita', disabled },
              { label: 'Downlaod XML', id: 'dfe.entrada.download_xml', disabled },
            ],
          },
          {
            label: 'MDF-E(MANIFESTO DESTINATÁRIO)',
            id: 'dfe.mdfe',
            children: [
              { label: 'Incluir', id: 'dfe.mdfe.cria', disabled },
              { label: 'Alterar', id: 'dfe.mdfe.altera', disabled },
              { label: 'Excluir', id: 'dfe.mdfe.exclui', disabled },
              { label: 'Cancelar', id: 'dfe.mdfe_cancela', disabled },
              { label: 'Consulta', id: 'dfe.mdfe.consulta', disabled },
              { label: 'Envia', id: 'dfe.mdfe.envia', disabled },
              { label: 'Encerra', id: 'dfe.mdfe.encerra', disabled },
            ],
          },
          {
            label: 'INUTILIZAÇÃO DE NF-e/NFC-e',
            id: 'dfe.inutilizacao',
            children: [
              { label: 'Incluir', id: 'dfe.inutilizacao.cria', disabled },
              { label: 'Consulta', id: 'dfe.inutilizacao.consulta', disabled },
            ],
          },
          {
            label: 'CC-e',
            id: 'dfe.cce',
            children: [
              { label: 'Incluir', id: 'dfe.cce.cria', disabled },
              { label: 'Excluir', id: 'dfe.cce.exclui', disabled },
              { label: 'Alterar', id: 'dfe.cce.altera', disabled },
              { label: 'Consulta', id: 'dfe.cce.consulta', disabled },
              { label: 'Envia', id: 'dfe.cce.envia', disabled },
            ],
          },
          {
            label: 'TRIBUTAÇÃO',
            id: 'dfe.tributacao',
            children: [
              { label: 'Incluir', id: 'dfe.tributacao.cria', disabled },
              { label: 'Alterar', id: 'dfe.tributacao.altera', disabled },
              { label: 'Excluir', id: 'dfe.tributacao.exclui', disabled },
              { label: 'Consulta', id: 'dfe.tributacao.consulta', disabled },
            ],
          },
        ],
      };

      const vendas = {
        label: 'Vendas',
        children: [
          {
            label: 'Dashboard',
            children: [
              { label: 'Consulta', id: 'vendas.dashboard.consulta', disabled },
            ],
          },
          {
            label: 'Pedido',
            id: 'vendas.pedido',
            children: [
              { label: 'Incluir', id: 'vendas.pedido.cria', disabled },
              { label: 'Alterar', id: 'vendas.pedido.altera', disabled },
              { label: 'Excluir', id: 'vendas.pedido.exclui', disabled },
              { label: 'Imprimir', id: 'vendas.pedido.imprime', disabled },
              { label: 'Cancela', id: 'vendas.pedido.cancela', disabled },
              { label: 'Relatório', id: 'vendas.pedido.relatorio', disabled },
              { label: 'Pagamento', id: 'vendas.pedido.pagamento', disabled },
              { label: 'Escolher vendedor', id: 'vendas.pedido.escolhe_vendedor', disabled },
              { label: 'Aplicar desconto', id: 'vendas.pedido.desconto', disabled },
              { label: 'Excluir Item', id: 'vendas.pedido.exclui_item', disabled },
              {
                label: 'Altera Item',
                id: 'vendas.pedido.altera_item',
                disabled: this.isVendedorExterno ? false : disabled,
              },
              { label: 'Registro de todos usuários', id: 'vendas.pedido.todos_usuarios', disabled },
              { label: 'Administrativo', id: 'vendas.pedido.adm', disabled },
            ],
          },
          {
            label: 'Ordem de Serviço',
            id: 'vendas.os',
            children: [
              { label: 'Incluir', id: 'vendas.os.cria', disabled },
              { label: 'Alterar', id: 'vendas.os.altera', disabled },
              { label: 'Excluir', id: 'vendas.os.exclui', disabled },
              { label: 'Imprimir', id: 'vendas.os.imprime', disabled },
              { label: 'Cancela', id: 'vendas.os.cancela', disabled },
              { label: 'Relatório', id: 'vendas.os.relatorio', disabled },
              { label: 'Pagamento', id: 'vendas.os.pagamento', disabled },
              { label: 'Escolher vendedor', id: 'vendas.os.escolhe_vendedor', disabled },
              { label: 'Aplicar desconto', id: 'vendas.os.desconto', disabled },
              { label: 'Excluir Item', id: 'vendas.os.exclui_item', disabled },
              { label: 'Altera Item', id: 'vendas.os.altera_item', disabled },
              { label: 'Criar Tipo', id: 'vendas.os.tipoobjeto.cria', disabled },
              { label: 'Alterar Tipo', id: 'vendas.os.tipoobjeto.altera', disabled },
              { label: 'Excluir Tipo', id: 'vendas.os.tipoobjeto.exclui', disabled },
              { label: 'Consulta Tipo', id: 'vendas.os.tipoobjeto.consulta', disabled },
              { label: 'Cria Marca', id: 'vendas.os.marca.cria', disabled },
              { label: 'Altera Marca', id: 'vendas.os.marca.altera', disabled },
              { label: 'Excluir Marca', id: 'vendas.os.marca.exclui', disabled },
              { label: 'Consulta Marca', id: 'vendas.os.marca.consulta', disabled },
              { label: 'Registro de todos usuários', id: 'vendas.os.todos_usuarios', disabled },
              { label: 'Administrativo', id: 'vendas.os.adm', disabled },
            ],
          },
          {
            label: 'Forma de Pagamento',
            id: 'vendas.formapagamento',
            children: [
              { label: 'Incluir', id: 'vendas.formapagamento.cria', disabled },
              { label: 'Alterar', id: 'vendas.formapagamento.altera', disabled },
              { label: 'Excluir', id: 'vendas.formapagamento.exclui', disabled },
              { label: 'Consulta', id: 'vendas.formapagamento.consulta', disabled },
            ],
          },
          {
            label: 'Desconto',
            id: 'vendas.desconto',
            children: [
              { label: 'Incluir', id: 'vendas.desconto.cria', disabled },
              { label: 'Alterar', id: 'vendas.desconto.altera', disabled },
              { label: 'Excluir', id: 'vendas.desconto.exclui', disabled },
              { label: 'Consultar', id: 'vendas.desconto.consulta', disabled },
            ],
          },
          {
            label: 'Devolução',
            id: 'vendas.devolucao',
            children: [
              { label: 'Incluir', id: 'vendas.devolucao.cria', disabled },
              { label: 'Alterar', id: 'vendas.devolucao.altera', disabled },
              { label: 'Excluir', id: 'vendas.devolucao.exclui', disabled },
              { label: 'Consultar', id: 'vendas.devolucao.consulta', disabled },
              { label: 'Cancelar', id: 'vendas.devolucao.cancela', disabled },
              { label: 'Finalizar', id: 'vendas.devolucao.finaliza', disabled },
              { label: 'Gerar Carta de Creedito', id: 'vendas.devolucao.geracarta', disabled },
              { label: 'Gerar NF-e de Devolução', id: 'vendas.devolucao.geranfe', disabled },
            ],
          },
        ],
      };
      if (this.$store.getters.config && this.$store.getters.config.ramoAtividade === 'otica') {
        for (let index = 0; index < vendas.children.length; index += 1) {
          const element = vendas.children[index];
          if (element.label === 'Pedido') {
            vendas.children[index].children.push(
              { label: 'Incluir Tipo Lente', id: 'vendas.otica.tipolente.cria', disabled },
              { label: 'Alterar Tipo Lente', id: 'vendas.otica.tipolente.altera', disabled },
              { label: 'Excluir Tipo Lente', id: 'vendas.otica.tipolente.exclui', disabled },
              { label: 'Consultar Tipo Lente', id: 'vendas.otica.tipolente.consulta', disabled },
              { label: 'Incluir Tipo Material', id: 'vendas.otica.tipomaterial.cria', disabled },
              { label: 'Alterar Tipo Material', id: 'vendas.otica.tipomaterial.altera', disabled },
              { label: 'Excluir Tipo Material', id: 'vendas.otica.tipomaterial.exclui', disabled },
              { label: 'Consultar Tipo Material', id: 'vendas.otica.tipomaterial.consulta', disabled },
              { label: 'Incluir Tipo Tratamento', id: 'vendas.otica.tipotratamento.cria', disabled },
              { label: 'Alterar Tipo Tratamento', id: 'vendas.otica.tipotratamento.altera', disabled },
              { label: 'Excluir Tipo Tratamento', id: 'vendas.otica.tipotratamento.exclui', disabled },
              { label: 'Consultar Tipo Tratamento', id: 'vendas.otica.tipotratamento.consulta', disabled },
              { label: 'Incluir Tipo Armacao', id: 'vendas.otica.tipoarmacao.cria', disabled },
              { label: 'Alterar Tipo Armacao', id: 'vendas.otica.tipoarmacao.altera', disabled },
              { label: 'Excluir Tipo Armacao', id: 'vendas.otica.tipoarmacao.exclui', disabled },
              { label: 'Consultar Tipo Armacao', id: 'vendas.otica.tipoarmacao.consulta', disabled },
            );
            break;
          }
        }
      }

      const clinica = {
        label: 'Clinica',
        children: [
          {
            label: 'Carteirinha',
            children: [
              { label: 'Incluir', id: 'carteirinha.cria', disabled },
              { label: 'Alterar', id: 'carteirinha.altera', disabled },
              { label: 'Excluir', id: 'carteirinha.exclui', disabled },
              { label: 'Cancela', id: 'carteirinha.cancela', disabled },
              { label: 'Consulta', id: 'carteirinha.consulta', disabled },
            ],
          },
          {
            label: 'Cadastro Modelo Carteirinha',
            children: [
              { label: 'Incluir', id: 'carteirinhamodelo.cria', disabled },
              { label: 'Alterar', id: 'carteirinhamodelo.altera', disabled },
              { label: 'Excluir', id: 'carteirinhamodelo.exclui', disabled },
              { label: 'Consulta', id: 'carteirinhamodelo.consulta', disabled },
            ],
          },
        ],
      };

      const seguranca = {
        label: 'Seguranca',
        children: [
          {
            label: 'Usuários',
            children: [
              { label: 'Incluir', id: 'auth.usuario.cria', disabled },
              { label: 'Alterar', id: 'auth.usuario.altera', disabled },
              { label: 'Excluir', id: 'auth.usuario.exclui', disabled },
              { label: 'Consulta', id: 'auth.usuario.consulta', disabled },
            ],
          },
          {
            label: 'Grupos',
            children: [
              { label: 'Incluir', id: 'auth.grupo.cria', disabled },
              { label: 'Alterar', id: 'auth.grupo.altera', disabled },
              { label: 'Excluir', id: 'auth.grupo.exclui', disabled },
              { label: 'Consulta', id: 'auth.grupo.consulta', disabled },
            ],
          },
          {
            label: 'Acesso Externo',
            children: [
              { label: 'Incluir', id: 'auth.acesso_externo.cria', disabled },
              { label: 'Alterar', id: 'auth.acesso_externo.altera', disabled },
              { label: 'Excluir', id: 'auth.acesso_externo.exclui', disabled },
              { label: 'Consulta', id: 'auth.acesso_externo.consulta', disabled },
            ],
          },
        ],
      };

      const suporte = {
        label: 'Suporte',
        children: [],
      };

      // So adiciona a opção de cidades caso seja a empresa linksoft
      if (this.$store.getters.currentOrg.id === 'linksoft') {
        cadastros.children.push({
          label: 'Cidades',
          children: [
            { label: 'Incluir', id: 'endereco.cidade.cria', disabled },
            { label: 'Alterar', id: 'endereco.cidade.altera', disabled },
            { label: 'Excluir', id: 'endereco.cidade.exclui', disabled },
            { label: 'Relatórios', id: 'endereco.cidade.relatorio', disabled },
          ],
        });
      }

      // So adiciona a opção de NCM caso seja a empresa linksoft
      if (this.$store.getters.currentOrg.id === 'linksoft') {
        dfe.children.push({
          label: 'NCM',
          id: 'dfe.ncm',
          children: [
            { label: 'Incluir', id: 'dfe.ncm.cria', disabled },
            { label: 'Alterar', id: 'dfe.ncm.altera', disabled },
            { label: 'Excluir', id: 'dfe.ncm.exclui', disabled },
            { label: 'Consulta', id: 'dfe.ncm.consulta', disabled },
          ],
        });
      }

      // So adiciona a opção de migração caso seja a empresa linksoft
      if (this.$store.getters.currentOrg.id === 'linksoft') {
        suporte.children.push({
          label: 'Migração',
          children: [
            { label: 'Migrar', id: 'suporte.migracao.cria', disabled },
          ],
        });
      }

      // mostra os menus de acordo com as rotas registradas
      let rotas = '';
      this.$router.options.routes.forEach((route) => {
        rotas += `${route.path},`;
      });
      permissoes.push(cadastros);
      if (rotas.includes('/pedido')) {
        permissoes.push(vendas);
      }
      if (rotas.includes('/nfe')) {
        permissoes.push(dfe);
      }
      if (rotas.includes('/produto')) {
        permissoes.push(Estoque);
      }
      if (rotas.includes('/contasreceber')) {
        permissoes.push(Financeiro);
      }
      if (rotas.includes('/spedfiscal')) {
        permissoes.push(Fiscal);
      }
      if (rotas.includes('/suporte')) {
        if (suporte.children.length > 0) {
          permissoes.push(suporte);
        }
      }
      if (rotas.includes('/carteirinha')) {
        permissoes.push(clinica);
      }
      permissoes.push(seguranca);

      return permissoes;
    },
    isGrupoPadrao() {
      return this.service.record.grupoPadrao;
    },
    isVendedorExterno() {
      if (this.service && this.service.record) {
        return this.service.record.name === 'VENDEDORES EXTERNOS';
      }
      return false;
    },
  },
  methods: {
    setCheckedKeys() {
      this.podeDispararEventoCheck = false;
      this.$refs.tree.reset();
      const checked = [];
      if (this.service.record.permissions) {
        // eslint-disable-next-line no-restricted-syntax
        for (const permission of this.service.record.permissions) {
          if (permission.check) {
            checked.push(permission.code);
          }
        }
      }
      this.checkedKeys = checked;
      this.$nextTick(() => {
        this.podeDispararEventoCheck = true;
      });
    },
    check(value) {
      if (!this.podeDispararEventoCheck) {
        return;
      }
      if (value.id) {
        if (this.service.record.permissions === null) {
          this.service.record.permissions = [];
        }
        let existe = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const permission of this.service.record.permissions) {
          if (permission.code === value.id) {
            permission.check = !permission.check;
            existe = true;
            return;
          }
        }
        if (!existe) {
          this.service.record.permissions.push({
            code: value.id,
            check: true,
          });
        }
      }
    },
    setUser(data) {
      if (data.id) {
        this.userId = data.id;
        this.userName = data.name;
        this.userEmail = data.email;
        if (!this.service.record.users) {
          this.service.record.users = [];
        }
        this.service.record.users.push({ id: data.id, name: data.name, email: data.email });
        // força o detalhe(grid de usuarios) ser recarregado
        this.service.setRecord(this.service.record);
        this.userId = '';
        this.userName = '';
        this.userEmail = '';
      }
    },
  },
};
</script>
