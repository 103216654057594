<template>
  <f-row>
    <f-col size="12" class="h-full w-full">
      <div class="preview-container h-full w-full" >
        <iframe :src="data" class="h-full w-full"/>
      </div>
    </f-col>
  </f-row>
</template>

<script>

export default {
  name: 'fIframe',
  props: {
    src: {
      type: String,
    },
  },
  components: {
  },
  computed: {
    data() {
      if (this.src) {
        if (this.src.includes('http') || this.src.includes('data:')) {
          return this.src;
        }
        const blob = new Blob([this.src], { type: 'application/pdf' });
        return window.URL.createObjectURL(blob);
      }
      return '';
    },
  },
};
</script>

<style scoped>
.preview-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
}
</style>
