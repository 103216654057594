import axios from 'axios';
import lodash from 'lodash';
import { request } from 'graphql-request';
import Util from './utils/util';

const getHost = () => {
  if (process.env.VUE_APP_API_HOST) {
    return process.env.VUE_APP_API_HOST;
  }
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.hostname}${process.env.VUE_APP_API_PORT || ''}`;
};

export default class Dataset {
  vue = null;

  constructor(config) {
    if (!config) {
      // eslint-disable-next-line no-param-reassign
      config = {};
    }
    this.timeout = config.timeout || 15000;
    this.axios = axios;
    this.appendMode = false;
    this.isNewRecord = false;
    this.baseUrl = config.baseUrl || `${getHost()}/api`;
    this.record = {};
    this.oldRecord = {};
    this.list = [];
    this.currentIndex = 0;
    this.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
    this.lastRecord = {};
    this.lastIndex = 0;
    this.ids = [];
    this.loading = false;
    this.loadingMessage = '';
    this.setParentChildConfig(config);
    this.setEvents(config);
    this.$utils = Util;
    if (config.getHeaders) {
      this.getHeaders = config.getHeaders;
    }
  }

  /**
   * Essa funcao executa todos os handles da lista de um field(evento)
   * @param field - nome do campo/lista de evento para ser executado
   * @param data - dados a ser enviado para a funcao
   */
  dispatch(field, data) {
    Object.keys(this[field])
      .forEach((idx) => {
        this[field][idx](data);
      });
  }

  /**
   * Funcao para configurar o parent/child, relacionamento entre datasets
   * @param config
   */
  setParentChildConfig(config) {
    this.parent = null;
    this.children = []; // guarda todos os dataset filhos
    if (config.parent) {
      this.parent = config.parent;
      this.parent.children.push(this);
      this.parentField = config.parentField;
    }
  }

  /**
   * Essa funcao  atualiza a lista no dataset parent
   * @param record
   */
  setFieldsOnParent(record) {
    Object.keys(record).forEach((key) => {
      this.parent.record[key] = record[key];
    });
    this.parent.setRecord(this.parent.record);
  }

  /**
   * Funcao para configurar os eventos do dataset
   * @param config
   */
  setEvents(config) {
    this.onBeforeSetList = [];
    this.onListChange = [];
    this.onListAdd = [];
    this.onRecordChange = []; // quando usa a funcao setRecord
    this.onSelect = config.onSelect ? config.onSelect : null;
    this.onBeforeRequest = null;
    this.onAfterRequest = null;
    this.onLoading = null;
  }

  getUrl() {
    let url = '';

    if (!lodash.isEmpty(this.parent)) {
      url = `${this.parent.getUrl()}/${this.parent.record.id}`;
    }
    url += `/${this.config.resource}`;

    if (this.config.base && lodash.isEmpty(this.parent)) {
      url = this.config.base + url;
    }
    return url;
  }

  /**
   * Essa funcao tem como objetivo gravar os dados do detalhe ao dataset parent(pai) até chegar no primeiro nivel que
   * nao houver mais parent
   */
  setParentField() {
    if (this.parent) {
      if (!this.parent.record[this.parentField]) {
        this.parent.record[this.parentField] = [];
      }
      this.parent.record[this.parentField] = this.list;
      this.parent.dispatch('onRecordChange', this.parent.record);
      this.parent.setParentField();
    }
  }

  /**
   * Funcao para salvar um registro no dataset, se for um dataset filho, ele verifica se tem as funcoes de
   * request implementada, se sim, entao significa que precisa fazer a request, caso nao tenha e seja um filho
   * ele tenta adicionar no array do dataset pai no campo definido no campo this.parentField
   * @return {Promise<unknown>}
   */
  save() {
    return new Promise((resolve, reject) => {
      let currentIndexBkp = this.list.length;
      let operation = 'getMutationCreate';
      if (this.record.id) {
        operation = 'getMutationUpdate';
        currentIndexBkp = this.currentIndex;
      }
      // se for um dataset filho e nao tiver com o metodo de request, entao adicione ao dataset parent no array
      if (this.parentField && !this[operation]) {
        // se novo registro, entao gere um id, caso contrario, altere-o
        if (!this.record.id) {
          this.record.id = this.list.length + 1;
          this.addRecordToList(this.record);
        } else {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.list.length - 1; i++) {
            if (this.list[i].id === this.record.id) {
              this.list[i] = this.record;
              break;
            }
          }
        }
        this.setParentField();
        resolve(this.record);
      } else {
        this.gqlMutation(this[operation]())
          .then((response) => {
            let record;
            if (this.parent) {
              // atualize o registro retornado pelo back para o registro atual do dataset
              const childField = response.record[this.parentField];
              record = childField[currentIndexBkp];
            } else {
              record = response.record;
            }
            this.setRecord(record);
            this.currentIndex = currentIndexBkp;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }

  delete() {
    return new Promise((resolve, reject) => {
      this.gqlMutation(this.getMutationDelete())
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Realiza uma consulta de dados, se nao tive parent, realiza uma request, caso tenha, pega do
   * dataset parent
   * @param params
   * @param page numero da paginacao de dados
   * @returns {Promise<unknown>}
   */
  find(params, page) {
    return new Promise((resolve, reject) => {
      if (this.getFindQuery) {
        this.gqlQuery(this.getFindQuery(params))
          .then((response) => {
            if (page === 1) {
              this.setList(response.result);
            } else {
              this.addToList(response.result);
            }
            resolve(response.result);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        this.setList(this.parent.record[this.parentField]);
        resolve();
      }
    });
  }

  getRecordsByIds(ids) {
    const stringIds = _.array().quoteStingArray(ids);
    return new Promise((resolve, reject) => this.gqlQuery(this.getByIds(stringIds))
      .then((response) => {
        resolve(response.result);
      })
      .catch((err) => {
        reject(err);
      }));
  }

  /**
   * Apenas remove um registro da lista, operacão somente em memória
   * @returns {Promise<void>}
   */
  remove() {
    this.list.splice(this.currentIndex, 1);
    this.setList(this.list);
    if (this.list.length > 0) {
      this.select(this.currentIndex - 1);
    }
    if (this.list.length === 0) {
      this.setRecord(this.getModel());
      this.currentIndex = 0;
      this.select(this.currentIndex);
    }
    this.setParentField();
  }

  // Returns the only parts that was changed since last object load
  returnRecordChanges(record) {
    const recordDiference = {};
    Object.keys(record)
      .forEach((key) => {
        if (record[key] !== this.lastRecord[key]) {
          recordDiference[key] = record[key];
        }
      });
    return recordDiference;
  }

  addRecordToList(record) {
    this.list.push(record);
    this.dispatch('onListChange', this.list);
    if (this.parent) {
      this.parent.dispatch('onListChange', this.list);
      this.dispatch('onRecordChange', this.parent.record);
    }
    this.last();
  }

  addToList(list) {
    this.list = this.list.concat(list);
    this.dispatch('onListAdd', list);
    this.last();
  }

  setRecord(record) {
    if (this.vue) {
      this.vue.$set(this, 'record', record);
    } else {
      this.record = record;
    }
    this.oldRecord = _.cloneDeep(record);
    this.selectChildrenData();
    this.dispatch('onRecordChange', record);
  }

  duplicate() {
    const newRecord = _.cloneDeep(this.record);
    this.newRecord();
    newRecord.id = '';
    this.setRecord(newRecord);
  }

  recordChanged() {
    return _.isEqual(this.oldRecord, this.record) === false;
  }

  setList(list) {
    if (list && list.length > 0) {
      let tempList = Util.Object.copyObject(list);
      if (this.appendMode) {
        tempList = this.list.concat(list);
      }

      if (this.onBeforeSetList.length > 0) {
        this.onBeforeSetList.forEach((func) => {
          func(list);
        });
      }

      this.ids = [];
      list.forEach((item) => {
        this.ids.push(item.id);
      });

      this.list = tempList;
    } else {
      this.list = [];
    }
    this.select(0);
    this.dispatch('onListChange', this.list);
  }

  /**
   * Funcao que seleciona um registro que esta dentro da variavel `list` através do index do mesmo
   * @param index
   */
  select(index) {
    let idx = index;
    if (this.list.length > 0) {
      if (idx > this.list.length - 1) {
        idx = this.list.length - 1;
      } else if (idx < 0) {
        idx = 0;
      }

      this.setLastRecord();

      this.setRecord(this.list[idx]);
      this.currentIndex = idx;
    } else {
      this.setRecord(this.getModel ? this.getModel() : {});
    }
    this.isNewRecord = false;
    if (this.onSelect) {
      this.onSelect(this.record);
    }
    this.selectChildrenData();
  }

  selectChildrenData() {
    if (this.children.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of this.children) {
        const bkIndex = child.currentIndex;
        child.setList(this.record[child.parentField]);
        child.select(bkIndex);
      }
    }
  }

  newRecord() {
    this.isNewRecord = true;
    this.setLastRecord();
    this.setRecord(this.getModel());
    this.selectChildrenData();
  }

  undo() {
    let record = this.lastRecord;
    if (!record && this.lastRecord) {
      record = this.getModel();
    }
    this.setRecord(record);
  }

  getModel() {
    return {};
  }

  setLastRecord() {
    this.lastRecord = Util.Object.copyObject(this.record);
    this.lastIndex = Util.Object.copyObject(this.currentIndex);
  }

  getIndexByRecord(record) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= this.list.length; i++) {
      if (this.list[i].id === record.id) {
        return i;
      }
    }
    return 0;
  }

  empty() {
    this.dispatch('onListChange', []);
    this.dispatch('onRecordChange', {});
  }

  first() {
    this.select(0);
  }

  prior() {
    this.select(this.currentIndex - 1);
  }

  next() {
    this.select(this.currentIndex + 1);
  }

  last() {
    this.select(this.list.length - 1);
  }

  // essa funcao recebe os parametros para o envio dos dados e realiza formatações em alguns tipos de dados especiais
  // como data e hora
  formatParamsValue(params) {
    if (_.isNil(params)) {
      return;
    }

    if (_.isNil(params.data)) {
      return;
    }

    // eslint-disable-next-line no-restricted-syntax
    Object.keys(params.data).forEach((key) => {
      let p = params.data[key];
      if (p instanceof Date) {
        p = this.$utils.Datetime.toStringIso(p);
        // eslint-disable-next-line no-param-reassign
        params.data[key] = p;
      }
    });
  }

  async request(url, method, params, options = {}) {
    if (this.onBeforeRequest) {
      await this.onBeforeRequest();
    }
    this.setLoading(true);
    return new Promise((resolve, reject) => {
      this.axios.request({
        url,
        baseURL: this.baseUrl,
        method,
        data: params,
        headers: options.HEADERS || this.getHeaders(),
        timeout: options.timeout || this.timeout,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          const errorProcessed = this.processAxiosErrorResponse(err);
          reject(errorProcessed, err.response);
        })
        .then(() => {
          if (this.onAfterRequest) {
            this.onAfterRequest();
          }
          this.setLoading(false);
        });
    });
  }

  async get(url, params, options) {
    return this.request(url, 'GET', params, options);
  }

  async post(url, params, options) {
    return this.request(url, 'POST', params, options);
  }

  async patch(url, params) {
    return this.request(url, 'PATCH', params);
  }

  /**
   * Realiza uma query usando o graphql
   * @param query
   * @param host é opcional, mas se passar, realiza uma request passando o host como parametro
   * @returns {Promise<unknown>}
   */
  async gqlQuery(query, host) {
    this.setLoading(true);
    return new Promise((resolve, reject) => {
      let hostUrl = host || `${this.baseUrl}`;
      if (!host && !hostUrl.includes('graphql')) {
        hostUrl = `${hostUrl}/graphql`;
      }
      request({
        url: hostUrl,
        document: query,
        requestHeaders: this.getHeaders(),
      })
        .then((response) => {
          if (response.errors && response.errors.length > 0) {
            reject(this.processGraphqlErrorResponse(response.response[0]));
            return;
          }
          Object.keys(response)
            .forEach((key) => {
              resolve({
                response,
                result: response[key],
              });
            });
        })
        .catch((error) => {
          const err = this.processGraphqlErrorResponse(error);
          reject(err.msg);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });
  }

  /**
   * Envia uma request de graphql mutantion
   * @param query
   * @param host
   * @returns {Promise<unknown>}
   */
  gqlMutation(query, host) {
    this.setLoading(true);
    if (!query.query) {
      throw new Error('Query nao foi passada como parametro');
    }
    this.formatParamsValue(query.params);
    return new Promise((resolve, reject) => {
      request({
        url: host || `${this.baseUrl}/graphql`,
        document: query.query,
        variables: query.params,
        requestHeaders: this.getHeaders(),
      })
        .then((response) => {
          Object.keys(response)
            .forEach((key) => {
              const result = response[key];
              if (this.parent) {
                this.setFieldsOnParent(result);
              }
              if (Util.Object.isObject(result)) {
                this.addRecordToList(result);
              }
              resolve({
                ok: true,
                record: result,
              });
            });
        })
        .catch((error) => {
          const err = this.processGraphqlErrorResponse(error);
          reject(err.msg);
        })
        .finally(() => {
          this.setLoading(false);
        });
    });
  }

  /**
   * essa funcao processa os erros das requests do Axios
   * @param errorResponse
   * @returns {{code: string, message: *}}
   */
  processAxiosErrorResponse(errorResponse) {
    const response = {
      message: errorResponse.message,
      code: '',
    };
    if (errorResponse.message.toLowerCase().includes('network error')) {
      response.code = 'NO_RESPONSE';
      response.message = 'Sem conexão com o servidor';
    } else if (errorResponse.message.toLowerCase().includes('timeout')) {
      response.code = 'NO_RESPONSE';
      response.message = `Servidor demorou mais que ${this.timeout / 1000} segundos para responder, tente novamente `;
    } else if (errorResponse.response) {
      response.code = errorResponse.response.data.code;
      response.message = errorResponse.response.data.message;
    }
    return response;
  }

  /**
   * Essa funcao processa os erros ds request feito em graphql
   * @param errorResponse
   * @returns {{code: string, message: string}}
   */
  processGraphqlErrorResponse(errorResponse) {
    let response = {
      msg: errorResponse,
      code: '',
    };
    if (_.isString(errorResponse)) {
      // eslint-disable-next-line no-param-reassign
      errorResponse = JSON.parse(errorResponse);
    }

    if (_.has(errorResponse, 'message') && errorResponse.message === 'Network request failed') {
      response.msg = 'Sem comunicação, verifique sua internet e tente novamente ';
      return response;
    }

    if (_.has(errorResponse, 'response.errors[0].message')) {
      response.msg = _.get(errorResponse, 'response.errors[0].message');
      // essa situacao ocorre no retorno de uma mutation
      if (response.msg.includes('"code"')) {
        response = JSON.parse(response.msg);
      } else {
        return response;
      }
    }

    if (_.has(errorResponse, 'response.error')) {
      response.msg = _.get(errorResponse, 'response.error');
      // essa situacao ocorre no retorno de uma mutation
      if (response.msg.includes('"code"')) {
        response = JSON.parse(response.msg);
      } else {
        return response;
      }
    }

    if (response.message && !response.msg) {
      response.msg = response.message;
    }
    return response;
  }

  /**
   * Seta o valor da variavel loading e executa também o evento onLoading
   * @param value
   */
  setLoading(value) {
    if (this.vue) {
      this.vue.$set(this, 'loading', value);
    } else {
      this.loading = value;
    }
    if (this.onLoading) {
      this.onLoading(value);
    }
  }

  getHeaders() {
    return { 'Content-Type': 'application/json' };
  }

  async DELETE(url, params) {
    return this.request(url, 'DELETE', params);
  }
}
