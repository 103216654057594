<template>
  <div>
    <f-alert>
      Configure seu email para que os emails enviados pelo sistema fique na sua caixa de saída,
      como se fosse você que tivesse enviado a partir do seu email.
    </f-alert>
    <f-checkbox caption="Habilitar integração" v-model="service.record.smtp.usarIntegracao"></f-checkbox>
    <f-form v-if="service.record.smtp.usarIntegracao" ref="formSmtp">
      <f-combobox caption='Provedor' v-model='service.record.smtp.provedor' :options="provedores" @change="select" />
      <f-alert v-if="alertaGmail" type="warning" :showIcon="false">
        Usuário do Gmail que usar 2 fatores de autenticação(Confirmação por celular na hora de logar),
        é necessário seguir as seguintes instruções :<br><br>
        <ul>
          <li>
            - Entre em
            <a href="https://security.google.com/settings/security/apppasswords" target="_blank" rel="noopener">
              https://security.google.com/settings/security/apppasswords
            </a>
          </li>
          <li>- Selecione a App (Personalizada)</li>
          <li>- Digite Sigeflex</li>
          <li>- Copie a senha gerada e use essa senha no campo de senha acima</li>
        </ul>
      </f-alert>
      <f-form-row>
        <f-textbox
          caption='Endereço do Servidor'
          v-model='service.record.smtp.host'
          caseOption="lowercase"
          width="300px"
          :validations="validations.required"
        />
        <f-combobox
          caption='Tipo Autenticação'
          v-model='service.record.smtp.seguranca'
          :options="tipoAutenticacao"
        />
      </f-form-row>
      <f-textbox
        caption='Usuário'
        v-model='service.record.smtp.usuario'
        caseOption="lowercase"
        :validations="validations.required"
      />
      <f-textbox
        caption='Senha'
        type="password"
        v-model='service.record.smtp.senha'
        :is-password="true"
        caseOption="mix"
        :validations="validations.required"
      />
      <f-textbox
        caption='Porta'
        v-model='service.record.smtp.porta'
        mask="###"
        :validations="validations.required"
      />
      <f-textbox
        caption='Nome no Email'
        v-model='service.record.smtp.nomeEmail'
        caseOption="mix"
        :validations="validations.required"
      />
      <f-textbox
        caption='Email remetente'
        v-model='service.record.smtp.email'
        caseOption="lowercase"
        :validations="validations.required"
      />
      <f-card title="Teste de Email - Destinatário">
        <f-textbox
          caption='Email Destinatário'
          v-model='destinatarioEmail'
          caseOption="lowercase"
          :validations="validations.required"
        />
        <f-button caption="Testar envio de email" @click="testEmail">
          <FontAwesomeIcon icon="envelope" />
        </f-button>
        <f-alert :type="mensagemTipo" v-if="mensagem">
          {{ mensagem }}
        </f-alert>
      </f-card>
    </f-form>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope);

export default {
  props: {
    service: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      alertaGmail: false,
      mensagem: '',
      destinatarioEmail: '',
      mensagemTipo: '',
      provedores: [
        { text: 'Gmail', value: 'gmail' },
        { text: 'Outlook', value: 'outlook' },
        { text: 'Outro', value: 'outro' },
      ],
      tipoAutenticacao: [
        { text: 'Básico', value: '' },
        { text: 'StartTLS', value: 'StartTLS' },
        { text: 'TLS', value: 'TLS' },
      ],
      validations: {
        required: [
          { required: true, message: 'Campo Obrigatório', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    select(option) {
      // eslint-disable-next-line default-case
      switch (option.value) {
        case 'gmail':
          this.service.record.smtp.host = 'smtp.gmail.com';
          this.service.record.smtp.porta = '587';
          this.alertaGmail = true;
          break;
        case 'outlook':
          this.service.record.smtp.host = 'smtp-mail.outlook.com';
          this.service.record.smtp.porta = '587';
          this.alertaGmail = false;
          break;
        default:
          this.alertaGmail = false;
      }
    },
    testEmail() {
      this.mensagem = '';
      if (!this.$refs.formSmtp.validate()) {
        return;
      }
      this.service.testSmtp(this.destinatarioEmail)
        .then(() => {
          this.mensagemTipo = 'success';
          this.mensagem = 'Teste realizado com sucesso!';
          this.$emit('testeOk', true);
        })
        .catch((err) => {
          this.mensagemTipo = 'error';
          this.mensagem = `Email não enviado, retorno do servidor de email: '${err}'`;
        });
    },
  },
};

</script>
