<template>
  <a
    href="javascript:"
    class="link hover:text-blue-400"
    :class="linkButtonClass"
    @click.prevent="push"
  >
    <slot></slot>
  </a>
</template>

<script>
// component to show many errors at same time
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    cssClass: {
      type: String,
      default: '',
    },
    openBlank: {
      type: Boolean,
      default: false,
    },
    linkButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    linkButtonClass() {
      return this.linkButton ? 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' : '';
    },
  },
  methods: {
    push() {
      this.$emit('click');
      if (this.openBlank) {
        window.open(this.url, '_blank');
        return;
      }
      if (this.url) {
        this.$router.push(this.url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.link {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 21px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

</style>
