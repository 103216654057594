import Dataset from '../../libs/dataset';
import AddressService from './PersonAddressService';
import ContactService from './PersonContactService';

const getFields = () => `
        id
        createdAt
        updatedAt
        name
        name2
        cpfCnpj
        ident
        identFree
        isCustomer
        isEmployee
        isSalesMan
        isVendor
        isTransporter
        obs
        codigo
        pai
        mae
        nascimento
        grupoId
        grupoNome
        usuarioId
        usuarioNome
        cliente {
          limiteCredito
          saldoDevedor
          creditoDisponivel
          vendedorId
          vendedorNome
        }
        vendedor {
          comissao
        }
        address {
          id
          address
          zipCode
          number
          city
          cityCode
          neighborhood
          state
          stateIdent
        }
        contacts {
          id
          name
          email
          phone
          byWhatsApp
        }
`;

class Service extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [
      { caption: 'Criado em', name: 'createdAt', type: 'datetime' },
      { caption: 'Nome/Razão Social', name: 'name' },
      { caption: 'Nome2', name: 'name2' },
      { caption: 'Cpf/Cnpj', name: 'cpfCnpj' },
      { caption: 'Cliente', name: 'isCustomer', type: 'boolean' },
      { caption: 'Fornecedor', name: 'isVendor', type: 'boolean' },
      { caption: 'Transp.', name: 'isTransporter', type: 'boolean' },
      { caption: 'Vendedor', name: 'isSalesMan', type: 'boolean' },
      { caption: 'Funcionário', name: 'isEmployee', type: 'boolean' },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome ou CPF/CNPJ',
        name: 'main',
        width: '400px',
        type: 'textbox',
      },
      {
        label: 'Consulta por Cidade',
        name: 'cidade',
        width: '200px',
        type: 'textbox',
      },
      {
        label: 'Tipo',
        name: 'type',
        type: 'combobox',
        default: '',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Cliente', value: 'isCustomer' },
          { text: 'Fornecedor', value: 'isVendor' },
          { text: 'Vendedor', value: 'isSalesMan' },
          { text: 'Transportador', value: 'isTransporter' },
          { text: 'Funcionário', value: 'isEmployee' },
        ],
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
    this.addressSchema = new AddressService({ parent: this, parentField: 'address' });
    this.contactSchema = new ContactService({ parent: this, parentField: 'contacts' });
  }

  addAddress(record) {
    this.addressSchema.newRecord();
    this.addressSchema.address = record.address;
    this.addressSchema.zipCode = record.zipCode;
    this.addressSchema.number = record.number;
    this.addressSchema.city = record.city;
    this.addressSchema.cityCode = record.cityCode;
    this.addressSchema.neighborhood = record.neighborhood;
    this.addressSchema.state = record.state;
    this.addressSchema.stateIdent = record.stateIdent;
    return this.addressSchema.save();
  }

  addContact(record) {
    this.contactSchema.newRecord();
    this.contactSchema.name = record.name;
    this.contactSchema.email = record.email;
    this.contactSchema.phone = record.phone;
    return this.contactSchema.save();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      isCustomer: null,
      isVendor: null,
      isEmployee: null,
      isSalesMan: null,
      isTransporter: null,
      cpfCnpj: null,
      ident: null,
      identFree: null,
      name: null,
      name2: null,
      obs: null,
      codigo: null,
      pai: null,
      mae: null,
      nascimento: null,
      grupoNome: null,
      grupoId: null,
      usuarioNome: null,
      usuarioId: null,
      vendedor: {
        comissao: null,
      },
      cliente: {
        vendedorId: null,
        vendedorNome: null,
        limiteCredito: null,
        saldoDevedor: null,
        creditoDisponivel: null,
      },
      address: [],
      contacts: [],
    };
  }

  getFindQuery(filters) {
    let filter = `filter: "${filters.main || ''}",
                    type: "${filters.type || ''}"`;

    if (filters.cidade) {
      filter += `cidade: "${filters.cidade}",`;
    }
    return `query {
              PersonList(${filter}){
                id
                createdAt
                updatedAt
                name
                name2
                cpfCnpj
                ident
                identFree
                isCustomer
                isEmployee
                isSalesMan
                isVendor
                isTransporter
                address {
                  id
                  address
                  zipCode
                  number
                  city
                  cityCode
                  neighborhood
                  state
                  stateIdent
                }
                contacts {
                  id
                  name
                  email
                  phone
                  byWhatsApp
                }
                vendedor {
                  comissao
                }
              }
            }`;
  }

  getByIds(ids) {
    return `query {
            PersonList(ids: [${ids.join()}]) {
              ${getFields()}
            }
          }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation PersonCreateMutation($data: PersonInput!) {
           PersonCreate(data: $data){
              ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation PersonUpdateMutation($id: String!, $data: PersonInput!) {
           PersonUpdate(id: $id, data: $data){
              ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation PersonDeleteMutation($id: String!){
          PersonDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  importaPorArquivo(fileString, fileName, cliente, fornecedor) {
    const query = `
         mutation PessoaImporta($fileString: String!, $fileName: String!, $cliente: Boolean, $fornecedor: Boolean){
           PessoaImporta(fileString: $fileString,fileName: $fileName, cliente: $cliente, fornecedor:$fornecedor)
         }
    `;
    return this.gqlMutation({
      query,
      params: {
        fileString, fileName, cliente, fornecedor,
      },
    });
  }

  getMutationParams(model) {
    return {
      name: model.name,
      name2: model.name2,
      isCustomer: model.isCustomer,
      isVendor: model.isVendor,
      isEmployee: model.isEmployee,
      isSalesMan: model.isSalesMan,
      isTransporter: model.isTransporter,
      cpfCnpj: model.cpfCnpj,
      ident: model.ident,
      identFree: model.identFree,
      obs: model.obs,
      codigo: model.codigo,
      pai: model.pai,
      mae: model.mae,
      nascimento: model.nascimento,
      contacts: model.contacts,
      address: model.address,
      grupoId: model.grupoId,
      grupoNome: model.grupoNome,
      usuarioId: model.usuarioId,
      usuarioNome: model.usuarioNome,
      cliente: {
        vendedorId: model.cliente.vendedorId,
        vendedorNome: model.cliente.vendedorNome,
        limiteCredito: model.cliente.limiteCredito,
      },
      vendedor: model.vendedor,
    };
  }

  getReportConfig() {
    return [
      {
        graphqlQueryName: 'PessoaReport',
        nomeRelatorio: 'Relatorio de Pessoas',
        id: '1',
        descricao: 'Emissão de relatórios de Pessoas',
        campos: [
          {
            tipo: 'combobox',
            caption: 'Tipo de Relatório(Visualização)',
            campoFiltro: 'tipoRelatorio',
            value: 'pessoaListaSimples',
            opcoes: [
              { value: 'pessoaListaSimples', text: 'Lista Simples' },
              { value: 'pessoaPorUf', text: 'Agrupado por UF' },
            ],
          },
          { tipo: 'date', caption: 'Data Cadastro', campoFiltro: 'dataCadastro' },
          {
            tipo: 'textbox', caption: 'Cidade', fieldName: 'cidade', campoFiltro: 'cidade',
          },
          {
            tipo: 'textbox', caption: 'UF', fieldName: 'uf', campoFiltro: 'uf',
          },
          {
            caption: 'Tipo de Pessoa',
            campoFiltro: 'tipoPessoa',
            tipo: 'combobox',
            value: '',
            opcoes: [
              { text: 'Todos', value: '' },
              { text: 'Cliente', value: 'isCustomer' },
              { text: 'Fornecedor', value: 'isVendor' },
              { text: 'Vendedor', value: 'isSalesMan' },
              { text: 'Transportador', value: 'isTransporter' },
              { text: 'Funcionário', value: 'isEmployee' },
            ],
          },
          {
            tipo: 'lookup',
            caption: 'Grupo',
            fieldName: 'nome',
            campoFiltro: 'grupoId',
            component: 'GrupoPessoas',
            filter: '',
          },
        ],
        ordenacao: [
          { value: 'createdAt', text: 'Data Cadastro' },
          { value: 'name', text: 'Nome' },
        ],
      },
    ];
  }
}

export default Service;
