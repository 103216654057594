import ObjectUtil from './utils/object';

export default class I18n {
  constructor(dictionaty) {
    this.dictionaty = dictionaty;
  }

  get(key) {
    let language = 'pt';
    if (window.i18n) {
      language = window.i18n;
    }
    return ObjectUtil.getValueFromString(this.dictionaty, `${language}.${key}`);
  }
}
