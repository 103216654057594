<style lang="scss">

.f-field {
  margin: 0 4px 4px 0 !important;
}

.f-invalid > input {
  border-color: #EF4444 !important;
}
</style>

<template>
  <div class="f-field flex justify-center flex-col w-auto" :id="generatedId()" :style="internalFieldStyle">
    <f-label
      :caption="caption"
      :isRequired="isRequired"
      :helpTitle="helpTitle"
      :helpMessage="helpMessage"
    />
    <div>
      <el-input
        autocomplete="disabled"
        ref="input"
        v-currency="options"
        :placeholder=placeholder
        :class="classes()"
        :size="size"
        :value="formattedValue"
        :disabled="disabled || internalDisabled"
        :readonly="readonly"
        @focus="onFocus"
        @blur="onBlur"
        @input="handleInput"
        @keyup.native="$emit('keyup', $event)"
        @change="$emit('change', $event)"
      >
      </el-input>
      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Input } from 'element-ui';
/* eslint no-duplicate-imports: ["error", { includeExports: true }] */
// eslint-disable-next-line import/no-duplicates
import { getValue, setValue } from 'vue-currency-input';
/* eslint no-duplicate-imports: ["error", { includeExports: true }] */
// eslint-disable-next-line import/no-duplicates
import VueCurrencyInput from 'vue-currency-input/dist/vue-currency-input.esm';
import inputMixing from './InputMixing';

Vue.use(VueCurrencyInput);
export default {
  data() {
    return {
      formattedValue: null,
    };
  },
  components: {
    'el-input': Input,
  },
  mixins: [
    inputMixing,
  ],
  props: {
    caption: { type: String, default: '' },
    value: { type: Number, default: null },
    readonly: { type: Boolean, default: false },
    isCurrency: { type: Boolean, default: false },
    precision: { type: Number, default: 2 },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    placeholder: { type: String, default: '' },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    icon: { type: String, default: '' },
    selectOnFocus: { type: Boolean, default: true },
  },
  computed: {
    valueRange() {
      return {
        min: this.min,
        max: this.max,
      };
    },
    options() {
      return {
        locale: 'pt',
        'value-range': this.valueRange,
        precision: this.precision,
        currency: {
          suffix: this.suffix,
          prefix: this.isCurrency ? 'R$ ' : this.prefix,
        },
      };
    },
  },
  mounted() {
    this.internalInputStyle['text-align'] = 'right';
    this.internalInputStyle.width = '150px';
    this.setValue(this.value);
  },
  methods: {
    handleInput(value) {
      this.$emit('input', getValue(this.$refs.input));
      this.formattedValue = value;
    },
    setValue(value) {
      setValue(this.$refs.input, value);
      if (!value) this.formattedValue = null;
    },
  },
  watch: {
    value: 'setValue',
  },
};
</script>
