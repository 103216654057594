<style scoped>

.p-menu-list {
  background-color: inherit;
  border: inherit;
}

>>> .p-menuitem-text {
  color: white !important;
}

>>> .p-menuitem-link:hover {
  color: white !important;
  background-color: #828598 !important;
}

.menu {
  height: 88vh;
}
</style>

<template>
  <div class="menu overflow-auto">
    <el-menu
      theme="light"
      unique-opened
      :background-color="color.backgroundColor"
      :text-color="color.textColor"
      :active-text-color="color.activeTextColor"
      :router="true"
      :mode="mode"
      :collapse="isCollapse"
    >
      <div
        v-for="(menu, idx) in menus" :key="menu.route"
        @mouseenter="setFooterMessage(menu.description)"
        @mouseleave="setFooterMessage('')"
      >
        <el-submenu
          v-if="menu.items && menu.items.length>0"
          :index="String(idx)"
          @click="$emit('menu-click', menu)"
        >
          <template slot="title" @mouseover="setFooterMessage(menu.description)">
            <i :class="menu.iconClass" v-if="menu.iconClass"></i>
            <div class="flex content-center items-center">
              <img v-if="menu.img" style="margin-right: 4px"  width="32px" height="32px" :src="menu.img"/>
              <span v-if="!isCollapse">{{ menu.text }}</span>
            </div>
          </template>
          <el-menu-item
            v-for="submenu in menu.items"
            :key="submenu.route"
            :index="submenu.route"
          >
            <div>
              <i :class="submenu.iconClass" v-if="submenu.iconClass"></i>
              <div class="flex content-center items-center">
                <img :src="submenu.img" v-if="submenu.img" width="32px" height="32px" class="mr-2"/>
                <span>{{ submenu.text }}</span>
              </div>
            </div>
          </el-menu-item>
        </el-submenu>

        <!---->
        <el-menu-item
          v-else
          @click="$emit('menu-click', menu)"
          :index="menu.route"
          class="flex"
        >
          <i :class="menu.iconClass" v-if="menu.iconClass"></i>
          <div class="flex content-center items-center mr-2" style="width: 40px">
            <img :src="menu.img" v-if="menu.img" width="32px" height="32px"/>
          </div>
          <template slot="title" @mouseover="setFooterMessage(menu.description)" v-if="!isCollapse">
            <span>{{ menu.text }}</span>
          </template>
          <!---->
          <div v-else>
            <slot></slot>
          </div>
        </el-menu-item>
        <!---->
      </div>
    </el-menu>
  </div>
</template>

<script>
import { Menu, MenuItem, Submenu } from 'element-ui';

export default {
  name: 'AppMenu',
  components: {
    'el-menu': Menu,
    'el-submenu': Submenu,
    'el-menu-item': MenuItem,
  },
  props: {
    menu: {
      type: Array,
      default: () => {
      },
    },
    color: {
      type: Object,
      default: () => ({
        textColor: '#fff',
        backgroundColor: '#1F2937',
        activeTextColor: '#ffd04b',
      }),
    },
    mode: {
      type: String,
      default: 'vertical',
    },
  },
  computed: {
    menus() {
      return this.generateMenu(this.menu);
    },
    isCollapse() {
      // se for um tamanho que seja mobile, entao nao colapse o menu pois sera mostrado em um drawer
      if (this.$store.getters.pageSize.width < 768 || this.$store.getters.pageSize.height < 415) {
        return false;
      }
      if (this.$store.getters.pageSize.width <= 960) {
        return true;
      }
      return this.$store.getters.menuCollapsed;
    },
  },
  methods: {
    setFooterMessage(message) {
      this.$store.dispatch('setMessage', { type: 'info', msg: message, time: 0 });
    },
    getMenu(m) {
      return {
        text: m.text,
        img: m.img,
        iconClass: m.iconClass,
        description: m.description,
        route: m.route,
        items: [],
      };
    },
    generateMenu() {
      const menu = [];
      Object.keys(this.menu)
        .forEach((key) => {
          const m = this.menu[key];
          menu.push(this.getMenu(m));
          if (m.items && m.items.length > 0) {
            // eslint-disable-next-line no-restricted-syntax,no-unused-vars
            for (const i of m.items) {
              menu[menu.length - 1].items.push(this.getMenu(i));
            }
          }
        });
      return menu;
    },
  },
};
</script>
