<template>
  <el-checkbox
    :value="value"
    :id="generatedId()"
    :disabled="disabled || internalDisabled"
    @input="$emit('input', $event)"
  >
    {{ caption }}
  </el-checkbox>
</template>

<script>
import { Checkbox } from 'element-ui';
import inputMixing from './InputMixing';

export default {
  mixins: [
    inputMixing,
  ],
  components: {
    'el-checkbox': Checkbox,
  },
  data() {
    return {
      checked: false,
    };
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setDisable(value) {
      this.internalDisabled = value;
    },
  },
};
</script>
