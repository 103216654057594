<script>

export default {
  data() {
    return {
      dataPagamentoPickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      unidades: [
        { text: 'UN', value: 'UN' },
        { text: 'M2', value: 'M2' },
        { text: 'M3', value: 'M3' },
        { text: 'ML', value: 'ML' },
        { text: 'KG', value: 'KG' },
        { text: 'FD', value: 'FD' },
        { text: 'CX', value: 'CX' },
        { text: 'LT', value: 'LT' },
        { text: 'M', value: 'M' },
        { text: 'CM', value: 'CM' },
        { text: 'SC', value: 'SC' },
      ],
      stateBr: [
        { value: 'AC', text: 'AC' },
        { value: 'AL', text: 'AL' },
        { value: 'AP', text: 'AP' },
        { value: 'AM', text: 'AM' },
        { value: 'BA', text: 'BA' },
        { value: 'CE', text: 'CE' },
        { value: 'DF', text: 'DF' },
        { value: 'ES', text: 'ES' },
        { value: 'GO', text: 'GO' },
        { value: 'MA', text: 'MA' },
        { value: 'MT', text: 'MT' },
        { value: 'MS', text: 'MS' },
        { value: 'MG', text: 'MG' },
        { value: 'PA', text: 'PA' },
        { value: 'PB', text: 'PB' },
        { value: 'PR', text: 'PR' },
        { value: 'PE', text: 'PE' },
        { value: 'PI', text: 'PI' },
        { value: 'RJ', text: 'RJ' },
        { value: 'RN', text: 'RN' },
        { value: 'RS', text: 'RS' },
        { value: 'RO', text: 'RO' },
        { value: 'RR', text: 'RR' },
        { value: 'SC', text: 'SC' },
        { value: 'SP', text: 'SP' },
        { value: 'SE', text: 'SE' },
        { value: 'TO', text: 'TO' },
      ],
    };
  },
};

</script>
