<template>
  <f-form
    ref="form"
    @mounted="formValidation = $event"
    v-loading="service.loading"
    element-loading-text="Aguarde..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="flex flex-wrap items-end">
      <div class="mr-1">
        <f-lookup
          v-model='model.nome'
          ref="item"
          width="300px"
          helpMessage="Consulta por nome ou código"
          :caption="produto === true ? 'Consulta produto' :  'Consulta Serviço'"
          :component="produto ? 'Produto' : 'Servico'"
          :value-id="model.id"
          :validations="validations.required"
          :openPageAutomatic="false"
          :pageTitle="produto === true ? 'Consulta produto' :  'Consulta Serviço'"
          :desableInternalValidation="true"
          @value="setItem"
          @keyup="consultaItem"
        />
      </div>
      <f-numberbox
        v-if="model.postoNumBico"
        caption='Nº Bico'
        class="mr-1"
        v-model='model.postoNumBico'
        :validations="validations.required"
        :precision="0"
        @keyup="keyUp"
      />
      <f-lookup
        v-model='model.pessoaNome'
        caption="Responsável pelo serviço"
        component="Person"
        pageTitle="Consulta de Pessoas"
        width="300px"
        helpMessage="Escolhe o responsável técnico pelo serviço"
        v-if="mostraResponsavel"
        :value-id="model.pessoaId"
        :openPageAutomatic="false"
        :filters="{type: 'isEmployee'}"
        @value="setPessoa"
      />
      <slot></slot>
      <f-numberbox
        caption='Quantidade'
        ref="quantidade"
        class="mr-1"
        v-model='model.quantidade'
        :validations="validations.required"
        :precision="4"
        width="150px"
        @keyup="keyUp"
      />
      <f-textbox
        caption='UN'
        class="mr-1"
        v-model='model.un'
        v-show="model.un"
        :disabled="true"
        width="60px"
      />
      <f-numberbox
        caption='Valor Unitário'
        ref="valorUnitario"
        width="150px"
        class="mr-2"
        v-model='model.valorUnitario'
        v-if="mostraValorUnitario"
        :validations="model.composto ? []: validations.required"
        :disabled="!podeAlterarValorUnitario || model.composto"
        :inputStyle="{color: this.$style.colorBlack}"
        @keyup="keyUp"
      />
      <f-numberbox
        caption='Total'
        width="150px"
        class="mr-2"
        v-if="mostraValorTotal"
        :value='total'
        :isCurrency="true"
        :inputStyle="{color: this.$style.colorBlack}"
        :disabled="!isQuantidadeFracionada"
        @input="calculaQuantidade"
        @keyup="keyUp"
      />
      <f-button caption="Add" @click="addItem" />
    </div>
    <CalculaMetragem
      v-model="mostraCalculoMetragm"
      :valor-unitario="model.valorUnitario"
      @concluido="concluidoCalculoMetragem"
    />
  </f-form>
</template>

<script>
import ProdutoService from './ProdutoService';
import ServicoService from './ServicoService';

import CalculaMetragem from './CalculaMetragem';

export default {
  name: 'ConsultaItem',
  components: {
    CalculaMetragem,
  },
  created() {
    if (this.produto) {
      this.service = new ProdutoService();
    } else {
      this.service = new ServicoService();
    }
  },
  props: {
    add: {
      type: Function,
      required: true,
      default: () => {
      },
    },
    produto: { type: Boolean, required: true },
    mostraResponsavelPeloServico: { type: Boolean, default: false },
    mostraValorUnitarioProduto: { type: Boolean, default: true },
    mostraTotalProduto: { type: Boolean, default: true },
    manterUltimoProduto: { type: Boolean, required: false },
    podeAlterarValorUnitario: { type: Boolean, required: false },
    tabelaPreco: { type: String, default: '' },
  },
  data() {
    return {
      formValidation: null,
      service: null,
      tabelaPrecoUtilizada: null,
      valorUnitarioOriginal: 0,
      mostraCalculoMetragm: false,
      metragemCalculada: false,
      model: this.getModel(),
      validations: {
        required: [
          { required: true, message: 'Campo obrigatório', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    total() {
      return this.$utils.Number.parseFloat(this.model.quantidade)
        * this.$utils.Number.parseFloat(this.model.valorUnitario);
    },
    config() {
      return this.$store.getters.config;
    },
    mostraResponsavel() {
      if (this.produto) {
        return false;
      }
      return this.mostraResponsavelPeloServico;
    },
    mostraValorUnitario() {
      return this.mostraValorUnitarioProduto;
    },
    mostraValorTotal() {
      return this.mostraTotalProduto;
    },
    isQuantidadeFracionada() {
      return _.includes(['KG', 'L', 'LT', 'M', 'M2', 'CM'], this.model.un);
    },
  },
  methods: {
    concluidoCalculoMetragem(record) {
      this.model.valorUnitario = record.total;
      this.model.comprimento = record.comprimento;
      this.model.largura = record.largura;
      this.model.medida = record.medida;
      this.metragemCalculada = true;
      this.$refs.quantidade.focus();
    },
    pegaPesoBalanca() {
      // nao tem necessidade de ser uma promessa e esperar pela resposta
      if (_.get(this, 'config.vendas.usaBalanca') && this.model.nome === '**') {
        this.model.nome = '';
        this.service.post('http://127.0.0.1:5000/peso', null,
          { timeout: 800, HEADERS: { 'Content-Type': 'application/json' } })
          .then((response) => {
            this.model.quantidade = parseFloat(response.data);
          }).catch(() => {
            this.$showMessage({
              title: 'Comunicação com a Balança',
              type: 'error',
              message: 'A balança não respondeu',
            });
          });
      }
    },
    processaQuantidade() {
      // se o ultimo caractere for um *
      if (this.model.nome && this.model.nome.length >= 2 && this.model.nome[this.model.nome.length - 1] === '*') {
        const quantidade = _.toNumber(this.model.nome.substring(0, this.model.nome.length - 1).replaceAll(',', '.'));
        if (quantidade > 0) {
          this.model.quantidade = quantidade;
        }
        this.model.nome = '';
      }
    },
    vendaPorBico(dadosBico) {
      this.service.getRecordsByIds({ ids: [dadosBico.produtoId] })
        .then((lista) => {
          if (lista && lista.length >= 1) {
            this.setItem(lista[0]);
            this.model.postoNumBico = dadosBico.numero;
            this.model.postoNumBomba = dadosBico.numBomba;
            this.model.valorUnitario = dadosBico.preco;
            this.model.quantidade = dadosBico.quantidade;
            if (this.model.quantidade > 0) {
              this.addItem();
              return;
            }
            this.$refs.quantidade.focus();
          }
        });
    },
    consultaItem(event) {
      this.pegaPesoBalanca();
      this.processaQuantidade();
      if (event.key === 'Enter') {
        if (!this.model.nome) {
          this.$emit('concluido', true);
          return;
        }
        if (!this.$store.getters.isMobile) {
          if (_.get(this, 'config.vendas.insercaoAutomaticaItens') && !this.model.quantidade) {
            this.model.quantidade = 1;
          }
        }
        // efetua a busca direta, se retornar 1 ja carrega no lookup, caso contrario abre a janela pra busca manual
        // OBS: no back ja busca pelo codigo se so tiver numero e pelo nome do produto se tiver letra
        // no entanto se a busca com letra retornar vazio o back refaz a busca pelo codigo independente se tiver letra
        this.service.find({ main: this.model.nome })
          .then(() => {
            // se deu certo o metodo find popula o .list do dataset(service), veja se trouxe registros
            if (this.service.list.length === 1) {
              this.setItem(this.service.list[0]);
            } else {
              this.$refs.item.openPage();
            }
          });
      }
    },
    keyUp(event) {
      if (event.key === 'Enter') {
        this.addItem();
      }
    },
    setItem(record) {
      if (!record.id) {
        this.model.id = null;
        return;
      }
      this.model.id = record.id;
      this.model.nome = record.nome;
      this.model.un = record.un;
      this.model.produtoEspecifico = record.produtoEspecifico;
      this.tabelaPrecoUtilizada = this.tabelaPreco;
      if (!this.tabelaPrecoUtilizada || this.tabelaPrecoUtilizada === '') {
        this.tabelaPrecoUtilizada = _.get(this, 'config.vendas.precoVendaPadrao');
      }
      if (!this.tabelaPrecoUtilizada || this.tabelaPrecoUtilizada === '') {
        this.tabelaPrecoUtilizada = 'avista';
      }
      switch (this.tabelaPrecoUtilizada) {
        case 'aprazo':
          this.model.valorUnitario = record.precoVendaAprazo;
          break;
        case 'atacado':
          this.model.valorUnitario = record.precoVendaAtacado;
          break;
        default:
          this.model.valorUnitario = record.valorUnitario;
      }
      if (!record.valorUnitario) {
        this.model.valorUnitario = record.valorUnitario || record.precoVendaAprazo || record.precoVendaAtacado;
      }
      this.model.valorUnitarioOriginal = record.precoVenda || record.valorUnitario;
      this.valorUnitarioOriginal = this.model.valorUnitario;
      this.model.composto = record.composto;
      this.service.list = [];
      if (record.consultaQuantidade > 0) {
        this.model.quantidade = record.consultaQuantidade;
      }
      if (this.model.quantidade > 0) {
        this.$nextTick(() => this.addItem());
        return;
      }
      this.$refs.quantidade.focus();
      this.$emit('consultaItem', this.model);
    },
    setPessoa(record) {
      this.model.pessoaNome = record.name;
      this.model.pessoaId = record.id;
    },
    getModel() {
      return {
        id: null,
        nome: null,
        pessoaNome: null,
        pessoaId: null,
        quantidade: null,
        valorUnitario: null,
        comprimento: null,
        largura: null,
        medida: null,
        total: null,
        un: null,
        numBico: null,
        frentistaCodig: null,
        numBomba: null,
      };
    },
    reset() {
      this.model = this.getModel();
      this.$refs.form.reset();
    },
    addItem() {
      this.model.total = this.model.valorUnitario * this.model.quantidade;
      if (this.formValidation && !this.formValidation.validate()) {
        return;
      }
      if (this.model.un === 'M2' && this.metragemCalculada === false) {
        this.mostraCalculoMetragm = true;
        return;
      }
      this.service.loading = true;
      this.add(this.model)
        .then(() => {
          this.service.loading = false;
          this.metragemCalculada = false;
          this.$refs.form.reset();
          if (this.manterUltimoProduto) {
            this.model.quantidade = null;
            this.model.comprimento = null;
            this.model.largura = null;
            this.model.medida = null;
            this.model.total = null;
            this.model.valorUnitario = this.valorUnitarioOriginal;
            this.$refs.quantidade.focus();
          } else {
            this.reset();
            this.$refs.item.focus();
          }
          this.$emit('added');
        })
        .catch((err) => {
          this.$showMessage({ title: 'Item não adicionado', type: 'error', message: err });
        })
        .finally(() => {
          this.service.loading = false;
        });
    },
    calculaQuantidade(value) {
      this.model.quantidade = value / this.model.valorUnitario;
    },
  },
};
</script>
