<template>
  <div
    class="flex"
    v-loading="service.loading"
    element-loading-text="Aguarde, importando produtos..."
    element-loading-spinner="el-icon-loading"
  >
    <f-form ref="form" @submit="importa">
      <div class="flex flex-col justify-center" style="width: 240px;">
        <div class="flex justify-center">
          <f-upload
            ref="upload"
            :maxSize="5048"
            :multiple="false"
            :showSubmitButton="false"
            accept=".csv"
            @removed="file = ''"
            @files-changed="selecionaArquivo"
          >
          </f-upload>
        </div>
        <f-button
          v-if="file"
          caption="Enviar/Importar"
          class="mt-4"
          size="md"
          type="success"
          @click="importa"
        />
      </div>
    </f-form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      file: '',
      fileName: '',
    };
  },
  props: {
    service: { type: Object },
  },
  methods: {
    selecionaArquivo($event) {
      this.fileName = $event[0].name;
      this.$utils.File.getBase64FromUrl($event[0].localUrl, (base64) => {
        this.file = base64;
      });
    },
    importa() {
      this.service.importaPorArquivo(this.file, this.fileName)
        .then((response) => {
          this.$showMessage({ title: 'Importacao de produtos', type: 'success', message: response.record });
          this.$emit('success', true);
        })
        .catch((error) => {
          this.$showMessage({
            title: 'Importacao de produtos',
            type: 'error',
            message: `Importacao não realizada : ${error}`,
          });
        });
    },
  },
};
</script>
