import Dataset from 'comps/libs/dataset';

const getFields = () => `
        id
        updatedAt
        createdAt
        nome
    `;

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      {
        name: 'nome',
        caption: 'Nome',
      },
    ];
    this.findFilters = [
      {
        type: 'textbox',
        width: '400px',
        label: 'Consulta por nome',
        name: 'main',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}", `;

    return `query {
              FabricanteList(${filter}) {
                id
                nome
              }
           }`;
  }

  getByIds(ids) {
    return `query {
              FabricanteList(ids: [${ids.join()}]) {
                ${getFields()}
              }
            }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation FabricanteCreate($data: FabricanteInput!) {
           FabricanteCreate(data: $data){
              ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation FabricanteUpdate($id: String!, $data: FabricanteInput!) {
           FabricanteUpdate(id: $id, data: $data){
              ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation FabricanteDelete($id: String!){
            FabricanteDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
    };
  }
}

export default Service;
