import axios from 'axios';

export default class ObjectGeneral {
  static renderHtmlContent(url) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static getHost() {
    return window.location.origin;
  }

  static isCnpj(value) {
    const valueOnlyNumber = _.string().onlyNumber(value);
    return valueOnlyNumber && valueOnlyNumber.length === 14;
  }
}
