export default class ArrayUtil {
  /**
   * Retorna o ultimo elemento de um array passado como parametro
   * @param array - array para ser retornado o ultimo elemento
   * Returns- retorna {} se o array nao estiver vazio
   */
  static getLastItem(array) {
    if (!array) {
      return {};
    }
    if (array.length === 0) {
      return {};
    }
    return array[array.length - 1];
  }

  /**
   * Realiza o somatório de um campo em um array de objetos
   */
  static sum(list, fieldName) {
    return list.reduce((a, b) => +a + +b[fieldName], 0);
  }

  /**
   * Realiza remocao de um item da lista, retornando a lista atualizada
   */
  static RemoveItem(list, item) {
    const index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
    return list;
  }

  /**
   * Realiza remocao de um item da lista de objetos comparando a key passada como parametro
   */
  static RemoveItemByKey(list, item, key) {
    let index = -1;
    Object.keys(list).forEach((idx) => {
      const o = list[idx];
      if (o[key] === item[key]) {
        // eslint-disable-next-line radix
        index = parseInt(idx);
      }
    });
    if (index > -1) {
      list.splice(index, 1);
    }
    return list;
  }

  static ToString(array) {
    return `"${array.join('","')}"`;
  }

  // adicione aspas em todos os elementos do array de string
  static quoteStingArray(array) {
    const list = [];
    Object.keys(array)
      .forEach((key) => list.push(`"${array[key]}"`));
    return list;
  }
}
