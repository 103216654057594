import { render, staticRenderFns } from "./CalculaMetroLinear.vue?vue&type=template&id=6406eaae&"
import script from "./CalculaMetroLinear.vue?vue&type=script&lang=js&"
export * from "./CalculaMetroLinear.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../sigeflex-clinica/frontend/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports