import Vue from 'vue';
import VueRouter from 'vue-router';
import Config from '../views/Config';
import Home from '../views/Home';
import Carteirinha from '../views/Carteirinha';
import CarteirinhaModelo from '../views/CarteirinhaModelo';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/carteirinha',
    name: 'Carteirinha',
    component: Carteirinha,
  },
  {
    path: '/carteirinhamodelo',
    name: 'Carteirinha',
    component: CarteirinhaModelo,
  },
  {
    path: '/config',
    name: 'Configuraçoes',
    component: Config,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
