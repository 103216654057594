<style scoped>
.tela-configuracao {
  height: 75vh;
  overflow: auto;
   border: 1px solid darkgray;
}
</style>
<template>
  <f-row
    v-loading="dataset.loading"
    element-loading-text="Aguarde..."
    element-loading-spinner="el-icon-loading"
    class="tela-configuracao"
  >
    <f-col col="12">
      <f-row>
        <f-col col="12">
          <slot name="header">
          </slot>
        </f-col>
      </f-row>
      <hr>
      <f-row>
        <f-col col="12" class="mt-5 mb-5 flex">
          <f-button caption="Salvar Configurações" @click="save">
            <FontAwesomeIcon icon="save" />
          </f-button>
          <f-button caption="Recarregar" @click="load">
            <FontAwesomeIcon icon="sync" />
          </f-button>
          <div class="ml-2">
            <slot name="detailButtons">
            </slot>
          </div>
        </f-col>
      </f-row>
      <div>
        <f-form ref="formValidation">
          <slot></slot>
        </f-form>
      </div>
    </f-col>
  </f-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';

library.add(faSave);

export default {
  name: 'ConfigForm',
  props: {
    dataset: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (!this.dataset) {
        return;
      }
      this.dataset.loadConfig()
        .then((response) => {
          this.$emit('success', response);
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err, time: 2000,
          });
          this.$emit('error', err);
        });
    },
    save() {
      if (this.$refs.formValidation && !this.$refs.formValidation.validate()) {
        return;
      }
      this.dataset.saveConfig()
        .then((response) => {
          this.$emit('save', response);
          this.$showMessage({
            title: '', type: 'success', message: 'Configuração gravada com sucesso', time: 2000,
          });
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err, time: 2000,
          });
        });
    },
  },
};
</script>
