<template>
  <el-progress
    :text-inside="textInside"
    :show-text="showText"
    :stroke-width="26"
    :percentage="percentage"
    :status="status"
    :type="type"
    :color="color"
    :format="format"
  ></el-progress>
</template>

<script>
import { Progress } from 'element-ui';

export default {
  name: 'fProgress',
  components: {
    'el-progress': Progress,
  },
  props: {
    percentage: { type: Number, default: 0 },
    status: { type: String, default: 'success' },
    type: { type: String, default: 'line' },
    showText: { type: Boolean, default: true },
    textInside: { type: Boolean, default: true },
    strokeWidth: { type: Number, default: null },
    color: { type: [String, Function, Array], default: '' },
    format: { type: Function, default: () => {} },
  },
};
</script>
