<template>
  <quill-editor
    ref="myQuillEditor"
    :value="value"
    :options="editorOption"
    style="height: 300px"
    @input="$emit('input', $event)"
    @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
  />
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'htmlEditor',
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
          ],
        },
      },
    };
  },
  methods: {
    onEditorBlur(quill) {
      this.$emit('blur', quill);
    },
    onEditorFocus(quill) {
      this.$emit('focus', quill);
    },
    onEditorReady(quill) {
      this.$emit('ready', quill);
    },
    onEditorChange({ quill, html, text }) {
      this.$emit('input', { quill, html, text });
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
};

</script>
