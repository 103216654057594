<style scoped>
* {
  box-sizing: border-box
}

.grid {
  height: calc(100vh - 500px);
  flex: 1 1;
  overflow: hidden;
  display: flex;
  margin-right: 10px;
}

.grid2 {
  flex: 1;
  overflow-y: hidden;
  height: -moz-calc(100%);
  height: -webkit-calc(100%);
  height: calc(100vh - 380px);
}

.rodape {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
}

</style>

<template>
  <!-- eslint-disable -->
  <div class="flex flex-col">
    <div v-if="crudForm.mode !== 'consulta' && showButtons === true" class="flex flex-wrap items-end">
      <f-button
        :caption="crudForm.getButtonCaption('Novo')"
        v-if="enableButtonNew && crudForm.hasPermission('cria')"
        @click="newRecord"
      >
        <FontAwesomeIcon icon="plus" />
      </f-button>
      <f-button :caption="crudForm.getButtonCaption('Abrir')" @click="openRecord" :disabled="!list.length>0">
        <FontAwesomeIcon icon="external-link-square-alt" />
      </f-button>
      <div class="ml-2 mt-2">
        <slot name="find-buttons" />
      </div>
      <f-button
        type="warning" :plain="true"
        caption="Ajuda"
        class="ml-2 mt-2"
        @click="$emit('link-doc', linkDoc)" v-if="linkDoc"
      >
        <FontAwesomeIcon icon="question" />
      </f-button>
    </div>

    <!-- Parte onde tem os filtros, so mostre filtros se o form nao for um "child" -->
    <div class="flex flex-wrap p-0 items-center" v-if="crudForm.mode === 'tab'">
        <span
          v-for="(filter, idx) in schema.findFilters.filter(item => item.visible === undefined || item.visible === true)"
          :key="filter.name">
         <f-textbox
           v-if="filter.name ==='main'"
           v-model="filtersValues[filter.name]"
           :caption="!$store.getters.isMobile ? filter.label: ''"
           :width="filter.width"
           :style="$store.getters.isMobile ? 'width: 65vw': ''"
           @keyup="keyup"
         />
         <FilterField
           class="ml-2 p-0 "
           v-else-if="!$store.getters.isMobile && !filter.extraFilter"
           v-model="filtersValues[filter.name]"
           :filter="filter"
           @change="keyup"
           @lookup-value="filtersValues[`${filter.name}_lookup_value`] = $event"
         />
      </span>
      <f-button
        @click="consulta"
        :caption="!$store.getters.isMobile ? 'Consulta': ''"
        :class="buttonSearshClass"
        :style="$store.getters.isMobile ? 'margin-top: -4px': ''"
      >
        <FontAwesomeIcon icon="search" />
      </f-button>
      <f-button
        v-if="haveExtraFilters || $store.getters.isMobile"
        type="warning"
        class="rounded-l-none mt-4"
        :caption="!$store.getters.isMobile ? 'Filtros': ''"
        :style="$store.getters.isMobile ? 'margin-top: -4px; margin-left: -1px': ''"
        @click="showExtraFilters = !showExtraFilters"
      >
        <FontAwesomeIcon icon="search-plus" />
      </f-button>
    </div>

    <div
      class="grid"
      v-loading="loading"
      :element-loading-text="loadingMessage"
      element-loading-spinner="el-icon-loading"
    >
      <slot name="grid">
        <f-grid
          class="grid2 f-flex-stretch"
          element-loading-text="Aguarde..."
          ref="grid"
          :columns="columns"
          :data="list"
          :selecionSingle="crudForm.isChild || $store.getters.isMobile || selecionSingle === true"
          :height="styleGrid"
          :filterRow="gridFilterRow"
          :styleCell="styleCell"
          :styleRow="styleRow"
          :allowColumnResizing="true"
          :id="gridId"
          :columnChooser="true"
          :searchPainel="searchPainel"
          :paging="pageSize === 50"
          :pageSize="pageSize"
          @dblClick="gridDoubleClick"
          @dataBound="$emit('griDataBound', $event)"
          @gridSort="gridSort"
          @change="gridChange"
          @keyup="gridKeyup"
          @new-page="goToLastGridPage"
        >
        </f-grid>
      </slot>
    </div>

    <div class="rodape border" v-if="crudForm.mode==='tab'">
      <p class="mr-2">Listados</p>
      <span class="mr-2">{{ list.length }}</span>
      <f-button type="success" v-if="lastPage === false" @click="maisRegistro">
        <FontAwesomeIcon icon="plus" />
        {{ pageSize }}
      </f-button>
    </div>
    <f-dialog v-model="showExtraFilters" :width="$store.getters.isMobile ? '100%':'40%'" title="Filtros de consulta:">
      <ShowExtraFilters
        v-if="showExtraFilters"
        :filters="schema.findFilters"
        :filtersValues="filtersValues"
        @keyup="keyup"
        @consulta="consulta"
        @success="showExtraFilters = !showExtraFilters"
      />
    </f-dialog>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';

import moment from 'moment';
import {
  faExternalLinkSquareAlt,
  faPlus,
  faSearch,
  faSearchPlus,
} from '@fortawesome/free-solid-svg-icons';
import FilterField from './CrudFormFindFilters';
import ShowExtraFilters from './CrudFormFindExtraFilters';

library.add(faPlus, faSearch, faSearchPlus, faExternalLinkSquareAlt);
export default {
  name: 'CrudFormFind',
  components: {
    FilterField,
    ShowExtraFilters,
  },
  props: {
    schema: { type: Object, required: true },
    height: { default: '100%' },
    filters: { type: Object, default: () => null },
    gridFilterRow: { type: Boolean, default: true },
    selecionSingle: { type: Boolean, default: false },
    allowColumnReordering: { type: Boolean, default: true },
    allowSorting: { type: Boolean, default: true },
    formulario: { type: Object },
    styleCell: { type: Function, default: null },
    styleRow: { default: null },
    getFormName: { type: Function, default: null },
    showButtons: { type: Boolean, default: true },
    searchPainel: { type: Boolean, default: false },
    enableButtonNew: { type: Boolean, default: true },
    formParent: { type: Object, default: () => null }, // indica que o form tem um master, ou seja, é um form filho
    formParentField: { type: String, default: null },
    crudForm: {
      type: Object,
      default: () => {
      },
    },
    gridColumns: { type: Array, default: () => [] },
    linkDoc: { type: String, default: '' },
  },
  data() {
    return {
      haveExtraFilters: false,
      showExtraFilters: false,
      filtersValues: {},
      loading: false,
      loadingMessage: 'Aguarde...',
      pageSize: 50,
      page: 1,
      lastPage: true,
      carregando: false,
      keyuptimeout: null,
      consultaHeight: '100%',
      slected_ids: [],
      dataset: null,
      message: {
        text: '',
        visible: false,
        type: 'info',
      },
      appStyle: {},
      colunas: [],
      list: [], // store the records used by the grid
      selectedRecords: {},
      filterStyle: {
        width: '300px',
        fontSize: '12px',
      },
    };
  },
  mounted() {
    this.find();
  },
  created() {
    if (this.crudForm.isChild) {
      this.formParent.schema.onRecordChange.push((record) => {
        this.list = record[this.formParentField] || [];
      });
      return;
    }

    // se nao for um detalhe, crie o dataset interno
    this.dataset = this.$utils.Object.clone(this.schema);
    // isso é necessario pois o dataset de consulta é diferente do dataset de cadastro, porém, no de cadastro é definido
    // essa funcao que retorna a query para fazer a consulta
    if (this.schema.getFindQuery) {
      this.dataset.getFindQuery = this.schema.getFindQuery;
    }
    this.setInitialFilter();
    this.dataset.onListChange.push((list) => {
      // atualiza a lista do form find, ou seja, o grid que está sendo mostrado
      this.list = list;
    });

    this.dataset.onListAdd.push((list) => {
      this.list = this.list.concat(list);
    });
  },
  computed: {
    buttonSearshClass() {
      let buttonClass = 'mt-4';
      if (this.haveExtraFilters) {
        buttonClass = `${buttonClass} rounded-none`;
      }
      return buttonClass;
    },
    columns() {
      const columns = [];
      this.gridColumns.forEach((column) => {
        if (column.visible !== false) {
          columns.push({
            field: column.name,
            title: column.caption,
            format: column.format,
            type: column.type,
            decimals: column.decimals,
            template: column.template,
            width: column.width,
          });
        }
      });
      return columns;
    },
    styleGrid() {
      if (this.mod === 'tab' || this.mod === 'consulta') {
        return '100%';
      }
      return 'auto';
    },
    // pega o id do grid, necessario para salvar a config do mesmo no localstorage e difenciar a config para cada tela
    gridId() {
      return `/users/settings/${this.$store.getters.infoLogin.email}/${window.location.href}/grid`;
    },
  },
  methods: {
    newRecord() {
      this.$emit('newRecord');
    },
    openRecord() { // Funcao para abrir o cadastro com os registros selecionados
      if (this.crudForm.isChild) {
        this.$emit('openRecord', {
          list: this.list,
          currentRecord: this.selectedRecords[0],
        });
        return;
      }
      this.$emit('openRecord', this.selectedRecords);
    },
    gridDoubleClick() {
      if (this.crudForm.isChild) {
        this.$emit('openRecord', {
          list: this.list,
          currentRecord: this.selectedRecords[0],
        });
        return;
      }
      this.$emit('dblClick', this.selectedRecords);
    },
    setInitialFilter() {
      // eslint-disable-next-line no-restricted-syntax
      for (const filter of this.schema.findFilters) {
        if (filter.value) {
          this.$set(this.filtersValues, filter.name, filter.value);
        }
      }
      Object.keys(this.$store.getters.crudFormFindInitialFilter).forEach((key) => {
        const item = this.$store.getters.crudFormFindInitialFilter[key];
        this.$set(this.filtersValues, key, item);
      });
      this.$store.dispatch('setCrudFormFindInitialFilter', {});
    },
    // funcao para processar os filtros, realizar transformacoes e alteracoes antes de submeter a consulta
    processaFiltros() {
      const filtrosValores = {};
      if (this.schema.findFilters) {
        // eslint-disable-next-line no-restricted-syntax
        for (const filter of this.schema.findFilters) {
          if (filter.extraFilter) {
            this.haveExtraFilters = true;
          }
          if (this.filtersValues[filter.name]) {
            const fieldName = filter.lookupFieldName ? `${filter.name}_lookup_value` : filter.name;
            filtrosValores[filter.name] = this.filtersValues[fieldName];
            if (['date', 'datetime'].includes(filter.type)) {
              filtrosValores[filter.name] = this.$utils.Datetime.toStringIso(filtrosValores[filter.name]);
            }
          }
        }
      }
      if (this.filters) {
        Object.keys(this.filters)
          .forEach((key) => {
            filtrosValores[key] = this.filters[key];
          });
      }
      if (_.get(filtrosValores, 'main', '').length >= 2) {
        this.pageSize = 1000;
      } else {
        this.pageSize = 50;
      }
      if (this.page > 1) {
        filtrosValores.main = `${filtrosValores.main || ''}[page=${this.page}]`;
      }
      return filtrosValores;
    },
    consulta() {
      this.page = 1;
      this.find();
    },
    find() {
      if (this.crudForm.mode !== 'tab') {
        this.$emit('find');
        return;
      }
      if (this.dataset) {
        const filtrosValores = this.processaFiltros();
        this.loading = true;
        this.dataset.find(filtrosValores, this.page)
          .then((records) => {
            this.lastPage = records.length < this.pageSize;
            if (this.list.length > 0) {
              if (this.$refs.grid) {
                this.$refs.grid.focus();
              }
            }
            this.$emit('find');
          })
          .catch((message) => {
            this.$showMessage({ type: 'error', message, time: 2000 });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    goToLastGridPage() {
      if (this.page > 1) {
        this.$refs.grid.goToLastPage();
      }
    },
    // funcao que formata os campos de data/hora, dinheiro, numeros baseada no tipo do campo definido
    formatFields(list) {
      const fieldsToFormat = this.schema.findGridColumns.filter((item) => ['datetime', 'date',
        'money', 'double'].includes(item.type));
      if (fieldsToFormat && fieldsToFormat.length) {
        const fields = this.$utils.Object.convertArrayToObject(fieldsToFormat, 'name');
        // eslint-disable-next-line no-restricted-syntax
        for (const item of list) {
          Object.keys(fields)
            .forEach((key) => {
              const field = fields[key];
              if (item[field.name]) {
                // eslint-disable-next-line default-case
                switch (field.type) {
                  case 'datetime':
                    item[field.name] = this.$utils.Datetime.strToDate(item[field.name]);
                    break;
                  case 'date':
                    item[field.name] = this.$utils.Datetime.strToDate(item[field.name]);
                    break;
                }
              }
            });
        }
      }
    },
    formatDatetime(value, format) {
      if (!format) {
        // eslint-disable-next-line no-param-reassign
        format = 'DD/MM/YYYY hh:mm:ss';
      }
      return moment(value)
        .format(format);
    },
    gridKeyup(event) {
      if (event.key === 'Enter') {
        if (this.sigeflex.lookupMode) {
          this.sigeflex.lookupCallback(this.registroSelecionado[0]);
          this.selectRecords();
        } else {
          this.selectedRecords();
        }
      }
    },
    keyup(event) {
      clearTimeout(this.keyuptimeout);
      if (event.key === 'Enter') {
        this.consulta();
        return;
      }
      if (this.$store.getters.infoLogin.userSettings.disableAutoFind) {
        return;
      }
      this.offset = 0;
      this.keyuptimeout = setTimeout(() => {
        this.consulta();
      }, 1500);
    },
    gridSort() {
    },
    gridChange(selectedRecords) {
      this.selectedRecords = selectedRecords;
      this.$emit('selectedRecords', this.selectedRecords);
    },
    resize() {
      this.$refs.grid.resize();
    },
    maisRegistro() {
      this.page += 1;
      this.find();
    },
    unSelectRows() {
      this.$refs.grid.unSelectRows();
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.getters.currentOrg': function () {
      return this.find();
    },
    // eslint-disable-next-line func-names
    '$store.getters.pageSize.height': function () {
      this.$nextTick(() => {
        let ht = 1080;
        const grid2 = document.querySelector('.grid2');
        const listados = document.querySelector('.rodape');
        if (grid2 && listados) {
          if (this.$store.getters.pageSize.height <= 470) {
            ht = this.$store.getters.pageSize.height - 250;
            grid2.setAttribute('style', `height: ${ht}px`);
            listados.setAttribute('style', 'margin-bottom: 40px');
            return;
          }
          if (this.$store.getters.pageSize.height <= 570) {
            ht = this.$store.getters.pageSize.height - 270;
            grid2.setAttribute('style', `height: ${ht}px`);
            listados.setAttribute('style', 'margin-bottom: 30px');
            return;
          }
          if (this.$store.getters.pageSize.height <= 670) {
            ht = this.$store.getters.pageSize.height - 290;
            grid2.setAttribute('style', `height: ${ht}px`);
            listados.setAttribute('style', 'margin-bottom: 20px');
            return;
          }
          if (this.$store.getters.pageSize.height <= 770) {
            ht = this.$store.getters.pageSize.height - 310;
            grid2.setAttribute('style', `height: ${ht}px`);
            listados.setAttribute('style', 'margin-bottom: 10px');
            return;
          }
          if (this.$store.getters.pageSize.height <= 870) {
            ht = this.$store.getters.pageSize.height - 330;
            grid2.setAttribute('style', `height: ${ht}px`);
            return;
          }
          if (this.$store.getters.pageSize.height <= 970) {
            ht = this.$store.getters.pageSize.height - 350;
            grid2.setAttribute('style', `height: ${ht}px`);
            return;
          }
        }
        ht = this.$store.getters.pageSize.height - 400;
        grid2.setAttribute('style', `height: ${ht}px`);
      });
    },
  },
};
</script>
