<template>
  <f-crud-form
    ref="form"
    v-if="service"
    v-on="$listeners"
    :schema='service'
     permissao="estoque.fabricante"
    :formsValidation="[$refs.formValidation]"
  >
    <f-row>
      <f-col col="12">
        <f-form ref="formValidation">
          <f-textbox
            caption="Nome"
            v-model="service.record.nome"
            :validations="validations.nome"
          />
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import Service from './FabricanteService';

export default {
  created() {
    this.service = new Service();
  },
  data() {
    return {
      formValidation: null,
      service: null,
      validations: {
        nome: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 60,
            message: 'Nome deve ter entre 2 e 60 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
};
</script>
