import { render, staticRenderFns } from "./Combobox.vue?vue&type=template&id=4206f459&scoped=true&"
import script from "./Combobox.vue?vue&type=script&lang=js&"
export * from "./Combobox.vue?vue&type=script&lang=js&"
import style0 from "./Combobox.vue?vue&type=style&index=0&id=4206f459&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../sigeflex-clinica/frontend/vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4206f459",
  null
  
)

export default component.exports