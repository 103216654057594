<template>
  <h5>
    Página nao encontrada
  </h5>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>

</style>
