<template>
  <div style="">
    <div class="flex flex-col justify-items-stretch shadow-lg p-4">
      <div v-if="licenciamento.situacao === 'PENDENTE'">
        <p class="mb-5 text-center font-sans text-lg">
          <b>Licença pendente de pagamento!</b>
        </p>
      </div>
      <div class="flex justify-center">
        <div>
          <f-textbox caption="Situacao" :value="licenciamento.situacao" :readonly="true"/>
          <f-textbox caption="Número Série" :value="licenciamento.numeroSerie" :readonly="true"/>
          <f-textbox caption="Tipo de Licença" :value="licenciamento.licenca" :readonly="true"/>
          <f-numberbox caption="Valor" :value="licenciamento.total" :readonly="true"/>
          <f-button
            v-if="licenciamento.situacao === 'PENDENTE'"
            caption="Clique aqui para efetuar o pagamento"
            type="success"
            @click="openCheckout"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicenciamentoRenovacao',
  data() {
    return {
      tabelaPreco: '',
    };
  },
  methods: {
    openCheckout() {
      window.open(this.licenciamento.checkoutUrl, '_blank');
    },
  },
  mounted() {
  },
  computed: {
    licenciamento() {
      return this.$store.getters.currentOrg.licenciamento;
    },
  },
};
</script>

<style scoped>

</style>
