<template>
  <f-dialog
    :width="$store.getters.isMobile ? '100%' : '70%'"
    title="Envio de Mensagem via Whatsapp"
    :value="value"
    @input="$emit('input', $event)"
  >
    <Whatsapp :numero="numero" :mensagem="mensagem" ref="whatsapp" v-if="value"/>
  </f-dialog>
</template>

<script>
import Whatsapp from './Whatsapp';

export default {
  name: 'WhatsappModal',
  props: {
    value: { type: Boolean, default: false },
    numero: { type: String, default: '' },
    mensagem: { type: String, default: '' },
  },
  components: {
    Whatsapp,
  },
  data() {
    return {
      numeroInterno: '',
    };
  },
  watch: {
    value(newValue) {
      this.value = newValue;
      this.numeroInterno = '';
      if (this.$refs.whatsapp) {
        this.$refs.whatsapp.reset();
      }
    },
    numero(newValue) {
      this.$nextTick(() => { this.numeroInterno = newValue; });
    },
  },
};
</script>
