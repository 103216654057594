<template>
  <Config @service="service = $event">
    <div v-if="service"></div>
  </Config>
</template>

<script>
import Config from 'comps/apps/config/Config';

export default {
  name: 'org',
  mixins: [
    Config,
  ],
  components: {
    Config,
  },
};
</script>
