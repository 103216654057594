<template>
  <f-crud-form
    v-if="service"
    v-on="$listeners"
    :styleCell="styleCell"
    :schema="service"
    :enableButtonNew="false"
    :enableButtonEdit="false"
    :formsValidation="[formValidation]"
    permissao="auth.usuario"
    detailSuccessMessage="Convite enviado com sucesso, o usuário precisa confirmar"
  >
    <div slot="find-buttons">
      <f-button
        caption="Convidar novo usuário"
        @click="mostraTelaConvite = true"
      />
    </div>
    <f-row>
      <f-col col="6">
        <f-form ref="form" @mounted="formValidation = $event">
          <f-textbox
            caption='Nome'
            id="auth-user-name"
            v-model='service.record.name'
            :validations="validations.name"
          />
          <f-textbox
            caption='Email'
            type="email"
            v-model='service.record.email'
            :validations="validations.email"
          />
        </f-form>
      </f-col>
    </f-row>
    <Convite :service="service" v-model="mostraTelaConvite" />
  </f-crud-form>
</template>

<script>
import UserService from './UserService';
import Convite from './UserNew';

export default {
  components: {
    Convite,
  },
  data() {
    return {
      service: null,
      formValidation: null,
      mostraTelaConvite: false,
      validations: {
        name: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        email: [
          {
            min: 2,
            max: 120,
            message: 'Email deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        groupName: [
          {
            required: true,
            message: 'Grupo é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new UserService();
  },
  mounted() {
    // quando passa o form para o service, o component form-crud irá tentar validar antes de salvar
    this.service.form = this.$refs.form;
  },
  methods: {
    styleCell(col, item) {
      if (col === 'status') {
        if (item.status === 'ATIVO') {
          return { 'background-color': this.$style.colorGreen, color: 'white' };
        }
        if (item.status === 'NÃO CONFIRMADO') {
          return { 'background-color': this.$style.colorRed, color: 'white' };
        }
      }
      return {};
    },
  },
};
</script>
