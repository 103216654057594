export default (org) => {
  if (org.permissoes) {
    if (org.permissoes.includes('pessoa.')) {
      return {
        text: 'Pessoas',
        description: 'Cadastro de Pessoas',
        route: '/pessoas',
        // eslint-disable-next-line global-require
        img: require('./person32x32.png'),
      };
    }
  }
  return null;
};
