<template>
  <div class="flex items-center">
    <span v-if="licenciamento" v-html="licenciamento.dadosSuporte"></span>
  </div>
</template>

<script>
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(faWhatsapp);
dom.watch();
export default {
  name: 'PagamentoLicenca',
  methods: {
    // funcao para rodar o chat em javascript da revenda para o suporte
    rodarChatJavascript() {
      if (this.licenciamento) {
        // eslint-disable-next-line no-new-func
        const F = new Function(this.licenciamento.chatJavascriptCode);
        return (F());
      }
      return null;
    },
  },
  computed: {
    licenciamento() {
      return this.currentOrg.licenciamento;
    },
    currentOrg() {
      return this.$store.getters.currentOrg;
    },
  },
  mounted() {
    this.rodarChatJavascript();
  },
  watch: {
    currentOrg() {
      this.rodarChatJavascript();
    },
  },
};
</script>
