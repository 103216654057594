export default {
  state: () => ({
    loading: false,
    message: {
      type: 'success',
      msg: '',
      time: null,
    },
    infoLogin: {
      name: '',
      email: '',
      token: '',
      settings: {},
      orgs: [],
    },
    currentOrg: {
      id: '',
      name: '',
      revendaNameSpace: '',
      permissoes: '',
      permissoesByKey: '',
      modulos: {},
    },
    pageSize: { width: 1140, height: 640 }, // variavel para guardar a informacao se o tamanho da tela
    // esse field é usado por campos lookups, passar valor de filtro
    // e a tela de consulta setar o valor para o filtro inicial, útil quando o usuario digite o
    // que quer consulta no campo lookup e o valor é passado para a tela de consulta
    crudFormFindInitialFilter: {},
    // campo indicando para o crudform se esta abrindo em modo lookup ou nao
    crudFormLookup: '',
    menuCollapsed: false,
    showMenu: false, // variavel para guardar a informacao se o menu ta aberto ou nao
  }),
  getters: {
    getMessage(state) {
      return state.message;
    },
    loading(state) {
      return state.loading;
    },
    infoLogin(state) {
      return state.infoLogin;
    },
    crudFormFindInitialFilter(state) {
      return state.crudFormFindInitialFilter;
    },
    crudFormLookup(state) {
      return state.crudFormLookup;
    },
    pageSize(state) {
      return state.pageSize;
    },
    isMobile(state) {
      return state.pageSize.width <= 768 || state.pageSize.height <= 414;
    },
    isLandscape(state) {
      return state.pageSize.width > state.pageSize.height;
    },
    menuCollapsed(state) {
      return state.menuCollapsed;
    },
    showMenu(state) {
      return state.showMenu;
    },
    currentOrg(state) {
      return state.currentOrg;
    },
    hasPermission: (state) => (code) => state.currentOrg.permissoesByKey[code] === true,
  },
  mutations: {
    setMessage(state, msg) {
      state.message = msg;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setInfoLogin(state, value) {
      state.infoLogin = value;
    },
    setCrudFormFindInitialFilter(state, value) {
      state.crudFormFindInitialFilter = value;
    },
    setCrudFormLookup(state, value) {
      state.crudFormLookup = value;
    },
    setPageSize(state, value) {
      state.pageSize = value;
    },
    setMenuCollapsed(state, value) {
      state.menuCollapsed = value;
    },
    setShowMenu(state, value) {
      state.showMenu = value;
    },
    setCurrentOrg(state, value) {
      state.currentOrg = value;
    },
  },
  actions: {
    setMessage(context, payload) {
      if (payload.time > 0) {
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          payload.msg = '';
          context.commit('setMessage', payload);
        }, payload.time);
      }
      context.commit('setMessage', payload);
    },
    setToken(context, token) {
      context.commit('setToken', token);
    },
    setLoading(context, value) {
      context.commit('setLoading', value);
    },
    setInfoLogin(context, value) {
      context.commit('setInfoLogin', value);
    },
    setCrudFormFindInitialFilter(context, value) {
      context.commit('setCrudFormFindInitialFilter', value);
    },
    setCrudFormLookup(context, value) {
      context.commit('setCrudFormLookup', value);
    },
    setPageSize(context, value) {
      context.commit('setPageSize', value);
    },
    setMenuCollapsed(context) {
      context.commit('setMenuCollapsed', !context.state.menuCollapsed);
    },
    setShowMenu(context, value) {
      context.commit('setShowMenu', value);
    },
    setCurrentOrg(context, value) {
      const currentOrg = context.state.infoLogin.orgs.filter((org) => org.id === value)[0];
      window.sessionStorage.setItem('currentOrg', JSON.stringify(currentOrg));
      context.commit('setCurrentOrg', currentOrg);
    },
  },
};
