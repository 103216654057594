import Login from './Login';
import Registration from './Register';
import Forgot from './ForgotPassword';
import Confirmation from './Confirmation';
import ChangePassword from './ChangePassword';
import Users from './User';
import Groups from './Group';
import Tokens from './Token';
import ConfirmRejectInvitation from './ConfirmRejectInvitation';
import UserProfile from './UserProfile';

const routers = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { requiresAuth: false },
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot,
    meta: { requiresAuth: false },
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation,
    meta: { requiresAuth: false },
  },
  {
    path: '/confirm_invitation',
    name: 'ConfirmInvitation',
    component: ConfirmRejectInvitation,
    meta: { requiresAuth: false },
  },
  {
    path: '/reject_invitation',
    name: 'RejectConfirmation',
    component: ConfirmRejectInvitation,
    meta: { requiresAuth: false },
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: false },
  },
  {
    path: '/usuarios',
    name: 'Usuários',
    component: Users,
    meta: { requiresAuth: true },
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/grupos',
    name: 'Grupos',
    component: Groups,
    meta: { requiresAuth: true },
  },
  {
    path: '/tokens',
    name: 'Tokens',
    component: Tokens,
    meta: { requiresAuth: true },
  },
];

export default routers;
