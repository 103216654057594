<template>
  <f-form-row>
    <f-dialog
      :value="value"
      width="60%"
      :fullstran="$store.getters.isMobile"
      title="Cálculo de Percentual sobre itens"
      @input="input"
    >
      <div class="flex flex-col">
        <div class="flex items-end">
          <f-numberbox
            caption="Percentual"
            ref="percentual"
            v-model="model.percentual"
            :autofocus="true"
          />
          <f-numberbox
            caption="Total Selecionado"
            :value="totalSelecionado"
            :disabled="true"
            :inputStyle="{color: this.$style.colorBlack}"
          />
          <f-numberbox
            caption="Valor Calculado"
            :value="valorTotal"
            :disabled="true"
            :inputStyle="{color: this.$style.colorBlack}"
          />
          <f-button caption="OK" @click="concluido" />
        </div>
        <div style="width: 100%">
          <f-grid
            class="grid2 f-flex-stretch"
            element-loading-text="Aguarde..."
            ref="grid"
            :columns="columns"
            :data="list"
            @change="gridChange"
          >
          </f-grid>
        </div>
      </div>
    </f-dialog>
  </f-form-row>
</template>

<script>
export default {
  name: 'CalculaMetroLenear',
  props: {
    percentual: {
      type: Number,
      required: true,
    },
    produtos: {
      type: Array,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: {
        percentual: 0,
        total: 0,
      },
      produtosSelecionados: [],
      list: [],
      columns: [
        {
          title: 'Item',
          field: 'nome',
        },
        {
          title: 'UN',
          field: 'un',
        },
        {
          title: 'Total',
          field: 'total',
          type: 'number',
        },
      ],
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.reset();
        this.model.percentual = this.percentual;
        Object.keys(this.produtos).forEach((key) => {
          const item = this.produtos[key];
          this.list.push({
            id: this.$utils.String.uiid(),
            nome: item.nome,
            un: item.un,
            total: item.total,
            check: true,
          });
        });
        this.$nextTick(() => this.$refs.percentual.focus());
      }
    },
  },
  computed: {
    totalSelecionado() {
      let soma = 0;
      Object.keys(this.produtosSelecionados).forEach((key) => {
        const item = this.produtosSelecionados[key];
        soma += item.total;
      });
      return soma;
    },
    valorTotal() {
      if (this.totalSelecionado > 0) {
        return (this.totalSelecionado * this.model.percentual) / 100;
      }
      return 0;
    },
  },
  methods: {
    gridChange(selectedRecords) {
      this.produtosSelecionados = selectedRecords;
    },
    input(value) {
      this.$emit('input', value);
    },
    concluido() {
      this.model.total = this.valorTotal;
      this.$emit('input', false);
      this.$emit('concluido', this.model);
    },
    reset() {
      this.model = {
        total: 0,
        percentual: 0,
      };
      this.list = [];
    },
  },
};
</script>
