import moment from 'moment';

export default class DatetimeUtil {
  formatDatetimeFromList(list) {
    const datetimeFields = this.grid.filter((item) => item.type === 'datetime');
    const dateFields = this.grid.filter((item) => item.type === 'date');
    if (datetimeFields || dateFields) {
      list.forEach((item) => {
        if (datetimeFields) {
          datetimeFields.forEach((field) => {
            // eslint-disable-next-line no-param-reassign
            item[field.name] = this.formatDatetime(item[field.name]);
          });
        }
        if (dateFields) {
          dateFields.forEach((field) => {
            // eslint-disable-next-line no-param-reassign
            item[field.name] = this.formatDatetime(item[field.name], 'DD/MM/YYYY');
          });
        }
      });
    }
  }

  static formatDatetime(value, format) {
    if (!format) {
      // eslint-disable-next-line no-param-reassign
      format = 'DD/MM/YYYY hh:mm:ss';
    }
    return moment(value).format(format);
  }

  static toStringIso(dateValue) {
    return moment(dateValue).toISOString(true);
  }

  static strToDate(strValue) {
    return moment(strValue).toDate();
  }

  static DiffInDays(date1, date2) {
    const a = moment(date1);
    const b = moment(date2);
    return a.diff(b, 'days'); // 1
  }
}
