<style lang="scss">

.f-field {
  margin: 0 4px 4px 0 !important;
}

.f-invalid >* input {
  border-color: #EF4444;
}

</style>

<template>
  <div class="f-field flex justify-center flex-col w-full" :id="generatedId()">
    <div>
      <f-label
        :caption="caption"
        :isRequired="isRequired"
        :helpTitle="helpTitle"
        :helpMessage="helpMessage"
      />
    </div>
    <div :class="classes()">
      <el-input-tag
        autocomplete="off"
        ref="input"
        :value="value"
        :size="size"
        :width="width"
        :autofocus="autofocus"
        :disabled="isDisabled"
        @input="$emit('input', $event)"
        @keyup.native="$emit('keyup', $event)"
      />
      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Tag } from 'element-ui';
import ElInputTag from 'el-input-tag';
import inputMixing from './InputMixing';

Vue.use(ElInputTag);
Vue.component('el-tag', Tag);

export default {
  mixins: [
    inputMixing,
  ],
  props: {
    caption: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: '',
    },
    caseOption: {
      type: String,
      default: 'uppercase',
    },
  },
};
</script>
