import Dataset from '../../libs/dataset';

class Service extends Dataset {
  constructor() {
    super();
    this.linkerHost = 'http://localhost:5000/api';
  }

  // no caso do linker, é enviado ja as etiquetas junto com a configuracao das mesmas
  imprimeEtiqueta(impressao) {
    return new Promise((resolve, reject) => {
      this.post(`${this.linkerHost}/etiquetas/imprimir`, impressao, { timeout: 60000 })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Service;
