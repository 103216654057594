<template>
    <div class="flex flex-col mt-3">
      <f-card>
        <div class="mb-5 text-center">
          <span class="mr-5">Envio de Mensagem via Whatsapp</span>
          <em class="fab fa-whatsapp fa-3x"></em>
          <p class="text-red-500 text-sm">É preciso está logado no whatsapp no seu computador para funcionar</p>
        </div>

        <f-form ref="formValidation">
          <f-textbox
            caption="Número"
            width="120px"
            v-model="numeroInterno"
            :validations="validations.required"
            :mask="['(##) ####-####', '(##) #####-####']"
          />
          <f-textbox
            caption="Mensagem"
            type="textarea"
            caseOption="normal"
            v-model="mensagemInterna"
            :validations="validations.mensagem"
          />
        </f-form>
        <f-button caption="Enviar" @click="enviarMensagem">
        </f-button>
      </f-card>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

library.add(faWhatsapp);
export default {
  name: 'Whatsapp',
  props: {
    numero: { type: String, default: '' },
    mensagem: { type: String, default: '' },
  },
  data() {
    return {
      mensagemInterna: '',
      numeroInterno: '',
      validations: {
        mensagem: [
          {
            min: 3, max: 500, message: 'Mensagem  deve conter entre 2 e 500 caracteres.', trigger: 'blur',
          },
          { required: true, message: 'Mensagem é obrigatório', trigger: 'blur' },
        ],
        required: [
          {
            required: true,
            message: 'Número é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  mounted() {
    this.numeroInterno = this.numero;
    this.mensagemInterna = this.mensagem;
  },
  methods: {
    enviarMensagem() {
      if (!this.$refs.formValidation.validate()) {
        return;
      }
      const numero = _.string().onlyNumber(this.numeroInterno);
      const url = `https://wa.me/55${numero}?text=${this.mensagemInterna}`;
      window.open(url, '_blank');
    },
    reset() {
      if (this.$refs.formValidation) {
        this.$refs.formValidation.reset();
      }
      this.mensagemInterna = '';
      this.numeroInterno = '';
    },
  },
  watch: {
    numero(newValue) {
      this.numeroInterno = newValue;
    },
    mensagem(newValue) {
      this.mensagemInterna = newValue;
    },
  },
};
</script>

<style scoped>
.fa-whatsapp {
  color: #fff;
  background: linear-gradient(#25d366, #25d366) 14% 84%/16% 16% no-repeat,
  radial-gradient(#25d366 60%, transparent 0);
}
</style>
