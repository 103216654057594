<template>
  <div
    class="flex justify-center w-full h-full text-center items-center"
    :class="classes"
  >
    <div v-show="message.msg" class="w-full justify-center text-center items-center">
      {{ message.msg }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppFooter',
  computed: {
    message() {
      return this.$store.getters.getMessage;
    },
    classes() {
      if (this.message.msg) {
        if (this.message.type === 'success') {
          return { 'bg-green-500': true, 'text-white': true };
        }
        if (this.message.type === 'error') {
          return ['bg-red-500', 'text-white'];
        }
      }
      return ['text-white'];
    },
  },
};
</script>

<style scoped>
.teste {
  background-color: #8b211e;
}
.footer {
  padding: 0;
  margin: 0;
  height: 5vh;
  background-color: inherit;
}
</style>
