<template>
  <div class="flex text-center">
    <h3>Bem vindo ao Sigeflex Clínica - Sistema de Gestão de Clínicas</h3>
  </div>
</template>

<script>
export default {
  name: 'index',

};
</script>

<style scoped>

</style>
