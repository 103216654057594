import Dataset from 'comps/libs/dataset';

const getFields = () => `
        id
        createdAt
        updatedAt
        nome
        valorUnitario
        codigo
        diasValidade
        linkPagamento
        descricaoPagamento
        un
`;

class Service extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [
      { caption: 'Nome', name: 'nome' },
      { caption: 'Código', name: 'codigo' },
      { caption: 'UN', name: 'un' },
      { caption: 'Valor Unitário', name: 'valorUnitario', type: 'currency' },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome ou código',
        name: 'main',
        width: '400px',
        type: 'textbox',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
      un: null,
      valorUnitario: null,
      diasValidade: null,
      codigo: null,
      linkPagamento: null,
      descricaoPagamento: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;
    return `query {
              ServicoList(${filter}){
                id
                createdAt
                updatedAt
                codigo
                nome
                un
                valorUnitario
              }
            }`;
  }

  getByIds(ids) {
    return `query {
            ServicoList(ids: [${ids.join()}]) {
             ${getFields()}
           }
         }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation ServicoCreateMutation($data: ServicoInput!) {
           ServicoCreate(data: $data){
             ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation ServicoMutation($id: String!, $data: ServicoInput!) {
           ServicoUpdate(id: $id, data: $data){
            ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation ServicoDeleteMutation($id: String!){
          ServicoDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
      valorUnitario: this.$utils.Number.parseFloat(model.valorUnitario),
      diasValidade: model.diasValidade || 0,
      un: model.un,
      codigo: model.codigo,
      linkPagamento: model.linkPagamento,
      descricaoPagamento: model.descricaoPagamento,
    };
  }
}

export default Service;
