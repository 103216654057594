<template>
  <f-form-row>
    <f-dialog
      :value="value"
      width="80%"
      title="Composição de Produto"
      @input="input"
      @close="close"
    >
      <div class="flex flex-column">
        <f-textbox
          caption='Composição do Produto'
          :value='produtoComposto.nome'
          :inputStyle="{color: this.$style.colorGreen}"
          :disabled="true"
        />
        <f-numberbox
          caption='Valor Unitário'
          :value='totalComposicao'
          :inputStyle="{color: this.$style.colorGreen}"
          :disabled="true"
        />
        <f-checkbox
          caption="Manter ultimo produto consultado?"
          persistElement="manter-ultimo-produto-consultado"
          v-model="manterUltimoProduto"
        />
        <f-radio-button-group
          v-model="tipoItem"
          :options="[{caption: 'Produto', value: 'produto'},{caption: 'Serviço', value: 'servico'}]"
        />
        <consulta-item
          v-if="tipoItem === 'servico'"
          ref="consultaItem"
          :produto="false"
          :add="addItemInterno"
          @concluido="$emit('concluido')"
          @consultaItem="consultaServico"
        />
        <consulta-item
          v-else
          ref="consultaItem"
          :produto="true"
          :add="addItemInterno"
          :manterUltimoProduto="manterUltimoProduto"
          @concluido="$emit('concluido')"
        />
        <f-grid
          class="grid2 f-flex-stretch"
          element-loading-text="Aguarde..."
          ref="grid"
          :columns="columns"
          :data="composicao"
          :deleteAction="deleteItem"
          @saving="onSaving"
          @change="onSelectecRecords"
        >
        </f-grid>
        <slot></slot>
        <CalculaMetroLinear
          v-model="mostraCalculoMetroLinear"
          :servico="servicoMetroLinear"
          :produtos="produtosComMetragem"
          @concluido="setaMetroCalculado"
        />
        <CalculaPercentual
          v-model="mostraCalculoPercentual"
          :percentual="servicoValorUnitario"
          :produtos="produtosComMetragem"
          @concluido="setaPercentualCalculado"
        />
      </div>
    </f-dialog>
  </f-form-row>
</template>

<script>
import ConsultaItem from './ConsultaItem';
import CalculaMetroLinear from './CalculaMetroLinear';
import CalculaPercentual from './CalculaPercentual';

export default {
  name: 'Composicao',
  components: {
    ConsultaItem,
    CalculaMetroLinear,
    CalculaPercentual,
  },
  props: {
    produtoComposto: {
      type: Object,
      default: () => {
      },
    },
    composicao: {
      type: Array,
      default: () => [],
    },
    addItem: {
      type: Function,
      default: () => null,
    },
    deleteItem: {
      type: Function,
      default: () => {
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tipoItem: 'produto',
      mostraCalculoMetroLinear: false,
      mostraCalculoPercentual: false,
      servicoMetroLinear: {},
      servicoValorUnitario: 0,
      manterUltimoProduto: false,
    };
  },
  computed: {
    columns() {
      return [
        { title: 'Tipo', field: 'tipo' },
        { title: 'Item', field: 'nome' },
        { title: 'UN', field: 'un' },
        {
          title: 'Quantidade', field: 'quantidade', type: 'number', editable: true,
        },
        {
          title: 'Comprimento', field: 'comprimento', visible: this.produtosComMetragem.length > 0, editable: true,
        },
        {
          title: 'Largura', field: 'largura', visible: this.produtosComMetragem.length > 0, editable: true,
        },
        { title: 'Medida', field: 'medida', visible: this.produtosComMetragem.length > 0 },
        { title: 'Valor Unitário Ítem', field: 'valorUnitario', type: 'currency' },
        { title: 'Valor Unitário Original', field: 'valorUnitarioOriginal', type: 'currency' },
        { title: 'Total', field: 'total', type: 'currency' },
      ];
    },
    produtosComMetragem() {
      const list = [];
      _.forEach(this.composicao, (item) => {
        if (item.comprimento > 0) {
          list.push({
            nome: item.nome,
            un: item.un,
            comprimento: item.comprimento,
            quantidade: item.quantidade,
            total: item.total,
          });
        }
      });

      return list;
    },
    totalComposicao() {
      let total = 0;
      _.forEach(this.composicao, (item) => {
        total += item.total;
      });
      return total;
    },
  },
  methods: {
    close() {
      const produto = {
        nome: this.$utils.Object.copyObject(this.produtoComposto),
        valorUnitario: this.totalComposicao,
        composicao: this.$utils.Object.copyObject(this.composicao),
      };
      this.$emit('close', produto);
    },
    setaMetroCalculado(record) {
      this.$refs.consultaItem.model.quantidade = record.totalMetroLinear;
    },
    setaPercentualCalculado(record) {
      this.$refs.consultaItem.model.valorUnitario = record.total;
    },
    addItemInterno(record) {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-param-reassign
        record.tipo = this.tipoItem;
        this.addItem(record)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    input(value) {
      this.$emit('input', value);
    },
    consultaServico(record) {
      if (record.un === 'ML') {
        this.mostraCalculoMetroLinear = true;
        this.servicoMetroLinear = record;
        return;
      }
      if (record.un === '%') {
        this.mostraCalculoPercentual = true;
        this.servicoValorUnitario = record.valorUnitario;
      }
    },
    onSaving(record) {
      this.$emit('updateItem', record);
    },
    onSelectecRecords(records) {
      this.$emit('selectedRecords', records || []);
    },
  },
};
</script>
