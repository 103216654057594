import Person from './Person';

const routers = [
  {
    path: '/pessoas',
    name: 'Pessoas',
    component: Person,
  },
];

export default routers;
