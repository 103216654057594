<style scoped>

.toolbar {
  border: 1px solid black;
}

.relatorio {
  box-shadow: 0 0 7px grey;
  border: 1px solid;
  padding: 15px;
  margin: 7px auto auto auto;
  width: 23cm;
}
</style>

<template>
  <f-dialog
    max-width="100%"
    :value="visible"
    :fullscreen="true"
    :title="title"
    @input="visible = $event"
  >
    <div class="flex toolbar" slot="toolbar" v-if="isHtml">
      <div style="margin: 3px; width: 100%" class="flex">
        <f-button v-if="!pdf" caption="Imprimir" @click="print" type="">
          <FontAwesomeIcon icon="print" color="Dodgerblue" />
        </f-button>
        <f-button v-if="!pdf" caption="Enviar por email" @click="print" type="" v-show="false">
          <FontAwesomeIcon icon="mail-bulk" color="#6B7280" />
        </f-button>
        <div style="margin-left: auto;">
          <f-button caption="Fechar" @click="close" type="">
            <FontAwesomeIcon icon="window-close" color="#EF4444" />
          </f-button>
        </div>
      </div>
      <slot name="toolbar"></slot>
    </div>

    <div class="w-auto">
      <StimulSoftViewer
        v-if="isStimulsoft"
        :report="reportTemplate"
        :data="reportData"
        style="width: -moz-available; min-height: 50vh; height:180mm; max-width: 25cm"
      />
      <iframe
        v-else-if="isHtml"
        style="width: -moz-available; min-height: 50vh; height:180mm; max-width: 25cm"
        title="Impressao"
        ref="myiframe"
        id="impressao-iframe"
        class="relatorio"
        :srcdoc="htmlContent"
      />
      <f-iframe v-else :src="`data:application/pdf;base64,${htmlContent}`" />
    </div>
  </f-dialog>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMailBulk, faPrint, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import StimulSoftViewer from './stimulsoftReport/StimulsoftViewer';
import Service from './ReportService';

library.add(faMailBulk, faPrint, faWindowClose);
export default {
  name: 'reportView',
  data() {
    return {
      service: null,
      visible: null,
      htmlContent: '',
      reportTemplate: '',
      reportData: '',
      title: '',
    };
  },
  created() {
    this.service = new Service();
    Vue.prototype.$showReport = this.showReport;
    Vue.prototype.$showReportHtml = this.showReportHtml;
  },
  components: {
    StimulSoftViewer,
  },
  props: {
    pdf: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isStimulsoft() {
      return this.reportTemplate !== '';
    },
    isHtml() {
      return this.htmlContent !== '';
    },
  },
  methods: {
    print() {
      this.$refs.myiframe.contentWindow.print();
    },
    renderHtmlContent() {
      if (_.string().isUrl(this.htmlContent)) {
        axios({
          method: 'get',
          url: this.htmlContent,
        })
          .then((result) => {
            this.htmlContent = result.data;
          })
          .catch((error) => {
            this.$showMessage({
              title: '',
              type: 'Impressão HTML',
              message: `Erro ao tentar imprimir uma URL html : ${error}`,
              time: 4500,
            });
          });
      }
    },
    close() {
      this.visible = false;
    },
    // mostra o relatório usando o stimulsoft
    showReport(title, reportName, data) {
      this.title = title;
      this.reportData = data;
      if (reportName.includes('ReportVersion')) {
        this.reportTemplate = reportName;
        this.mostraPreview(true);
        return;
      }

      if (!window.REPORTS) window.REPORTS = {};
      if (window.REPORTS[reportName]) {
        this.reportTemplate = window.REPORTS[reportName];
        this.mostraPreview(true);
        return;
      }

      this.service.getReportTemplate(reportName)
        .then((template) => {
          window.REPORTS[reportName] = template;
          this.reportTemplate = template;
          this.mostraPreview(true);
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err, time: 4500,
          });
        });
    },
    showReportHtml(title, htmlContent) {
      this.title = title;
      this.htmlContent = htmlContent;
      this.mostraPreview(true);
    },
    mostraPreview(value) {
      this.visible = value;
    },
    reset() {
      this.htmlContent = '';
      this.reportTemplate = '';
      this.reportData = '';
      this.title = '';
    },
  },
  watch: {
    visible(newValue) {
      this.visible = newValue;
      if (newValue) {
        this.renderHtmlContent();
      } else {
        this.reset();
      }
    },
  },
};
</script>
