import Dataset from 'comps/libs/dataset';

export default class LoginService extends Dataset {
  async login(username, password) {
    return this.post('/users/login', {
      username,
      password,
    });
  }

  async register(name, username, password, organization, revenda, licenca) {
    return this.post('/users/register', {
      name,
      username,
      password,
      organization,
      revenda,
      licenca,
    });
  }

  async forgot(username) {
    return this.post('/users/forgot', { username });
  }

  async confirmation(username, code) {
    return this.post('/users/confirmation', {
      username,
      code,
    });
  }

  async changePassword(username, currentPassword, newPassword, repeatedNewPassword) {
    return this.post('/users/change_password', {
      username,
      currentPassword,
      newPassword,
      repeatedNewPassword,
    });
  }
}
