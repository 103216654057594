import Dataset from '../../libs/dataset';

const getFields = () => `
        id
        updatedAt
        createdAt
        disponivel
        nome
        descricao
        modulo
        tipoImpressora
        fotoPreview
        template
        dadosTeste
        colunas
    `;

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      {
        name: 'nome',
        caption: 'Nome',
      },
      {
        name: 'disponivel',
        caption: 'Disponível',
        type: 'boolean',
      },
      {
        name: 'descricao',
        caption: 'Descrição',
      },
      {
        name: 'tipoImpressora',
        caption: 'Tipo Impressora',
      },
      {
        name: 'modulo',
        caption: 'Modulo',
      },
      {
        name: 'fotoPreview',
        caption: 'Foto',
      },
    ];
    this.findFilters = [
      {
        type: 'textbox',
        width: '400px',
        label: 'Consulta por nome, descrição',
        name: 'main',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      disponivel: null,
      nome: null,
      descricao: null,
      modulo: null,
      tipoImpressora: null,
      fotoPreview: null,
      template: null,
      dadosTeste: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}", `;

    return `query {
              EtiquetaList(${filter}) {
                id
                nome
                disponivel
                descricao
                modulo
                tipoImpressora
                fotoPreview
              }
           }`;
  }

  getByIds(ids) {
    return `query {
              EtiquetaList(ids: [${ids.join()}]) {
                ${getFields()}
              }
            }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation EtiquetaCreate($data: EtiquetaInput!) {
           EtiquetaCreate(data: $data){
              ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation EtiquetaUpdate($id: String!, $data: EtiquetaInput!) {
           EtiquetaUpdate(id: $id, data: $data){
              ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation EtiquetaDelete($id: String!){
            EtiquetaDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
      disponivel: model.disponivel,
      descricao: model.descricao,
      modulo: model.modulo,
      tipoImpressora: model.tipoImpressora,
      fotoPreview: model.fotoPreview,
      template: model.template,
      dadosTeste: model.dadosTeste,
      colunas: model.colunas,
    };
  }

  getHostUrl() {
    if (this.getHeaders().org) {
      return this.getHeaders().org;
    }
    return 'https://erp.sigeflex.com/api/linksoft';
  }

  getEtiquetasPorModulo(modulo) {
    return new Promise((resolve, reject) => {
      this.get(`${this.getHostUrl()}/etiquetas?modulo=${modulo}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  imprimeEtiquetaHtml(nome, dados) {
    return new Promise((resolve, reject) => {
      this.post(`${this.getHostUrl()}/etiquetas/gera`, { modelo: nome, data: dados })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Service;
