<template>
  <f-combobox
    :caption="caption"
    :value="value"
    :options="ufs"
    :validations="validations"
    :filterable="true"
    :fieldStyle="{width: '70px !important'}"
    @input="$emit('input', $event)"
  />
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: 'Uf',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validations() {
      if (!this.required) {
        return [];
      }
      return [
        {
          required: true,
          message: 'Campo obrigatório',
          trigger: 'blur',
        },
      ];
    },
  },
  data() {
    return {
      ufs: [
        { value: '', text: '' },
        { value: 'AC', text: 'AC' },
        { value: 'AL', text: 'AL' },
        { value: 'AP', text: 'AP' },
        { value: 'AM', text: 'AM' },
        { value: 'BA', text: 'BA' },
        { value: 'CE', text: 'CE' },
        { value: 'DF', text: 'DF' },
        { value: 'ES', text: 'ES' },
        { value: 'GO', text: 'GO' },
        { value: 'MA', text: 'MA' },
        { value: 'MT', text: 'MT' },
        { value: 'MS', text: 'MS' },
        { value: 'MG', text: 'MG' },
        { value: 'PA', text: 'PA' },
        { value: 'PB', text: 'PB' },
        { value: 'PR', text: 'PR' },
        { value: 'PE', text: 'PE' },
        { value: 'PI', text: 'PI' },
        { value: 'RJ', text: 'RJ' },
        { value: 'RN', text: 'RN' },
        { value: 'RS', text: 'RS' },
        { value: 'RO', text: 'RO' },
        { value: 'RR', text: 'RR' },
        { value: 'SC', text: 'SC' },
        { value: 'SP', text: 'SP' },
        { value: 'SE', text: 'SE' },
        { value: 'TO', text: 'TO' },
      ],
    };
  },
};
</script>
