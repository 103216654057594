<style scoped lang="scss">

.f-field {
  display: flex;
  flex-direction: column;
  margin-right: 3px;
  width: auto !important;

  label {
    margin-bottom: 3px;
  }

  small {
    margin: 5px;
  }
}
</style>

<template>
  <f-row class="f-field flex justify-center flex-col w-full" :style="fieldStyle">
    <f-col>
      <f-label
        :caption="caption"
        :isRequired="isRequired"
        :helpMessage="helpMessage"
      />
    </f-col>
    <f-col>
      <el-select
        ref="input"
        :id="generatedId()"
        :multiple="multiple"
        :collapse-tags="collapseTags"
        :placeholder="placeholder"
        :value="value"
        :size="size"
        :allow-create="allowEdit"
        :filterable="filterable"
        :disabled="disabled || internalDisabled"
        :remote="true"
        :loading="loading"
        @input="$emit('input', $event)"
        @change="change"
        @visible-change="$emit('dropdown', $event)"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :disabled="item.disabled  || false"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
    </f-col>
  </f-row>
</template>

<script>
import { Option, Select } from 'element-ui';
import inputMixing from './InputMixing';

export default {
  mixins: [
    inputMixing,
  ],
  components: {
    'el-select': Select,
    'el-option': Option,
  },
  methods: {
    change(value) {
      const item = this.options.filter((i) => i.value === value)[0];
      this.saveValueToLocalStorage(value);
      this.$emit('change', item);
    },
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: '',
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    collapseTags: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    width: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
