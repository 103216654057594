<style scoped>
>>> .el-dialog__header {
  padding: 0;
}

.close-btn {
  cursor: pointer;
  position: absolute;
}

.header {
  background-color: #3b82f6;
  margin: 0 !important;
  height: 50px;
  color: white;
}
</style>

<template>
  <el-dialog
    class="m-1 h-auto"
    :visible.sync="visible"
    :close-on-press-escape="closeOnEscape"
    :close-on-click-modal="closeOnClickOutside"
    :fullscreen="fullscreen"
    :closable="closable"
    :show-header="showHeader"
    :show-close="false"
    :modal="isModal"
    :width="width"
    :height="height"
    :center="centerContent"
    :append-to-body="true"
    :top="top"
    @close="hide"
    @open="show"
  >
    <div slot="title">
      <slot name="header">
        <div class="flex align-items-center justify-content-center text-center text-lg font-semibold header">
          <FontAwesomeIcon v-if="icon" :icon="icon" style="margin-right: 5px;"/>
          <slot name="title">
            <p style="color: white">{{ title }}</p>
          </slot>
          <FontAwesomeIcon class="close-btn" style="left: 10px;" icon="window-close" color="white" @click="hide"/>
          <FontAwesomeIcon class="close-btn" style="right: 10px;" icon="window-close" color="white" @click="hide"/>
        </div>
        <slot name="toolbar">
        </slot>
      </slot>
    </div>
    <slot></slot>
    <template #footer>
      <slot name="footer">
      </slot>
    </template>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

library.add(faWindowClose);

export default {
  name: 'fDialog',
  components: {
    'el-dialog': Dialog,
  },
  props: {
    icon: { type: String, default: '' },
    value: { default: false },
    title: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    closeOnEscape: {
      type: Boolean,
      default: true,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    centerContent: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '80%',
    },
    height: {
      type: String,
      default: '70%',
    },
    top: {
      type: String,
      default: '2vh',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    hide() {
      // isso é necessário pq por algum motivo ao fechar o dialog, está enviando váris vezes o evento @close
      if (this.value) {
        this.$emit('input', false);
        this.$emit('close', true);
      }
    },
    show() {
      this.$emit('input', true);
    },
  },
  watch: {
    value(value) {
      this.visible = value;
    },
    visible(value) {
      this.$emit('input', value);
      if (value === false) {
        this.hide();
      }
    },
  },
};
</script>
