import Vue from 'vue';
import Comps from 'comps/index';
import App from './App';
import './registerServiceWorker';
import router from './router';

import store from './store';

Vue.use(Comps, store, router);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
