<style lang="scss">
body {
  margin: 0;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.f-header {
  margin-bottom: 15px;
  height: 7vh;
}

.f-middle {
  margin: 0;
  padding: 0;
  height: 88vh !important;
  overflow: hidden;
  .row {
    width: 100%;
  }
}

.f-footer {
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// css da parte do meio

.f-left-sidebar {
  background: #343a40;
  margin: 0;
  padding: 0;
}

// css da coluna esquerda
.f-system-info {
  //background-color: #0d47a1;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & * {
    width: 100%;
  }
}

.f-user-info {
  background-color: #343a40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-menu {
  background-color: #343a40;
}
.cor-barra {
  background-color: #1F2937;
}
</style>

<template>
  <f-row style="width: 100%">
    <f-col col="12" class="p-0" style="padding: 0;">
      <f-row class="bg-blue-500 f-header">
        <!--        &lt;!&ndash;  header &ndash;&gt;-->
        <f-col col="12" class="p-0 bg-blue-900 flex f-header">
          <div class="w-40 flex items-center justify-center">
            <slot name="system-info"></slot>
          </div>
          <div class="w-4/6 flex items-center">
            <slot name="header"></slot>
          </div>
          <div class="w-2/6 flex items-center">
            <slot name="user-info" class="w-1/5"></slot>
          </div>
        </f-col>
      </f-row>
      <!--  Conteudo do meio -->
      <f-row class="f-middle">
        <f-col col="12" class="flex m-0 p-0" style="padding: 0;">
          <div class="bg-gray-800" v-if="$store.getters.pageSize.width > 768">
            <slot name="menu"></slot>
          </div>
          <div class="bg-gray-800" v-else-if="$store.getters.showMenu">
            <f-drawer
              v-if="$store.getters.showMenu"
              custom-class="cor-barra"
              size="auto"
              @input="$store.dispatch('setShowMenu', false)"
            >
              <slot name="menu" class="bg-gray-800"></slot>
            </f-drawer>
          </div>
          <div class="conteudo-tabs" style="flex: 1 1;width: 85%;">
            <slot></slot>
          </div>
        </f-col>
        <!--  footer -->
      </f-row>
      <f-row class="f-footer bg-gray-800">
        <f-col class="flex w-full justify-center h-full">
          <slot name="footer"></slot>
        </f-col>
      </f-row>
    </f-col>
  </f-row>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    this.$emit('mounted');
  },
};
</script>
