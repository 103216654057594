import Dataset from '../../libs/dataset';

const getFields = () => `
        id
        nome
        nome2
        cpfCnpj
        ie
        identification
        email
        telefone
        cpfCnpj
        endCep
        endEndereco
        endNumero
        endBairro
        endCidade
        endCidadeCodigo
        endUf
        ramoAtividade
        logomarca {
            nomeArquivo
            base64
            url
        }
        slackToken
        licenca {
          chatJavascriptCode
          dadosSuporte
        }
        orgCodinome
        suporte {
          canalSlack
        }
        geral {
          usaMultiMoeda
        }
        vendas {
          descontoMaximo
          acrescimoMaximo
          margemCustoPadrao
          margemLucroPadrao
          usaRecorrencia
          usaPrevisaoEntrega
          usaCondicaoPagamento
          vendeProduto
          vendeServico
          usaBalanca
          insercaoAutomaticaItens
          agruparItensIguais
          codificacaoBalanca
          precoVendaPadrao
          impressaoPadrao
          integracoes {
            nfe {
              tipoEmissaoComposicao
              tipoGeracao
              api
              token
              emiteNFcePedidoRapido
            }
          }
       }
       smtp {
         usarIntegracao
         provedor
         usuario
         senha
         porta
         host
         nomeEmail
         email
         seguranca
       }
       dfe {
          obs
          regimeTributario
          tributacaoNome
          tributacaoId
          contador {
            nome
            cpfCnpj
            cnpjEscritorio
            crc
            endCep
            endEndereco
            endNumero
            endBairro
            endCidade
            endCidadeCodigo
            endUf
            telefone
            email
          }
          certificado {
            arquivoNome
            validade
            senha
            downloadLink
          }
          nfeConfig {
            ambientePadrao
            seriePadrao
            entradaNSU
            entradaBuscaAutomatica
            entradaConsultaBloqueadaAte
          }
          nfceConfig {
            ambientePadrao
            seriePadrao
            csc
            token
          }
          mdfeConfig {
            ambientePadrao
            seriePadrao
          }
      }
`;

class Config extends Dataset {
  constructor() {
    super();
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
      nome2: null,
      cpfCnpj: null,
      ie: null,
      endCep: null,
      endEndereco: null,
      endNumero: null,
      endBairro: null,
      endCidade: null,
      endCidadeCodigo: null,
      endUf: null,
      telefone: null,
      email: null,
      ramoAtividade: null,
      logomarca: {
        nomeArquivo: null,
        base64: null,
        url: null,
      },
      slackToken: null,
      licenca: {
        chatJavascriptCode: null,
        dadosSuporte: null,
      },
      orgCodinome: null,
      suporte: {
        canalSlack: null,
      },
      geral: {
        usaMultiMoeda: false,
      },
      smtp: {
        usarIntegracao: null,
        provedor: null,
        usuario: null,
        senha: null,
        porta: null,
        host: null,
        nomeEmail: null,
        email: null,
        seguranca: null,
      },
      vendas: {
        descontoMaximo: null,
        acrescimoMaximo: null,
        margemCustoPadrao: null,
        margemLucroPadrao: null,
        usaRecorrencia: null,
        usaPrevisaoEntrega: null,
        usaCondicaoPagamento: null,
        vendeProduto: null,
        vendeServico: null,
        usaBalanca: null,
        insercaoAutomaticaItens: null,
        agruparItensIguais: null,
        codificacaoBalanca: null,
        precoVendaPadrao: null,
        impressaoPadrao: null,
        integracoes: {
          nfe: {
            tipoGeracao: null,
            tipoEmissaoComposicao: null,
            api: null,
            token: null,
            emiteNFcePedidoRapido: null,
          },
        },
      },
      dfe: {
        contador: {
          nome: null,
          cpfCnpj: null,
          cnpjEscritorio: null,
          crc: null,
          endCep: null,
          endEndereco: null,
          endNumero: null,
          endBairro: null,
          endCidade: null,
          endCidadeCodigo: null,
          endUf: null,
          telefone: null,
          email: null,
        },
        nfeConfig: {
          ambientePadrao: null,
          seriePadrao: null,
          entradaNSU: null,
          entradaBuscaAutomatica: null,
          entradaConsultaBloqueadaAte: null,
        },
        certificado: {
          arquivoNome: null,
          validade: null,
          senha: null,
          downloadLink: null,
          conteudoUpload: null,
        },
        nfceConfig: {
          ambientePadrao: null,
          seriePadrao: null,
          csc: null,
          token: null,
        },
        mdfeConfig: {
          ambientePadrao: null,
          seriePadrao: null,
        },
        tributacaoNome: null,
        tributacaoId: null,
      },
    };
  }

  getQuery() {
    return `query {
              ConfigGet(filter: "") {
                 ${getFields()}
             }
         }`;
  }

  loadConfig() {
    return new Promise((resolve, reject) => {
      this.gqlQuery(this.getQuery())
        .then((response) => {
          let record = response.result;
          if (record == null) {
            record = this.dataset.getModel();
          }
          this.setRecord(record);
          resolve(record);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  saveConfig() {
    return new Promise((resolve, reject) => {
      if (this.formValidation && !this.formValidation.validate()) {
        return;
      }
      this.gqlMutation(this.getMutationSave())
        .then((response) => {
          this.setRecord(response.record);
          resolve(response.record);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMutationSave() {
    return {
      query: `
          mutation ConfigSet($data: ConfigInputType!) {
            ConfigSet(data: $data){
              ${getFields()}
            }
          }`,
      params: this.getMutationParams(this.record),
    };
  }

  getMutationParams(model) {
    return {
      data: {
        nome: model.nome,
        nome2: model.nome2,
        cpfCnpj: model.cpfCnpj,
        ie: model.ie,
        endCep: model.endCep,
        endEndereco: model.endEndereco,
        endNumero: model.endNumero,
        endBairro: model.endBairro,
        endCidade: model.endCidade,
        endCidadeCodigo: model.endCidadeCodigo,
        endUf: model.endUf,
        telefone: model.telefone,
        email: model.email,
        ramoAtividade: model.ramoAtividade,
        identification: model.identification,
        slackToken: model.slackToken || '',
        vendas: model.vendas,
        licenca: model.licenca,
        suporte: model.suporte,
        orgCodinome: model.orgCodinome,
        logomarca: {
          base64: model.logomarca.base64 || '',
          nomeArquivo: model.logomarca.nomeArquivo || '',
        },
        geral: {
          usaMultiMoeda: model.geral.usaMultiMoeda || false,
        },
        smtp: model.smtp,
        dfe: {
          cfop: model.dfe.cfop,
          regimeTributario: model.dfe.regimeTributario,
          aliquota: model.dfe.aliquota,
          aliqIcmsCalcDif: model.dfe.aliqIcmsCalcDif,
          obs: model.dfe.obs,
          tributacaoNome: model.dfe.tributacaoNome,
          tributacaoId: model.dfe.tributacaoId,
          certificado: {
            arquivoNome: model.dfe.certificado.arquivoNome,
            conteudoUpload: model.dfe.certificado.conteudoUpload,
            senha: model.dfe.certificado.senha,
          },
          emitente: model.dfe.emitente,
          contador: model.dfe.contador,
          nfeConfig: {
            ambientePadrao: model.dfe.nfeConfig.ambientePadrao,
            entradaBuscaAutomatica: model.dfe.nfeConfig.entradaBuscaAutomatica,
            entradaNSU: model.dfe.nfeConfig.entradaNSU || 0,
            seriePadrao: model.dfe.nfeConfig.seriePadrao,
          },
          nfceConfig: {
            ambientePadrao: model.dfe.nfceConfig.ambientePadrao,
            seriePadrao: model.dfe.nfceConfig.seriePadrao,
            csc: model.dfe.nfceConfig.csc,
            token: model.dfe.nfceConfig.token,
          },
          mdfeConfig: model.dfe.mdfeConfig,
        },
      },
    };
  }

  enviarXmls(email, dataInicial, dataFinal) {
    const dataIni = this.$utils.Datetime.formatDatetime(dataInicial, 'YYYY-MM-DDT00:00:00.000Z');
    const dataFin = this.$utils.Datetime.formatDatetime(dataFinal, 'YYYY-MM-DDT23:59:59.000Z');
    return new Promise((resolve, reject) => {
      const query = `
         mutation NfeEnviaXmls($email: String!, $dataInicial: DateTime!, $dataFinal: DateTime!){
          NfeEnviaXmls(email: $email, dataInicial: $dataInicial, dataFinal: $dataFinal)
         }
    `;
      this.gqlMutation({ query, params: { email, dataInicial: dataIni, dataFinal: dataFin } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getWsStatus(tipo) {
    return new Promise((resolve, reject) => {
      const query = `
         query {
          GetWsStatus(tipo: "${tipo}") {
            nfe
            nfeXMotivo
          }
         }
    `;
      this.gqlMutation({ query })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  testSmtp(destinatarioEmail) {
    return new Promise((resolve, reject) => {
      const query = `
         mutation ConfigTestSmtp($data: ConfigInputType!, $destinatarioEmail: String!){
          ConfigTestSmtp(data: $data, destinatarioEmail: $destinatarioEmail)
         }
    `;
      const data = this.getMutationParams(this.record);
      this.gqlMutation({
        query,
        params: {
          data: data.data, destinatarioEmail,
        },
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Config;
