<template>
  <form @keydown.enter="onSubmit" class="w-full" :id="id" ref="form">
    <f-row class="f-form flex justify-center w-full">
      <f-col class="flex justify-center flex-col w-full">
        <slot></slot>
      </f-col>
    </f-row>
  </form>
</template>

<script>

export default {
  name: 'fForm',
  props: {
    vertical: {
      type: Boolean,
      default: true, // else it's horizontal
    },
    grid: {
      type: Boolean,
      default: false, // controls side by side
    },
    fixedWidth: {
      type: Boolean,
      default: false, // only works for horizontal form
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    inline: { type: Boolean, default: false },
    id: { type: String, default: '' },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    executeOnChildren(children, methodName) {
      Object.keys(children)
        .forEach((key) => {
          const child = children[key];
          if (child[methodName]) {
            if (!child[methodName]() && child.validationFieldName && this.valid) {
              this.valid = false;
            }
          }
          if (child.$children && !child.submit) {
            this.executeOnChildren(child.$children, methodName);
          }
        });
    },
    validate() {
      this.valid = true;
      this.executeOnChildren(this.$children, 'validate');
      this.$emit('validate', this.valid);
      return this.valid;
    },
    reset() {
      this.executeOnChildren(this.$children, 'reset');
      this.$emit('reset');
      this.valid = true;
    },
    onSubmit(e) {
      e.preventDefault();
      this.$emit('submit', e);
    },
    submit() {
      this.$refs.form.submit();
    },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.vertical) {
        classes.push('p-fluid');
      }
      if (this.grid) {
        classes.push('p-formgrid p-grid');
      }
      if (this.inline) {
        classes.push('p-formgroup-inline');
      }
      return classes;
    },
  },
  mounted() {
    this.$emit('mounted', this);
  },
};
</script>
