// eslint-disable-next-line max-classes-per-file
import Dataset from '../../libs/dataset';

class Service extends Dataset {
  constructor(config) {
    super(config);
    this.findGridColumns = [
      {
        caption: 'CEP',
        name: 'zipCode',
      },
      {
        caption: 'Endereco',
        name: 'address',
      },
      {
        caption: 'Nº',
        name: 'number',
      },
      {
        caption: 'Cidade',
        name: 'city',
      },
      {
        caption: 'Estado',
        name: 'state',
      },
      {
        caption: 'Inscrição Estadual',
        name: 'stateIdent',
      },
    ];
    this.model = this.getModel();
  }

  getModel() {
    return {
      id: null,
      address: null,
      zipCode: null,
      number: null,
      city: null,
      cityCode: null,
      neighborhood: null,
      state: null,
      stateIdent: null,
    };
  }
}

export default Service;
