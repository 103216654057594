import Dataset from '../../libs/dataset';

class Service extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [
      {
        caption: 'Nome',
        name: 'nome',
      },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome ou Código',
        name: 'main',
        width: '400px',
        type: 'textbox',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
      obs: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;
    return `query {
              GrupoPessoasList(${filter}){
                id
                createdAt
                updatedAt
                nome
              }
            }`;
  }

  getFields() {
    return `
        id
        createdAt
        updatedAt
        nome
        obs
    `;
  }

  getByIds(ids) {
    return `query {
            GrupoPessoasList(ids: [${ids.join()}]) {
              ${this.getFields()}
           }
         }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation GrupoPessoasCreateMutation($data: GrupoPessoasInput!) {
           GrupoPessoasCreate(data: $data){
            ${this.getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation GrupoPessoasMutation($id: String!, $data: GrupoPessoasInput!) {
           GrupoPessoasUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation GrupoPessoasDeleteMutation($id: String!){
          GrupoPessoasDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
      obs: model.obs,
    };
  }
}

export default Service;
