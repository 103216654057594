<template>
<div class="ml-3">
  <f-button
    type="info"
    style="color: #7f1d1d; background-color: #fee2e2"
    v-if="availableUpdate"
    @click="update"
  >
    {{$store.getters.isMobile ? 'Apliar atualização': 'Nova atualização! Clique aqui ou pressione F5!'}}
  </f-button>
</div>
</template>

<script>
import DataSet from '../libs/dataset';

export default {
  name: 'appUpdate',
  data() {
    return {
      dataset: new DataSet(),
      availableUpdate: false,
    };
  },
  mounted() {
    this.checkUpdate();
    this.autoCheckUpdate();
  },
  methods: {
    checkUpdate() {
      this.dataset.get('/version')
        .then((response) => {
          this.availableUpdate = response.data !== process.env.VUE_APP_VERSION;
        });
    },
    update() {
      window.location.reload();
    },
    autoCheckUpdate() {
      setTimeout(() => {
        this.checkUpdate();
        this.autoCheckUpdate();
      }, 300000); // verifica a cada 5 minutos
    },
  },
};
</script>
