<template>
  <f-row class="field-row">
    <slot></slot>
  </f-row>
</template>

<script>
export default {
  name: 'fieldrow',
};
</script>

<style scoped>
.field-row {
  margin: 5px;
}
</style>
