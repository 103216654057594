<template>
  <div class="flex items-center">
    <f-button
      type="default"
      size="md"
      class="text-red mr-1"
      @click="collapseMenu"
    >
      <FontAwesomeIcon icon="bars" />
    </f-button>
    <f-combobox
      caption=""
      size="md"
      :value="currentOrg.id"
      :options="getEmpresas"
      @change="setOrg"
    />
    <f-button
      type="success"
      class="mr-3"
      v-if="!$store.getters.isMobile"
      :circle="true"
      @click="mostraOrgPadrao = true"
    >
      <FontAwesomeIcon icon="check"/>
    </f-button>
    <Suporte v-if="!$store.getters.isMobile"/>
    <app-update/>
    <OrgPadrao v-model="mostraOrgPadrao" />
    <NovaOrg v-model="mostraNovaEmpresa" />
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faCheck } from '@fortawesome/free-solid-svg-icons';
import Suporte from './Suporte';
import OrgPadrao from '../../apps/auth/OrgPadrao';
import NovaOrg from '../../apps/auth/NovaOrg';
import appUpdate from '../appUpdate';

library.add(faBars, faCheck);
export default {
  name: 'AppHeader',
  components: {
    Suporte,
    OrgPadrao,
    appUpdate,
    NovaOrg,
  },
  data() {
    return {
      mostraOrgPadrao: false,
      mostraNovaEmpresa: false,
    };
  },
  methods: {
    collapseMenu() {
      this.$store.dispatch('setMenuCollapsed');
      this.$store.dispatch('setShowMenu', !this.$store.getters.showMenu);
    },
    setOrg(org) {
      if (org.value === 'nova_empresa') {
        this.mostraNovaEmpresa = true;
        return;
      }
      this.$store.dispatch('setCurrentOrg', org.value);
    },
  },
  computed: {
    headerStyle() {
      const style = this.$store.getters['app/appStyle'].headerColor;
      return {
        'background-color': style.backgroundColor,
        color: style.color,
      };
    },
    currentOrg() {
      return this.$store.state.app.currentOrg;
    },
    getEmpresas() {
      const empresas = [];
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const org of this.$store.state.app.infoLogin.orgs) {
        const item = {
          text: org.name,
          value: org.id,
        };

        if (org.orgCodinome) {
          item.text = org.orgCodinome;
        }

        if (org.cnpj) {
          item.text += ` (${org.cnpj})`;
        }

        empresas.push(item);
      }
      if (this.$store.getters.hasPermission('configuracao.altera')) {
        empresas.push({
          text: 'Adicionar nova empresa',
          value: 'nova_empresa',
        });
      }
      return empresas;
    },
  },
};
</script>

<style lang="scss" scoped>

.btn-pi-bars {
  margin: 0 5px 0 0;
}

.header-row {
  padding: 0;
  margin: 0;
}

</style>
