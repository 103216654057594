<template>
  <LoginArea>
    <div class="flex flex-col justify-center content-center">
      <h2 class="f-title-1 text-center">{{ i18n.get("loginTitle") }}</h2>
      <p class="f-subtitle-1">{{ i18n.get("forgotPasswordTitle") }}</p>
    </div>
    <f-row class="w-full">
      <f-col col="12 flex justify-center">
        <f-form
          v-loading="service.loading"
          element-loading-text="Aguarde..."
          element-loading-spinner="el-icon-loading"
          class="login-form"
          ref="form"
          @submit="forgot"
        >
          <span v-show="successMsg.length === 0">
            <f-textbox
              v-model="model.email" icon="fas fa-user"
              type="email"
              size="medium"
              :caption="i18n.get('fieldEmail')"
              :validations="validations.email"
            />
          </span>
          <f-link
            class="text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click.native.prevent="forgot"
          >
            {{ i18n.get("forgotButtonCaption") }}
          </f-link>
          <f-alert-block
            v-if="successMsg.length || errorMsg.length"
            :success="successMsg"
            :errors="errorMsg"
          />
          <f-link class="text-center m-5" url="/login">{{ i18n.get("backLoginPage") }}</f-link>
        </f-form>
      </f-col>
    </f-row>
  </LoginArea>
</template>

<script>
import LoginArea from './LoginArea';

require('./login.scss');

export default {
  name: 'ForgotPasswordPage',
  mixins: [
    LoginArea,
  ],
  components: {
    LoginArea,
  },
  props: {
    recoveryButtonCaption: {
      type: String,
      default: 'Receber senha de recuperação',
    },
  },
  methods: {
    async forgot() {
      this.resetMessages();
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.model.loading = true;
      this.service.forgot(this.model.email)
        .then((response) => {
          this.successMsg = [response.data];
          setTimeout(() => {
            this.routeToLogin();
          }, 5000);
        })
        .catch((err) => {
          this.errorMsg = [err.message];
        });
    },
  },
};
</script>
