<template>
  <f-app :data="appData" id="app">
    <router-view>
    </router-view>
  </f-app>
</template>

<script>
import AuthMenu from 'comps/apps/auth/menu';
import PessoaMenu from 'comps/apps/person/menu';

export default {
  name: 'App',
  methods: {
    criaMenus() {
      const menu = [{
        text: 'Home',
        description: 'Ir para a home',
        route: '/',
        // eslint-disable-next-line global-require
        img: require('comps/assets/home32x32.png'),
      }];
      menu.push({
        text: 'Carteirinha',
        description: 'Controle de Carteirinha',
        route: '/carteirinha',
        // eslint-disable-next-line global-require
        img: require('@/assets/carteirinha32x32.png'),
      });
      if (this.$store.getters.currentOrg && this.$store.getters.currentOrg.permissoes.includes('carteirinhamodelo')) {
        menu.push({
          text: 'Carteirinha Modelo Impressao',
          description: 'Cadastro do modelo de impressao da carteirinha',
          route: '/carteirinhamodelo',
          // eslint-disable-next-line global-require
          img: require('@/assets/carteirinha32x32.png'),
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const criaMenu of [PessoaMenu, AuthMenu]) {
        const menuCriado = criaMenu(this.$store.getters.currentOrg);
        if (menuCriado) {
          menu.push(menuCriado);
        }
      }
      this.appData.menu = menu;
    },
  },
  data() {
    return {
      appData: {
        appInfo: {
          appName: 'Sigeflex Clínica',
        },
        menu: [],
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.app.currentOrg': function () {
      this.criaMenus();
    },
  },
};
</script>
