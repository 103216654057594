<template>
  <div
    class="flex flex-col flex-wrap"
    style="height: auto"
    v-loading="service.loading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text="'Aguarde...'"
  >
    <f-form ref="formValidation">
      <div class="flex items-end justify-between flex-wrap">
        <div class="flex items-end  flex-wrap">
          <f-combobox
            caption="Estoque"
            :options="estoquesOption"
            v-model="model.estoqueNome"
            :validations="validations.required"
          />
          <f-combobox
            caption="Tipo de Operação"
            v-model="model.tipo"
            :options="tipoOperacaoOptions"
            :validations="validations.required"
          />
          <f-numberbox
            :fieldStyle="{width: 'auto'}"
            caption="Quantidade"
            v-model="model.quantidade"
            :validations="validations.required"
            :precision="4"
          />
          <f-button caption="OK" @click="addMovimentacao" />
        </div>
        <f-button @click="getMovimentacao">
          <FontAwesomeIcon icon="sync" />
        </f-button>
      </div>
    </f-form>
    <div style="width: 100%">
      <f-grid
        :data="movimentacaoEstoque"
        :columns="movimentoColunas"
        :styleCell="styleCell"
        :selecionSingle="true"
      >
      </f-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MovimentoEstoque',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: { estoqueNome: 'PADRAO', tipo: '', quantidade: 0 },
      movimentacaoEstoque: [],
      estoquesOption: [
        { value: 'PADRAO', text: 'PADRAO' },
      ],
      tipoOperacaoOptions: [
        { value: 'e', text: 'Entrada' },
        { value: 's', text: 'Saída' },
      ],
      movimentoColunas: [
        { field: 'createdAt', title: 'Data/Hora', type: 'datetime' },
        { field: 'origem', title: 'Origem' },
        { field: 'origemNumero', title: 'Origem Número' },
        { field: 'pessoaNome', title: 'Pessoa' },
        { field: 'userName', title: 'Usuário' },
        { field: 'estoqueNome', title: 'Estoque' },
        { field: 'tipo', title: 'Tipo' },
        { field: 'qAnterior', title: 'Qtde. Anterior' },
        { field: 'quantidade', title: 'Quantidade Movimentada' },
        { field: 'valorUnitario', title: 'V. Unitário' },
        { field: 'qAtual', title: 'Qtde. Atual' },
      ],
      validations: {
        required: [
          { required: true, message: 'Campo obrigatório', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    addMovimentacao() {
      if (this.$refs.formValidation && !this.$refs.formValidation.validate()) {
        return;
      }

      this.service.addMovimentoEstoque(this.model)
        .then((response) => {
          Object.keys(response.record).forEach((key) => {
            const item = response.record[key];
            // insere no inicio do array
            this.movimentacaoEstoque.splice(0, 0, item);
          });
          this.reset();
        })
        .catch((err) => {
          this.$showMessage({
            title: 'Movimentação não adicionada',
            type: 'error',
            message: err,
          });
        });
    },
    getMovimentacao() {
      this.service.getMovimentacaoProduto()
        .then((result) => {
          this.movimentacaoEstoque = result;
        });
    },
    reset() {
      this.$refs.formValidation.reset();
      this.model = {
        quantidade: 0,
        tipo: '',
        estoque: '',
      };
    },
    styleCell(col, item) {
      if (col === 'tipo') {
        if (item.tipo === 's') {
          return '<div class="bg-green-500 text-white">SAÍDA</div>';
        }
        return '<div class="bg-blue-500 text-white">ENTRADA</div>';
      }
      return {};
    },
  },
  mounted() {
    this.getMovimentacao();
  },
};
</script>
