<template>
  <div>
    <el-tag
      :key="tag"
      v-for="tag in tags"
      :closable="closable"
      :type="type"
      :effect="effect"
      @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
  </div>
</template>

<script>
import { Tag } from 'element-ui';

export default {
  name: 'Tag',
  components: {
    'el-tag': Tag,
  },
  props: {
    tags: {
      type: Array,
    },
    type: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: false,
    },
    // Tag provide three different themes: dark、light and plain
    effect: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClose(tag) {
      this.$emit('close', tag);
    },
  },
};
</script>
