export default class Publisher {
  constructor() {
    this.events = {};
  }

  subscribe(event, subscriber) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(subscriber);
  }

  dispatch(eventName, param) {
    window.parent.postMessage(JSON.stringify({ event: eventName, param }), '*');
    const event = this.events[eventName];
    if (event) {
      Object.keys(event).forEach((key) => {
        if (!event[key].executedCount) {
          event[key].executedCount = 0;
        }
        event[key].executedCount += 1;
        event[key].callback(param);
      });
    }
  }
}
