<style scoped>
.conteudo-config-form {
  height: 70vh;
  overflow: auto;
}
</style>
<template>
  <div class="conteudo-config-form">
    <f-config-form :dataset="service" v-if="service" @save="salvaConfig" ref="configForm">
      <f-tabs
        type="border-card"
      >
        <f-tab-pane label="Dados da Organização" class="tab-find">
          <f-col col="12">
            <f-form-row>
              <f-textbox
                caption='Razão Social'
                v-model='service.record.nome'
                :fieldStyle="{width: '400px'}"
                :validations="validations.razaoSocial"
              />
              <f-textbox
                caption='Nome Fantasia'
                v-model='service.record.nome2'
                :fieldStyle="{width: '400px'}"
                :validations="validations.nome2"
              />
              <f-textbox
                caption='Identificação/Codinome da empresa'
                v-model='service.record.orgCodinome'
                helpMessage="Identificação a ser exibida no campo superior de seleção de empresas (necessário relogar)"
                :fieldStyle="{width: '400px'}"
              />
            </f-form-row>
            <f-form-row>
              <cpfCnpj v-model='service.record.cpfCnpj' @dados="setDados" />
              <f-textbox
                caption='IE'
                v-model='service.record.ie'
                :fieldStyle="{width: '400px'}"
              />
            </f-form-row>
            <Endereco
              :allRequired="true"
              :cep="service.record.endCep"
              :endereco="service.record.endEndereco"
              :bairro="service.record.endBairro"
              :numero="service.record.endNumero"
              :uf="service.record.endUf"
              :cidade="service.record.endCidade"
              :cidade-codigo="service.record.endCidadeCodigo"
              @cep="service.record.endCep = $event"
              @endereco="service.record.endEndereco = $event"
              @bairro="service.record.endBairro = $event"
              @numero="service.record.endNumero = $event"
              @uf="service.record.endUf = $event"
              @cidade="service.record.endCidade = $event"
              @cidadeCodigo="service.record.endCidadeCodigo = $event"
            />
            <f-form-row>
              <f-textbox
                caption='Telefone'
                v-model='service.record.telefone'
                :validations="validations.telefone"
              />
              <f-textbox
                type="email"
                caption='Endereço de e-mail'
                v-model='service.record.email'
                :fieldStyle="{width: '400px'}"
                :validations="validations.email"
              />
            </f-form-row>
            <f-form-row v-if="service">
              <logomarca :service="service" />
            </f-form-row>
            <f-combobox
              caption="Ramo de Atividade"
              v-model='service.record.ramoAtividade'
              :options="ramoAtividade"
            />
          </f-col>
        </f-tab-pane>
        <f-tab-pane label="Integrações" class="tab-find">
          <f-tabs>
            <f-tab-pane label="Integração com email próprio">
              <Smtp :service="service" @testeOk="$refs.configForm.save()"/>
            </f-tab-pane>
            <f-tab-pane label="Integração com Slack">
              <f-textbox
                caption='Slack Token'
                v-model='service.record.slackToken'
                caseOption=""
              />
            </f-tab-pane>
          </f-tabs>
        </f-tab-pane>
        <f-tab-pane label="Geral" class="tab-find">
          <f-checkbox caption='Usa multi moeda' v-model='service.record.geral.usaMultiMoeda' caseOption="lowercase" />
        </f-tab-pane>
        <f-tab-pane label="Suporte" class="tab-find">
          <f-textbox
            caption='Canal Slack para notificação'
            v-model='service.record.suporte.canalSlack'
            caseOption="lowercase"
          />
        </f-tab-pane>
        <f-tab-pane label="Vendas" class="tab-find">
          <f-form-row>
            <f-numberbox
              caption="Desconto máximo"
              v-model='service.record.vendas.descontoMaximo'
              suffix="%"
              helpMessage="Desconto máximo pré-autorizado a todos os usuários, excedendo esse valor,
                   tela de autorização será apresentada"
            />
            <f-numberbox
              caption="Acréscimo máximo"
              v-model='service.record.vendas.acrescimoMaximo'
              suffix="%"
              helpMessage="Acréscimo máximo pré-autorizado a todos os usuários"
            />
            <f-numberbox
              caption="Margem Custo Padrão"
              v-model='service.record.vendas.margemCustoPadrao'
              suffix="%"
              helpMessage="Porcentagem de acrécimo de custo que será somado ao custo básico no item de entrada."
            />
            <f-numberbox
              caption="Margem Lucro Padrão"
              v-model='service.record.vendas.margemLucroPadrao'
              suffix="%"
              helpMessage="Porcentagem de lucro estimado a ser calculado na entrada do item no estoque.
              Ao preencher o sistema efetuará o calculo automatico do preço de venda."
            />
          </f-form-row>
          <f-combobox
            caption="Preço padrão de venda"
            v-model='service.record.vendas.precoVendaPadrao'
            :options="vendas.precoVendaPadrao"
          />
          <f-combobox
            caption="Impressão padrão tela de pedido"
            v-model='service.record.vendas.impressaoPadrao'
            :options="vendas.impressaoPadrao"
          />
          <f-textbox
            v-if="service.record.vendas.usaBalanca"
            caption='Formato leitura cód. Balança'
            caseOption=""
            helpText="Padrão do formato para leitura do código de balança"
            v-model='service.record.vendas.codificacaoBalanca'
            :fieldStyle="{width: '193px'}"
          />
          <f-form-row class="flex flex-wrap">
            <f-checkbox caption="Usa Recorrência" v-model="service.record.vendas.usaRecorrencia" />
            <f-checkbox caption="Usa Previsão de Entrega" v-model="service.record.vendas.usaPrevisaoEntrega" />
            <f-checkbox caption="Usa Condição de Pagamento" v-model="service.record.vendas.usaCondicaoPagamento" />
            <f-checkbox caption="Vende Produto" v-model="service.record.vendas.vendeProduto" />
            <f-checkbox caption="Vende Serviço" v-model="service.record.vendas.vendeServico" />
            <f-checkbox caption="Usa Balança" v-model="service.record.vendas.usaBalanca" />
            <f-checkbox
              caption="Inserção Automática de Ítens"
              v-model="service.record.vendas.insercaoAutomaticaItens"
              helpMessage="Ao marcar essa opção, o sistema colocar quantidade 1 como padrão, agilizando a venda"
            />
            <f-checkbox
              caption="Agrupar ítens iguais"
              v-model="service.record.vendas.agruparItensIguais"
              helpMessage="Ao marcar essa opção, o sistema agrupará os itens iguais no pedido"
            />
          </f-form-row>
          <f-tabs>
            <f-tab-pane label="Emissão de Nota Fiscal">
              <f-combobox
                caption="Tipo de Emissão produtos compostos"
                v-model='service.record.vendas.integracoes.nfe.tipoEmissaoComposicao'
                :options="vendas.tipoEmissaoProdutosCompostos"
              />
              <f-combobox
                caption="Tipo de Geração de Nota(NFe e NFC-e na tela de pedido)"
                v-model='service.record.vendas.integracoes.nfe.tipoGeracao'
                :options="vendas.tipoGeracaoNfe"
              />
              <f-checkbox
                caption="Emite NFC-e automaticamente na tela de Pedido rápido"
                v-model="service.record.vendas.integracoes.nfe.emiteNFcePedidoRapido"
              />
              <!-- por enquanto so tem integracao com o sigeflex erp local -->
              <div v-if="false">
                <f-combobox
                  caption="API de emissão suportado"
                  v-model='service.record.vendas.integracoes.nfe.api'
                />
                <f-textbox
                  caption='Token'
                  type="textarea"
                  caseOption=""
                  v-model='service.record.vendas.integracoes.nfe.token'
                />
              </div>
            </f-tab-pane>
          </f-tabs>
        </f-tab-pane>
        <f-tab-pane label="DFe" class="tab-find">
          <ConfigDfe :service="service" />
        </f-tab-pane>
        <f-tab-pane label="Dados da Licença" class="tab-find">
          <LicenciamentoPagamento />
        </f-tab-pane>
        <f-tab-pane
          label="Suporte ao Cliente"
          class="tab-suporte"
          v-if="temModuloLicenca"
        >
          <f-textbox
            caption="Código Javascript do chat de suporte (somente o código javascript)"
            caseOption=""
            type='textarea'
            v-model='service.record.licenca.chatJavascriptCode'
          />
          <f-textbox
            caption="Informações de Contato do Suporte (Suporte a HTML)"
            caseOption=""
            type="textarea"
            v-model="service.record.licenca.dadosSuporte"
          />
        </f-tab-pane>
        <f-tab-pane :label="tab.caption" class="tab-find" v-for="tab in tabs" :key="tab.name">
          <slot :name="tab.name"></slot>
        </f-tab-pane>
      </f-tabs>
    </f-config-form>
  </div>
</template>

<script>
import Endereco from 'comps/apps/person/Endereco';
import LicenciamentoPagamento from 'comps/apps/licenciamento/LicenciamentoPagamento';
import ConfigService from './ConfigService';
import ConfigDfe from './dfe/Config';
import Logomarca from './Logomarca';
import Smtp from './Smtp';

export default {
  name: 'Config',
  created() {
    this.service = new ConfigService();
    this.$emit('service', this.service);
  },
  props: {
    formName: {},
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Endereco,
    ConfigDfe,
    Logomarca,
    LicenciamentoPagamento,
    Smtp,
  },
  computed: {
    temModuloLicenca() {
      if (!this.$store.getters.currentOrg.licenciamento.modulos) {
        return false;
      }
      return this.$store.getters.currentOrg.licenciamento.modulos.MODULO_LICENCA !== '';
    },
  },
  data() {
    return {
      ramoAtividade: [
        { text: 'Comércio em Geral', value: 'comerciogeral' },
        { text: 'Oficina de Veículos', value: 'oficinaveiculo' },
        { text: 'Assistência Técnica', value: 'assistenciatecnica' },
        { text: 'Restaurante', value: 'restaurante' },
        { text: 'Óticas', value: 'otica' },
        { text: 'Atacado', value: 'atacado' },
      ],
      vendas: {
        precoVendaPadrao: [
          { text: 'A vista', value: 'avista' },
          { text: 'A prazo', value: 'aprazo' },
          { text: 'Atacado', value: 'atacado' },
        ],
        impressaoPadrao: [
          { text: 'A4', value: 'Pedido' },
          { text: 'Bobina', value: 'PedidoBobina' },
        ],
        tipoEmissaoProdutosCompostos: [
          { text: 'Somente o produto composto', value: 1 },
          { text: 'Somente composição', value: 2 },
          { text: 'Produto composto e composição', value: 3 },
        ],
        tipoGeracaoNfe: [
          { text: 'Autoriza direto', value: 1 },
          { text: 'Apenas geração', value: 2 },
        ],
      },
      validations: {
        razaoSocial: [
          {
            required: true,
            message: 'Campo obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 60,
            message: 'Campo deve ter entre 2 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        name2: [
          {
            min: 5,
            max: 60,
            message: 'Nome deve ter entre 5 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        revendaNameSpace: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      service: null,
    };
  },
  methods: {
    selectedLogo($event) {
      this.service.record.logoUrl = $event[0].localUrl;
    },
    apagarLogo() {
      this.service.record.logoUrl = '';
      this.service.record.logoBase64 = '';
    },
    salvaConfig(data) {
      this.$store.dispatch('setConfig', data);
    },
    setDados(dados) {
      if (!this.service.record.id) {
        this.service.record.nome = dados.nome;
        this.service.record.nome2 = dados.nome2;
        this.service.record.ie = dados.ie;
        this.service.record.cnpj = _.string().onlyNumber(dados.cnpj);
        this.service.record.endCep = dados.cep;
        this.service.record.endEndereco = dados.endereco;
        this.service.record.endNumero = dados.numero;
        this.service.record.endBairro = dados.bairro;
        this.service.record.endCidade = dados.cidade;
        this.service.record.endCidadeCodigo = dados.cidadeCodigo;
        this.service.record.endUf = dados.uf;
        this.service.record.ie = dados.ie;
        this.service.record.email = dados.email;
        this.service.record.telefone = dados.telefone1;
        this.service.record.dfe.regimeTributario = dados.simples ? 'SIMPLES' : 'NORMAL';
      }
    },
  },
  watch: {
    /* Faz com que force o recarregamento das configuracoes ao trocar de empresa, evitando salvar informações de uma
    empresa na outra */
    // eslint-disable-next-line func-names
    '$store.getters.currentOrg': function () {
      this.service.loadConfig();
    },
  },
};
</script>
