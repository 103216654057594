 <template>
  <div>
    <el-image
      ref="image"
      :src="src"
      :fit="fit"
      :preview-src-list="previewList"
      @load="load"
      @error="$emit('error', $event)"
    >
      <div slot="error" class="image-slot">
        <font-awesome-icon
          icon="camera"
          size="6x"
          title=""/>
      </div>
      <slot></slot>
    </el-image>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Image } from 'element-ui';

library.add([faCamera]);
export default {
  components: {
    'el-image': Image,
  },
  data() {
    return {
      base64: '',
      file: {
        type: Object,
      },
      url: '',
    };
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    removeButtonCaption: {
      type: String,
      default: 'Remover',
    },
    fit: {
      type: String,
      default: 'fill',
    }, // ['fill', 'contain', 'cover', 'none', 'scale-down']
    preview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    previewList() {
      let result = [];
      if (this.preview) {
        result = [this.base64];
      }
      return result;
    },
  },
  methods: {
    load(img) {
      this.file = img;
      this.$utils.File.getBase64FromUrl(this.src, (base64) => {
        this.base64 = base64;
        this.$emit('change', this);
      });
    },
    remove() {
      this.base64 = '';
      this.$emit('change', this);
      this.$emit('input', '');
    },
    getBase64() {
      const img = document.getElementById('imageid');
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      this.base64 = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    },
  },
};
</script>
