<template>
  <div
    class="flex flex-col flex-wrap"
    style="height: auto"
    v-loading="service.loading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text="'Aguarde...'"
  >
    <f-form ref="formValidation">
      <div class="flex items-end justify-between flex-wrap">
        <f-lookup
          v-model='responsavel.pessoaNome'
          caption='Responsavel pelo ajuste'
          component="User"
          helpMessage="Informe a pessoa responsável pela contagem (opcional)."
          :desableInternalValidation="true"
          :value-id="responsavel.pessoaId"
          @value="setResponsavel"
        />
        <div class="flex items-end  flex-wrap">
          <f-combobox
            caption="Estoque"
            v-model="model.estoqueNome"
            helpMessage="Escolha o estoque em que o produto está localizado."
            :options="estoquesOption"
            :validations="validations.required"
            @input="changeEstoque"
          />
          <f-numberbox
            :fieldStyle="{width: 'auto'}"
            caption="Quantidade Atual"
            v-model="model.quantidade"
            helpMessage="Quantidade atual informada pelo sistema."
            :precision="4"
            :disabled="true"
          />
          <f-numberbox
            :fieldStyle="{width: 'auto'}"
            caption="Quantidade Desejada"
            v-model="quantidadeDesejada"
            helpMessage="Informe a quantidade desejada e clique em OK para que o sistema gere o movimento
             de ajuste no estoque."
            :validations="validations.quantidade"
            :precision="4"
          />
          <f-button caption="OK" @click="ajustarEstoque" />
        </div>
      </div>
    </f-form>
  </div>
</template>

<script>
export default {
  name: 'AjusteEstoque',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        id: null, estoqueNome: 'PADRAO', tipo: '', quantidade: 0,
      },
      quantidadeDesejada: 0,
      responsavel: { pessoaId: null, pessoaNome: null },
      estoquesOption: [
        { value: 'PADRAO', text: 'PADRAO' },
      ],
      validations: {
        required: [
          { required: true, message: 'Campo obrigatório', trigger: 'blur' },
        ],
        quantidade: [
          {
            min: 0,
            message: 'Quantidade deve ser maior ou igual a ZERO.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    // recupera as opcoes de estoque disponiveis no produto para ser exibido no combobox
    setEstoqueOptions() {
      this.estoquesOption = [];
      Object.keys(this.service.record.estoquesList).forEach((key) => {
        const item = this.service.record.estoquesList[key];
        const itemOption = { value: item.id, text: item.estoqueNome };
        // insere no inicio do array
        this.estoquesOption.splice(0, 0, itemOption);
      });
      if (this.estoquesOption.length === 0) {
        this.estoquesOption = { value: 'PADRAO', text: 'PADRAO' };
      }
      if (this.service.record.estoquesList.length > 0) {
        this.model.id = this.service.record.estoquesList[0].id;
        this.model.quantidade = this.service.record.estoquesList[0].quantidade;
        this.model.estoqueNome = this.service.record.estoquesList[0].estoqueNome;
      }
    },
    // ao alternar o estoque, pega a quantidade atual do estoque selecionado
    changeEstoque(selected) {
      Object.keys(this.service.record.estoquesList).forEach((key) => {
        if (this.service.record.estoquesList[key].id === selected) {
          this.model.id = this.service.record.estoquesList[key].id;
          this.model.quantidade = this.service.record.estoquesList[key].quantidade;
        }
      });
    },
    setResponsavel(record) {
      this.responsavel.pessoaNome = record.name;
      this.responsavel.pessoaId = record.id;
    },
    ajustarEstoque() {
      if (this.$refs.formValidation && !this.$refs.formValidation.validate()) {
        return;
      }
      if (this.quantidadeDesejada && this.quantidadeDesejada < 0) {
        this.$showMessage({
          title: 'Quantidade não ajustada!',
          type: 'error',
          message: 'Quantidade não pode ser inferior a ZERO.',
        });
        return;
      }
      this.model.quantidade = this.quantidadeDesejada;
      this.service.ajustarEstoque(this.model, this.responsavel)
        .then(() => {
          this.$showMessage({
            title: 'Quantidade ajustada!',
            type: 'success',
            message: `A quantidade foi ajustada com sucesso para ${this.model.quantidade}!`,
          });
          this.$emit('sucesso', true);
        })
        .catch((err) => {
          this.changeEstoque(this.model.id);
          this.$showMessage({
            title: 'Quantidade não ajustada!',
            type: 'error',
            message: err,
          });
        });
    },
  },
  mounted() {
    this.setEstoqueOptions();
  },
};
</script>
