<template>
  <div>
    <f-button
      caption="Certificado Digital"
      type="success"
      @click="mostraEnvio = true"
    >
      <FontAwesomeIcon icon="upload"/>
    </f-button>
    <f-dialog v-model="mostraEnvio" width="35%" title="Certificado digital A1">
      <div
        v-loading="service.loading"
        element-loading-text="Aguarde..."
        element-loading-spinner="el-icon-loading"
        v-if="service && service.record"
      >
        <div class="flex justify-center">
          <f-upload
            ref="upload"
            :maxSize="100"
            :multiple="false"
            :showSubmitButton="false"
            accept=".pfx,.p12"
            @removed="service.record.dfe.certificado.arquivoNome = ''"
            @files-changed="selecionaCertificado"
          >
          </f-upload>
        </div>
        <f-alert type="warning" v-if="!service.record.dfe.certificado.arquivoNome">
          Você ainda não enviou um certificado digital A1 (arquivo pfx)
        </f-alert>
        <f-form ref="formValidation">
          <f-textbox
            type="password"
            caption='Senha do certificado'
            v-model='service.record.dfe.certificado.senha'
            :validations="validations.senha"
          />
        </f-form>
        <f-textbox
          v-show="service.record.dfe.certificado.arquivoNome"
          caption='Arquivo'
          witdh="300px"
          :value='service.record.dfe.certificado.arquivoNome'
          :disabled="true"
        />
        <f-datetimebox
          v-show="service.record.dfe.certificado.validade"
          caption='Vencimento'
          type="date"
          :value="service.record.dfe.certificado.validade"
          :disabled="true"
        />
        <f-button
          v-show="service.record.dfe.certificado.conteudoUpload"
          caption="Enviar"
          type="success"
          @click="save"
        >
          <FontAwesomeIcon icon="upload"/>
        </f-button>
        <f-button
          v-if="service.record.dfe.certificado.downloadLink"
          caption="Download do Certificado"
          class="mt-4"
          @click="downloadCertificado"
        >
          <FontAwesomeIcon icon="download"/>
        </f-button>
      </div>
    </f-dialog>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faUpload, faCertificate } from '@fortawesome/free-solid-svg-icons';
import ConfigService from '../ConfigService';

library.add(faDownload, faUpload, faCertificate);

export default {
  data() {
    return {
      service: null,
      mostraEnvio: false,
      validations: {
        senha: [
          {
            required: true,
            message: 'Senha é obrigatória.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new ConfigService();
  },
  methods: {
    downloadCertificado() {
      this.$utils.File.downloadFile(this.service.record.dfe.certificado.downloadLink);
    },
    save() {
      if (!this.$refs.formValidation.validate()) {
        return;
      }
      this.service.saveConfig()
        .then((response) => {
          this.$showMessage({
            type: 'success',
            message: 'Certificado enviado com sucesso!',
          });
          this.$emit('save', response.record);
        })
        .catch((err) => {
          this.$showMessage({
            type: 'error',
            message: 'Certificado não enviado: '.concat(err),
          });
        });
    },
    selecionaCertificado($event) {
      this.service.record.dfe.certificado.arquivoNome = $event[0].name;
      this.$utils.File.getBase64FromUrl($event[0].localUrl, (base64) => {
        this.$set(this.service.record.dfe.certificado, 'conteudoUpload', base64);
      });
    },
  },
  watch: {
    mostraEnvio(value) {
      if (value) {
        this.service.loadConfig();
      }
    },
  },
};

</script>
