<template>
  <el-popover
    :placement="placement"
    :title="title"
    :width="width"
    :trigger="trigger"
    :content="content"
  >
    <div slot="reference">
      <slot></slot>
    </div>
  </el-popover>
</template>

<script>
import { Popover } from 'element-ui';

export default {
  name: 'fPopover',
  components: {
    'el-popover': Popover,
  },
  props: {
    placement: {
      type: String,
      default: 'top-start',
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    trigger: {
      type: String,
      default: 'hover',
    },
    width: {
      type: String,
      default: '200',
    },
  },
};
</script>
