<style lang="scss" scoped>
.crud-form-detail-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 6px 6px 6px 6px;
  margin-bottom: 200px;
}

.form-detail {
  width: 100%;

  .detail-content {
    width: 100%;
    height: 60vh;
    overflow: auto;
    border: 1px solid darkgray;
  }
}
</style>

<template>
    <div
      v-loading="schema.loading"
      :element-loading-text="schema.loadingMessage || 'Aguarde...'"
      element-loading-spinner="el-icon-loading"
      class="form-detail"
    >
      <!--Form contendo os botoes padroes da tela de cadastro -->
      <div class="flex mb-5 flex-wrap items-end" v-if="mostrardetailButtons">
        <f-button
          v-show="enableButtonNew && recordMode === 'view' && !readonly"
          v-if="crudForm.hasPermission('cria')"
          :caption="crudForm.getButtonCaption('Novo')"
          @click="newRecord()"
        >
          <FontAwesomeIcon icon="plus"/>
        </f-button>

        <f-button
          :caption="crudForm.getButtonCaption('Salvar')"
          @click="salvaRegistro"
          v-show="enableButtonSave && (crudForm.hasPermission('cria') || crudForm.hasPermission('altera')) && !readonly"
        >
          <FontAwesomeIcon icon="save"/>
        </f-button>

        <f-button
          v-show="(recordMode === 'edit' || recordMode === 'new') && !readonly"
          :caption="crudForm.getButtonCaption('Desfazer')"
          @click="cancel"
        >
          <FontAwesomeIcon icon="undo"/>
        </f-button>

        <f-button
          v-show="crudForm.mode === 'tab'"
          :caption="crudForm.getButtonCaption('Consultar')"
          @click="$emit('selectTab', '0')"
        >
          <FontAwesomeIcon icon="search"/>
        </f-button>
        <f-button
          v-show="enableButtonDelete && recordMode === 'view' && hasRecord  && !readonly"
          :caption="crudForm.getButtonCaption('Excluir')"
          :disabled="schema.list.length === 0"
          v-if="crudForm.hasPermission('exclui')"
          @click="deleteRecord"
        >
          <FontAwesomeIcon icon="trash"/>
        </f-button>

        <f-button
          v-show="recordMode === 'view' && hasRecord && !crudForm.isChild"
          :caption="crudForm.getButtonCaption('Recarrega')"
          @click="loadRecords()"
        >
          <FontAwesomeIcon icon="sync"/>
        </f-button>

        <f-button
          v-show="recordMode === 'view' && hasRecord && enableButtonDuplicate && !readonly"
          v-if="crudForm.hasPermission('cria')"
          :caption="crudForm.getButtonCaption('Duplica')"
          :disabled="schema.list.length === 0"
          @click="duplicar"
        >
          <FontAwesomeIcon icon="copy"/>
        </f-button>
        <f-button type="warning" :plain="true" caption="Ajuda" @click="$emit('link-doc', linkDoc)" v-if="linkDoc">
          <FontAwesomeIcon icon="question"/>
        </f-button>
        <span class="mr-2"></span>
        <div class="mt-2">
          <slot name="detailButtons">
          </slot>
        </div>

        <!-- botoes de navegacao, prior, next, last, first -->
        <div class="flex flex-start ml-2 mt-2 items-end" v-if="recordCount > 1">
          <f-button
            v-show="recordMode === 'view' && hasRecord"
            :disabled="schema.currentIndex ===0"
            @click="first"
          >
            <FontAwesomeIcon icon="fast-backward"/>
          </f-button>

          <f-button
            v-show="recordMode === 'view' && hasRecord"
            :disabled="schema.currentIndex ===0"
            @click="prior"
          >
            <FontAwesomeIcon icon="step-backward"/>
          </f-button>
          <span style="padding-top: 10px" v-show="recordMode === 'view'">{{ registroAtual }}</span>

          <f-button
            v-show="recordMode === 'view' && hasRecord"
            :disabled="schema.currentIndex === recordCount-1"
            @click="next"
          >
            <FontAwesomeIcon icon="step-forward"/>
          </f-button>

          <f-button
            v-show="recordMode === 'view' && hasRecord"
            :disabled="schema.currentIndex === recordCount-1"
            @click="last"
          >
            <FontAwesomeIcon icon="fast-forward"/>
          </f-button>
        </div>
        <slot name="after-navigation">
        </slot>
      </div>
      <f-row>
        <f-col ref="content" class="detail-content flex flex-wrap">
          <slot class="crud-form-detail-content"></slot>
        </f-col>
      </f-row>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCopy,
  faEdit,
  faFastBackward,
  faFastForward,
  faPlus,
  faPrint,
  faSave,
  faSearch,
  faStepBackward,
  faStepForward,
  faSync,
  faTrash,
  faUndo,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons';

library.add(faStepForward, faStepBackward, faFastBackward, faFastForward, faEdit, faPlus,
  faSearch, faTrash, faSync, faCopy, faSave, faUndo, faPrint, faQuestion);

export default {
  name: 'CrudFormDetail',
  props: {
    schema: { type: Object, required: true },
    successMessage: { type: String, default: 'Registro gravado com sucesso.' },
    // indica que o form tem um master, ou seja, é um form filho
    formParent: { type: Object, default: () => null },
    formParentField: { type: String, default: null },
    // forms passados como parametro para realizar validacao antes de salvar
    formsValidation: { type: Array, default: () => [] },
    mostrardetailButtons: { default: true },
    enableButtonEdit: { type: Boolean, default: true },
    enableButtonNew: { type: Boolean, default: true },
    enableButtonSave: { type: Boolean, default: true },
    enableButtonDelete: { type: Boolean, default: true },
    enableButtonDuplicate: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    crudForm: { type: Object, default: () => {} },
    permissao: { type: String, default: '' },
    linkDoc: { type: String, default: '' },
  },
  data() {
    return {
      list: [],
      record: {},
      ids: [], // guarde os ids dos registros que estao sendo manipulados
      message: {
        show: false,
        title: '',
        type: 'info',
      },
      recordMode: 'view',
    };
  },
  created() {
    this.schema.onListChange.push((list) => {
      this.list = list;
    });
  },
  methods: {
    salvaRegistro() {
      this.$emit('beforeSave', this.schema.record);
      // isso é necessário para evitar erro se houver alguma validacao de backend, caso contrário
      // o rollbar vai notificar erro e também aparece erro no console
      this.save().catch(() => {});
    },
    newRecord() {
      this.setRecordStatus('new');
      this.schema.setRecord(this.schema.getModel());
      this.$emit('newRecord', '');
    },
    edit() {
      this.setRecordStatus('edit');
      if (this.formParent) {
        this.formParent.$refs.formDetail.edit();
      }
    },
    duplicar() {
      this.schema.duplicate();
      this.$emit('duplicate', this.schema);
      this.$showMessage({
        title: '', type: 'success', message: 'Registro duplicado, altere o que deseja!', time: 4500,
      });
    },
    cancel() {
      if (this.recordMode === 'edit' || this.recordMode === 'new') {
        this.setRecordStatus('view');
        this.schema.undo();
        this.$nextTick(() => {
          this.resetForm();
        });
        this.$showMessage({
          title: '', type: 'info', message: 'Registro cancelado!', time: 4500,
        });
        this.$emit('cancel');
      }
    },
    // funcao para limpar os campos do form utilizado no slot, necessario para limpar o estado caso
    // os campos esteja com status de invalido por exemplo
    resetForm() {
      // eslint-disable-next-line no-restricted-syntax
      for (const formValidation of this.formsValidation) {
        if (formValidation) {
          formValidation.reset();
        }
      }
    },
    saveSchema() {
      return new Promise((resolve, reject) => {
        this.schema.save()
          .then((response) => {
            this.setRecordStatus('view');
            if (!this.formParent) {
              this.$showMessage({
                title: '', type: 'success', message: this.successMessage, time: 2000,
              });
            }
            // só salve o save do form parent, caso esse formulario seja um filho somente em memoria, ou seja, no save
            // ele nao tem uma rota específica e salva somente no modelo do parent
            if (!this.schema.getMutationUpdate && this.formParent && this.formParent.$refs.formDetail) {
              this.formParent.$refs.formDetail.save();
            }
            resolve(response);
            this.$emit('save', response.record);
          })
          .catch((err) => {
            reject(err);
            this.$showMessage({
              title: 'Documento não foi salvo', type: 'error', message: err, time: 4500,
            });
          });
      });
    },
    save() {
      return new Promise((resolve, reject) => {
        if (this.formsValidation.length > 0) {
          let validated = true;
          // eslint-disable-next-line no-restricted-syntax
          for (const formValidation of this.formsValidation) {
            if (formValidation && !formValidation.validate() && validated) {
              validated = false;
            }
          }
          if (!validated) {
            reject(new Error('Campos obrigatórios não preenchidos'));
            return;
          }
        }
        // se o parent aida nao foi salvo e o filho tem sua propria rota
        if (this.formParent && !this.schema.parent.record.id && this.schema.getMutationUpdate) {
          if (this.formParent.$refs.formDetail) {
            // Realizado backup dos dados pois apos salvar o pai o mesmo era perdido
            const bkpRecord = this.schema.record;
            this.formParent.save()
              .then(() => {
              // Restaurando backup dos dados
                this.schema.record = bkpRecord;
                this.saveSchema()
                  .then(() => {
                    resolve();
                  }).catch((err) => {
                    reject(err);
                  });
              }).catch((err) => {
                reject(err);
              });
            return;
          }
        }
        this.saveSchema()
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteRecord() {
      if (!this.hasRecord) {
        return;
      }

      this.$confirm('Excluir Registro?', 'Pergunta', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'error',
      })
        .then((resp) => {
          if (resp === 'confirm') {
          // se for o filho que funciona somente em memoria, entao so remova do array sem
          // fazer request
            if (this.crudForm.isChild && !this.schema.getMutationDelete) {
              this.schema.remove();
              this.formParent.schema.record[this.formParentField] = this.schema.list;
              this.formParent.schema.dispatch('onRecordChange', this.formParent.schema.record);
              this.$showMessage({
                title: '', type: 'success', message: 'Excluído com sucesso!', time: 3000,
              });
              return;
            }
            this.schema.gqlMutation(this.schema.getMutationDelete())
              .then(() => {
                if (this.crudForm.isChild) {
                  this.schema.select(this.schema.currentIndex - 1);
                } else {
                  this.schema.remove();
                }
                this.$showMessage({
                  title: '', type: 'success', message: 'Excluído com sucesso!', time: 3000,
                });
              })
              .catch((err) => {
                this.$showMessage({
                  title: 'Documento não excluído', type: 'error', message: err,
                });
              });
          }
        });
    },
    // essa funcao traz os registros passados como parametros, pegando o id de cada um e trazendo
    loadRecords(records) {
      if (this.crudForm.isChild) {
        this.schema.setList(records.list);
        const idx = this.schema.getIndexByRecord(records.currentRecord);
        this.schema.select(idx);
        this.resetForm();
        this.$emit('loadRecord', this.schema.record);
        return;
      }

      if (records != null) {
        this.ids = [];
        Object.keys(records)
          .forEach((key) => this.ids.push(`"${records[key].id}"`));
      }
      this.setRecordStatus('view');
      this.schema.gqlQuery(this.schema.getByIds(this.ids))
        .then((response) => {
          this.schema.setList(response.result);
          this.resetForm();
          this.$emit('loadRecord', this.schema.record);
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err,
          });
        });
    },
    first() {
      if (this.schema.recordChanged()) {
        this.save()
          .then(() => {
            this.schema.first();
            this.$emit('loadRecord', this.schema.record);
          });
      }
      this.schema.first();
      this.$emit('loadRecord', this.schema.record);
    },
    prior() {
      if (this.schema.recordChanged()) {
        this.save()
          .then(() => {
            this.schema.prior();
            this.$emit('loadRecord', this.schema.record);
          });
      }
      this.schema.prior();
      this.$emit('loadRecord', this.schema.record);
    },
    next() {
      if (this.schema.recordChanged()) {
        this.save()
          .then(() => {
            this.schema.next();
            this.$emit('loadRecord', this.schema.record);
          });
      }
      this.schema.next();
      this.$emit('loadRecord', this.schema.record);
    },
    last() {
      if (this.schema.recordChanged()) {
        this.save()
          .then(() => {
            this.schema.last();
            this.$emit('loadRecord', this.schema.record);
          });
      }
      this.schema.last();
      this.$emit('loadRecord', this.schema.record);
    },
    disableEnableAllControls(children, disable) {
      Object.keys(children)
        .forEach((key) => {
          const child = children[key];
          if (child.setDisable) {
            child.setDisable(disable);
          }
          if (child.$children) {
            this.disableEnableAllControls(child.$children, disable);
          }
        });
    },
    setRecordStatus(value) {
      this.recordMode = value;
      this.$emit('recordStatus', value);
    },
  },
  watch: {
    recordMode(newValue) {
      this.resetForm();
      this.$emit('recordStatus', newValue);
    },
  },
  computed: {
    recordCount() {
      return this.list.length || 0;
    },
    registroAtual() {
      if (this.recordCount === 0) {
        return '';
      }
      return `${this.schema.currentIndex + 1}/${this.recordCount}`;
    },
    hasRecord() {
      return this.schema.record !== null;
    },
  },
};
</script>
