<template>
  <f-crud-form
    ref="formPerson"
    v-if="service"
    v-on="$listeners"
    :schema="service"
    :tabs="tabsInterna"
    :formsValidation="[$refs.formValidation]"
    permissao="pessoa"
  >
    <div slot="find-buttons">
      <f-button caption="Whatsapp" @click="mandarMensagemWhatsapp" >
        <em class="fab fa-whatsapp"></em>
      </f-button>
    </div>
    <f-row>
      <f-col col="12">
        <f-form ref="formValidation">
          <f-form-row class="flex flex-wrap">
            <f-checkbox
              caption="Cliente"
              v-model="service.record.isCustomer"
              v-if="$store.getters.hasPermission('pessoa.cliente')"
            />
            <f-checkbox
              caption="Fornecedor"
              v-model="service.record.isVendor"
              v-if="$store.getters.hasPermission('pessoa.fornecedor')"
            />
            <f-checkbox
              caption="Vendedor"
              v-model="service.record.isSalesMan"
              v-if="$store.getters.hasPermission('pessoa.vendedor')"
            />
            <f-checkbox
              caption="Funcionário"
              v-model="service.record.isEmployee"
              v-if="$store.getters.hasPermission('pessoa.funcionario')"
            />
            <f-checkbox
              caption="Transportador"
              v-model="service.record.isTransporter"
              v-if="$store.getters.hasPermission('pessoa.transportador')"
            />
          </f-form-row>
          <f-form-row>
            <cpfCnpj v-model='service.record.cpfCnpj' @dados="setDados" ref="cpfCnpj"/>
            <f-textbox
              :caption="captionIdent"
              v-model='service.record.ident'
              :validations="validations.ident"
              :disabled="service.record.identFree"
            />
            <f-checkbox
              caption="IE ISENTO"
              v-model="service.record.identFree"
              v-if="isCnpj"
            />
          </f-form-row>
          <f-textbox
            id="razao_social"
            caption='Nome/Razão Social'
            v-model='service.record.name'
            :validations="validations.name"
          />
          <f-textbox
            caption='Apelido/Nome Fantasia'
            v-model='service.record.name2'
            :validations="validations.name2"
          />
          <div v-if='service.record.isSalesMan' class="flex flex-wrap">
            <f-numberbox caption='Comissão %' v-model='service.record.vendedor.comissao' />
          </div>
          <div v-show="service.record.isCustomer">
            <f-lookup
              v-model="service.record.cliente.vendedorNome"
              caption='Vendedor vinculado ao cliente'
              component="Person"
              :filters="{type: 'isSalesMan'}"
              :value-id="service.record.cliente.vendedorId"
              @value="selectVendedor"
            />
            <f-form-row>
              <f-numberbox caption='Limite de Crédito' v-model='service.record.cliente.limiteCredito' />
              <f-numberbox
                caption='Saldo Devedor'
                v-model='service.record.cliente.saldoDevedor'
                :inputStyle="{color: this.$style.colorRed}"
                :disabled="true"
              />
              <f-numberbox
                caption='Crédito Disponível'
                v-model='service.record.cliente.creditoDisponivel'
                :inputStyle="inputStyleCreditoDisponivel"
                :disabled="true"
              />
            </f-form-row>
          </div>
        </f-form>
      </f-col>
    </f-row>
    <f-row>
      <f-col col="12">
        <f-tabs class="h-44">
          <f-tab-pane label="Endereços">
            <AddressItem v-if='formPerson' :service="service" :formParent="formPerson" />
          </f-tab-pane>
          <f-tab-pane label="Contatos">
            <ContactItem v-if='formPerson' :service="service" :formParent="formPerson" />
          </f-tab-pane>
          <f-tab-pane label="Outros dados">
            <f-textbox caption='Pai' v-model='service.record.pai' />
            <f-textbox caption='Mae' v-model='service.record.mae' />
            <f-datetimebox type="date" caption='Data de Nascimento' v-model='service.record.nascimento' />
            <f-lookup
              v-model="service.record.grupoNome"
              caption='Grupo'
              component="GrupoPessoas"
              :value-id="service.record.grupoNome"
              @value="selectGrupo"
            />
            <f-textbox caption='Código' v-model='service.record.codigo' />
            <f-lookup
              v-model="service.record.usuarioNome"
              caption='Usuário'
              component="User"
              :value-id="service.record.usuarioId"
              @value="setUser"
            />
            <f-textbox
              caption="Observação"
              type="textarea"
              v-model="service.record.obs"
              :validations="validations.obs"
            />
          </f-tab-pane>
        </f-tabs>
      </f-col>
    </f-row>
    <div slot="admin">
      <f-tabs type="border-card">
        <f-tab-pane label="Importação de Pessoas">
          <ImportacaoArquivo :importCallBack="importaPorArquivo" >
             <f-checkbox caption="Cliente" v-model="importaComoCliente" />
             <f-checkbox caption="Fornecedor" v-model="importaComoFornecedor" />
          </ImportacaoArquivo>
        </f-tab-pane>
      </f-tabs>
    </div>
    <WhatsappModal v-model="mostrarWhatsapp" :numero="numeroWhatsapp" />
  </f-crud-form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Vue from 'vue';
import WhatsappModal from '../../custom/WhatsappModal';
import PersonService from './PersonService';
import AddressItem from './PersonAddress';
import ContactItem from './PersonContact';
import GrupoPessoa from '../grupopessoas/GrupoPessoa';
import ImportacaoArquivo from '../../custom/ImportacaoArquivo';

library.add(faWhatsapp);
Vue.component('GrupoPessoas', GrupoPessoa);

export default {
  components: {
    AddressItem,
    ContactItem,
    ImportacaoArquivo,
    WhatsappModal,
  },
  data() {
    return {
      currentTab: 0,
      numeroWhatsapp: '',
      mostrarWhatsapp: false,
      importaComoCliente: false,
      importaComoFornecedor: false,
      service: {},
      formPerson: null,
      formContacts: null,
      validations: {
        name: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        name2: [
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        ident: [
          {
            min: 5,
            max: 20,
            message: 'Rg deve ter entre 5 e 20 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    captionIdent() {
      return this.isCnpj ? 'IE' : 'Doc. Identificacao';
    },
    isCnpj() {
      return _.general().isCnpj(this.service.record.cpfCnpj);
    },
    inputStyleCreditoDisponivel() {
      if (this.service.record.cliente.creditoDisponivel > 0) {
        return { color: this.$style.colorGreen };
      }
      if (this.service.record.cliente.creditoDisponivel <= 0) {
        return { color: this.$style.colorRed };
      }
      return { };
    },
    tabsInterna() {
      const tabs = [];
      if (!this.$store.getters.hasPermission('pessoa.importacao')) {
        return tabs;
      }
      tabs.push({ name: 'admin', caption: 'Administrativo' });
      return tabs;
    },
  },
  created() {
    this.service = new PersonService();
  },
  mounted() {
    // quando passa o form para o service, o component form-crud irá tentar validar antes de salvar
    this.service.form = this.$refs.form;
    this.formPerson = this.$refs.formPerson;
    this.formContacts = this.$refs.formContacts;
  },
  methods: {
    selectGrupo(records) {
      this.service.record.grupoId = records.id;
      this.service.record.grupoNome = records.nome;
    },
    selectVendedor(records) {
      this.service.record.cliente.vendedorId = records.id;
      this.service.record.cliente.vendedorNome = records.name;
    },
    setUser(data) {
      if (data.id) {
        this.service.record.usuarioId = data.id;
        this.service.record.usuarioNome = data.name;
      }
    },
    setDados(dados) {
      if (!this.service.record.id) {
        this.service.record.name = dados.nome;
        this.service.record.name2 = dados.nome2;
        this.service.record.ident = dados.ie;
        if (this.service.record.address.length === 0) {
          this.service.record.address.push({
            id: this.$utils.String.uiid(),
            address: dados.endereco,
            zipCode: dados.cep,
            number: dados.numero,
            city: dados.cidade,
            cityCode: dados.cidadeCodigo,
            neighborhood: dados.bairro,
            state: dados.uf,
          });
        }
        if (this.service.record.contacts.length === 0) {
          this.service.record.contacts.push({
            id: this.$utils.String.uiid(),
            email: dados.email,
            phone: dados.telefone1,
          });
        }
      }
    },
    importaPorArquivo(file, filename) {
      return new Promise((resolve, reject) => {
        this.service.importaPorArquivo(file, filename, this.importaComoCliente, this.importaComoFornecedor)
          .then(() => {
            resolve();
            this.$showMessage({
              title: 'Importacao de Pessoas',
              type: 'success',
              message: 'Importação realizada com sucesso',
            });
          })
          .catch((error) => {
            reject();
            this.$showMessage({
              title: 'Importacao de pessoas',
              type: 'error',
              message: `Importacao não realizada : ${error}`,
            });
          });
      });
    },
    mandarMensagemWhatsapp() {
      this.numeroWhatsapp = '';
      if (this.formPerson.formFind.selectedRecords && this.formPerson.formFind.selectedRecords.length === 1
        && this.formPerson.formFind.selectedRecords[0].contacts) {
        // eslint-disable-next-line
        for (const contato of this.formPerson.formFind.selectedRecords[0].contacts || []) {
          if (contato.byWhatsApp && contato.phone) {
            this.numeroWhatsapp = contato.phone;
          }
        }
      }
      this.mostrarWhatsapp = !this.mostrarWhatsapp;
    },
  },
};
</script>

<style scoped>
.fa-whatsapp  {
  color:#fff;
  background:
    linear-gradient(#25d366,#25d366) 14% 84%/16% 16% no-repeat,
    radial-gradient(#25d366 60%,transparent 0);
}
</style>
