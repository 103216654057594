<template>
  <div>
    <f-button
      caption="Enviar Logomarca"
      type="success"
      @click="mostraLogomarca = true"
    >
      <FontAwesomeIcon icon="camera" />
    </f-button>
    <div v-if="service.record">
      <f-dialog v-model="mostraLogomarca" width="35%" title="Envio da Logomarca">
        <div
          v-loading="service.loading"
          element-loading-text="Aguarde..."
          element-loading-spinner="el-icon-loading"
          v-if="service && service.record"
        >
          <div class="flex flex-col justify-center">
            <f-image
              class="text-center shadow-md"
              v-show="service.record.logomarca.url"
              :src="service.record.logomarca.url"
              @change="service.record.logomarca.base64 = $event.base64"
            />
            <br>
            <div v-if="!service.record.logomarca.url">
              <f-upload
                :maxSize="100"
                :multiple="false"
                :show-submit-button="false"
                :maxImageWidth="200"
                :maxImageHeight="200"
                accept="image/*"
                @removed="apagarLogo"
                @files-changed="selectedLogo"
              >
              </f-upload>
            </div>
          </div>
          <div class="flex justify-center">
            <f-alert type="warning" v-if="!service.record.logomarca.url">
              Você ainda não enviou uma imagem de logomarca
            </f-alert>
            <f-button
              v-show="novoUpload"
              caption="Enviar"
              type="success"
              @click="save"
            >
              <FontAwesomeIcon icon="upload" />
            </f-button>
            <f-button
              v-if="service.record.logomarca.url"
              type="danger"
              :caption="'Apagar Logomarca'"
              @click="apagarLogo"
            >
              <FontAwesomeIcon icon="trash" />
            </f-button>
          </div>
        </div>
      </f-dialog>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';

library.add(faUpload, faTrash, faCamera);

export default {
  props: {
    service: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      mostraLogomarca: false,
      novoUpload: false,
    };
  },
  methods: {
    selectedLogo($event) {
      this.novoUpload = true;
      this.service.record.logomarca.url = $event[0].localUrl;
    },
    apagarLogo() {
      this.novoUpload = false;
      this.service.record.logomarca.base64 = 'excluir';
      if (this.service.record.logomarca.nomeArquivo) {
        this.service.record.logomarca.nomeArquivo = '';
        this.save()
          .then(() => {
            this.service.record.logomarca.url = '';
          });
      } else {
        this.service.record.logomarca.url = '';
      }
    },
    save() {
      return new Promise((resolve, reject) => {
        this.service.saveConfig()
          .then((response) => {
            this.novoUpload = false;
            this.$showMessage({
              type: 'success',
              message: 'Logomarca atualizada com sucesso!',
            });
            resolve(response.record);
          })
          .catch((err) => {
            this.$showMessage({
              type: 'error',
              message: 'Erro ao atualizar logomarca: '.concat(err.message),
            });
            reject(err);
          });
      });
    },
  },
};

</script>
