<template>
  <f-form>
    <f-dialog
      :width="$store.getters.isMobile ? '100%' : '50%'"
      :value="value" title="ALTERACAO CONJUNTA DE PRODUTOS"
      @input="$emit('input', $event)"
    >
      <div  class="flex flex-col justify-center">
        <f-alert type="warning">
          <strong>
            As alterações serão feitas baseadas nos filtro informados abaixo ou para os produtos selecionados.
            Se nenhum filtro ou produto for informado, as alterações serão aplicadas a todos os produtos.
          </strong>
        </f-alert>
        <strong>Alterações:</strong>
        <f-row>
          <f-lookup
            v-model='alteracoes.fabricante.nome'
            caption='Fabricante'
            component="Person"
            width="200px"
            :filters="{type: 'isVendor'}"
            :value-id="alteracoes.fabricante.id"
            @value="setFabricante"
          />
          <f-lookup
            caption='Categoria'
            v-model='alteracoes.categoria.nome'
            component="Categoria"
            width="200px"
            :value-id="alteracoes.categoria.id"
            @value="setCategoria"
          />
          <f-combobox caption="Unidade" v-model='alteracoes.unidade' :options="unidades"/>
        </f-row>
        <f-checkbox
          v-if="!alteracoes.naoFracionavel"
          caption="Tornar fracionável"
          v-model="alteracoes.fracionavel"
        />
        <f-checkbox
          v-if="!alteracoes.fracionavel"
          caption="Tornar não fracionável"
          v-model="alteracoes.naoFracionavel"
        />
        <strong>Filtros:</strong>
        <f-row>
          <f-lookup
            v-model='alteracoes.filtro.fabricante.nome'
            caption='Fabricante'
            component="Person"
            width="200px"
            :filters="{type: 'isVendor'}"
            :value-id="alteracoes.filtro.fabricante.id"
            @value="setFiltroFabricante"
          />
          <f-lookup
            caption='Categoria'
            v-model='alteracoes.filtro.categoria.nome'
            component="Categoria"
            width="200px"
            :value-id="alteracoes.filtro.categoria.id"
            @value="setFiltroCategoria"
          />
        </f-row>
        <div v-if="mostraProdutosEspecificos">
          <f-row>
            <div class="flex items-end">
              <f-lookup
                v-model="produtoSelecionado.nome"
                caption='Produto'
                ref="produtoTextbox"
                component="Produto"
                width="200px"
                :style="'margin-top: 10px;'"
                :value-id="produtoSelecionado.id"
                @value="selectProduto($event)"
              />
              <f-button caption="Adicionar" class="mb-1" @click="addProduto"/>
            </div>
          </f-row>
          <div
            class="grid"
            element-loading-text="Aguarde..."
            element-loading-spinner="el-icon-loading"
          >
            <f-grid
              class="grid2 f-flex-stretch"
              element-loading-text="Aguarde..."
              ref="grid"
              :selecionSingle="true"
              :columns="columns"
              :data="list"
              @dblClick="removeProduto"
              @change="gridChange"
            >
            </f-grid>
            <slot class="grid" name="grid">
            </slot>
          </div>
        </div>
        <f-alert v-else type="warning">
          <strong>
            Não é possivel selecionar produtos especificos ao filtrar por categoria ou fabricante.
          </strong>
        </f-alert>
        <f-alert v-if="alertaTodoEstoque" type="warning">
          <strong>
            ATENÇÃO!!! Nenhum filtro foi selecionado. Caso prossiga as alterações serão aplicadas a
             TODOS OS PRODUTOS DO ESTOQUE.
          </strong>
        </f-alert>
        <f-button caption="Alterar" @click="alteraProdutos" class="mt-5" size="md" type="success"/>
      </div>
    </f-dialog>
  </f-form>
</template>

<script>

export default {
  data() {
    return {
      mostraProdutosEspecificos: true,
      alteracoes: this.getNullData(),
      produtoSelecionado: {
        id: null,
        nome: null,
      },
      selectedRecords: {},
      columns: [
        {
          field: 'nome',
          title: 'Nome',
          type: 'String',
          width: '100px',
        },
      ],
      list: [],
      unidades: [
        { text: '', value: '' },
        { text: 'UN', value: 'UN' },
        { text: 'M2', value: 'M2' },
        { text: 'M3', value: 'M3' },
        { text: 'ML', value: 'ML' },
        { text: 'KG', value: 'KG' },
        { text: 'CX', value: 'CX' },
        { text: 'LT', value: 'LT' },
        { text: 'M', value: 'M' },
        { text: 'CM', value: 'CM' },
      ],
    };
  },
  mounted() {
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    produtosIniciais: { type: Array, default: () => [] },
  },
  computed: {
    alertaTodoEstoque() {
      let temAlteracoes = false;
      if (this.alteracoes.categoria.id || this.alteracoes.fabricante.id || this.alteracoes.unidade
       || this.alteracoes.fracionavel || this.alteracoes.naoFracionavel) {
        temAlteracoes = true;
      }
      if (temAlteracoes && !this.alteracoes.filtro.categoria.id && !this.alteracoes.filtro.fabricante.id
        && this.alteracoes.filtro.ids.length === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    loadSelected() {
      this.alteracoes = this.getNullData();
      this.list = [];
      _.forEach(this.produtosIniciais, (prod) => {
        this.selectProduto(prod);
        this.addProduto();
      });
    },
    setFiltroFabricante(record) {
      this.alteracoes.filtro.fabricante.nome = record.name;
      this.alteracoes.filtro.fabricante.id = record.id;
    },
    setFiltroCategoria(record) {
      this.alteracoes.filtro.categoria.nome = record.nome;
      this.alteracoes.filtro.categoria.id = record.id;
    },
    setFabricante(record) {
      this.alteracoes.fabricante.nome = record.name;
      this.alteracoes.fabricante.id = record.id;
    },
    setCategoria(record) {
      this.alteracoes.categoria.nome = record.nome;
      this.alteracoes.categoria.id = record.id;
    },
    selectProduto(record) {
      if (!record.id) {
        this.resetProdutoSelecionado();
        return;
      }
      this.produtoSelecionado.id = record.id;
      this.produtoSelecionado.nome = record.nome;
    },
    resetProdutoSelecionado() {
      this.produtoSelecionado.id = null;
      this.produtoSelecionado.nome = null;
    },
    addProduto() {
      if (!this.produtoSelecionado.id || this.produtoSelecionado.id === '') {
        return;
      }
      const produto = {
        id: this.produtoSelecionado.id,
        nome: this.produtoSelecionado.nome,
      };

      this.resetProdutoSelecionado();

      if (this.alteracoes.filtro.ids.includes(produto.id)) {
        this.$showMessage({
          title: 'Alteracao conjunta de produtos',
          type: 'error',
          message: 'Produto já está na lista!',
        });
        return;
      }
      this.list.push(produto);
      this.alteracoes.filtro.ids.push(produto.id);
    },
    removeProduto() {
      if (this.selectedRecords.length > 0) {
        if (this.selectedRecords.length < 2) {
          this.list = this.$utils.Array.RemoveItem(this.list, this.selectedRecords[0]);
          this.alteracoes.filtro.ids = this.$utils.Array.RemoveItem(this.alteracoes.filtro.ids,
            this.selectedRecords[0].id);
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const p of this.selectedRecords) {
            this.list = this.$utils.Array.RemoveItem(this.list, p);
            this.alteracoes.filtro.ids = this.$utils.Array.RemoveItem(this.alteracoes.filtro.ids, p.id);
          }
        }
      }
    },
    gridChange(selectedRecords) {
      this.selectedRecords = selectedRecords;
      this.$emit('selectedRecords', this.selectedRecords);
    },
    getNullData() {
      return {
        filtro: {
          fabricante: {
            nome: null,
            id: null,
          },
          categoria: {
            nome: null,
            id: null,
          },
          ids: [],
        },
        fabricante: {
          nome: null,
          id: null,
        },
        categoria: {
          nome: null,
          id: null,
        },
        unidade: null,
        fracionavel: false, // se marcar, os produtos encontrados serão marcados como facionaveis
        naoFracionavel: false, // se marcar, os produtos encontrados serão marcados como não facionaveis
      };
    },
    async alteraProdutos() {
      if (!this.alteracoes.categoria.id && !this.alteracoes.fabricante.id && !this.alteracoes.unidade
        && !this.alteracoes.fracionavel && !this.alteracoes.naoFracionavel) {
        this.$showMessage({
          message: 'É necessario especificar quais alteracoes devem ser efetuadas.',
          type: 'error',
        });
        return;
      }
      let pergunta = 'Deseja realmente aplicar estas alteraçoes em todos os produtos selecionados?';
      if (this.alteracoes.filtro.ids.length === 0 && !this.alteracoes.filtro.categoria.id
        && !this.alteracoes.filtro.fabricante.id) {
        pergunta = 'Nenhum filtro foi selecionado. Ao confirmar, as alteraçoes serão feitas a todos os'
          + ' produtos do sistema. Deseja realmente aplicar estas alteraçoes em todos os produtos do estoque?';
      }

      const resp = await this.$confirm(pergunta,
        'Confirma operação?', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'warning',
        });
      if (resp) {
        this.$emit('alteraProdutos', this.alteracoes);
        this.$emit('input', false);
        this.alteracoes = this.getNullData();
      }
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.loadSelected();
      }
    },
    // Os watches abaixo servem para desabilitar a selecao de produtos, pois n faz sentido
    // informar o filtro por categoria ou fabricante ao mesmo tempo que os ids
    // eslint-disable-next-line func-names
    'alteracoes.filtro.fabricante.id': function () {
      if (this.alteracoes.filtro.fabricante.id || this.alteracoes.filtro.categoria.id) {
        this.mostraProdutosEspecificos = false;
        return;
      }
      this.mostraProdutosEspecificos = true;
    },
    // eslint-disable-next-line func-names
    'alteracoes.filtro.categoria.id': function () {
      if (this.alteracoes.filtro.fabricante.id || this.alteracoes.filtro.categoria.id) {
        this.mostraProdutosEspecificos = false;
        return;
      }
      this.mostraProdutosEspecificos = true;
    },
  },
};
</script>
