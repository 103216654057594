import { cnpj } from 'cpf-cnpj-validator';

// eslint-disable-next-line func-names
window.String.prototype.capitalize = function () {
  return this.replace(/(^|\s)([a-z])/g,
    (m, p1, p2) => p1 + p2.toUpperCase());
};

export default class StringUtil {
  // format bytes, return string in 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB
  static formatBytes(bytes, decimals) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const result = parseFloat((bytes / (k ** i)).toFixed(dm))
      .toString();
    return `${result}  ${sizes[i]}`;
  }

  /**
   * Retorna se a string e somente numero
   * @param str - string a ser verificada se é somente numero
   * @return boolean - true se a str é somente número, false caso nao seja
   */
  static isOnlyNumber(str) {
    return /^\d+$/.test(str);
  }

  static onlyNumber(str) {
    if (!str) {
      return '';
    }
    return str.replace(/[^0-9]/g, '');
  }

  /**
   * Checa se o codigo(tabela ascii) de uma key(evento keyup por exemplo é númerico)
   * @param key
   * @return {boolean}
   */
  static isNumericKey(key) {
    if (!key) {
      return false;
    }
    return (key.keyCode >= 48 && key.keyCode <= 57) || (key.keyCode >= 96 && key.keyCode <= 105);
  }

  /**
   * Checa se o codigo(tabela ascii) de uma key(evento keyup por exemplo) é Delete ou Backspace
   * @param key
   * @return {boolean}
   */
  static isErasingKey(key) {
    if (!key) {
      return false;
    }
    return (key.keyCode === 8 || key.keyCode === 46);
  }

  /**
   * Checa se um CNPJ é valido ou nao
   * @param v
   * @return {boolean}
   */
  static isValidCnpj(v) {
    return cnpj.isValid(v);
  }

  /**
   * Checa se uma string é uma Url valida
   * @return {boolean}
   * @param str
   */
  static isUrl(str) {
    let url;
    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  static uiid() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }
}
