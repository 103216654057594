<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    col: {
      default: '',
    },
    md: {
      default: '',
    },
    lg: {
      default: '',
    },
    xl: {
      default: '',
    },
    sm: {
      default: '',
    },
  },
  computed: {
    classes() {
      let column = '';
      const sizes = ['md', 'lg', 'xl', 'sm'];
      Object.keys(sizes)
        .forEach((key) => {
          const size = sizes[key];
          if (this[size]) {
            column += ` col-${size}-${this[size]}`;
          }
        });
      if (column === '' && this.col) {
        column = `col-lg-${this.col} col-xl-${this.col} col-md-${this.col} col-sm-12`;
      }
      return column;
    },
  },
};
</script>

<style scoped>
</style>
