<template>
  <div>
    <f-dialog :value="value" @input="$emit('input', $event)" width="50%" title="Geração de Carga pra Balança">
      <div class="flex flex-col items-center justify-center" v-if="service">
        <f-button
          caption="Gerar Arquivo Txt para carga(itensmgv.txt)"
          class="mt-5"
          size="md"
          type="success"
          @click="geraArquivo"
        />
        <a
          class="text-xl font-bold mt-5 underline decoration-blue-500 hover:decoration-blue-500/40
          focus:decoration-blue-500/40 motion-safe:transition-all motion-safe:duration-200"
          download="itensmgv.txt"
          v-if="arquivoBase64"
          :href="`data:text/plain;base64,${arquivoBase64}`">
          Download arquivo
        </a>
      </div>
    </f-dialog>
  </div>
</template>

<script>

export default {
  props: {
    service: { type: Object },
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      arquivoBase64: null,
      formato: 'itensmgv',
    };
  },
  methods: {
    reset() {
      this.arquivoBase64 = null;
    },
    geraArquivo() {
      this.service.geraArquivoTxtBalanca('itensmgv')
        .then((response) => {
          this.arquivoBase64 = response;
          this.$showMessage({
            title: 'XML importado!',
            type: 'success',
            message: 'O XML enviado ao sistema foi importado com sucesso!',
          });
        })
        .catch((err) => {
          this.$showMessage({
            title: 'XML não foi salvo!',
            type: 'error',
            message: err,
          });
        });
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.reset();
      }
      this.value = newValue;
    },
  },
};
</script>
