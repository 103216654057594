import Dataset from 'comps/libs/dataset';

const getProdutoFields = () => `
        id
        situacao
        createdAt
        nome
        un
        isFracionavel
        descricao
        aplicacao
        codigoProduto
        codigoBarra
        valorUnitario
        precoVendaAprazo
        precoVendaAtacado
        precoUltimaCompra
        custoManual
        precoCusto
        custoMedio
        codigoReferencia
        fabricanteId
        fabricanteNome
        categoriaNome
        categoriaId
        tributacaoNome
        tributacaoId
        pesoBruto
        pesoLiquido
        quantidadeMinima
        quantidadeMaxima
        diasGarantiaEmpresa
        diasGarantia
        quantidadeMaxima
        quantidadeMinimaVenda
        quantidadeVendaMultiplo
        codigoAnp
        codigoAnpDescricao
        produtoEspecifico
        ncm
        cest
        descricaoAdicionalVenda
        composto
        quantidadeEstoqueTotal
        estoquesList{
          id
          estoqueNome
          quantidade
        }
        composicao {
          id
          itemId
          tipo
          nome
          un
          valorUnitario
          quantidade
          total
        }
    `;

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      { name: 'nome', caption: 'Nome' },
      { name: 'composto', caption: 'Composto', visible: false },
      { caption: 'Código Produto', name: 'codigoProduto' },
      { caption: 'Código Barra', name: 'codigoBarra' },
      { caption: 'Código Referência', name: 'codigoReferencia' },
      { name: 'un', caption: 'UN' },
      { name: 'categoriaNome', caption: 'Categoria' },
      { name: 'fabricanteNome', caption: 'Fabricante' },
      { name: 'quantidadeEstoqueTotal', caption: 'Qtde.', type: 'float' },
      { name: 'valorUnitario', caption: 'Preço Venda Avista', type: 'currency' },
      { name: 'precoVendaAprazo', caption: 'Preço Venda A prazo', type: 'currency' },
    ];
    this.findFilters = [
      {
        type: 'textbox', width: '300px', label: 'Consulta por Codigo ou nome', name: 'main',
      },
      { type: 'textbox', label: 'Por referência', name: 'referencia' },
      { type: 'textbox', label: 'Categoria', name: 'categoria' },
      { type: 'textbox', label: 'Fabricante', name: 'fabricante' },
      {
        label: 'Situação',
        name: 'situacao',
        type: 'combobox',
        value: '',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Ativo', value: 'ATIVO' },
          { text: 'Inativo', value: 'INATIVO' },
        ],
      },
      {
        label: 'Ordenar por:',
        name: 'orderByDirection',
        type: 'combobox',
        value: localStorage.getItem('produtoOrderByDirection'),
        options: [
          { text: '', value: '' },
          { text: 'Criação Crescente', value: 'createAtAsc' },
          { text: 'Criação Decrescente', value: 'createAtDesc' },
          { text: 'Nome Crescente', value: 'nomeAsc' },
          { text: 'Nome Decrescente', value: 'nomeDesc' },
        ],
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      situacao: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
      codigoSistema: null,
      codigoEan: null,
      codigoProduto: null,
      codigoBarra: null,
      codigoReferencia: null,
      un: null,
      isFracionavel: false,
      valorUnitario: null,
      precoVendaAprazo: null,
      precoVendaAtacado: null,
      precoUltimaCompra: null,
      custoManual: false,
      precoCusto: null,
      custoMedio: null,
      pesoLiquido: null,
      pesoBruto: null,
      quantidadeMinima: null,
      quantidadeMaxima: null,
      quantidadeMinimaVenda: null,
      quantidadeVendaMultiplo: null,
      diasGarantiaEmpresa: null,
      diasGarantia: null,
      codigoAnp: null,
      codigoAnpDescricao: null,
      produtoEspecifico: null,
      fabricanteNome: null,
      fabricanteId: null,
      categoriaNome: null,
      categoriaId: null,
      tributacaoNome: null,
      tributacaoId: null,
      ncm: null,
      cest: null,
      descricao: null,
      aplicacao: null,
      obs: null,
      descricaoAdicionalVenda: null,
      composto: null,
      composicao: null,
      estoquesList: [],
      quantidadeEstoqueTotal: null,
    };
  }

  getFindQuery(filters) {
    let filter = `filter: "${filters.main || ''}", `;

    if (filters.situacao) {
      filter += `situacao: "${filters.situacao}",`;
    }

    if (filters.fabricante) {
      filter += `fabricante: "${filters.fabricante}",`;
    }

    if (filters.categoria) {
      filter += `categoria: "${filters.categoria}"`;
    }
    if (filters.referencia) {
      filter += `referencia: "${filters.referencia}"`;
    }
    if (filters.orderByDirection) {
      filter += `orderByDirection: "${filters.orderByDirection}"`;
      localStorage.setItem('produtoOrderByDirection', filters.orderByDirection);
    } else {
      localStorage.setItem('produtoOrderByDirection', '');
    }

    return `query {
              ProdutoList(${filter}) {
                id
                situacao
                nome
                un
                ncm
                isFracionavel
                codigoProduto
                codigoBarra
                codigoReferencia
                fabricanteNome
                categoriaNome
                valorUnitario
                precoVendaAprazo
                precoVendaAtacado
                composto
                quantidadeMinima
                quantidadeMaxima
                consultaQuantidade
                quantidadeEstoqueTotal
              }
           }`;
  }

  getByIds(ids) {
    return `query {
              ProdutoList(ids: [${ids.join()}]) {
                ${getProdutoFields()}
              }
            }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation ProdutoCreate($data: ProdutoInput!) {
           ProdutoCreate(data: $data){
              ${getProdutoFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation ProdutoUpdate($id: String!, $data: ProdutoInput!) {
           ProdutoUpdate(id: $id, data: $data){
              ${getProdutoFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation ProdutoDelete($id: String!){
            ProdutoDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  addComposicao(model) {
    if (!this.record.composicao) {
      this.record.composicao = [];
    }
    this.record.composicao.push({
      id: this.$utils.String.uiid(),
      itemId: model.id,
      tipo: model.tipo,
      nome: model.nome,
      un: model.un,
      quantidade: model.quantidade,
      valorUnitario: model.valorUnitario,
      total: model.total,
    });
    return this.save();
  }

  deleteComposicao(record) {
    this.$utils.Array.RemoveItem(this.record.composicao, record);
    return this.save();
  }

  getComposicaoProduto(produtoId) {
    const query = `
          query {
            ProdutoList(ids:["${produtoId}"]){
                id
                nome
                un
                composto
                composicao {
                    id
                    itemId
                    tipo
                    nome
                    un
                    valorUnitario
                    quantidade
                    total
                }
            }
          }`;
    return new Promise((resolve, reject) => {
      this.gqlQuery(query)
        .then(((response) => {
          if (response.result && response.result.length > 0) {
            resolve(response.result[0]);
          } else {
            resolve([]);
          }
        }))
        .catch((err) => {
          reject(err);
        });
    });
  }

  async imprimir() {
    const query = `
          query {
            ProdutoImprimir(ids:["${this.record.id}"]){
              id
              pdfBase64
            }
          }`;
    const result = await this.gqlQuery(query);
    return result;
  }

  addMovimentoEstoque(movimentoEstoque) {
    const query = `
          mutation ProdutoAddMovimentacao($data: ProdutoMovimentacaoInput!) {
            ProdutoAddMovimentacao(data: $data){
              id
              createdAt
              tipo
              quantidade
              qAtual
              qAnterior
              estoqueNome
              origem
              origemNumero
            }
          }`;
    // eslint-disable-next-line no-param-reassign
    const params = {
      data: {
        origem: 'Mov. Manual',
        produtoId: this.record.id,
        estoqueNome: movimentoEstoque.estoqueNome,
        quantidade: movimentoEstoque.quantidade,
        tipo: movimentoEstoque.tipo,
      },
    };

    return new Promise((resolve, reject) => {
      this.gqlMutation({ query, params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  ajustarEstoque(ajusteEstoque, responsavel) {
    const query = `
          mutation ProdutoAjustarEstoque(
            $produtoId: String!, $estoqueId: String!, $quantidade: Float,
            $pessoaId: String, $pessoaNome: String
          ) {
            ProdutoAjustarEstoque(
              produtoId: $produtoId, estoqueId: $estoqueId, quantidade: $quantidade,
              pessoaId: $pessoaId, pessoaNome: $pessoaNome
            )
          }`;
    // eslint-disable-next-line no-param-reassign
    const params = {
      produtoId: this.record.id,
      estoqueId: ajusteEstoque.id || '',
      quantidade: ajusteEstoque.quantidade,
      pessoaId: responsavel.pessoaId,
      pessoaNome: responsavel.pessoaNome,
    };

    return new Promise((resolve, reject) => {
      this.gqlMutation({ query, params })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMovimentacaoProduto(filtro) {
    let filter = `produtoId: "${this.record.id}", `;
    if (filtro && filtro.tipo) {
      filter += `tipoMovimento: "${filtro.tipo}", `;
    }
    if (filtro && filtro.origem) {
      filter += `origem: "${filtro.origem}"`;
    }
    const query = `
          query {
            ProdutoMovimentoEstoqueList(${filter}){
                id
                createdAt
                tipo
                userName
                quantidade
                valorUnitario
                qAtual
                qAnterior
                estoqueNome
                origem
                origemNumero
                pessoaId
                pessoaNome
            }
          }`;
    return new Promise((resolve, reject) => {
      this.gqlQuery(query)
        .then(((response) => {
          resolve(response.result);
        }))
        .catch((err) => {
          reject(err);
        });
    });
  }

  geraArquivoTxtBalanca(formato) {
    const query = `
          query {
            GetArquivoBalanca(formato: "${formato}")
          }`;
    return new Promise((resolve, reject) => {
      this.gqlQuery(query)
        .then(((response) => {
          resolve(response.result);
        }))
        .catch((err) => {
          reject(err);
        });
    });
  }

  importaPorArquivo(fileString, fileName) {
    const query = `
         mutation ProdutoImporta($fileString: String!, $fileName: String!){
           ProdutoImporta(fileString: $fileString,fileName: $fileName)
         }
    `;
    return this.gqlMutation({ query, params: { fileString, fileName } });
  }

  alteraProdutos(alteracoes) {
    const query = `
         mutation ProdutoAlteracaoConjunta($data: AlteracoesInput!){
          ProdutoAlteracaoConjunta(data: $data)
         }
    `;
    return new Promise((resolve, reject) => {
      this.gqlMutation({ query, params: { data: alteracoes } })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMutationParams(model) {
    const params = {
      situacao: model.situacao,
      nome: model.nome,
      codigoProduto: model.codigoProduto,
      codigoBarra: model.codigoBarra,
      codigoReferencia: model.codigoReferencia,
      un: model.un,
      isFracionavel: model.isFracionavel,
      valorUnitario: this.$utils.Number.parseFloat(model.valorUnitario),
      precoVendaAprazo: this.$utils.Number.parseFloat(model.precoVendaAprazo),
      precoVendaAtacado: this.$utils.Number.parseFloat(model.precoVendaAtacado),
      precoUltimaCompra: this.$utils.Number.parseFloat(model.precoUltimaCompra),
      custoManual: model.custoManual,
      precoCusto: this.$utils.Number.parseFloat(model.precoCusto),
      custoMedio: this.$utils.Number.parseFloat(model.custoMedio),
      quantidadeMinima: this.$utils.Number.parseFloat(model.quantidadeMinima),
      quantidadeMaxima: this.$utils.Number.parseFloat(model.quantidadeMaxima),
      quantidadeMinimaVenda: this.$utils.Number.parseFloat(model.quantidadeMinimaVenda),
      quantidadeVendaMultiplo: this.$utils.Number.parseFloat(model.quantidadeVendaMultiplo),
      fabricanteNome: model.fabricanteNome,
      fabricanteId: model.fabricanteId,
      categoriaNome: model.categoriaNome,
      categoriaId: model.categoriaId,
      tributacaoNome: model.tributacaoNome,
      tributacaoId: model.tributacaoId,
      descricao: model.descricao,
      localizacao: model.localizacao,
      aplicacao: model.aplicacao,
      diasGarantiaEmpresa: this.$utils.Number.parseFloat(model.diasGarantiaEmpresa),
      diasGarantia: this.$utils.Number.parseFloat(model.diasGarantia),
      codigoAnp: model.codigoAnp,
      codigoAnpDescricao: model.codigoAnpDescricao,
      produtoEspecifico: model.produtoEspecifico,
      pesoBruto: model.pesoBruto,
      pesoLiquido: model.pesoLiquido,
      descricaoAdicionalVenda: model.descricaoAdicionalVenda,
      ncm: model.ncm,
      cest: model.cest,
      composto: model.composto,
    };
    if (model.composicao && model.composicao.length > 0) {
      params.composicao = [];
      // eslint-disable-next-line no-unused-vars,no-restricted-syntax
      for (const c of model.composicao) {
        params.composicao.push({
          itemId: c.itemId,
          tipo: c.tipo,
          nome: c.nome,
          un: c.un,
          quantidade: this.$utils.Number.parseFloat(c.quantidade),
          valorUnitario: this.$utils.Number.parseFloat(c.valorUnitario),
        });
      }
    }
    return params;
  }

  getReportConfig() {
    return [
      {
        graphqlQueryName: 'ProdutoReport',
        graphqlMailName: true,
        nomeRelatorio: 'Relatorio de Produtos',
        id: '1',
        descricao: 'Emissão de relatórios de produtos com diversos filtros e visualização',
        campos: [
          {
            tipo: 'combobox',
            caption: 'Tipo de Relatório(Visualização)',
            campoFiltro: 'tipoRelatorio',
            value: 'ProdutoListaSimples',
            opcoes: [
              { value: 'ProdutoListaSimples', text: 'Lista Simples' },
              { value: 'ProdutoPorCategoria', text: 'Agrupado por Categoria' },
              { value: 'ProdutoPorFabricante', text: 'Agrupado por Fabricante' },
              { value: 'ProdutoCustoEstoqueListaSimples', text: 'Custo de Estoque' },
            ],
          },
          {
            tipo: 'combobox',
            caption: 'Situação',
            campoFiltro: 'situacao',
            value: '',
            opcoes: [
              { value: 'ATIVO', text: 'ATIVO' },
              { value: 'INATIVO', text: 'INATIVO' },
            ],
          },
          { tipo: 'date', caption: 'Data Cadastro', campoFiltro: 'createdAt' },
          { tipo: 'number', caption: 'P. Custo', campoFiltro: 'precoCusto' },
          { tipo: 'number', caption: 'P. Venda Avista', campoFiltro: 'precoVendaAvista' },
          { tipo: 'number', caption: 'P. Venda Aprazo', campoFiltro: 'precoVendaAprazo' },
          { tipo: 'number', caption: 'Quantidade', campoFiltro: 'quantidade' },
          {
            tipo: 'lookup',
            caption: 'Categoria',
            fieldName: 'nome',
            campoFiltro: 'categoriaId',
            component: 'Categoria',
            filter: '',
          },
          {
            tipo: 'lookup',
            caption: 'Fabricante',
            fieldName: 'nome',
            campoFiltro: 'fabricanteId',
            component: 'Fabricante',
            filter: '',
          },
        ],
        ordenacao: [
          { value: 'createdAt', text: 'Data de Cadastro' },
          { value: 'nome', text: 'Nome' },
          { value: 'quantidade', text: 'Quantidade' },
          { value: 'precoCusto', text: 'Preço Custo' },
          { value: 'valorUnitario', text: 'Preço Avista' },
          { value: 'precoVendaAprazo', text: 'Preço A Prazo' },
          { value: 'categoriaNome', text: 'Categoria' },
        ],
      },
    ];
  }
}

export default Service;
