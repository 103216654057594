<template>
  <f-row>
    <f-col cols="12">
      <f-row style="height: 100px; display: flex;" v-if="multiple || files.length === 0">
        <f-col class="flex text-center" style="justify-content: center !important;">
          <div class="drop"
               @click="openSelectDialog"
               @dragover.prevent
               @drop="drop">
            {{ dragAndDropCaption }}
            <input
              v-show="false"
              ref="fileInput"
              @change="onFileChange"
              :disabled="disabled || internalDisabled"
              :accept="accept"
              :multiple="multiple"
              type="file">
            <font-awesome-icon icon="upload"/>
          </div>
        </f-col>
      </f-row>
      <f-row v-if="files.length">
        <f-col class="mt-3">
          <f-row v-for="(file, index) in files" :key="index">
            <f-col cols="7">
              <font-awesome-icon
                :icon="iconFileType(file)"
                f-tooltip.hover
                class="pointer-cursor text-anchor"
                :title="file.type"
              />
              {{ file.name }}
            </f-col>
            <f-col cols="3 text-muted text-centered">
              <small>{{ formatBytes(file.size, 2) }}</small>
            </f-col>
            <f-col cols="2 text-right">
              <font-awesome-icon
                icon="trash"
                f-tooltip.hover
                title="Remover arquivo"
                class="remove-file text-danger"
                @click="removeFile"
              />
            </f-col>
          </f-row>
          <hr>
        </f-col>
      </f-row>
      <f-alert-errors :errors="errors"/>
      <f-row v-if="percentCompleted">
        <f-col>
          <f-progress :value="percentCompleted" :max="100" show-progress animated></f-progress>
        </f-col>
      </f-row>
      <f-button
        v-if="files.length > 0 && showSubmitButton"
        type="success"
        caption="Enviar"
        @click="send(urlUpload)"
      />
    </f-col>
  </f-row>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFileArchive,
  faFileContract,
  faFileImage,
  faFilePdf,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import AlertError from './AlertBlock';

library.add([faTrash, faFilePdf, faFileImage, faFileContract, faFileArchive, faUpload]);

export default {
  components: {
    'f-alert-errors': AlertError,
  },
  name: 'upload',
  props: {
    showSubmitButton: { default: true },
    showImage: { default: true },
    disabled: { default: false },
    accept: { default: null }, // null = accept all files type
    urlUpload: { default: '' },
    multiple: { default: true }, // enable multi files upload
    dragAndDropCaption: { default: 'Arraste e solte arquivos aqui.' },
    params: {
      default() {
        return {};
      },
    }, // extra params to include on upload
    maxSize: {
      type: Number,
      default: 2048,
    },
    maxImageWidth: {
      type: Number,
      default: 0,
    },
    maxImageHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      files: [],
      percentCompleted: 0,
      internalDisabled: false,
      internalParams: {}, // used to concat params and send to upload
      errors: [],
    };
  },
  methods: {
    formatBytes(fileSize, decimalPlaces) {
      return this.$utils.String.formatBytes(fileSize, decimalPlaces);
    },
    iconFileType(file) {
      if (file.type.indexOf('pdf') > -1) return 'file-pdf';
      if (file.type.indexOf('image') > -1) return 'file-image';
      if (file.type.indexOf('compressed') > -1) return 'file-archive';
      if (file.type.indexOf('zip') > -1) return 'file-archive';
      return 'file-contract';
    },
    async onFileChange(event) {
      this.clearErrors();
      const files = Array.from(event.target.files);
      await this.processFiles(files);
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
      this.$emit('change', this.files);
    },
    openSelectDialog(event) {
      this.clearErrors();
      this.$refs.fileInput.click();
      this.$emit('click', event);
    },
    async processFiles(files) {
      let validated = true;
      // eslint-disable-next-line no-plusplus
      for (let key = 0; key <= files.length - 1; key++) {
        let file = files[key];
        file.localUrl = URL.createObjectURL(file);
        if (this.$utils.File.isImageFile(file)) {
          // eslint-disable-next-line no-await-in-loop
          const newFile = await this.$utils.File.compressImageFile(file,
            { maxHeight: this.maxImageHeight, maxWidth: this.maxImageWidth });
          newFile.compressed = file.size > newFile.size;
          if (newFile.compressed) {
            newFile.oldSize = file.size;
            // eslint-disable-next-line no-const-assign
            file = newFile;
          }
        }
        if (this.validate(file)) {
          if (this.multiple) {
            this.files.push(file);
          } else {
            this.files = [file];
            break;
          }
        } else {
          validated = false;
          break;
        }
      }
      if (validated) {
        this.$emit('files-changed', this.files);
      }
    },
    reset() {
      this.files = [];
      this.percentCompleted = 0;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
    },
    setDisable(value) {
      this.internalDisabled = value;
    },
    onUploading(percentCompleted) {
      this.percentCompleted = percentCompleted;
      return this.$emit('onUploading', percentCompleted);
    },
    drop(event) {
      event.stopPropagation();
      event.preventDefault();
      this.processFiles(event.dataTransfer.files);
    },
    clearErrors() {
      this.errors = [];
    },
    validate(file) {
      if (!file) {
        return false;
      }
      let msgIfTriedCompressed = '';
      if (file.compressed) {
        msgIfTriedCompressed = `(Mesmo o sistema tentando reduzir o tamanho. \n
        tamanho original : ${this.$utils.String.formatBytes(file.oldSize, 2)} \n
        Tamanho depois da redução: ${this.$utils.String.formatBytes(file.size, 2)} )`;
      }
      const sizeInKb = file.size / 1024;
      if (sizeInKb > this.maxSize) {
        this.errors.push(`O arquivo "${file.name}" é muito grande${msgIfTriedCompressed}, por favor, selecione um
        arquivo com até ${this.$utils.String.formatBytes(this.maxSize * 1024, 2)}.`);
      } else if (file.size === 0) {
        this.errors.push(`O arquivo "${file.name}" está vazio, por favor, selecione um arquivo válido.`);
      }

      return this.errors.length === 0;
    },
    send(url) {
      this.percentCompleted = 0;
      if (this.files) {
        this.internalParams = {
          file: this.files.length === 1 ? this.files[0] : this.files,
        };
        Object.keys(this.params).forEach((key) => {
          this.internalParams[key] = this.params[key];
        });
        this.$reqs.upload(url, this.internalParams, this.onUploading)
          .then((response) => {
            this.$emit('success', response.data);
            this.reset();
          })
          .catch((error) => {
            this.$emit('error', error.data);
          });
      }
    },
    removeFile(index) {
      this.$emit('removed', { index, file: this.files[index] });
      this.files.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.row-file-upload {
  align-items: flex-end;
}

.remove-file {
  cursor: pointer;
  color: #3e88c9;

  &:hover {
    background-position: 0 100%;
    color: #0904ff;
  }
}

.file-upload {
  cursor: pointer;
  transition: all 1s;
  font-size: 20px;
  color: #3e88c9;
}

.drop {
  background-color: #f2f2f2;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 2px;
  cursor: pointer;
  height: 100%;
  margin: 0 auto;
  max-height: 400px;
  width: 100%;

  svg {
    color: #3e88c9;
  }
}

.error-item {
  word-wrap: break-word; /* IE */
}
</style>
