<style lang="scss">

.f-field {
  margin: 0 4px 4px 0 !important;
}

.f-invalid > * input {
  border-color: #EF4444;
}

</style>

<template>
  <div>
    <p>{{ caption }}</p>
    <el-radio-group :value="value" @input="$emit('input', $event)" :class="cssClass">
      <el-radio :label="r.value" v-for="r in options" :key="r.value">{{ r.caption }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { Radio, RadioGroup } from 'element-ui';
import inputMixing from './InputMixing';

export default {
  mixins: [
    inputMixing,
  ],
  components: {
    'el-radio-group': RadioGroup,
    'el-radio': Radio,
  },
  props: {
    options: {
      type: Array,
      default: () => {
      },
    },
    value: {
      type: String,
      default: null,
    },
    caption: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
