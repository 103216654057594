// eslint-disable-next-line max-classes-per-file
import Dataset from '../../libs/dataset';

const getFields = () => `
      id
      createdAt
      updatedAt
      name
      grupoPadrao
      permissions {
        code
        name
        description
        check
      }
      users {
        id
        name
        email
      }
    `;

class GroupUserService extends Dataset {
  constructor(config) {
    super(config);
    this.findGridColumns = [
      { caption: 'Nome', name: 'name' },
      { caption: 'Email', name: 'email' },
    ];
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      name: null,
    };
  }
}

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      { caption: 'Criado em', name: 'createdAt', type: 'datetime' },
      { caption: 'Nome', name: 'name' },
      { caption: 'Grupo Padrão', name: 'grupoPadrao' },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome',
        name: 'main',
        type: 'textbox',
        value: '',
      },
    ]; // filtro para ser criado pela tela de consulta

    this.userSchema = new GroupUserService({ parent: this, parentField: 'users' });
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      name: null,
      grupoPadrao: null,
      permissions: [],
      permissionsList: null,
      users: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;

    return `query {
              GroupList(${filter}){
                id
                createdAt
                name
                grupoPadrao
              }
            }`;
  }

  getByIds(ids) {
    return `query {
              GroupList(ids: [${ids.join()}]) {
                ${getFields()}
              }
            }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation GroupCreateMutation($data: GroupInputType!) {
           GroupCreate(data: $data){
             ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation GroupUpdateMutation($id: String!, $data: GroupInputType!) {
           GroupUpdate(id: $id, data: $data){
             ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation GroupDeleteMutation($id: String!){
          GroupDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(record) {
    const params = {
      name: record.name,
      permissions: record.permissions ? record.permissions.filter((item) => item.check) : [],
      users: record.users,
    };
    params.users = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const user of record.users || []) {
      if (user.name && user.id) {
        params.users.push({ id: user.id, name: user.name, email: user.email });
      }
    }
    return params;
  }
}

export default Service;
