import Dataset from '../../libs/dataset';

const getFields = () => `
        id
        createdAt
        updatedAt
        nome
        tipo
        template
        dadosExemplo
`;

class Service extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [
      { caption: 'Criado em', name: 'createdAt', type: 'datetime' },
      { caption: 'Nome', name: 'name' },
      { caption: 'tipo', name: 'Tipo' },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome',
        name: 'main',
        width: '400px',
        type: 'textbox',
      },
      {
        label: 'Tipo',
        name: 'type',
        type: 'combobox',
        default: '',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Html', value: 'html' },
          { text: 'Stimulsoft', value: 'stimulsoft' },
        ],
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      updatedAt: null,
      nome: null,
      template: null,
      tipo: null,
      dadosExemplo: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}",
                    type: "${filters.type || ''}"`;
    return `query {
              ReportList(${filter}){
                id
                createdAt
                updatedAt
                nome
                tipo
              }
            }`;
  }

  getByIds(ids) {
    return `query {
            ReportList(ids: [${ids.join()}]) {
              ${getFields()}
            }
          }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation ReportCreateMutation($data: ReportInput!) {
           ReportCreate(data: $data){
              ${getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation ReportUpdateMutation($id: String!, $data: ReportInput!) {
           ReportUpdate(id: $id, data: $data){
              ${getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation ReportDeleteMutation($id: String!){
          ReportDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  /**
   * Retorna o template do relatorio baseado no nome
   * @param name
   * @returns {Promise<unknown>}
   */
  getReportTemplate(name) {
    return new Promise((resolve, reject) => {
      const query = `
         query {
           ReportList(nome: "${name}"){
              template
           }
         }
    `;
      this.gqlQuery(query)
        .then((response) => {
          resolve(response.result[0].template);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
      template: model.template,
      tipo: model.tipo,
    };
  }
}

export default Service;
