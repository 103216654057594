<template>
  <el-tree
    ref="tree"
    node-key="id"
    highlight-current
    :show-checkbox="showCheckbox"
    :default-expand-all="defaultExpandAll"
    :default-checked-keys="defaultCheckedKeys"
    :data="data"
    :props="defaultProps"
    @check-change="$emit('check-change', $event)"
    @check="$emit('check', $event)"
    @check-click="$emit('check-click', $event)"
    @node-click="$emit('node-click', $event)"
    @node-expand="$emit('node-expand', $event)"
    @node-collapse="$emit('node-collapse', $event)"
  >
  </el-tree>
</template>

<script>
import { Tree } from 'element-ui';

export default {
  name: 'Treeview',
  components: {
    'el-tree': Tree,
  },
  props: {
    data: {
      type: Array,
      default: () => {
      },
    },
    defaultProps: {
      type: Object,
      default: () => ({
        children: 'children',
        label: 'label',
      }),
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    defaultExpandAll: {
      type: Boolean,
      default: false,
    },
    defaultCheckedKeys: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    reset() {
      this.$refs.tree.setCheckedKeys([]);
    },
    getCheckedNodes() {
      this.$emit('checkNodes', this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      this.$emit('checkKeys', this.$refs.tree.getCheckedKeys());
    },
  },
};
</script>
