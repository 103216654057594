<template>
  <LoginArea>
     <p v-if="revenda">Revenda : {{revenda}}</p>
    <slot>
      <h2 class="f-title-1">{{ i18n.get('register') }}</h2>
    </slot>

    <f-form
      class="login-form"
      ref="form"
      v-loading="service.loading"
      element-loading-text="Aguarde..."
      element-loading-spinner="el-icon-loading"
    >
      <f-textbox
        v-model="model.name"
        id="user-name"
        size="medium"
        :caption="i18n.get('fieldName')"
        :validations="validations.name"
      />

      <f-textbox
        id="email"
        type="email"
        size="medium"
        v-model="model.email"
        :caption="i18n.get('fieldEmail')"
        :validations="validations.email"
      />

      <f-textbox
        id="user-password"
        v-model="model.password"
        type="password"
        size="medium"
        icon="fas fa-lock"
        :caption="i18n.get('fieldPassword')"
        :placeholder="i18n.get('passwordPlaceHolder')"
        :validations="validations.password"
      />

      <cpfCnpj
        size="medium"
        v-model="model.cnpj"
        :caption="i18n.get('fieldCnpj')"
        :internalStyle="'width: 100%;margin: 0 0 4px 0 !important;'"
        :required="true"
        :onlyCnpj="true"
        @dados="setOrg"
        @error="setEmptyOrg"
      />

      <f-button
        size="medium"
        :caption="i18n.get('signedUpButton')"
        :disabled="!organization.cnpj"
        @click="$refs.form.validate() ? showConfirmation = !showConfirmation: false"
      />
      <f-link class="text-center m-5" url="/login">
        {{ i18n.get('backLoginPage') }}
      </f-link>
      <f-alert-block
        v-if="successMsg.length || errorMsg.length"
        :success="successMsg"
        :errors="errorMsg"
      />
      <f-alert v-if="showSearshCnpjResponse" :type="'warning'">
        Os dados da empresa não foram localizados!<br>
        Confira se o CNPJ informado está correto.
      </f-alert>
    </f-form>
    <f-dialog
      v-model="showConfirmation"
      title="CONFIRMAR REGISTRO"
      :width="$store.getters.isMobile ? '100%': '40%'"
    >
      <div v-if="organization.cnpj">
        <f-alert :type="'info'">
          Confirme as informações da <br v-if="$store.getters.isMobile">empresa.<br>
          (Após a conclusão as informações <br v-if="$store.getters.isMobile">
          podem ser ajustadas nas <br>configurações)<br>
        </f-alert>
        <f-textbox
          v-model="organization.cnpj"
          :mask="['##.###.###/####-##']"
          :disabled="true"
          :caption="i18n.get('fieldCnpj')"
        />
        <f-textbox
          v-model="organization.nomeFantasia"
          caption="Nome Fantasia"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.razaoSocial"
          caption="Razão Social"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.ie"
          caption="IE"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.cep"
          caption="CEP"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.rua"
          caption="RUA"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.numero"
          caption="Número"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.bairro"
          caption="Bairro"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.cidade"
          caption="Cidade"
          :disabled="true"
        />
        <f-textbox
          v-model="organization.uf"
          caption="UF"
          :disabled="true"
        />
        <br>
        <f-link
          class="text-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          @click.native.prevent="register"
        >
          {{ i18n.get('signedUpButtonConfirm') }}
        </f-link>
      </div>
      <div v-else>
        <f-alert :type="'warning'">
          Os dados da empresa não foram localizados!<br>
          Confira se o CNPJ informado está correto.
        </f-alert>
      </div>
    </f-dialog>
  </LoginArea>
</template>

<script>
import LoginArea from './LoginArea';

require('./login.scss');

export default {
  name: 'RegisterPage',
  mixins: [
    LoginArea,
  ],
  components: {
    LoginArea,
  },
  data() {
    return {};
  },
  methods: {
    setOrg(dados) {
      if (dados.nome2) {
        this.errorMsg = [];
        this.organization.cnpj = this.model.cnpj;
        this.organization.ie = dados.ie;
        this.organization.simples = dados.simples;
        this.organization.razaoSocial = dados.nome;
        this.organization.nomeFantasia = dados.nome2;
        this.organization.rua = dados.endereco;
        this.organization.cep = dados.cep;
        this.organization.numero = dados.numero;
        this.organization.cidade = dados.cidade;
        this.organization.cidadeCodigo = dados.cidadeCodigo;
        this.organization.bairro = dados.bairro;
        this.organization.uf = dados.uf;
        this.organization.email = dados.email;
        this.organization.telefone = dados.telefone1;
        this.showSearshCnpjResponse = false;
        return;
      }
      this.setEmptyOrg();
    },
    setEmptyOrg() {
      this.showSearshCnpjResponse = true;
      this.organization = {
        cnpj: null,
        ie: null,
        simples: null,
        razaoSocial: null,
        nomeFantasia: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        cidadeCodigo: null,
        uf: null,
        email: null,
        telefone: null,
      };
    },
    async register() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.showConfirmation = false;
      this.model.loading = true;
      this.successMsg = '';
      this.errorMsg = '';
      this.service.register(
        this.model.name,
        this.model.email,
        this.model.password,
        this.organization,
        this.$route.query.revenda || 'linksoft',
        this.$route.query.licenca || process.env.VUE_APP_LICENCA_PADRAO,
      )
        .then((response) => {
          this.successMsg = [response.data];
          setTimeout(() => {
            this.$router.push({
              name: 'Confirmation',
              params: {
                username: this.model.email,
              },
            });
          }, 2000);
        })
        .catch((err) => {
          this.errorMsg = [err.message];
          if (err.code === 'ALREADY_REGISTERED') {
            this.routeToLogin(5000);
          }
        });
    },
  },
};
</script>
