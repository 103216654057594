<template>
  <div
    class="flex flex-col flex-wrap"
    style="height: auto"
    v-loading="service.loading"
    element-loading-spinner="el-icon-loading"
    :element-loading-text="'Aguarde...'"
  >
    <div style="width: 100%">
      <f-grid
        :data="movimentacaoEstoque"
        :columns="movimentoColunas"
        :selecionSingle="true"
      >
      </f-grid>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HistoricoCompras',
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      movimentacaoEstoque: [],
      movimentoColunas: [
        { field: 'createdAt', title: 'Data/Hora', type: 'datetime' },
        { field: 'pessoaNome', title: 'Pessoa' },
        { field: 'origemNumero', title: 'Nota' },
        { field: 'quantidade', title: 'Quantidade Movimentada' },
        { field: 'valorUnitario', title: 'V. Unitário' },
      ],
    };
  },
  methods: {
    getMovimentacao() {
      const filtro = {
        tipo: 'e',
        origem: 'NF de Entrada',
      };
      this.service.getMovimentacaoProduto(filtro)
        .then((result) => {
          this.movimentacaoEstoque = result;
        });
    },
  },
  mounted() {
    this.getMovimentacao();
  },
};
</script>
