<style scoped>
.title {
  background:  #0a7ac1;
  color: white;
  font-size: 18px;
}
</style>

<template>
  <div class="border-2">
    <div class="card-header text-center title">
      <slot name="title" class="text-center">
      </slot>
    </div>
    <div class="h-full">
      <slot></slot>
    </div>
    <div class="card-footer text-muted">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '',
    },
  },
};

</script>
