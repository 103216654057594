<style scoped>
.disabled {
  pointer-events: none;
}
</style>

<template>
  <div :class="{ disabled: disabled || internalDisabled  }">
    <el-transfer
      :value="value"
      :filterable="filterable"
      :button-texts="buttonTexts"
      :data="data || []"
      :titles="titles"
      @input="$emit('input', $event)"
      @change="handleChange"
    >
    </el-transfer>
  </div>
</template>

<script>
import { Transfer } from 'element-ui';
import baseMixing from './BaseMixing';

export default {
  mixins: [
    baseMixing,
  ],
  name: 'Treeview',
  components: {
    'el-transfer': Transfer,
  },
  props: {
    data: {
      type: Array,
      default: () => {
      },
    },
    value: {
      type: Array,
      default: () => {
      },
    },
    titles: {
      type: Array,
      default: () => ['Origem', 'Destino'],
    },
    buttonTexts: {
      type: Array,
      default: () => ['', ''],
    },
    filterable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleChange(value, direction, movedKeys) {
      this.$emit('change', value, direction, movedKeys);
    },
    getCheckedNodes() {
      this.$emit('checkNodes', this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      this.$emit('checkKeys', this.$refs.tree.getCheckedKeys());
    },
  },
};
</script>
