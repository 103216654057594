<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle, faBan } from '@fortawesome/free-solid-svg-icons';

library.add(faQuestionCircle, faBan);

export default {
  data() {
    return {
      validationMsg: [],
      validationFired: false,
      validationFieldName: 'value',
      internalDisabled: false,
      isFocused: false,
      internalInputStyle: {},
    };
  },
  methods: {
    classes() {
      let classes = {
        'f-invalid': this.validationMsg.length > 0,
      };
      if (this.inputCssClasses) {
        classes = { ...classes, ...this.inputCssClasses };
      }
      return classes;
    },
    setInputStyle() {
      if (this.computedInputStyle && this.$refs.input) {
        Object.keys(this.computedInputStyle).forEach((key) => {
          this.$refs.input.$el.children[0].style[key] = this.computedInputStyle[key];
        });
      }
    },
    validate() {
      const validationMsg = [];
      // se estiver desabilitado, entao nao valide nada pois o usuario nao tem como corrigir
      if (this.isDisabled) {
        return true;
      }
      Object.keys(this.validations)
        .forEach((idx) => {
          const validation = this.validations[idx];
          if (validation.required) {
            const value = this[this.validationFieldName];
            if (value === 0) {
              return;
            }
            if (!value) {
              if (!validation.message) {
                validation.message = 'Esse campo é obrigatório';
              }
              validationMsg.push(validation.message);
            }
            return;
          }
          if (validation.min && validation.max && this[this.validationFieldName]
            && (this[this.validationFieldName].length < validation.min
              || this[this.validationFieldName].length > validation.max)) {
            if (!validation.message) {
              validation.message = `Esse campo deve ter entre ${validation.min} e ${validation.max} caracteres`;
            }
            validationMsg.push(validation.message);
          }
        });

      this.validationMsg = validationMsg;
      this.validationFired = true;
      return this.validationMsg.length === 0;
    },
    reset() {
      this.validationFired = false;
      this.validationMsg = [];
    },
    generatedId() {
      if (this.id) {
        return this.id;
      }
      return this.caption.toLocaleLowerCase()
        .replace(/ /g, '-');
    },
    setDisable(value) {
      this.internalDisabled = value;
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    onChange() {
      this.isFocused = false;
    },
    onFocus(event) {
      this.isFocused = true;
      this.$emit('focus', event);
      if (this.selectOnFocus) {
        this.selectText();
      }
    },
    onBlur(event) {
      this.isFocused = false;
      this.$emit('blur', event);
    },
    selectText() {
      this.$refs.input.$el.children[0].select();
    },
    saveValueToLocalStorage(value) {
      localStorage.setItem(this.persistElement, value);
    },
    setValueFromLocalStorage() {
      const value = localStorage.getItem(this.persistElement);
      if (value === 'null' || value === 'underfined') {
        this.$emit('input', null);
      } else if (value === 'true') {
        this.$emit('input', true);
      } else if (value === 'false') {
        this.$emit('input', false);
      } else {
        this.$emit('input', value);
      }
    },
    getValueFromLocalStorage() {
      if (this.persistElement) {
        const url = localStorage.getItem(this.persistElement);
        if (url === 'null' || url === 'underfined') {
          return null;
        }
      }
      return '';
    },
  },
  props: {
    validations: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'mini',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    selectOnFocus: {
      type: Boolean,
      default: false,
    },
    inputCssClasses: {
      type: Object,
      default: () => {
      },
    },
    inputStyle: {
      type: Object,
      default: () => {
      },
    },
    fieldStyle: {
      type: Object,
      default: () => {
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    helpTitle: {
      type: String,
      default: 'O que é esse campo?',
    },
    helpMessage: {
      type: String,
      default: '',
    },
    persistElement: { // propriedade para gravar o valor do campo no locastorage
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    cssClass: {
      type: [String, Object],
      default: '',
    },
  },
  computed: {
    isRequired() {
      if (this.validations.length === 0) {
        return false;
      }
      const val = this.validations.filter((item) => item.required === true)[0];
      return val !== undefined;
    },
    isDisabled() {
      return this.disabled || this.internalDisabled;
    },
    computedInputStyle() {
      const style = {};
      if (this.internalInputStyle) {
        Object.assign(style, this.internalInputStyle);
      }
      if (this.inputStyle) {
        Object.assign(style, this.inputStyle);
      }
      if (this.isDisabled && !this.inputStyle) {
        Object.assign(style, this.$style.styleDesabilitado);
      }
      return style;
    },
    internalFieldStyle() {
      if (!this.fieldStyle) {
        return {
          width: this.isMobile ? '100%' : this.width,
        };
      }
      return this.fieldStyle;
    },
    isMobile() {
      if (!this.$store) {
        return false;
      }
      return this.$store.getters.isMobile;
    },
  },
  watch: {
    value(value) {
      if (this.validationFired) {
        this.validate();
      }
      if (this.persistElement) {
        this.saveValueToLocalStorage(value);
      }
    },
    inputStyle() {
      this.$nextTick(() => {
        this.setInputStyle();
      });
    },
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
    if (this.persistElement) {
      this.setValueFromLocalStorage();
    }
    this.$nextTick(() => {
      this.setInputStyle({});
    });
  },
};
</script>
