<template>
  <div id="viewer" style="height: 100%">
  </div>
</template>

<script>
require('./report/stimulsoft.designer.office2013.white.blue.css');

export default {
  name: 'stimulsoft-viewer',
  props: {
    report: { default: '' },
    data: { default: '' },
    showDesigner: { default: false },
  },
  data() {
    return {
      localization: '',
    };
  },
  mounted() {
    this.carregaStimulsoft();
  },
  methods: {
    carregaStimulsoft() {
      window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile('static/stimulsoft/pt-BR.xml');
      window.Stimulsoft.Base.StiLicense.key = '6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnIpSwcuNzHmdcLpUmuQyf3jpe9X7A'
        + 'wgVauW6ofhxbvD/T6UesGk6GFCJcsJGGBto7o2Jc7RhRg3GUEHtKX+sDnHD0qqR69Jo0ZuNXlhfY3dDwQwoQ/W9A3mG+mEuUrMLyvbiQ+'
        + 'lts0fIH8l8/iI8aTddyX+xtTGseSk5gRLuISyqxZGyfVF+ArhNC0cJ224m0H+RIfE+20hc3NoBKgSKcytZbSTrTIIYh8iyfx2n7zkw+'
        + 'UG6sGxhg33ERTat4jgGg+aAk7rYK1UqHdPa5PrvY+w8V5+Kr6iBXrKI+ToaQClGABSAhQlknCZ5+UtNrSc7OKZG13+RyTJcgpL63Avza5'
        + 'rnyw7QkVOSj1qOmM6TWdT+5Ds6D6TQ4XQuzXXrQddJMgigK8jnsD2WSeYrCNwQGuFDtEjSCiVKKqi1ZJ79cPWJdQagYOikH/zUDGDKawbwAf'
        + 'cw2tP4ts2aWiRGvIYaEduJ485v35oEi+Dy4DejOkeu4LB2pF9Zm/hGx+IkIE1idszd1ayjx5hOZ7ssIv5dekqP7TIiaoSrsxkG8Pq5Zonw'
        + '==';
      const viewerOptions = this.createViewerOptions();
      const viewer = new window.Stimulsoft.Viewer.StiViewer(viewerOptions, 'StiViewer', false);
      viewer.showProcessIndicator();

      // console.log('Creating a new report instance')
      const report = new window.Stimulsoft.Report.StiReport();

      // console.log('Load reports from JSON object')
      report.load(this.report);

      // Remove all connections in report template (they are used in the first place)
      report.dictionary.databases.clear();
      // Registered JSON data specified in the report with same name
      report.regData('Demo', 'Demo', this.data);

      // Assigning report to the viewer, the report will be built automatically after rendering the viewer
      viewer.report = report;

      // console.log('Rendering the viewer to selected element')
      setTimeout(() => {
        viewer.renderHtml('viewer');
      }, 1000);
    },
    createViewerOptions() {
      const options = new window.Stimulsoft.Viewer.StiViewerOptions();
      options.height = '100%';
      options.appearance.scrollbarsMode = true;
      options.toolbar.showDesignButton = this.showDesigner;
      options.toolbar.showAboutButton = false;
      options.toolbar.printDestination = window.Stimulsoft.Viewer.StiPrintDestination.Direct;
      options.appearance.htmlRenderMode = window.Stimulsoft.Report.Export.StiHtmlExportMode.Table;
      options.appearance.fullScreenMode = false;
      return options;
    },
  },
};
</script>
