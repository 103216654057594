<template>
  <f-crud-form
    ref=formCarteirinhaModelo
    v-if="service"
    v-on="$listeners"
    :schema="service"
    :styleCell="styleCell"
    :formsValidation="[$refs.formValidation]"
    @save="$refs.upload.reset()"
  >
    <f-row slot="detailButtons">
      <f-button caption="Imprimir" @click="imprimir">
        <FontAwesomeIcon icon="print"/>
      </f-button>
    </f-row>
    <f-row>
      <f-col col="7">
        <f-form ref="formValidation">
          <f-form-row>
            <f-datetimebox
              caption='Criado em'
              v-model='service.record.createdAt'
              v-show="service.record.createdAt"
              :disabled="true"
            />
            <f-textbox
              caption='Nome'
              v-model='service.record.nome'
              width="100px"
              :validations="validations.nome"
            />
            <f-numberbox
              caption='Valor Unitário'
              v-model='service.record.valor'
            />
          </f-form-row>
          <f-form-row>
            <f-textbox
              caption='HTML Template'
              type="textarea"
              v-model='service.record.htmlTemplate'
            />
            <f-textbox
              caption='Stimulsoft Template'
              type="textarea"
              v-model='service.record.stimulsoftTemplate64'
            />
            <f-textbox
              caption='Dados Exemplo'
              type="textarea"
              v-model='service.record.dadosExemplo'
            />
          </f-form-row>
          <f-form-row>
            <f-col col="3">
              <h5>Background</h5>
              <f-image
                :src="service.record.imagemBackgroundUrl"
                @change="service.record.imagemBackground64 = $event.base64"
              />
              <f-upload
                ref="upload"
                :maxSize="600"
                :multiple="false"
                :show-submit-button="false"
                :maxImageWidth="800"
                :maxImageHeight="800"
                @removed="service.record.imagemBackgroundUrl = ''"
                @files-changed="selectedPhoto"
              >
              </f-upload>
            </f-col>
          </f-form-row>
          <f-textbox
            caption='Obs'
            type="textarea"
            v-model='service.record.obs'
            :validations="validations.obs"
          />
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import CarteirinhaModeloService from './carteirinhaModeloService';

export default {
  data() {
    return {
      service: null,
      reportService: 'carteirinhaModelo',
      mostrarBaixarFicha: false,
      validations: {
        nome: [
          {
            min: 3,
            max: 60,
            message: 'O Nome do relatório deve conter entre 3 e 60 caracteres.',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'É obrigatório informar um nome para o relatorio.',
            trigger: 'blur',
          },
        ],
        obs: [
          {
            min: 2,
            max: 220,
            message: 'Obs deve ter entre 2 e 220 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new CarteirinhaModeloService();
  },
  mounted() {
    // quando passa o form para o service, o component form-crud irá tentar validar antes de salvar
    this.service.form = this.$refs.form;
  },
  computed: {
    inserindo() {
      return true;
    },
  },
  methods: {
    imprimir() {
      this.$showMessage({
        title: 'Imprimindo...',
        type: 'info',
        message: 'Imprimindo...',
      });
      this.service.imprimir()
        .then((resp) => {
          if (resp.result.htmlContent) {
            this.$showReportHtml('Impressão de Carteirinha', resp.result.htmlContent);
          }
        })
        .catch((err) => {
          this.$showMessage({
            title: 'Impressão de pedido',
            type: 'error',
            message: `Pedido não impresso : ${err.message || err}`,
          });
        });
    },
    selectedPhoto($event) {
      this.service.record.imagemBackgroundUrl = $event[0].localUrl;
    },
    styleCell(col, item) {
      if (col === 'cancelamentoMotivo') {
        if (item.cancelamentoMotivo !== '') {
          return {
            'background-color': this.$style.colorRed,
            color: 'white',
          };
        }
      }
      return {};
    },
  },
};

</script>
