<template>
  <div>
    <f-alert :type="type" v-if="type">
      <p v-if="type === 'success'">Convite confirmado com sucesso!
        <router-link to="/login">
          <b>Clique aqui</b> para efetuar login ou aguarde que será redirecionado...
        </router-link>
      </p>
      <p v-else>
        {{ message }}
      </p>
    </f-alert>
  </div>
</template>

<script>
import UserService from './UserService';

export default {
  data() {
    return {
      message: '',
      type: '',
    };
  },
  created() {
    this.service = new UserService();
    const invitationCode = this.$route.query.token;
    if (!invitationCode) {
      this.type = 'error';
      this.message = 'Token de convite não foi passado na URL, verifique o link enviado';
      return;
    }

    if (this.$route.path === '/confirm_invitation') {
      this.service.confirmInvitation(invitationCode)
        .then(() => {
          this.type = 'success';
          this.message = '';
          setTimeout(() => {
            this.$router.push({ name: 'Login' });
          }, 4000);
        }).catch((response) => {
          this.type = 'error';
          this.message = response.message;
        });
      return;
    }

    this.type = 'error';
    this.service.rejectInvitation(invitationCode)
      .then(() => {
        this.message = 'Convite rejeitado com sucesso...';
      }).catch((response) => {
        this.type = 'error';
        this.message = response.message;
      });
  },
};
</script>
