<style scoped>
>>>.input-width > input {
  width: 180px !important;
}

</style>
<style scoped lang="scss">

.f-field {
  display: flex;
  flex-direction: column;
  width: 180px !important;

  label {
    margin-bottom: 3px;
  }

  small {
    margin: 5px;
  }
}
</style>

<template>
  <div class="f-field mb-6 flex justify-center flex-col w-full" :id="generatedId()">
    <div>
      <f-label
        :caption="caption"
        :isRequired="isRequired"
        :helpMessage="helpMessage"
      />
    </div>
    <div :class="classes()">
      <el-date-picker
        :value="internalValue"
        :size="size"
        :disabled="disabled || internalDisabled"
        :type="type"
        :class="{'input-width': type === 'datetime'}"
        :format="internalFormat"
        :placeholder="placeholder"
        :picker-options="pickerOptions"
        :style="{ width: '150px' }"
        :readonly="readonly"
        @input="$emit('input', $event)"
      />
      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'element-ui';
import inputMixing from './InputMixing';

export default {
  mixins: [
    inputMixing,
  ],
  components: {
    'el-date-picker': DatePicker,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Date],
      default: null,
    },
    pickerOptions: {
      type: Object,
      default: null,
    },
    buttonClick: {
      type: Function,
      default: null,
    },
    type: {
      type: String,
      default: 'datetime',
    },
    icon: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
  },
  computed: {
    internalFormat() {
      if (this.format) return this.format;
      if (this.type === 'date') {
        return 'dd/MM/yyyy';
      }
      return 'dd/MM/yyyy HH:mm:ss';
    },
  },
  watch: {
    value(newValue) {
      if (typeof (newValue) === 'string') {
        this.internalValue = _.moment(newValue).toDate();
      } else {
        this.internalValue = newValue;
      }
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
};
</script>
