<style scoped>
>>>.el-card {
  /*background-color: inherit;*/
  /*color: inherit !important;*/
}

</style>
<template>
  <el-card :shadow="shadow">
    <div slot="header" class="clearfix text-center" v-if="title">
      <slot name="header">
        <strong>{{ title }}</strong>
      </slot>
    </div>
    <slot></slot>
  </el-card>
</template>

<script>
import { Card } from 'element-ui';

export default {
  components: {
    'el-card': Card,
  },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    shadow: {
      type: String,
      default: 'hover',
    },
  },
};
</script>
