<template>
  <div
    :element-loading-text="``"
    element-loading-spinner="el-icon-loading"
    style="margin: 0 4px 4px 0"
  >
    <div class="flex items-end">
      <f-textbox
        ref="input"
        class="mr-0"
        :caption='captionInterno'
        :value="value"
        :validations="validation"
        :size="size"
        :style="internalStyle"
        :onlyNumber="true"
        @input="$emit('input',$event)"
        @keyup="keyUpCpfCnpj"
      />
      <f-button
        v-if="false"
        icon="el-icon-search"
        type="primary"
        class="rounded-l-none t-1"
        style="margin: 0 0 4px 0"
      />
    </div>
    <f-alert type="info" v-if="consultando">Consultando CNPJ {{ value }}...</f-alert>
    <f-alert v-if="erroApi" type="error">{{ erroApi }}</f-alert>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CpfCnpj',
  props: {
    value: { type: String, default: '' },
    caption: { type: String, default: '' },
    size: { type: String, default: 'mini' },
    internalStyle: { type: String, default: 'margin: 0 0 4px 0 !important;' },
    required: { type: Boolean, default: false },
    onlyCnpj: { type: Boolean, default: false },
  },
  data() {
    return {
      ultimaConsulta: '',
      erroApi: '',
      consultando: false,
      validationMsgCpfCnpj: 'CPF/CNPJ deve ter 11(CPF) ou 14(CNPJ) caracteres',
      validationMsgCnpj: 'CNPJ deve ter 14 caracteres',
    };
  },
  computed: {
    validation() {
      const validacoes = [];
      if (this.required) {
        validacoes.push({
          required: true,
          message: this.onlyCnpj ? 'CNPJ é obrigatório' : 'CPF/CNPJ é obrigatório',
          trigger: 'blur',
        });
      }
      validacoes.push({
        min: 11,
        max: 14,
        message: this.onlyCnpj ? this.validationMsgCnpj : this.validationMsgCpfCnpj,
        trigger: 'blur',
      });
      return validacoes;
    },
    isCnpj() {
      const value = _.string().onlyNumber(this.value);
      const result = value && value.length === 14;
      if (result) {
        this.$emit('isCnpj', result);
      }
      return result;
    },
    isCpf() {
      const value = _.string().onlyNumber(this.value);
      const result = value && value.length === 11;
      if (result) {
        this.$emit('isCpf', result);
      }
      return result;
    },
    captionInterno() {
      if (this.onlyCnpj) {
        return 'CNPJ';
      }
      if (this.caption) return this.caption;
      if (this.isCnpj) return 'CNPJ';
      if (this.isCpf) return 'CPF';
      return 'Cpf/Cnpj';
    },
  },
  methods: {
    mostraAlerta(mensagem, tempo) {
      this.erroApi = mensagem;
      setTimeout(() => {
        this.erroApi = '';
      }, tempo || 3000);
    },
    keyUpCpfCnpj() {
      this.erroApi = '';
      if (this.isCnpj) {
        const valorConsulta = _.string().onlyNumber(this.value);
        if (this.ultimaConsulta === valorConsulta) {
          return;
        }
        this.ultimaConsulta = valorConsulta;
        this.consultando = true;
        axios.get(`https://publica.cnpj.ws/cnpj/${valorConsulta}`)
          .then(((response) => {
            this.consultando = false;
            const { data } = response;
            const model = {
              nome: data.razao_social,
              nome2: data.estabelecimento.nome_fantasia,
              cnpj: data.estabelecimento.cnpj,
              ie: null,
            };
            if (data.estabelecimento.inscricoes_estaduais.length > 0) {
              model.ie = data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual;
            }
            if (data.simples) {
              model.simples = data.simples.simples === 'Sim';
            } else {
              model.simples = false;
            }
            model.endereco = `${data.estabelecimento.tipo_logradouro || ''} ${data.estabelecimento.logradouro}`;
            model.cep = data.estabelecimento.cep;
            model.numero = data.estabelecimento.numero;
            model.cidade = data.estabelecimento.cidade.nome;
            model.cidadeCodigo = String(data.estabelecimento.cidade.ibge_id);
            model.bairro = data.estabelecimento.bairro;
            model.uf = data.estabelecimento.estado.sigla;
            if (data.estabelecimento.telefone1) {
              model.telefone1 = data.estabelecimento.ddd1 + data.estabelecimento.telefone1;
            }
            if (data.estabelecimento.telefone2) {
              model.telefone2 = data.estabelecimento.ddd2 + data.estabelecimento.telefone2;
            }
            model.email = data.estabelecimento.email;
            this.$emit('dados', model);
            this.$emit('input', data.estabelecimento.cnpj);
          }))
          .catch((err) => {
            this.consultando = false;
            if (err.response && err.response.data && err.response.data.detalhes) {
              const { data } = err.response;
              switch (data.status) {
                case 429:
                  this.mostraAlerta('Excedido o limite máximo de 3 consultas por minuto, aguarde no máximo 1 '
                    + 'minuto e tente novamente', 5000);
                  break;
                default:
                  this.$emit('error', data.detalhes);
                  this.mostraAlerta(data.detalhes);
              }
            }
          });
      }
    },
  },
};
</script>
