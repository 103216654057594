<style lang="scss">
.crud-form {
  width: 100%;
}

.tab-consulta {
  position: relative;
}

.tab-content {
  height: 100%;
}

.tab-detail {
  width: 100%;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  div {
    padding: 0 3px 0 0;
  }
}

.tab-find {
  height: 100%;
}

html {
  height: 100%;
}
</style>

<template>
  <!-- eslint-disable -->
  <f-row class="h-100">
    <f-col class="crud-form h-100">
      <!-- tabs form  mode, quando mostra as abas de consulta e cadastros juntas -->
      <f-tabs
        v-if="mode === 'tab' || mode === 'child-tab'"
        v-model="currentTab"
        @tab-click="tabClick"
        style="height: 100%"
        type="border-card"
      >
        <f-tab-pane label="Consulta" class="tab-find">
          <crud-form-find
            ref="formFind"
            :schema="schema"
            :formulario="getForm"
            :formParent="formParent"
            :formParentField="formParentField"
            :style="{height: `75vh`}"
            :styleCell="styleCell"
            :styleRow="styleRow"
            :mostrarBotoes="mostrarBotoesConsulta"
            :showButtons="showFindButtons"
            :isChild="isChild"
            :crudForm="getCrudForm"
            :selecionSingle="lookupMode === 'consulta'"
            :grid-columns="schema.findGridColumns"
            :filters="filters"
            :allowColumnReordering="allowColumnReordering"
            :allowSorting="allowSorting"
            :searchPainel="searchPainel"
            :link-doc="linkDoc"
            :enableButtonNew="enableButtonNew"
            @griDataBound="$emit('griDataBound', $event)"
            @openRecord="openRecord"
            @dblClick="dblClick"
            @newRecord="newRecord"
            @find="$emit('find', $event)"
            @selectedRecords="$emit('selectedRecords', $event)"
            @link-doc="ajuda"
          >
            <div slot="find-buttons">
              <slot name="find-buttons"></slot>
            </div>
            <div slot="filters">
              <slot name="filters"></slot>
            </div>
            <div slot="grid" v-if="$slots.grid">
              <slot name="grid">
              </slot>
            </div>
          </crud-form-find>
        </f-tab-pane>
        <f-tab-pane label="Cadastro" class="tab-detail">
          <crud-form-detail
            ref="formDetail"
            :formulario="getForm"
            :schema="schema"
            :height="height - 200"
            :mostrarBotoesCadastro="mostrarBotoesCadastro"
            :successMessage="detailSuccessMessage"
            :mostrardetailButtons="mostrardetailButtons"
            :enableButtonNew="enableButtonNew"
            :enableButtonEdit="enableButtonEdit"
            :enableButtonSave="enableButtonSave"
            :enableButtonDelete="enableButtonDelete"
            :enableButtonDuplicate="enableButtonDuplicate"
            :formsValidation="formsValidation"
            :formParent="formParent"
            :formParentField="formParentField"
            :readonly="readonly"
            :crudForm="getCrudForm"
            :permissao="permissao"
            :link-doc="linkDoc"
            @selectTab="consultaRegistros"
            @selectedRecords="selectTab($event)"
            @beforeSave="$emit('beforeSave', $event)"
            @save="$emit('save')"
            @loadRecord="onLoadRecord"
            @newRecord="$emit('newRecord', $event)"
            @cancel="$emit('cancel', $event)"
            @recordStatus="$emit('recordStatus', $event)"
            @duplicate="$emit('duplicate', $event)"
            @link-doc="ajuda"
          >
            <div slot="detailButtons" class="flex">
              <slot name="detailButtons"></slot>
            </div>
            <div slot="after-navigation" class="flex">
              <slot name="after-navigation"></slot>
            </div>
            <slot>
            </slot>
          </crud-form-detail>
        </f-tab-pane>
        <f-tab-pane v-if="schema.getReportConfig && lookupMode === ''" label="Relatórios" class="tab-report">
          <CrudFormReport :service="schema"/>
        </f-tab-pane>
        <f-tab-pane :label="tab.caption" class="tab-find" v-for="tab in tabs" :key="tab.name" v-if="lookupMode === ''">
          <slot :name="tab.name"></slot>
        </f-tab-pane>
      </f-tabs>

      <!-- child form mode, onde aparece primeiro a tela de consulta que abre em modal a tela de cadastro -->
      <div v-else-if="mode === 'child'" style="height: 100%;position: relative">
        <crud-form-find
          :schema="schema"
          :formParent="formParent"
          :formParentField="formParentField"
          :gridFilterRow="false"
          :gridSelecionSingle="true"
          :styleCell="styleCell"
          :styleRow="styleRow"
          :mostrarBotoes="mostrarBotoesConsulta"
          :mode="mode"
          :showButtons="showFindButtons"
          :crudForm="getCrudForm"
          :grid-columns="schema.findGridColumns"
          :searchPainel="true"
          :link-doc="linkDoc"
          @openRecord="openRecord"
          @dblClick="dblClick"
          @newRecord="newRecord"
          @find="$emit('find', $event)"
          @link-doc="ajuda"
        >
        </crud-form-find>
        <f-dialog
          v-model="showFormDetailInDialog"
          :title="formTitle"
          :fullscreen="$store.getters.isMobile"
          height="80%"
        >
          <crud-form-detail
            ref="formDetail"
            :schema="schema"
            :height="height - 200"
            :mostrarBotoesCadastro="mostrarBotoesCadastro"
            :successMessage="detailSuccessMessage"
            :mostrardetailButtons="mostrardetailButtons"
            :enableButtonNew="enableButtonNew"
            :enableButtonEdit="enableButtonEdit"
            :enableButtonSave="enableButtonSave"
            :enableButtonDelete="enableButtonDelete"
            :enableButtonDuplicate="enableButtonDuplicate"
            :mode="mode"
            :formParent="formParent"
            :formParentField="formParentField"
            :formsValidation="formsValidation"
            :readonly="readonly"
            :crudForm="getCrudForm"
            :permissao="permissao"
            :link-doc="linkDoc"
            @selectTab="selectTab($event)"
            @selectedRecords="selectTab($event)"
            @beforeSave="$emit('beforeSave', $event)"
            @save="$emit('save')"
            @loadRecord="onLoadRecord"
            @newRecord="$emit('newRecord', $event)"
            @cancel="$emit('cancel', $event)"
            @recordStatus="$emit('recordStatus', $event)"
            @link-doc="ajuda"
          >
            <div slot="detailButtons" class="">
              <slot name="detailButtons"></slot>
            </div>
            <slot>
            </slot>
          </crud-form-detail>
        </f-dialog>
      </div>
    </f-col>
  </f-row>
</template>

<script>
import CrudFormFind from './CrudFormFind';
import CrudFormDetail from './CrudFormDetail';
import CrudFormReport from './CrudFormReport';

export default {
  name: 'crud-form',
  components: {
    CrudFormFind,
    CrudFormDetail,
    CrudFormReport,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    formTitle: {
      type: String,
      default: '',
    },
    styleCell: {
      type: Function,
      default: null,
    },
    styleRow: {
      type: Function,
      default: null,
    },
    formsValidation: {
      type: Array,
      default: () => [],
    }, // forms passados como parametro para realizar validacao antes de salvar
    schema: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      default: () => null,
    },
    detailSuccessMessage: {
      type: String,
      default: 'Registro gravado com sucesso. ',
    },
    filtroInicial: { default: '' },
    mostrarBotoesConsulta: { default: true },
    mostrarBotoesCadastro: { default: true },
    lookup: {
      type: Boolean,
      default: false,
    },
    // essa prop é usada no caso de lookup, ja passa um filtro e quando abrir a tela, o valor do
    // filtro ja está definido
    filter: {
      type: String,
      default: '',
    },
    mode: { default: 'tab' },
    mostrardetailButtons: { default: true },
    enableButtonEdit: {
      type: Boolean,
      default: true,
    },
    enableButtonNew: {
      type: Boolean,
      default: true,
    },
    enableButtonSave: {
      type: Boolean,
      default: true,
    },
    enableButtonDelete: {
      type: Boolean,
      default: true,
    },
    enableButtonDuplicate: {
      type: Boolean,
      default: true,
    },
    showFindButtons: {
      type: Boolean,
      default: true,
    },
    allowColumnReordering: {
      type: Boolean,
      default: true,
    },
    allowSorting: {
      type: Boolean,
      default: true,
    },
    searchPainel: {
      type: Boolean,
      default: false,
    },
    parentField: {
      type: String,
      default: '',
    },
    formParent: {
      type: Object,
      default: () => null,
    }, // indica que o form tem um master, ou seja, é um form filho
    formParentField: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    permissao: {
      type: String,
      default: '',
    },
    linkDoc: { type: String, default: '' },
  },
  data() {
    return {
      lookupMode: 'consulta',
      showFormDetailInDialog: false,
      mostrarRelatorio: false,
      mensagem: {
        text: '',
        visible: false,
        type: 'info',
      },
      height: '100%',
      children: [], // guardar os forms filhos
      currentTab: '0',
      master: null, // guardar referencia para o form pai
      appStyle: {},
    };
  },
  created() {
    this.lookupMode = this.$store.getters.crudFormLookup;
    this.$store.dispatch('setCrudFormLookup', '');
    if (this.formParent) {
      this.formParent.children.push(this);
    }
  },
  mounted() {
    if (this.lookupMode !== '' && this.lookupMode !== 'consulta') {
      this.openRecord([{ id: this.lookupMode }]);
    }
  },
  computed: {
    getCrudForm() {
      return this;
    },
    getForm() {
      return this;
    },
    detail() {
      return this.$refs.formDetail;
    },
    formFind() {
      return this.$refs.formFind;
    },
    isChild() {
      return this.formParent != null && this.formParentField != null;
    },
  },
  methods: {
    onCloseDialog() {
      if (this.master && this.master.$refs.form_cadastro) {
        this.master.$refs.form_cadastro.loadRecords();
      }
    },
    onResize() {
      // this.height = $(window).height() - 220
      this.height = window.innerHeight;
    },
    setRecordStatus(value) {
      this.$refs.formDetail.setRecordStatus(value);
    },
    newRecord() {
      if (this.mode === 'child') {
        this.showFormDetailInDialog = true;
        this.$nextTick(() => {
          this.$refs.formDetail.newRecord();
        });
      } else {
        this.currentTab = '1';
        this.$refs.formDetail.newRecord();
      }
      this.$emit('newRecord');
    },
    save() {
      return this.$refs.formDetail.save();
    },
    edit() {
      return this.$refs.formDetail.edit();
    },
    loadRecords(records) {
      return this.$refs.formDetail.loadRecords(records);
    },
    tabClick(tab) {
      if (tab.index === '0') {
        this.$refs.formFind.offset = 0;
        this.$refs.formFind.find();
      }
    },
    getDatasetConfig() {
      return {
        baseUrl: process.env.BASE_URL || '',
        headers: this.getHeader(),
      };
    },
    openRecord(records) {
      this.$emit('open-record', records);
      if (this.isChild) {
        this.showFormDetailInDialog = true;
        this.$nextTick(() => {
          this.$refs.formDetail.loadRecords(records);
        });
        if (this.mode === 'child-tab') {
          this.selectTab('1');
        }
        return;
      }
      this.selectTab('1');
      this.$refs.formDetail.loadRecords(records);
    },
    find() {
      this.$refs.formFind.find();
    },
    dblClick(records) {
      if (this.lookupMode === 'consulta') {
        this.$emit('dblClick', records);
      } else {
        this.openRecord(records);
      }
    },
    onLoadRecord(record) {
      this.$emit('loadRecord', record);
    },
    selectTab(index) {
      this.currentTab = index;
    },
    consultaRegistros($event) {
      this.$refs.formFind.find();
      this.selectTab($event);
    },
    abrirAbaConsulta() {
      this.$refs.tab.currentName = '0';
    },
    getButtonCaption(caption) {
      if (this.isChild) {
        return '';
      }
      if (this.$store.getters.pageSize.width > 768) {
        return ` ${caption}`;
      }
      return '';
    },
    hasPermission(codigo) {
      if (this.permissao) {
        return this.$store.getters.hasPermission(`${this.permissao}.${codigo}`);
      }
      return true;
    },
    ajuda(link) {
      window.open(`https://doc.sigeflex.com/artigos/${link}`, '_blank');
    },
    unSelectRows() {
      this.formFind.unSelectRows();
    },
  },
};
</script>
