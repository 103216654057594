import Dataset from '../../libs/dataset';

class UserService extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [
      { caption: 'Status', name: 'status' },
      { caption: 'Registrado em', name: 'createdAt', type: 'datetime' },
      { caption: 'Nome', name: 'name' },
      { caption: 'Email', name: 'email' },
    ];
    this.findFilters = [
      {
        label: 'Consulta por nome',
        name: 'main',
        width: '400px',
        type: 'textbox',
        value: '',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.model = this.getModel();
    this.record = this.model;
  }

  getModel() {
    return {
      id: null,
      name: null,
      email: null,
      groupName: null,
      groupId: null,
      settings: {
        disableAutoFind: false,
        fontSize: '',
      },
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;

    return `query {
              UserList(${filter}){
                id
                status
                createdAt
                updatedAt
                name
                email
              }
            }`;
  }

  getFields() {
    return `
       id
       createdAt
       updatedAt
       name
       email
       settings {
         disableAutoFind
         fontSize
       }
    `;
  }

  getByIds(ids) {
    return `query {
            UserList(ids: [${ids.join()}]) {
             ${this.getFields()}
           }
         }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation UserCreateMutation($data: UserInputType!) {
           UserCreate(data: $data){
             ${this.getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation UserUpdateMutation($id: String!, $data: UserInputType!) {
           UserUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation UserDeleteMutation($id: String!){
          UserDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      name: model.name,
      email: model.email,
      settings: {
        disableAutoFind: model.settings.disableAutoFind || false,
        fontSize: '',
      },
    };
  }

  convidaUsuario(convite) {
    return new Promise((resolve, reject) => {
      const query = `
         mutation UserCreateMutation($data: UserInputType!){
          UserCreate(data: $data) {
            ${this.getFields()}
          }
         }
    `;
      this.gqlMutation({
        query,
        params: {
          data: {
            name: convite.user.name,
            email: convite.user.email,
            groupId: convite.group.id,
          },
        },
      })
        .then((response) => {
          this.setRecord(response.record);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  changePassword(dadosAlteraSenha) {
    return new Promise((resolve, reject) => {
      const query = `
         mutation UserChangePassword($userName: String!, $currentPassword: String!,
          $newPassword: String!, $repeatedNewPassword: String!){
          UserChangePassword(userName: $userName, currentPassword: $currentPassword,
            newPassword: $newPassword, repeatedNewPassword: $repeatedNewPassword)
         }
    `;
      this.gqlMutation({
        query,
        params: {
          userName: this.record.email,
          currentPassword: dadosAlteraSenha.currentPassword,
          newPassword: dadosAlteraSenha.newPassword,
          repeatedNewPassword: dadosAlteraSenha.repeatedNewPassword,
        },
      })
        .then((response) => {
          this.setRecord(response.record);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  setOrgPadrao() {
    return new Promise((resolve, reject) => {
      const query = `
         mutation UserSetOrgPadraoMutation($userId: String){
          UserSetOrgPadrao(userId: $userId)
         }
    `;
      this.gqlMutation({
        query,
        params: {
          userId: '',
        },
      })
        .then((response) => {
          this.setRecord(response.record);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  confirmInvitation(invitationCode) {
    return this.post('/users/confirm_invitation', { token: invitationCode });
  }

  rejectInvitation(invitationCode) {
    return this.post('/users/reject_invitation', { token: invitationCode });
  }
}

export default UserService;
