<template>
  <div>
    <f-crud-form
      ref="form"
      linkDoc="estoque/produtos"
      v-if="service"
      v-on="$listeners"
      :schema='service'
      :formsValidation="[$refs.formValidation]"
      :styleCell="styleCell"
      :styleRow="styleRow"
      :tabs="tabsInterna"
      permissao="estoque.produto"
      @newRecord="newRecord"
      @selectedRecords="onSelectedRecords"
    >
      <f-row slot="detailButtons">
        <f-button
          caption="Ajuste de Estoque"
          @click="mostrarAjusteEstoque = !mostrarAjusteEstoque"
          v-if="$store.getters.hasPermission('estoque.produto.movimentoestoque')"
        >
          <FontAwesomeIcon icon="check-double" />
        </f-button>
        <f-button
          caption="Movimentação de Estoque"
          @click="mostrarMovimentoEstoque = !mostrarMovimentoEstoque"
          v-if="$store.getters.hasPermission('estoque.produto.movimentoestoque')"
        >
          <FontAwesomeIcon icon="file-alt" />
        </f-button>
        <f-button
          caption="Historico de Compras"
          @click="mostrarCompras = !mostrarCompras"
          v-if="$store.getters.hasPermission('estoque.produto.movimentoestoque')"
        >
          <FontAwesomeIcon icon="file-alt" />
        </f-button>
      </f-row>

      <div slot="find-buttons">
        <f-button caption="Imprimir Etiquetas" @click="imprimirEtiquetas"></f-button>
        <f-button
          caption="Arquivo carga balança"
          v-if="usaBalanca"
          @click="mostraArquivoCargaBalanca = !mostraArquivoCargaBalanca"
        />
        <f-button
          caption="Alteração Conjunta"
          @click="mostrarAlteracaoConjunta=!mostrarAlteracaoConjunta"
        />
      </div>

      <f-col col="12">
        <f-form ref="formValidation">
          <f-form-row>
            <f-datetimebox
              caption='Criado em'
              v-show="service.record.createdAt"
              v-model='service.record.createdAt'
              :disabled="true"
            />
            <f-datetimebox
              caption='Alterado em'
              v-show="service.record.updatedAt"
              v-model='service.record.updatedAt'
              :disabled="true"
            />
          </f-form-row>
          <f-textbox caption="Nome" ref="nome" v-model="service.record.nome" :validations="validations.nome" />
          <f-form-row>
            <f-textbox
              caption="Cód. Produto"
              helpMessage="Código do produto que nao seja o código de barra,
              se deixar em branco, o sistema gerará um código sequencial automaticamente."
              v-model="service.record.codigoProduto"
            />
            <f-textbox
              caption="Cód. Barra"
              helpMessage="Digite um de barra do produto"
              v-model="service.record.codigoBarra"
            />
            <f-textbox
              caption="Cód. Referência"
              v-model="service.record.codigoReferencia"
            />
          </f-form-row>

          <f-form-row>
            <f-lookup
              v-model='service.record.fabricanteNome'
              caption='Fabricante'
              component="Fabricante"
              :value-id="service.record.fabricanteId"
              @value="setFabricante"
            />
            <f-lookup
              v-model='service.record.categoriaNome'
              caption='Categoria'
              component="Categoria"
              :value-id="service.record.categoriaId"
              @value="setCategoria"
            />
          </f-form-row>

          <f-form-row>
            <f-combobox
              caption="UN"
              v-model='service.record.un'
              :options="unidades"
              :validations="validations.un"
              :allowEdit="true"
            />
            <f-numberbox
              caption="Preço Venda A vista"
              v-model='service.record.valorUnitario'
              decimals="5"
              :isCurrency="true"
              :disabled="service.record.composto"
            />
            <f-numberbox
              caption="Preço Venda A Prazo"
              v-model='service.record.precoVendaAprazo'
              decimals="5"
              :isCurrency="true"
              :disabled="service.record.composto"
            />
            <f-numberbox
              caption="Preço Venda Atacado"
              v-model='service.record.precoVendaAtacado'
              decimals="5"
              :isCurrency="true"
              :disabled="service.record.composto"
            />
            <f-alert v-if="service.record.composto" type="warning">
              <strong>Esse produto é composto</strong>, seu Valor Unitário é calculado baseado na sua composição
            </f-alert>
          </f-form-row>
          <f-form-row>
            <f-checkbox
              caption="Produto Composto"
              v-model="service.record.composto"
            />
            <f-button
              caption="Composição"
              class="ml-3"
              @click="mostrarComposicao = true"
              v-if="service.record.composto"
            />
          </f-form-row>
          <f-checkbox
            caption="Produto Fracionável"
            v-model="service.record.isFracionavel"
          />
          <f-alert v-if="alertaQuantidade('menorZero')" type="warning">
            <strong>Quantidade está negativa</strong>
          </f-alert>
          <f-alert v-if="alertaQuantidade('igualZero')" type="warning">
            <strong>Produto sem estoque</strong>
          </f-alert>
          <f-alert v-if="alertaQuantidade('menorIgualMinima')" type="warning">
            <strong>Quantidade mínima atingida</strong>
          </f-alert>
          <f-alert v-if="alertaQuantidade('maiorIgualMaxima')" type="warning">
            <strong>Quantidade máxima atingida</strong>
          </f-alert>

          <slot name="dados-especificos">
            <f-tabs type="border-card" class="mb-5">
              <f-tab-pane label="Dados Específicos">
                <div style="overflow: auto;">
                  <f-row>
                    <f-col cols="12">
                      <f-combobox
                        caption="Situação"
                        v-model="service.record.situacao"
                        :inputStyle="styleSituacao"
                        :options="situacao"
                      />
                      <f-form-row>
                        <f-textbox
                          caption="Dias Garantia Empresa"
                          v-model='service.record.diasGarantiaEmpresa'
                          type="number"
                        />
                        <f-textbox
                          caption="Dias Garantia Fornecedor"
                          v-model='service.record.diasGarantia'
                          type="number"
                        />
                      </f-form-row>
                    </f-col>
                  </f-row>
                  <f-form-row>
                    <f-combobox
                      caption="Produto Específico"
                      v-model="service.record.produtoEspecifico"
                      :options="produtoEspecifico"
                    />
                    <f-form-row v-if="service.record.produtoEspecifico === 'combustivel'">
                      <f-textbox
                        caption="Código ANP"
                        width="130px"
                        v-model='service.record.codigoAnp'
                        :maxlength="9"
                      />
                      <f-textbox caption="Descrição ANP"  width="500px" v-model='service.record.codigoAnpDescricao' />
                    </f-form-row>
                  </f-form-row>
                </div>
              </f-tab-pane>
              <f-tab-pane label="Descrição/Aplicação">
                <f-textbox
                  type="textarea"
                  caption="Descrição"
                  height="80px"
                  v-model='service.record.descricao'
                  helpMessage="Escreva aqui descrições mais detalhadas do produto, como especificações técnicas etc."
                  :rows="3"
                  :cols="100"
                />
                <f-textbox
                  type="textarea"
                  caption="Aplicação"
                  height="250px"
                  helpMessage="Escreva aqui orientações de como esse produto deve ser utilizado."
                  v-model='service.record.aplicacao'
                />
              </f-tab-pane>
              <f-tab-pane label="Custos">
                <f-checkbox caption="Informar Custo manualmente" v-model="service.record.custoManual"/>
                <f-form-row>
                  <f-numberbox
                    caption="Preço Ultima Compra"
                    v-model='service.record.precoUltimaCompra'
                    :disabled="true"
                  />
                  <f-numberbox
                    caption="Preço Custo"
                    v-model='service.record.precoCusto'
                    :disabled="!service.record.custoManual"
                  />
                  <f-numberbox
                    caption="Custo Médio"
                    helpMessage="O custo médio é uma estimativa baseada nas 100 últimas movimentacoes por notas de
                     entrada aceitas com este produto."
                    v-model='service.record.custoMedio'
                    :disabled="!service.record.custoManual"
                  />
                </f-form-row>
              </f-tab-pane>
              <f-tab-pane label="Estoque">
                <f-form-row>
                  <f-textbox
                    caption="Quantidade Mínima"
                    v-model='service.record.quantidadeMinima'
                    type="number"
                    :fieldStyle="{width: '180px'}"
                  />
                  <f-textbox
                    caption="Quantidade Máxima"
                    v-model='service.record.quantidadeMaxima'
                    type="number"
                    :fieldStyle="{width: '180px'}"
                  />
                </f-form-row>
                <f-grid :data=service.record.estoquesList :selecionSingle="true" :columns="estoqueColunas"></f-grid>
              </f-tab-pane>
              <f-tab-pane label="Vendas">
                <f-textbox caption="Qtde. Mínima" v-model='service.record.quantidadeMinimaVenda' type="number" />
                <f-textbox caption="Qtde. Multiplo" v-model='service.record.quantidadeVendaMultiplo' type="number" />
              </f-tab-pane>
              <f-tab-pane label="Dados Fiscais">
                <f-form-row>
                  <f-numberbox
                    caption="Peso Bruto (Kg)"
                    v-model='service.record.pesoBruto'
                    :decimals="3"
                    suffix=" Kg"
                  />
                  <f-numberbox
                    caption="Peso Líquido (Kg)"
                    v-model='service.record.pesoLiquido'
                    :decimals="3"
                    suffix=" Kg"
                  />
                </f-form-row>
                <f-form-row>
                  <f-textbox
                    caption="NCM"
                    width="130px"
                    v-model='service.record.ncm'
                    :maxlength="8"
                  />
                  <f-textbox
                    caption="Cest"
                    width="130px"
                    v-model='service.record.cest'
                    :maxlength="7"
                  />
                  <f-lookup
                    v-model="service.record.tributacaoNome"
                    caption='Tributação'
                    component="Tributacao"
                    @value="setTributacao"
                    :value-id="service.record.tributacaoId"
                  />
                </f-form-row>
              </f-tab-pane>
              <f-tab-pane :label="tab.caption" class="tab-find" v-for="tab in tabs" :key="tab.name">
                <slot :name="tab.name"></slot>
              </f-tab-pane>
            </f-tabs>
          </slot>
        </f-form>
      </f-col>

      <div slot="admin">
        <f-tabs type="border-card">
          <f-tab-pane label="Importação de Produtos">
            <ProdutoImportacao :service="service" />
          </f-tab-pane>
        </f-tabs>
      </div>

      <!--IMPRESSAO DE ETIQUETAS-->
      <ImpressaoEtiquetas
        modulo="PRODUTO"
        v-model="mostrarImpressaoEtiquetas"
        :dados="produtosImpressaoEtiquetas"
      >
        <div class="flex flex-col justify-center">
          <strong class="text-center mb-4">Produtos a serem impressos</strong>
          <f-grid
            :data="produtosImpressaoEtiquetas"
            :allow-edit="true"
            :allow-sorting="false"
            :columns="etiquetasColunas"
            :selecionSingle="true"
            @save="salvarAlteracaoEtiqueta"
          >
          </f-grid>
        </div>
      </ImpressaoEtiquetas>

      <!--ALTERACAO CONJUNTA, POR EXEMPLO MUDAR CATEGORIA DE VÁRIOS PRODUTOS DE UMA VEZ SÓ -->
      <ProdutoAlteracaoConjunta
        v-model="mostrarAlteracaoConjunta"
        :produtosIniciais="selectedRecords"
        @alteraProdutos="alteraProdutos"
      />
      <f-dialog
        v-model="mostrarAjusteEstoque"
        width="80%"
        height="80%"
        :fullscreen="$store.getters.isMobile"
        :title="`Ajuste de estoque - ${this.service.record.nome}`"
        :icon="'check-double'"
      >
        <AjusteEstoque
          v-if="mostrarAjusteEstoque"
          :service="service"
          @sucesso="mostrarAjusteEstoque = !mostrarAjusteEstoque; $refs.form.loadRecords()"
        />
      </f-dialog>
      <f-dialog
        v-model="mostrarMovimentoEstoque"
        width="80%"
        height="80%"
        :fullscreen="$store.getters.isMobile"
        :title="`Movimentação de Produto - ${this.service.record.nome}`"
      >
        <MovimentoEstoque :service="service" v-if="mostrarMovimentoEstoque" />
      </f-dialog>
      <f-dialog
        v-model="mostrarCompras"
        width="80%"
        height="80%"
        :fullscreen="$store.getters.isMobile"
        :title="`Historico de compras de Produto - ${this.service.record.nome}`"
      >
        <HistoricoCompras :service="service" v-if="mostrarCompras" />
      </f-dialog>
      <ProdutoBalancaArquivoTxtCarga
        v-if="usaBalanca"
        :service="service"
        v-model="mostraArquivoCargaBalanca"
      />
      <Composicao
        v-model="mostrarComposicao"
        :produtoComposto="service.record"
        :composicao="service.record.composicao || []"
        :addItem="addComposicao"
        :deleteItem="deleteComposicao"
        @close="setValorUnitario"
      />
    </f-crud-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckDouble, faFileAlt, faLevelDownAlt, faLevelUpAlt, faNetworkWired, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import ImpressaoEtiquetas from '../../etiqueta/ImpressaoEtiquetas';
import ProdutoAlteracaoConjunta from './ProdutoAlteracaoConjunta';
import Service from './ProdutoService';
import Categoria from '../categoria/Categoria';
import Fabricante from '../fabricante/Fabricante';
import Composicao from './ProdutoComposicao';
import MovimentoEstoque from './MovimentoEstoque';
import AjusteEstoque from './AjusteEstoque';
import HistoricoCompras from './HistoricoCompras';
import ProdutoBalancaArquivoTxtCarga from './ProdutoBalancaArquivoTxtCarga';
import ProdutoImportacao from './ProdutoImportacao';

library.add(faCheckDouble, faFileAlt, faNetworkWired, faLevelDownAlt, faLevelUpAlt, faTimes);
dom.watch();
Vue.component('Categoria', Categoria);
Vue.component('Fabricante', Fabricante);
export default {
  components: {
    ProdutoAlteracaoConjunta,
    Composicao,
    ImpressaoEtiquetas,
    MovimentoEstoque,
    AjusteEstoque,
    HistoricoCompras,
    ProdutoBalancaArquivoTxtCarga,
    ProdutoImportacao,
  },
  props: {
    modelo: { default: 'tab' },
    tabs: {
      type: Array,
      default: () => [],
    },
    gridProdutos: null,
  },
  mounted() {
    if (this.gridProdutos) {
      this.schema.grid = this.gridProdutos;
    }
  },
  created() {
    this.service = new Service();
    this.$emit('created', this.service);
  },
  computed: {
    styleSituacao() {
      let style = { color: this.$style.colorBlack };
      if (this.service.record.situacao === 'INATIVO') {
        style = { color: this.$style.colorRed };
      }
      return style;
    },
    usaBalanca() {
      if (!this.$store.getters.config.vendas) {
        return false;
      }
      return this.$store.getters.config.vendas.usaBalanca;
    },
    tabsInterna() {
      const tabs = [];
      if (!this.$store.getters.hasPermission('estoque.produto.altera')) {
        return tabs;
      }
      tabs.push({ name: 'admin', caption: 'Administrativo' });
      return tabs;
    },
  },
  data() {
    return {
      formValidation: null,
      service: null,
      mostrarComposicao: false,
      mostrarImpressaoEtiquetas: false,
      produtosImpressaoEtiquetas: [],
      relatorioTitulo: '',
      selectedRecords: [],
      produtoEspecifico: [
        { text: '', value: '' },
        { text: 'Combustível', value: 'combustivel' },
      ],
      estoqueColunas: [
        { field: 'estoqueNome', title: 'Estoque' },
        { field: 'quantidade', title: 'Quantidade' },
      ],
      etiquetasColunas: [
        { field: 'CodigoBarra', title: 'Código Barra' },
        { field: 'CodigoProduto', title: 'Código Produto' },
        { field: 'Nome', title: 'Nome' },
        { field: 'PrecoAvista', title: 'Preço A Vista', type: 'currency' },
        { field: 'PrecoAprazo', title: 'Preço A prazo', type: 'currency' },
        { field: 'PrecoAtacado', title: 'Preço Atacado', type: 'currency' },
        { field: 'QtdeEtiqueta', title: 'Qtde Etiqueta' },
      ],
      validations: {
        nome: [
          { required: true, message: 'Nome é obrigatório', trigger: 'blur' },
        ],
        un: [
          { required: true, message: 'UN é obrigatório', trigger: 'blur' },
        ],
        descricao: [
          {
            min: 5,
            max: 220,
            message: 'Descrição deve ter entre 5 e 220 caracteres',
            trigger: 'blur',
          },
        ],
        aplicacao: [
          {
            min: 5,
            max: 120,
            message: 'Aplicação deve ter entre 5 e 220 caracteres',
            trigger: 'blur',
          },
        ],
        codigoEan: [
          {
            min: 5,
            max: 13,
            message: 'Código barra deve ter entre 7 e 13 caracteres',
            trigger: 'blur',
          },
        ],
        ncm: [
          {
            min: 4,
            max: 8,
            message: 'NCM deve ter entre 4 e 8 caracteres',
            trigger: 'blur',
          },
        ],
      },
      desabilitaQuantidadeInicial: false,
      mostrarAlteracaoConjunta: false,
      mostraArquivoCargaBalanca: false,
      mostrarMovimentoEstoque: false,
      mostrarAjusteEstoque: false,
      mostrarCompras: false,
      quantidadeAjusteEstoque: 0,
      alteracaoConjutaPrecoPercentual: 0,
      alteracaoPrecoTipoPrecoAlterar: 'avista',
      alteracaoPrecoTipoPrecoAlterarOptions: [
        { text: 'A Vista', value: 'avista' },
        { text: 'A Prazo', value: 'aprazo' },
      ],
      alteracaoPrecoDescricao: '',
      alteracaoPrecoCategoriaID: null,
      alteracaoPrecoCategoriaNome: null,
      alteracaoPrecoFabricanteID: null,
      alteracaoPrecoFabricanteNome: null,
      unidades: [
        { text: 'UN', value: 'UN' },
        { text: 'M2', value: 'M2' },
        { text: 'M3', value: 'M3' },
        { text: 'ML', value: 'ML' },
        { text: 'KG', value: 'KG' },
        { text: 'FD', value: 'FD' },
        { text: 'CX', value: 'CX' },
        { text: 'LT', value: 'LT' },
        { text: 'M', value: 'M' },
        { text: 'MT', value: 'MT' },
        { text: 'CM', value: 'CM' },
        { text: 'SC', value: 'SC' },
      ],
      situacao: [
        { text: 'ATIVO', value: 'ATIVO' },
        { text: 'INATIVO', value: 'INATIVO' },
      ],
    };
  },
  methods: {
    alteraProdutos(alteracoes) {
      this.service.alteraProdutos(alteracoes)
        .then(() => {
          this.$showMessage({
            title: 'Alterações conjuntas',
            type: 'success',
            message: 'Produtos ajustados com sucesso!',
          });
          this.$refs.form.$refs.formFind.consulta();
        })
        .catch((err) => {
          this.$showMessage({ message: `Erro ao executar alterações conjuntas: ${err}`, type: 'error' });
        });
    },
    imprimirEtiquetas() {
      const ids = _.map(this.selectedRecords, 'id');
      this.service.getRecordsByIds(ids)
        .then((lista) => {
          this.produtosImpressaoEtiquetas = [];
          Object.keys(lista).forEach((key) => {
            const item = lista[key];
            this.produtosImpressaoEtiquetas.push({
              id: key,
              Nome: item.nome,
              CodigoBarra: item.codigoBarra,
              CodigoProduto: item.codigoProduto,
              PrecoAvista: _.number().formataDinheiro(item.valorUnitario),
              PrecoAprazo: _.number().formataDinheiro(item.precoVendaAprazo),
              PrecoVarejo: _.number().formataDinheiro(item.valorUnitario),
              PrecoAtacado: _.number().formataDinheiro(item.precoVendaAtacado),
              QtdeEtiqueta: 1,
            });
          });
          this.mostrarImpressaoEtiquetas = !this.mostrarImpressaoEtiquetas;
        })
        .catch((err) => {
          this.$showMessage({ message: `Erro ao imprimir etiquetas ${err}`, type: 'error' });
        });
    },
    setTributacao(record) {
      this.service.record.tributacaoId = record.id;
      this.service.record.tributacaoNome = record.nome;
    },
    salvarAlteracaoEtiqueta(dados) {
      this.produtosImpressaoEtiquetas = dados.data;
    },
    addComposicao(record) {
      return this.service.addComposicao(record);
    },
    deleteComposicao(record) {
      this.service.deleteComposicao(record);
    },
    setValorUnitario(record) {
      this.service.record.valorUnitario = record.valorUnitario;
    },
    newRecord() {
      this.service.record.situacao = 'ATIVO';
      this.service.record.un = 'UN';
      this.$refs.nome.focus();
    },
    setCategoria(record) {
      this.service.record.categoriaNome = record.nome;
      this.service.record.categoriaId = record.id;
    },
    setFabricante(record) {
      this.service.record.fabricanteNome = record.nome;
      this.service.record.fabricanteId = record.id;
    },
    loadRecords(ids) {
      this.$refs.form.loadRecords(ids);
    },
    onSelectedRecords(records) {
      this.selectedRecords = records.filter((item) => item.id);
    },
    async alteracaoConjutaPreco() {
      const criterio = {
        fabricante: this.alteracaoPrecoFabricanteID,
        categoria: this.alteracaoPrecoCategoriaID,
        descricao: this.alteracaoPrecoDescricao,
        tipo_preco_alterar: this.alteracaoPrecoTipoPrecoAlterar,
        percentual: this.alteracaoConjutaPrecoPercentual,
      };

      const resp = await this.$confirm('Deseja realmente efetuar alteração?', 'Confirma operação?', {
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        type: 'warning',
      });

      if (resp) {
        const response = await this.service.POST('/altera_preco', criterio);
        if (response.sucesso) {
          this.$showMessage({ message: 'Preços alterados com sucesso!', type: 'success' });
        }
      }
    },
    async atualizaNcm() {
      const retorno = await this.service.POST('/atualiza_ncm', { atualizar_existente: false });
      if (retorno.sucesso) {
        this.$showMessage({ message: 'NCMs Atualizados com sucesso', type: 'success' });
      }
    },
    setRecord(dataset) {
      this.service = dataset;
      this.desabilitaQuantidadeInicial = this.service.record.quantidade_inicial > 0;
    },
    styleCell(col, item) {
      if (col === 'quantidadeEstoqueTotal') {
        // quantidade abaixo de zero
        if (this.$utils.Number.parseFloat(item.quantidadeEstoqueTotal) < 0) {
          return `<div  style="color: red">${item.quantidadeEstoqueTotal} <i class="fa fa-times"></i></div>`;
        }
        // quantidade igual a zero
        if (this.$utils.Number.parseFloat(item.quantidadeEstoqueTotal) === 0) {
          return `<div  style="color: red">${item.quantidadeEstoqueTotal} </div>`;
        }
        // quantidade minima atingida
        if (item.quantidadeMinima) {
          if (this.$utils.Number.parseFloat(item.quantidadeEstoqueTotal)
            <= this.$utils.Number.parseFloat(item.quantidadeMinima)) {
            return `<div  style="color: orange">${item.quantidadeEstoqueTotal} `
              + '<i class="fa fa-level-down-alt"></i></div>';
          }
        }
        // quantidade maxima atingida
        if (item.quantidadeMaxima) {
          if (this.$utils.Number.parseFloat(item.quantidadeEstoqueTotal)
            >= this.$utils.Number.parseFloat(item.quantidadeMaxima)) {
            return `<div  style="color: green">${item.quantidadeEstoqueTotal} `
              + '<i class="fa fa-level-up-alt"></i></div>';
          }
        }
      }
      if (col === 'nome') {
        if (item.composto) {
          return `<div>
                    ${item.nome}
                    <i
                     class="fa fa-network-wired ml-2 text-xs text-gray-500"
                     title="Produto Composto"
                     style="font-size: xx-small"
                     />
                  </div>`;
        }
        return {};
      }
      return {};
    },
    styleRow(item) {
      if (item.situacao === 'INATIVO') {
        return { color: '#DC2626', textDecoration: 'line-through' };
      }
      return {};
    },
    alertaQuantidade(tipo) {
      let mostra = false;
      switch (tipo) {
        case 'menorZero':
          if (this.$utils.Number.parseFloat(this.service.record.quantidadeEstoqueTotal) < 0) {
            mostra = true;
          }
          break;
        case 'igualZero':
          if (this.$utils.Number.parseFloat(this.service.record.quantidadeEstoqueTotal) === 0) {
            mostra = true;
          }
          break;
        case 'menorIgualMinima':
          if (this.service.record.quantidadeMinima) {
            if (this.$utils.Number.parseFloat(this.service.record.quantidadeEstoqueTotal)
              <= this.$utils.Number.parseFloat(this.service.record.quantidadeMinima)) {
              mostra = true;
            }
          }
          break;
        case 'maiorIgualMaxima':
          if (this.service.record.quantidadeMaxima) {
            if (this.$utils.Number.parseFloat(this.service.record.quantidadeEstoqueTotal)
              >= this.$utils.Number.parseFloat(this.service.record.quantidadeMaxima)) {
              mostra = true;
            }
          }
          break;
        default:
          mostra = false;
          break;
      }
      return mostra;
    },
  },
};
</script>
