<template>
  <f-crud-form
    formParentField="contacts"
    mode="child"
    :schema="service.contactSchema"
    :formParent="formParent"
    :formsValidation="[$refs.formContacts]"
  >
    <f-form ref="formContacts">
      <f-row>
        <f-col col="12">
          <f-datetimebox
            caption='Criado em'
            :value='service.contactSchema.record.createdAt'
            v-show='service.contactSchema.record.createdAt'
            :disabled="true"
          />
          <f-datetimebox
            caption='Última Alteração'
            :value='service.contactSchema.record.updatedAt'
            v-show='service.contactSchema.record.updatedAt'
            :disabled="true"
          />
          <f-textbox caption='Nome' v-model='service.contactSchema.record.name'
                     :validations="validations.contactName" />
          <f-textbox
            type="email"
            caption='E-mail'
            v-model='service.contactSchema.record.email'
            :validations="validations.email"
          />
          <f-textbox caption='Telefone' v-model='service.contactSchema.record.phone' :validations="validations.phone" />
          <f-checkbox caption="Esse número é Whatsapp" v-model="service.contactSchema.record.byWhatsApp" />
          <f-form-row>
            <f-link
              v-if="service.contactSchema.record.phone"
              v-show="service.contactSchema.record.byWhatsApp"
              :linkButton="true"
              :openBlank="true"
              :url="getWhatsappLink"
            >
              Abrir conversa com o Whatsapp do contato
            </f-link>
          </f-form-row>
        </f-col>
      </f-row>
    </f-form>
  </f-crud-form>
</template>

<script>

export default {
  name: 'PessoaContato',
  data() {
    return {
      mostrarWhatsapp: false,
      validations: {
        contactName: [
          {
            min: 3,
            max: 120,
            message: 'Nome deve conter entre 3 e 120 caracteres.',
            trigger: 'blur',
          },
        ],
        email: [
          {
            min: 8,
            max: 60,
            message: 'E-mail deve conter entre 8 e 60 caracteres.',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            min: 9,
            max: 17,
            message: 'Telefone deve conter entre 9 e 17 caracteres.',
            trigger: 'blur',
          },
        ],
      },
      formValidation: null,
    };
  },
  computed: {
    getWhatsappLink() {
      let whatsAppLink = 'https://api.whatsapp.com/send?phone=';
      if (!this.service.contactSchema.record.phone.startsWith('55')) {
        whatsAppLink += '55';
      }
      whatsAppLink += this.service.contactSchema.record.phone;
      return whatsAppLink;
    },
    getPhoneMask() {
      let mask = [''];
      if (this.service.contactSchema.record.phone !== undefined) {
        const phone = this.service.contactSchema.record.phone.replace('-',
          '').replace('(', '').replace(')', '').replace('+', '');
        switch (phone.length) {
          case 9:
            mask = ['#####-####'];
            break;
          case 10:
            mask = ['(##)####-####'];
            break;
          case 11:
            mask = ['(##)#####-####'];
            break;
          case 12:
            mask = ['+##(##)####-####'];
            break;
          case 13:
            mask = ['+##(##)#####-####'];
            break;
          default:
            mask = ['####-####'];
            break;
        }
        return mask;
      }
      return mask;
    },
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    formParent: {
      type: Object,
      required: true,
    },
  },
};
</script>
