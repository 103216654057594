const authentication = (to, from, next) => {
  let isAuthenticated = false;
  const requiredAuthentication = to.meta.requiresAuth === undefined
    || to.meta.requiresAuth === true;
  if (to.query.token) {
    window.sessionStorage.setItem('x-auth-token', to.query.token);
    isAuthenticated = true;
  } else if (window.sessionStorage.getItem('infoLogin')) {
    isAuthenticated = true;
  }

  if (requiredAuthentication && !isAuthenticated) {
    if (to.name !== 'Login') {
      next({
        name: 'Login',
        query: to.query,
        params: { notAuthenticated: true },
        replace: true,
      });
      return;
    }
  }

  if (!to.matched.length) {
    next('/notfound');
  } else {
    next({
      query: null,
      replace: true,
    });
  }
};

export default {
  authentication,
};
