import Dataset from 'comps/libs/dataset';

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      {
        name: 'createdAt',
        caption: 'Data/Hora Emissão',
        type: 'datetime',
      },
      {
        name: 'cancelamentoMotivo',
        caption: 'Motivo Cancelamento',
        type: 'string',
      },
      {
        name: 'numero',
        caption: 'Número',
      },
      {
        name: 'pessoaCpf',
        caption: 'CPF',
        type: 'string',
      },
      {
        name: 'pessoaNome',
        caption: 'Pessoa',
      },
      {
        name: 'validade',
        caption: 'Validade',
        type: 'date',
      },
      {
        name: 'total',
        caption: 'total',
        type: 'currency',
      },
    ];
    this.findFilters = [
      {
        type: 'textbox',
        width: '400px',
        label: 'Consulta por número da carteira ou nome do paciente',
        name: 'main',
      },
      {
        label: 'Validade (A partir) ',
        type: 'date',
        name: 'vencimentoGte',
      },
      {
        label: 'Validade (Até) ',
        type: 'date',
        name: 'vencimentoLte',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = {
      id: null,
      createdAt: null,
      fotoBase64: null,
      fotoUrl: null,
      numero: null,
      obs: null,
      pessoaId: null,
      pessoaNome: null,
      vendedor: {
        id: null,
        nome: null,
        apelido: null,
        comissao: null,
      },
      validade: null,
    };
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      fotoBase64: null,
      fotoUrl: null,
      numero: null,
      obs: null,
      pessoaId: null,
      pessoaNome: null,
      pessoaCpf: null,
      pessoaRg: null,
      cancelamentoMotivo: null,
      cancelamentoUsuario: null,
      cancelamentoDataHora: null,
      vendedor: {
        id: null,
        nome: null,
        apelido: null,
        comissao: null,
      },
      validade: null,
    };
  }

  getFindQuery(filters) {
    let filter = `filter: "${filters.main || ''}"`;

    if (filters.vencimentoGte) {
      filter += `vencimentoGte: "${filters.vencimentoGte.replace('03:00:00', '00:00:00')}",`;
    }

    if (filters.vencimentoLte) {
      filter += `vencimentoLte: "${filters.vencimentoLte.replace('03:00:00', '23:59:59')}"`;
    }
    return `query {
              CarteirinhaList(${filter}) {
                id
                createdAt
                pessoaNome
                pessoaCpf
                cancelamentoMotivo
                pessoaRg
                vendedor {
                  id
                  nome
                  apelido
                  comissao
                }
                validade
                numero
                total
              }
           }`;
  }

  getByIds(ids) {
    return `
      query {
        CarteirinhaList(ids: [${ids.join()}]) {
          ${this.getFields()}
       }
      }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation CarteirinhaCreateMutation($data: CarteirinhaInputType!) {
           CarteirinhaCreate(data: $data){
              ${this.getFields()}
           }
         }`,
      params: this.getMutationParams(this.record),
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation CarteirinhaUpdateMutation($id: String!, $data: CarteirinhaInputType!) {
           CarteirinhaUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: this.getMutationParams(this.record),
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation CarteirinhaDeleteMutation($id: String!){
          CarteirinhaDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getReportConfig() {
    return [
      {
        graphqlQueryName: 'ReportCarteirinhaPorVendedor',
        nomeRelatorio: 'Relatório de Carteirinha por Vendedor',
        id: '1',
        descricao: '',
        campos: [
          {
            tipo: 'date',
            caption: 'Criado em',
            campoFiltro: 'createdAt',
            opcoes: [],
          },
          {
            tipo: 'date',
            caption: 'Validade',
            campoFiltro: 'validade',
            opcoes: [],
          },
          {
            tipo: 'lookup',
            caption: 'Vendedor',
            fieldName: 'name',
            campoFiltro: 'vendedor',
            component: 'Person',
            filter: 'isSalesMan',
            opcoes: [],
          },
          {
            tipo: 'lookup',
            caption: 'Usuário',
            fieldName: 'name',
            campoFiltro: 'usuarioId',
            component: 'User',
            opcoes: [],
          },
        ],
        ordenacao: [
          {
            value: 'createdAt',
            text: 'Criado em',
          },
          {
            value: 'validade',
            text: 'Data de validade',
          },
          {
            value: 'numero',
            text: 'Numero',
          },
        ],
      },
    ];
  }

  async imprimir() {
    const query = `
          query {
            CarteirinhaImprimir(ids:["${this.record.id}"]){
              id
              htmlContent
            }
          }`;
    const result = await this.gqlQuery(query);
    return result;
  }

  getFields() {
    return `id
             createdAt
             pessoaId
             pessoaNome
             pessoaCpf
             pessoaRg
             cancelamentoMotivo
             cancelamentoUsuario
             cancelamentoDataHora
             vendedor {
              id
              nome
              apelido
              comissao
             }
             validade
             fotoUrl
             numero
             total
             obs`;
  }

  cancelar(id) {
    const query = `
         mutation CarteirinhaCancelar($id: String!, $motivo: String!){
          CarteirinhaCancelar(id: $id, motivo: $motivo) {
            ${this.getFields()}
          }
         }
    `;
    return new Promise((resolve, reject) => {
      this.gqlMutation({ query, params: { id, motivo: this.record.cancelamentoMotivo } })
        .then((response) => {
          this.setRecord(response.record);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getMutationParams(model) {
    const params = {
      data: {
        obs: model.obs,
        pessoaNome: model.pessoaNome,
        pessoaId: model.pessoaId,
        pessoaCpf: model.pessoaCpf,
        pessoaRg: model.pessoaRg,
        vendedor: {
          id: model.vendedor.id,
          nome: model.vendedor.nome,
          apelido: model.vendedor.apelido,
          comissao: model.vendedor.comissao,
        },
        fotoBase64: model.fotoBase64 || '',
      },
    };
    if (model.id) {
      params.id = model.id;
    }
    return params;
  }
}

export default Service;
