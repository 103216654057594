<style>
.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>
<template>
  <el-badge :value="value" :max="max" class="item" :type="type">
    <slot></slot>
  </el-badge>
</template>

<script>
import { Badge } from 'element-ui';

export default {
  components: {
    'el-badge': Badge,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'danger', // primary / success / warning / danger / info
    },
    max: {
      type: Number,
      default: null,
    },
  },
};
</script>
