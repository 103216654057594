<template>
  <f-crud-form
    v-if="service"
    :schema="service"
    v-on="$listeners"
    :formsValidation="[formValidation]"
    @save="valorTotalServico = 0"
  >
    <f-row>
      <f-col col="12">
        <f-form @mounted="formValidation = $event">
          <f-textbox
            id="nome"
            caption='Nome'
            v-model='service.record.nome'
            :validations="validations.nome"
          />
          <f-textbox
            id="codigoServico"
            caption='Codigo'
            v-model='service.record.codigo'
          />
          <f-form-row>
            <f-combobox
              caption="UN"
              v-model='service.record.un'
              :options="unidades"
              :validations="validations.un"
              :allowEdit="true"
            />
            <f-textbox
              id="Dias Validade"
              v-if="service.record.un === 'DIAS'"
              type="number"
              caption='Dias Validade'
              v-model='service.record.diasValidade'
            />
            <f-textbox
              caption='Descrição condição pagamento'
              caseOption=""
              v-model='service.record.descricaoPagamento'
            />
          </f-form-row>
          <f-numberbox
            v-if="service.record.un === 'DIAS'"
            :caption='`Valor do servico para ${service.record.diasValidade} dias`'
            v-model='valorTotalServico'
            @keyup="calculaValorUnitario"
          />
          <f-numberbox
            id="valorUnitario"
            caption='Valor Unitário'
            v-model='service.record.valorUnitario'
            :validations="validations.valorUnitario"
          />
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import Service from './ServicoService';

export default {
  data() {
    return {
      service: null,
      formValidation: null,
      valorTotalServico: 0,
      unidades: [
        { text: 'UN', value: 'UN' },
        { text: 'M3', value: 'M3' },
        { text: 'ML', value: 'ML' },
        { text: 'M', value: 'M' },
        { text: 'CM', value: 'CM' },
        { text: '%', value: '%' },
        { text: 'DIAS', value: 'DIAS' },
        { text: 'MES', value: 'MES' },
        { text: 'ANO', value: 'ANO' },
      ],
      validations: {
        nome: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        un: [
          {
            required: true,
            message: 'UN é obrigatório',
            trigger: 'blur',
          },
        ],
        valorUnitario: [
          {
            required: true,
            message: 'Valor unitário é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    calculaValorUnitario() {
      this.service.record.valorUnitario = _.round(this.valorTotalServico / this.service.record.diasValidade, 0);
    },
  },
  created() {
    this.service = new Service();
  },
};
</script>
