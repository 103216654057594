<template>
  <f-crud-form
    v-if="service"
    v-on="$listeners"
    :schema="service"
    :formsValidation="[$refs.formValidation]"
  >
    <f-row>
      <f-col col="12">
        <f-form ref="formValidation">
          <f-form-row>
            <f-textbox caption='Nome' v-model='service.record.nome' :validations="validations.nome" width="450px"/>
          </f-form-row>
          <f-textbox
            caption='CFOP Saída dentro estado'
            v-model='service.record.cfopSaida'
            width="200px"
            mask="####"
            :onlyNumber="true"
            :maxlength="4"
          />
          <f-tabs class="h-44">
            <f-tab-pane label="ICMS">
              <f-form-row>
                <f-combobox
                  caption='ICMS CST'
                  v-model='service.record.icmsCstNormal'
                  :options="icmsCstOuCson"
                  :validations="validations.required"
                />
                <f-combobox
                  caption="Origem"
                  v-model='service.record.icmsOrigem'
                  :options="$store.state.dfe.icmsOrigem"
                />
              </f-form-row>
              <f-form-row>
                <f-numberbox caption='ICMS Alíquota' v-model='service.record.icmsAliquota'/>
                <f-numberbox caption='ICMS Redução da Base' v-model='service.record.icmsReducaoBase'/>
                <f-numberbox caption='ICMS Crédito %' v-model='service.record.icmsCreditoPercentual'/>
              </f-form-row>
              <p>ICMS ST:</p>
              <f-form-row>
                <f-combobox
                  caption='Modalidade Base Cálculo ST'
                  v-model='service.record.icmsStModalidadeCalculo'
                  :options="icmsStModalidadeCalculoOpcoes"
                />
                <f-numberbox caption='ICMS ST Alíquota' v-model='service.record.icmsCreditoPercentual'/>
                <f-numberbox caption='Margem Valor Adici. (MVA)' v-model='service.record.icmsCreditoPercentual'
                />
              </f-form-row>
            </f-tab-pane>
            <f-tab-pane label="IPI">
              <f-form-row>
                <f-combobox caption='IPI CST' v-model='service.record.ipiCst' :options="ipiCst"/>
                <f-numberbox caption='IPI Alíquota %' v-model='service.record.ipiAliquota'/>
              </f-form-row>
            </f-tab-pane>
            <f-tab-pane label="PIS">
              <f-form-row>
                <f-combobox caption='PIS CST' v-model='service.record.pisCst' :options="pisCofinsCst"/>
                <f-numberbox caption='PIS Alíquota %' v-model='service.record.pisAliquota'/>
              </f-form-row>
            </f-tab-pane>
            <f-tab-pane label="COFINS">
              <f-form-row>
                <f-combobox caption='COFINS CST' v-model='service.record.cofinsCst' :options="pisCofinsCst"/>
                <f-numberbox caption='COFINS Alíquota %' v-model='service.record.cofinsAliquota'/>
              </f-form-row>
            </f-tab-pane>
          </f-tabs>
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>

import TributacaoService from './TributacaoService';

export default {
  components: {},
  data() {
    return {
      mode: '',
      service: null,
      validations: {
        nome: [
          {
            min: 3,
            max: 120,
            message: 'Nome do cadastro da tributacao deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
          {
            required: true,
            message: 'Nome do cadastro da tributacao é obrigatório',
            trigger: 'blur',
          },
        ],
        required: [
          { required: true, message: 'Campo obrigatório', trigger: 'blur' },
        ],
      },
      icmsStModalidadeCalculoOpcoes: [
        { text: 'Preço tabelado ou máximo sugerido', value: 0 },
        { text: 'Lista Negativa (valor)', value: 1 },
        { text: 'Lista Positiva (valor)', value: 2 },
        { text: 'Lista Neutra (valor)', value: 3 },
        { text: 'Margem Valor Agregado (%)', value: 4 },
        { text: 'Pauta (valor)', value: 5 },
      ],
    };
  },
  computed: {
    icmsCstOuCson() {
      return this.$store.getters.icmsCstOuCson;
    },
    ipiCst() {
      if (!this.$store.state.dfe.ipiCst) {
        return [];
      }
      return this.$store.state.dfe.ipiCst;
    },
    pisCofinsCst() {
      if (!this.$store.state.dfe) {
        return [];
      }
      return this.$store.state.dfe.pisCofinsCst;
    },
  },
  created() {
    this.service = new TributacaoService();
  },
};
</script>
