<style scoped>
.error-divider {
  margin-top: 0px;
}
</style>

<template>
  <div class="flex mb-2">
    <hr v-if="showDivider" class="error-divider" />
    <f-row class="text-small w-full">
      <f-col class="text-small w-full">
        <f-alert type="success" v-if="success.length">
          <div v-for="msg in success" v-bind:key="msg">
            - {{ msg }}
          </div>
        </f-alert>
        <f-alert type="error" v-if="errors.length">
          <div v-for="msg in errors" v-bind:key="msg">
            - {{ msg }}
          </div>
        </f-alert>
        <f-alert type="info" v-if="info.length">
          <div v-for="msg in info" v-bind:key="msg">
            - {{ msg }}
          </div>
        </f-alert>
      </f-col>
    </f-row>
  </div>
</template>

<script>
// component to show many errors at same time
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    success: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Array,
      default: () => [],
    },
    showDivider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
