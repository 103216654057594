<template>
  <f-row>
    <f-col>
      <f-dialog
        :value="value"
        width="70%"
        :closeOnClickOutside="true"
        title="Adicionar uma nova empresa"
        @input="$emit('input', $event)"
      >
        <f-form ref="formValidation" v-if="value" v-loading="service.loading">
          <f-form-row>
            <cpfCnpj v-model='service.record.cpfCnpj' @dados="setDados" :onlyCnpj="true"/>
          </f-form-row>
          <f-alert type="warning">
            Ao adicionar uma nova empresa, uma nova licença será necessária
          </f-alert>
          <div v-if="mostraDemaisDados">
            <f-textbox
              caption='Razão Social'
              v-model='service.record.name'
              :validations="validations.required"
            />
            <f-textbox
              caption='Nome Fantasia'
              v-model='service.record.name2'
              :validations="validations.required"
            />
            <f-form-row>
              <f-col col="12" class="mt-5 mb-5 flex">
                <f-button caption="Cadastrar nova empresa" @click="save">
                  <FontAwesomeIcon icon="save"/>
                </f-button>
              </f-col>
            </f-form-row>
          </div>
        </f-form
        >
      </f-dialog>
    </f-col>
  </f-row>
</template>

<script>

import Service from './OrgService';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      msgAguarde: 'Aguarde...',
      service: null,
      mostraDemaisDados: false,
      validations: {
        required: [
          {
            required: true,
            message: 'Campo obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    save() {
      if (this.$refs.formValidation && !this.$refs.formValidation.validate()) {
        return;
      }
      this.service.save()
        .then(() => {
          this.$showMessage({
            title: '', type: 'success', message: 'Empresa adicionada com sucesso', time: 2000,
          });
        })
        .catch((err) => {
          this.$showMessage({
            title: 'Empresa não adicionada', type: 'error', message: err, time: 4500,
          });
        });
    },
    setDados(dados) {
      this.mostraDemaisDados = true;
      if (!this.service.record.id) {
        this.service.record.name = dados.nome;
        this.service.record.name2 = dados.nome2;
        this.service.record.cpfCnpj = _.string().onlyNumber(dados.cnpj);
      }
    },
    reset() {
      this.service.record.name = null;
      this.service.record.name2 = null;
      this.service.record.cpfCnpj = null;
      if (this.$refs.formValidation) {
        this.$refs.formValidation.reset();
      }
      this.mostraDemaisDados = false;
    },
  },
  created() {
    this.service = new Service();
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.reset();
      }
    },
  },
};
</script>
