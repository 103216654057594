<template>
  <div class="flex flex-col items-center max-w-md md:flex-grow-0 w-1/2 flex-grow bg-white p-5 shadow-md rounded-lg">
    <img src="./assets/logo-192x192.png" alt="" width="128px" height="128px">
    <div>SIGEFLEX ERP</div>
    <div class="flex flex-col items-center content-center w-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LoginService from './loginService';

export default {
  name: 'LoginArea',
  created() {
    this.revenda = localStorage.getItem('revenda');
    if (this.$route.query.revenda) {
      this.revenda = this.$route.query.revenda;
      localStorage.setItem('revenda', this.revenda);
    }
    this.service = new LoginService();
    this.i18n = this.$newI18n(this.messages);
  },
  data() {
    return {
      revenda: '',
      messages: {
        pt: {
          loginTitle: 'Login',
          loginSubTitle: 'Login',
          loginSuccess: 'Login efetuado com sucesso....',
          forgotPassword: 'Esqueci a senha',
          forgotPasswordTitle: 'Recuperacao de Senha',
          forgotButtonCaption: 'Receber senha de recuperação',
          confirmationButtonCaption: 'Confirmar Código',
          confirmationFieldCodeCaption: 'Código',
          recoveryButtonCaption: 'Alterar senha',
          confirmationTitle: 'Confirmação de código',
          changePasswordTitle: 'Alteração de senha',
          changePasswordSubTitle: 'Altere sua senha para maior segurança',
          register: 'Registrar-se',
          fieldEmail: 'E-mail',
          fieldPassword: 'Senha',
          fieldName: 'Nome',
          fieldOrg: 'Organização',
          fieldCnpj: 'CNPJ',
          loginButton: 'Login',
          signedUpButton: 'Registrar uma nova empresa',
          signedUpButtonConfirm: 'Confirmar registro da nova empresa?',
          backLoginPage: 'Voltar a página de login.',
          passwordPlaceHolder: '-',
        },
      },
      model: {
        email: '',
        password: '',
        loading: false,
        org: '',
        cnpj: '',
        service: {},
      },
      showConfirmation: false,
      showSearshCnpjResponse: false,
      organization: {
        cnpj: null,
        nomeFantasia: null,
        razaoSocial: null,
        ie: null,
        cep: null,
        rua: null,
        numero: null,
        bairro: null,
        cidade: null,
        cidadeCodigo: null,
        uf: null,
        contatoEmail: null,
        contatoTelefone: null,
        simples: false,
      },
      i18n: {},
      successMsg: [],
      errorMsg: [],
      infoMsg: [],
      validations: {
        email: [
          {
            required: true,
            message: 'Insira um email válido',
            trigger: 'blur',
          },
          {
            min: 7,
            max: 120,
            message: 'Email deve ter entre 7 e 120 caracteres',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Insira um email válido',
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: 'Insira um nome válido',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Email deve ter entre 3 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        org: [
          {
            required: true,
            message: 'Insira um nome de Organização válida',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Org deve ter entre 3 e 60 caracteres',
            trigger: 'blur',
          },
        ],
        cnpj: [
          {
            required: true,
            message: 'Insira o CNPJ da Organização',
            trigger: 'blur',
          },
          {
            min: 14,
            max: 14,
            message: 'O CNPJ deve ter 14 caracteres',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'Insira uma senha',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 30,
            message: 'Senha deve ter entre 4 e 30 caracteres',
            trigger: 'blur',
          },
        ],
        confirmationCode: [
          {
            required: true,
            message: 'Código confimação é obrigatório',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 4,
            message: 'Código deve ter 4 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    resetMessages() {
      this.successMsg = [];
      this.errorMsg = [];
      this.infoMsg = [];
    },
    routeToLogin(time) {
      setTimeout(() => {
        this.$router.push({
          name: 'Login',
          params: {
            username: this.model.email,
            password: this.model.password,
          },
        });
      }, time);
    },
  },
};
</script>
