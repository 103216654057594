import Dataset from 'comps/libs/dataset';

const getTributacaoFields = () => `
        id
        createdAt
        nome
        cfopSaida
        icmsCstSimplesNacional
        icmsCstNormal
        icmsOrigem
        icmsModalidadeCalculo
        icmsAliquota
        icmsReducaoBase
        icmsCreditoPercentual
        icmsStModalidadeCalculo
        ipiCst
        ipiAliquota
        ipiReducaoBase
        ipiClasseEnquadramento
        ipiCodigoEnquadramento
        ipiCodigoSeloControle
        pisCst
        pisAliquota
        pisReducaoBase
        pisStAliquota
        pisStReducaoBase
        cofinsCst
        cofinsAliquota
        cofinsReducaoBase
        cofinsStAliquota
        cofinsStReducaoBase
    `;

class TributacaoService extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      { name: 'nome', caption: 'Nome' },
    ];
    this.findFilters = [
      {
        type: 'textbox',
        width: '400px',
        label: 'Consulta por Nome',
        name: 'main',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      nome: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;

    return `query {
              TributacaoList(${filter}) {
                id
                createdAt
                nome
                cfopSaida
                icmsCstSimplesNacional
                icmsCstNormal
                icmsOrigem
                icmsModalidadeCalculo
                icmsAliquota
                icmsReducaoBase
                icmsCreditoPercentual
                icmsStModalidadeCalculo
                ipiCst
                ipiAliquota
                ipiReducaoBase
                ipiClasseEnquadramento
                ipiCodigoEnquadramento
                ipiCodigoSeloControle
                pisCst
                pisAliquota
                pisReducaoBase
                pisStAliquota
                pisStReducaoBase
                cofinsCst
                cofinsAliquota
                cofinsReducaoBase
                cofinsStAliquota
                cofinsStReducaoBase
              }
           }`;
  }

  getByIds(ids) {
    return `query {
              TributacaoList(ids: [${ids.join()}]) {
                ${getTributacaoFields()}
            }
          }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation TributacaoCreate($data: TributacaoInputType!) {
          TributacaoCreate(data: $data){
            ${getTributacaoFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation TributacaoUpdate($id: String!, $data: TributacaoInputType!) {
          TributacaoUpdate(id: $id, data: $data){
            ${getTributacaoFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation TributacaoDelete($id: String!){
           TributacaoDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      nome: model.nome,
      cfopSaida: model.cfopSaida,
      icmsOrigem: model.icmsOrigem,
      icmsCstSimplesNacional: model.icmsCstSimplesNacional,
      icmsCstNormal: model.icmsCstNormal,
      icmsModalidadeCalculo: model.icmsModalidadeCalculo,
      icmsAliquota: model.icmsAliquota,
      icmsReducaoBase: model.icmsReducaoBase,
      icmsCreditoPercentual: model.icmsCreditoPercentual,
      icmsStModalidadeCalculo: model.icmsStModalidadeCalculo,
      ipiCst: model.ipiCst,
      ipiAliquota: model.ipiAliquota,
      ipiReducaoBase: model.ipiReducaoBase,
      ipiClasseEnquadramento: model.ipiClasseEnquadramento,
      ipiCodigoEnquadramento: model.ipiCodigoEnquadramento,
      ipiCodigoSeloControle: model.ipiCodigoSeloControle,
      pisCst: model.pisCst,
      pisAliquota: model.pisAliquota,
      pisReducaoBase: model.pisReducaoBase,
      pisStAliquota: model.pisStAliquota,
      pisStReducaoBase: model.pisStReducaoBase,
      cofinsCst: model.cofinsCst,
      cofinsAliquota: model.cofinsAliquota,
      cofinsReducaoBase: model.cofinsReducaoBase,
      cofinsStAliquota: model.cofinsStAliquota,
      cofinsStReducaoBase: model.cofinsStReducaoBase,
    };
  }
}

export default TributacaoService;
