<template>
  <div
    v-loading="service.loading"
    element-loading-text="Aguarde, Obtendo status junto ao SEFAZ..."
    element-loading-spinner="el-icon-loading"
  >
    <f-button caption="Clique aqui para obter o Status" class="ml-3" @click="getWsStatus">
      <FontAwesomeIcon icon="sync" />
    </f-button>
    <p class="font-serif">UF: {{$store.getters.config.endUf}}</p>
    <f-tag :effect="effect" :tags="[`${tipoLegivel}: ${status.nfeXMotivo}`]" :type="getTypeFromBol(status.online)"/>
  </div>
</template>

<script>

import Service from '../ConfigService';

export default {
  name: 'ConsultaStatusWs',
  props: {
    tipo: {
      type: String,
      default: 'nfe',
    },
  },
  data() {
    return {
      service: new Service(),
      effect: '',
      status: {
        online: true,
        nfeXMotivo: 'Aguardando consulta... (Clique no botão de consultar)',
      },
    };
  },
  computed: {
    tipoLegivel() {
      let tipo = 'NF-e';
      if (this.tipo === 'nfce') {
        tipo = 'NFC-e';
      }
      if (this.tipo === 'mdfe') {
        tipo = 'MDF-e';
      }
      return tipo;
    },
  },
  mounted() {
    this.status.nfeXMotivo = 'Aguardando consulta... (Clique no botão de consultar)';
    this.effect = 'light';
  },
  methods: {
    getTypeFromBol(status) {
      let type = 'danger';
      if (status) {
        type = 'success';
      }
      return type;
    },
    getWsStatus() {
      this.effect = 'dark';
      this.status.online = true;
      this.status.nfeXMotivo = 'Consultando. Aguarde...';
      this.service.getWsStatus(this.tipo)
        .then((data) => {
          this.status.online = data.record.nfe;
          this.status.nfeXMotivo = 'SEFAZ paralizado ou não respondeu';
          if (data.record.nfeXMotivo) {
            this.status.nfeXMotivo = data.record.nfeXMotivo;
          }
        })
        .catch((err) => {
          this.status.online = false;
          this.status.nfeXMotivo = err;
          this.$showMessage({ title: 'Ocorreu um erro ao consultar o SEFAZ!', type: 'error', message: err });
        });
    },
  },
};
</script>
