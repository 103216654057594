<template>
  <LoginArea class="login-area">
    <div class="flex flex-col justify-center content-center">
      <h2 class="f-title-1 text-center">{{ i18n.get('changePasswordTitle') }}</h2>
      <p class="f-subtitle-1">{{ i18n.get('changePasswordSubTitle') }}</p>
    </div>

    <f-row class="w-full">
      <f-col col="12 flex justify-center">
        <f-form
          class="login-form"
          v-loading="service.loading"
          :model="model"
          ref="form"
          @submit.native.prevent="change"
        >
          <span v-show="successMsg.length === 0">
            <f-textbox
              type="email"
              id="email"
              v-model="model.email"
              icon="fas fa-user"
              :caption="i18n.get('fieldEmail')"
              :validations="validations.email"
            />
            <f-textbox
              v-model="model.password"
              id="password"
              type="password"
              icon="fas fa-lock"
              :caption="i18n.get('fieldPassword')"
              :validations="validations.password"
            />
            <f-textbox
              v-model="model.newPassword"
              id="newPassword"
              type="password"
              icon="fas fa-lock"
              caption="Nova senha"
              :validations="validations.password"
            />
            <f-textbox
              v-model="model.repeatedNewPassword"
              id="repeatedNewPassword"
              type="password"
              icon="fas fa-lock"
              caption="Repetir nova senha"
              :validations="validations.password"
            />
            <f-button
              :caption="i18n.get('recoveryButtonCaption')"
              @click="change"
            />
          </span>
          <f-alert-block
            v-if="successMsg.length || errorMsg.length"
            :success="successMsg"
            :errors="errorMsg"
          />
          <f-link url="/login">{{ i18n.get('backLoginPage') }}</f-link>
        </f-form>
      </f-col>
    </f-row>
  </LoginArea>
</template>

<script>
import LoginArea from './LoginArea';

export default {
  name: 'ForgotPasswordPage',
  mixins: [
    LoginArea,
  ],
  components: {
    LoginArea,
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        newPassword: '',
        repeatedNewPassword: '',
        loading: false,
      },
    };
  },
  created() {
    if (this.$route.params) {
      this.model.email = this.$route.params.email;
    }
  },
  methods: {
    async change() {
      this.resetMessages();
      const valid = await this.$refs.form.validate();
      if (!valid) {
        return;
      }
      this.model.loading = true;
      this.service.changePassword(this.model.email, this.model.password, this.model.newPassword,
        this.model.repeatedNewPassword)
        .then((response) => {
          this.successMsg = [response.data];
          this.model.password = ''; // para nao tentar efeutuar login com a senha antiga
          setTimeout(() => {
            this.routeToLogin();
          }, 2000);
        })
        .catch((err) => {
          this.errorMsg = [err.message];
        });
    },
  },
};
</script>
