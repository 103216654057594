export default class NumberUtil {
  static parseFloat(value) {
    return parseFloat(value);
  }

  static formataDinheiro(numero, code) {
    if (!_.toNumber(numero)) {
      numero = 0;
    }
    return `${this.getCurrencySymbol(code)} ${this.formataNumero(numero, 2)}`;
  }

  static formataNumero(numero, digitos) {
    if (!_.toNumber(numero)) {
      numero = 0;
    }
    return `${numero.toFixed(digitos || 2) // casas decimais
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
  }

  static getCurrencySymbol(code) {
    switch (code) {
      case 'USD':
        return '$';
      default:
        return 'R$';
    }
  }
}
