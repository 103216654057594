import ObjectUtil from './object';
import StringUtil from './string';
import DatetimeUtil from './datetime';
import ArrayUtil from './arrays';
import FileUtil from './file';
import NumberUtil from './number';
import DataUtil from './data';
import General from './general';

export default class Util {
  static Object = ObjectUtil;

  static String = StringUtil;

  static Datetime = DatetimeUtil;

  static File = FileUtil;

  static Number = NumberUtil;

  static Array = ArrayUtil;

  static Data = DataUtil;

  static General = General;
}
