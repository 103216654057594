<template>
  <div class="flex" v-show="caption">
    <label class="font-serif text-sm">
      {{ caption }}
    </label>
    <span v-if="isRequired" class="ml-1 text-red-500">*</span>
    <f-popover v-if="helpMessage" :title="helpTitle" :content="helpMessage" trigger="click">
      <FontAwesomeIcon
        icon="question-circle"
        class="m-0 ml-1 text-blue-500 relative -top-2 text-xs"
        size="sm"
      />
    </f-popover>
  </div>
</template>

<script>

export default {
  name: 'fInputLabel',
  props: {
    caption: {
      type: String,
      default: '',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    helpTitle: {
      type: String,
      default: '',
    },
    helpMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
