// esse arquivo é utilizado para guardar os mais variados dados da aplicação
export default class ObjectUtil {
  // guarda os dados de UF no formato de texto e valor, ideal para usar em combobox
  static ufsTextValue = [
    { value: '', text: '' },
    { value: 'AC', text: 'AC' },
    { value: 'AL', text: 'AL' },
    { value: 'AP', text: 'AP' },
    { value: 'AM', text: 'AM' },
    { value: 'BA', text: 'BA' },
    { value: 'CE', text: 'CE' },
    { value: 'DF', text: 'DF' },
    { value: 'ES', text: 'ES' },
    { value: 'GO', text: 'GO' },
    { value: 'MA', text: 'MA' },
    { value: 'MT', text: 'MT' },
    { value: 'MS', text: 'MS' },
    { value: 'MG', text: 'MG' },
    { value: 'PA', text: 'PA' },
    { value: 'PB', text: 'PB' },
    { value: 'PR', text: 'PR' },
    { value: 'PE', text: 'PE' },
    { value: 'PI', text: 'PI' },
    { value: 'RJ', text: 'RJ' },
    { value: 'RN', text: 'RN' },
    { value: 'RS', text: 'RS' },
    { value: 'RO', text: 'RO' },
    { value: 'RR', text: 'RR' },
    { value: 'SC', text: 'SC' },
    { value: 'SP', text: 'SP' },
    { value: 'SE', text: 'SE' },
    { value: 'TO', text: 'TO' },
  ]
}
