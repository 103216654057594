<style lang="scss">

.f-field {
  margin: 0 4px 4px 0 !important;
}

.f-invalid > * input {
  border-color: #EF4444;
}

.uppercase {
  input,textarea {
    text-transform: uppercase;
  }
}

.lowercase {
  input,textarea {
    text-transform: lowercase;
  }
}

</style>

<template>
  <div class="f-field flex flex-col" :style="internalFieldStyle">
    <div>
      <f-label
        :caption="caption"
        :isRequired="isRequired"
        :helpTitle="helpTitle"
        :helpMessage="helpMessage"
      />
    </div>
    <div :class="classes()">
      <el-input
        v-if="mask"
        autocomplete="disabled"
        ref="input"
        :id="generatedId()"
        :maxlength="maxlength"
        :type="internalType"
        :value="value"
        :size="size"
        :autofocus="autofocus"
        :disabled="isDisabled"
        :readonly="readonly"
        :placeholder="placeholder"
        @blur="onBlur"
        v-mask="mask"
        @focus="onFocus"
        @input="input"
        @keyup.native="keyup"
        @keypress.native="keypress"
      />
      <el-input
        v-else
        autocomplete="disabled"
        ref="input"
        :id="generatedId()"
        :class="textTransformation"
        :maxlength="maxlength"
        :autosize="{ minRows: this.minRows, maxRows: this.maxRows}"
        :type="internalType"
        :value="value"
        :size="size"
        :autofocus="autofocus"
        :disabled="isDisabled"
        :readonly="readonly"
        :placeholder="placeholder"
        @focus="onFocus"
        @blur="onBlur"
        @input="input"
        @keyup.native="keyup"
        @keypress.native="keypress"
      />
      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
    </div>
  </div>
</template>

<script>
import { Input } from 'element-ui';
import { mask } from 'ke-the-mask';
import inputMixing from './InputMixing';

export default {
  mixins: [
    inputMixing,
  ],
  directives: { mask },
  components: {
    'el-input': Input,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: null,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    buttonClick: {
      type: Function,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    icon: {
      type: String,
      default: '',
    },
    mask: {
      type: [String, Array],
      default: null,
    },
    caseOption: {
      type: String,
      default: 'uppercase',
    },
    maxlength: {
      type: [String, Number],
      default: '',
    },
    onlyNumber: {
      type: Boolean,
      default: false,
    },
    minRows: {
      type: Number,
      default: 4,
    },
    maxRows: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    internalType() {
      if (this.type === 'email') {
        return 'text';
      }
      return this.type;
    },
    textTransformation() {
      if (this.type === 'password') {
        return 'mix';
      }
      if (this.caseOption === 'lowercase' || this.type === 'email') {
        return 'lowercase';
      }
      if (this.caseOption === 'uppercase') {
        return 'uppercase';
      }

      return '';
    },
  },
  methods: {
    onBlur($event) {
      this.$emit('blur', $event);
    },
    input($event) {
      this.$emit('input', $event);
    },
    // eslint-disable-next-line consistent-return
    isNumber(evt) {
      // eslint-disable-next-line no-param-reassign
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    keypress(event) {
      if (this.onlyNumber) {
        this.isNumber(event);
      }
      this.$emit('keypress', event);
    },
    keyup(event) {
      this.$emit('keyup', event);
    },
  },
};
</script>
