<template>
  <f-form>
    <h2>Emissao de relatórios do sistema:</h2>
    <div class="row" style="height: 65vh;">
      <div
        class="col-sm-6 col-md-4"
        :style="$store.getters.isMobile ? 'height: 40%;' : `height: ${getHeight};`"
      >
        <f-grid
          :style="`height: 100%;`"
          :selecionSingle="true"
          :data="gridData"
          :columns="[{title:'Lista de Relatórios', field:'titulo'}]"
          @change="selecionaRelatorio"
        >
        </f-grid>
      </div>
      <div
        class="col-sm-6 col-md-6"
        v-loading="service.loading"
        element-loading-text="Aguarde, gerando relatório..."
        element-loading-spinner="el-icon-loading"
        :style="$store.getters.isMobile ? 'height: 60%;' : `height: ${getHeight};`"
      >
        <f-panel v-if="relatorioAtual" class="p-5" :style="`height: 100%;`">
          <div slot="title">{{ relatorioAtual.nomeRelatorio }}</div>
          <div
            class="overflow-auto mb-5 border p-5"
            :style="this.$store.getters.pageSize.height < 900 ? 'height: 70%;': 'height: 80%;'"
          >
            <div v-for="field in relatorioAtual.campos" :key="field.caption">
              <f-combobox
                v-if="field.tipo==='tagbox' && isVisible(field)"
                v-model="field.value"
                :multiple="true"
                :caption="field.caption"
                :options="field.opcoes"
              />
              <f-combobox
                v-if="field.tipo==='combobox' && isVisible(field)"
                v-model="field.value"
                :caption="field.caption"
                :options="field.opcoes"
              />
              <f-textbox
                v-if="field.tipo==='string' || field.tipo==='textbox'"
                v-model="field.value"
                :caption="field.caption"
              />
              <f-checkbox
                v-if="field.tipo==='checkbox'"
                v-model="field.value"
                :caption="field.caption"
              />
              <f-row>
                <f-datetimebox
                  v-if="field.tipo==='datetime'"
                  v-model="field.value"
                  :caption="field.caption + ' A Partir'"
                  @input="inputDateTimeInicial($event, field)"
                />
                <f-datetimebox
                  v-if="field.tipo==='datetime'"
                  v-model="field.value2"
                  :caption="field.caption + ' Até'"
                  @input="inputDateTimeFinal($event, field)"
                />
              </f-row>
              <f-row>
                <f-datetimebox
                  v-if="field.tipo==='date'"
                  type="date"
                  v-model="field.value"
                  :caption="field.caption + ' A Partir'"
                />
                <f-datetimebox
                  v-if="field.tipo==='date'"
                  type="date"
                  v-model="field.value2"
                  :caption="field.caption + ' Até'"
                />
              </f-row>
              <f-row>
                <f-numberbox
                  v-if="field.tipo==='number'"
                  v-model="field.value"
                  :caption="field.caption + ' A Partir'"
                />
                <f-numberbox
                  v-if="field.tipo==='number'"
                  v-model="field.value2"
                  :caption="field.caption + ' Até'"
                />
              </f-row>
              <f-lookup
                v-if="field.tipo==='lookup'"
                v-model='field.value'
                :caption='field.caption'
                :component='field.component'
                :filters="{type: (field.filter || '')}"
                :fieldName="field.fieldName"
                :valueId="field.value2"
                :editable="false"
                @value="field.value2 = $event.id"
              />
            </div>

            <f-row>
              <f-combobox
                v-if="relatorioAtual.ordenacao"
                caption="Ordenar por"
                v-model="ordenacaoCampos"
                :options="relatorioAtual.ordenacao"
              />
              <f-combobox
                v-if="relatorioAtual.ordenacao"
                caption="Tipo Ordenacao"
                :options="[{text:'Crescente', value: 'asc'}, {text:'Decrescente', value:'desc'}]"
                v-model="ordenacaoDirecao"
              />
            </f-row>

          </div>
          <f-button caption="Gerar Relatório" @click="gerarRelatorio" />
          <f-button
            v-if="relatorioAtual.graphqlMailName"
            caption="Enviar por E-mail"
            @click="mostraEmail = !mostraEmail"
          />
          <f-alert type="error" v-if="semDados">Não há dados para mostrar com o critério escolhido</f-alert>
        </f-panel>
      </div>
      <f-dialog v-model="mostraEmail" width="40%" title="Enviar Relatório por E-mail">
        <f-form ref="enviaEmail">
          <f-textbox
            type="email"
            placeholder="Informe o endereço de email para envio do relatório"
            v-model='emailInterno'
            :validations="validations.email"
          />
          <f-button caption="Enviar Relatório por E-mail" @click="$refs.enviaEmail.validate() ? gerarRelatorio(): ''" />
        </f-form>
      </f-dialog>
    </div>
  </f-form>
</template>

<script>

export default {
  props: {
    service: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mostraEmail: false,
      emailInterno: null,
      ordenacao: null,
      ordenacaoCampos: '',
      ordenacaoDirecao: 'asc',
      relatorioAtual: null,
      semDados: false,
      validations: {
        email: [
          { required: true, trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    gridData() {
      const registros = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const relatorio of this.service.getReportConfig()) {
        registros.push({
          id: relatorio.id,
          titulo: relatorio.nomeRelatorio,
          descricao: relatorio.descricao,
        });
      }
      return registros;
    },
    getHeight() {
      if (this.$store.getters.pageSize.height > 700) {
        return '100%';
      }
      return `${(this.$store.getters.pageSize.height / 100) * 45}px`;
    },
  },
  methods: {
    isVisible(field) {
      if (field.visibleVue) {
        return field.visibleVue(this.$store);
      }
      return field.visible !== false;
    },
    inputDateTimeInicial(newvalue, field) {
      let data = this.moment(newvalue);
      if (data.isValid()) {
        data = data.format('DD/MM/YYYY 00:00:00');
      } else {
        data = null;
      }
      this.$set(field, 'value', data);
    },
    inputDateTimeFinal(newvalue, field) {
      let data = this.moment(newvalue);
      if (data.isValid()) {
        data = data.format('DD/MM/YYYY 23:59:59');
      } else {
        data = null;
      }
      this.$set(field, 'value2', data);
    },
    /* eslint-disable */
    getReportFilter() {
      let filter = '';
      for (const campo of this.relatorioAtual.campos) {
        if (campo.tipo === 'textbox') {
          if (campo.value) {
            filter += `${campo.campoFiltro}: "${campo.value}",`;
          }
          continue
        }


        if (campo.tipo === 'datetime') {
          if (campo.value) {
            filter += `${campo.campoFiltro}Gte: "${campo.value.toJSON()}",`;
          }

          if (campo.value2) {
            filter += `${campo.campoFiltro}Lte: "${campo.value2.toJSON()}",`;
          }
          continue
        }

        if (campo.tipo === 'date') {
          if (campo.value) {
            filter += `${campo.campoFiltro}Gte: "${campo.value.toJSON().replace('03:00:00', '00:00:00')}",`;
          }

          if (campo.value2) {
            filter += `${campo.campoFiltro}Lte: "${campo.value2.toJSON().replace('03:00:00', '23:59:59')}",`;
          }
          continue
        }

        if (campo.tipo === 'lookup') {
          if (campo.value) {
            filter += `${campo.campoFiltro}: "${campo.value2}",`;
          }
          continue
        }
        if (campo.tipo === 'checkbox') {
          if (campo.value) {
            filter += `${campo.campoFiltro}: ${campo.value},`;
          }
          continue
        }

        if (campo.tipo === 'number') {
          if (!isNaN(parseFloat(campo.value)) && campo.value !== '') {
            filter += `${campo.campoFiltro}Gte: ${campo.value},`;
          }

          if (!isNaN(parseFloat(campo.value2)) && campo.value2 !== '') {
            filter += `${campo.campoFiltro}Lte: ${campo.value2},`;
          }
          continue
        }

        if (campo.tipo === 'tagbox') {
          if (campo.value.length > 0) {
            filter += `${campo.campoFiltro}: [${_.array().quoteStingArray(campo.value)}],`;
          }
          continue
        }
        if (campo.tipo === 'combobox') {
          if (campo.value !== '') {
            filter += `${campo.campoFiltro}: "${campo.value || ''}",`;
          }
        }
      }
      if (this.ordenacaoCampos !== '') {
        filter += `orderBy: "${this.ordenacaoCampos || ''}", `;
        filter += `orderDirection: "${this.ordenacaoDirecao || ''}",`;
      }
      return filter;
    },
    /* eslint-disable */
    gerarRelatorio() {
      let filter = this.getReportFilter();
      if (this.mostraEmail && this.emailInterno) {
        filter += `mail: "${this.emailInterno}",`;
      }
      if (filter) {
        filter = filter.substring(0, filter.length - 1);
      }
      const query = `
        query {
          ${this.relatorioAtual.graphqlQueryName}(${filter}){
            htmlContent
            template
            dados
          }
        }`;
      this.getReport(query);
    },
    getReport(query) {
      this.semDados = false;
      this.service.gqlQuery(query)
        .then((response) => {
          this.semDados = response.result.dados === '' && response.result.htmlContent === '';
          if (this.semDados) {
            return
          }

          //se foi solicitado o envio por email, so mostre a mensagem de confirmacao e saia
          if (response.result.htmlContent === 'OK') {
            this.$showMessage({
              title: 'Envio de relatório por email',
              type: 'success',
              message: 'Seu relatório está sendo preparado. Aguarde a chegada do e-mail.',
            });
            this.mostraEmail = !this.mostraEmail;
            this.emailInterno = null;
            return;
          }

          if(response.result.dados) {
            this.$showReport('Impressão de Relatórios', response.result.template, response.result.dados);
          } else {
            this.$showReportHtml('Impressão de Relatórios', response.result.htmlContent);
          }
        })
        .catch((error) => {
          this.$showMessage({
            title: 'Impressão',
            type: 'error',
            message: `Erro ao imprimir : ${ error || error.message || error.code || 'unknown error'}`,
          });
        });
    },
    //selecionaRelatorio ao navegar nos relatorios no gird, essa funcao seta o relatorio atual
    selecionaRelatorio(registro) {
      // eslint-disable-next-line no-plusplus
      for (const config of this.service.getReportConfig()) {
        if (config.id === registro[0].id) {
          this.relatorioAtual = config;
          this.ordenacaoCampos = '';
          if (config.ordenacao) {
            this.ordenacaoCampos = config.ordenacao[0].value;
          }
        }
      }
    }
  },
};
</script>
