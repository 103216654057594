<template>
  <LoginArea>
    <div class="flex flex-col justify-center content-center">
      <h2 class="f-title-1 text-center">{{ i18n.get('loginTitle') }}</h2>
      <p class="f-subtitle-1">Acesse o sistema</p>
    </div>

    <f-row class="w-full">
      <f-col
        col="12 flex justify-center"
        v-loading="service.loading"
        element-loading-text="Aguarde..."
        element-loading-spinner="el-icon-loading"
      >
        <f-form
          ref="form"
          class="login-form"
          @submit="login"
        >
          <f-textbox
            ref="email"
            type="email"
            id="email"
            size="medium"
            v-model="model.email"
            icon="fas fa-user"
            :autofocus="true"
            :caption="i18n.get('fieldEmail')"
            :validations="validations.email"
          />
          <f-textbox
            ref="password"
            v-model="model.password"
            id="password"
            type="password"
            size="medium"
            icon="fas fa-lock"
            caseOption=""
            :caption="i18n.get('fieldPassword')"
            :validations="validations.password"
          />
          <f-alert-block
            v-if="successMsg.length || errorMsg.length"
            :success="successMsg"
            :errors="errorMsg"
          />
          <f-link
            class="text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            @click="login"
          >
            {{ i18n.get('loginButton') }}
          </f-link>
          <f-link class="text-center mt-5" url="/forgot">
            {{ i18n.get('forgotPassword') }}
          </f-link>
          <hr class="m-5">
          <f-link
            class="text-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            url="/registration"
          >
            {{ i18n.get('signedUpButton') }}
          </f-link>
        </f-form>
      </f-col>
    </f-row>
  </LoginArea>
</template>

<script>
import LoginArea from './LoginArea';

require('./login.scss');

export default {
  name: 'LoginPage',
  mixins: [
    LoginArea,
  ],
  components: {
    LoginArea,
  },
  props: {
    enabledRegister: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    if (this.$route.params) {
      this.model.email = this.$route.params.username;
      this.model.password = this.$route.params.password;
    }
  },
  mounted() {
    if (this.model.email && this.model.password) {
      this.login();
    }
  },
  methods: {
    login() {
      this.resetMessages();
      const valid = this.$refs.form.validate();
      if (!valid) {
        if (!this.model.email) {
          this.$refs.email.focus();
          return;
        }
        this.$refs.password.focus();
        return;
      }
      this.model.loading = true;
      this.successMsg = [];
      this.errorMsg = [];
      this.service.login(this.model.email, this.model.password)
        .then((response) => {
          this.successMsg = [this.i18n.get('loginSuccess')];
          setTimeout(() => {
            window.sessionStorage.setItem('infoLogin', JSON.stringify(response.data));
            this.$store.dispatch('setInfoLogin', response.data);
            this.$store.dispatch('setCurrentOrg', response.data.currentOrg.id);
            this.$router.push({
              name: 'Home',
            });
          }, 800);
        })
        .catch((err) => {
          if (err.code === 'MISSING_CONFIRMATION') {
            setTimeout(() => {
              this.$router.push({
                name: 'Confirmation',
                params: {
                  username: this.model.email,
                },
              });
            }, 1000);
          } else if (err.code === 'CHANGE_PASSWORD') {
            setTimeout(() => {
              this.$router.push({
                name: 'ChangePassword',
                params: {
                  email: this.model.email,
                },
              });
            }, 1000);
          }
          this.errorMsg = [err.message];
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.login-form {
  width: 80%;
}

.forgot-password {
  margin-top: 10px;
}

.login-area {
  .login-form {
    .row-footer {
      display: flex;
      justify-content: space-between;
    }
  }

}

$teal: rgb(0, 124, 137);
.el-button--primary {
  background: $teal;
  border-color: $teal;

  &:hover,
  &.active,
  &:focus {
    background: lighten($teal, 7);
    border-color: lighten($teal, 7);
  }
}

</style>
