<style lang="scss">
.navigator {
  display: flex;

  & * {
    margin: 0 5px 0 0;
  }
}

.public-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

.container-fluid {
  height: inherit;
  margin: 0;
  padding: 0;

}

.tabs {
  height: 85vh;
  min-height: 85vh;
}

.active {
  background-color: #10B981;
  color: white;
  border: transparent;
}
</style>

<template>
  <div class="container-fluid">
    <f-row v-if="isAuthenticated === false || isPublicRoute" class="public-view">
      <f-col col="12" class="public-view bg-gray-100">
        <router-view></router-view>
      </f-col>
    </f-row>

    <Layout1 v-else>
      <span slot="header">
         <AppHeader/>
      </span>
      <div class="shadow p-1">
        <button
          icon="el-icon-close"
          v-for="(item) in openedPage"
          :key="item.route"
          :class="{'active' : item.route === activeTab}"
          style="position: relative; padding-right: 30px"
          class="border-gray-200 hover:bg-blue-500 text-black-500
          hover:text-white py-2 px-4 border hover:border-transparent
          rounded mr-1 p-2 px-4 rounded pr-15"
          @click="routeTo(item.route)"
          @click.middle="item.route !== '/' ? removeTab(item.route): ''"
        >
          {{ item.title }}
          <FontAwesomeIcon
            v-if="item.route !=='/'"
            icon="times-circle"
            size="sm"
            style="position:absolute; top: 2px; right: 10px"
            :class="{'text-red-300': item.route !== activeTab}"
            @click.stop="removeTab(item.route)"
          />
        </button>
      </div>
      <div class="">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
      <div slot="menu">
        <AppUserInfo v-if="$store.getters.isMobile" class="m-5"/>
        <hr>
        <AppMenu :menu="data.menu" @menu-click="openTab($event.text, $event.route)"/>
      </div>

      <span slot="system-info">
       <AppSystemInfo :data="data.appInfo"/>
      </span>

      <span slot="user-info">
       <AppUserInfo v-if="!$store.getters.isMobile"/>
      </span>

      <div slot="footer" class="w-full h-full justify-center text-center items-center">
        <AppFooter/>
      </div>
      <ReportView/>
    </Layout1>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import AppSystemInfo from './AppSystemInfo';
import AppUserInfo from './AppUserInfo';
import AppMenu from './AppMenu';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import VuexAppModule from './vuexAppModule';
import Layout1 from '../layouts/Layout1';
import ReportView from '../../apps/report/ReportView';

library.add(faTimesCircle);
require('./app.scss');

export default {
  components: {
    Layout1,
    AppSystemInfo,
    AppUserInfo,
    AppMenu,
    AppHeader,
    AppFooter,
    ReportView,
  },
  name: 'App',
  props: {
    data: {
      type: Object,
      default: () => ({
        appInfo: {
          appName: '',
        },
        menu: [],
      }),
    },
  },
  data() {
    return {
      activeTab: '/',
      openedPage: [
        {
          title: 'Home',
          route: '/',
          closable: false,
        },
      ],
    };
  },
  computed: {
    isPublicRoute() {
      return this.$route.meta.requiresAuth === false;
    },
    isAuthenticated() {
      return this.$store.getters.infoLogin && this.$store.getters.infoLogin.token !== '';
    },
  },
  beforeCreate() {
    this.$store.registerModule('app', VuexAppModule);
  },
  created() {
    this.setSessionData();
    const menu = this.data.menu.filter((item) => item.route === this.$route.path)[0];
    if (menu) {
      this.openTab(menu.text, menu.route);
    }
  },
  mounted() {
    window.onresize = this.onResize;
    this.onResize();
    if (this.isAuthenticated) {
      this.openTab(this.$route.name, this.$route.path);
      this.$emit('open', true);
    }
  },
  methods: {
    routeTo(route) {
      if (this.$route.path === route) {
        return;
      }
      this.$router.push(route);
      this.activeTab = route;
    },
    openTab(text, route) {
      if (this.isAuthenticated) {
        // necessario para o caso de está em modo de celular
        this.$store.dispatch('setShowMenu', false);
        const exists = this.openedPage.filter((item) => item.route === route);
        if (exists.length === 0) {
          this.openedPage.push({
            title: text,
            route,
            closable: true,
          });
        }
        this.activeTab = route;
      }
    },
    removeTab(targetName) {
      const tabs = this.openedPage;
      let activeName = this.activeTab;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.route === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.route;
            }
          }
        });
      }
      this.openedPage = tabs.filter((tab) => tab.route !== targetName);
      this.routeTo(activeName);
    },
    // funcao para setar os dados que sao gravados na sessao
    setSessionData() {
      if (!this.$store.getters.infoLogin.token) {
        const infoLogin = window.sessionStorage.getItem('infoLogin');
        if (infoLogin) {
          this.$store.dispatch('setInfoLogin', JSON.parse(infoLogin));
        }
      }
      if (!this.$store.getters.currentOrg.id) {
        const currentOrgSession = window.sessionStorage.getItem('currentOrg');
        if (currentOrgSession && currentOrgSession !== 'undefined') {
          const org = JSON.parse(currentOrgSession);
          this.$store.dispatch('setCurrentOrg', org.id);
        }
      }
    },
    onResize() {
      this.$store.dispatch('setPageSize', { width: window.innerWidth, height: window.innerHeight });
    },
  },
  watch: {
    $route(to) {
      this.openTab(to.name, to.path);
    },
    isAuthenticated(newValue) {
      if (newValue) {
        this.$emit('open', true);
      }
    },
  },
};
</script>
