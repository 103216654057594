<template>
  <el-button
    :size="size"
    :type="type"
    :disabled="disabled"
    :round="round"
    :icon="icon"
    :circle="circle"
    :plain="plain"
    value="Submit"
    @click="$emit('click', $event)"
  >
    <slot></slot>
    {{ caption }}
    <slot name="right"></slot>
  </el-button>
</template>

<script>
import { Button } from 'element-ui';

export default {
  name: 'f-button',
  components: {
    'el-button': Button,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    width: {
      default: 'auto',
    },
    type: {
      type: String,
      default: 'primary',
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    size: {
      default: 'mini',
    },
  },
  computed: {
    buttonClass() {
      const classes = [];
      switch (this.type) {
        case 'success':
          classes.push('p-button-success');
          break;
        case 'info':
          classes.push('p-button-success');
          break;
        case 'danger':
          classes.push('p-button-danger');
          break;
        case 'warning':
          classes.push('p-button-warning');
          break;
        case 'secondary':
          classes.push('p-button-secondary');
          break;
        default:
          classes.push('p-button-primary');
      }

      switch (this.size) {
        case 'sm':
          classes.push('p-button-sm');
          break;
        case 'lg':
          classes.push('p-button-lg');
          break;
        default:
          classes.push('p-button-primary');
      }

      if (this.rounded) {
        classes.push('p-button-raised p-button-rounded');
      }

      if (this.isLink) {
        classes.push('p-button-link');
      }
      return classes;
    },
  },
};
</script>

<style scoped>

.el-button--primary {
  background: #409EFF;
  border-color: #409EFF;
}

.f-button {
  margin: 5px 3px 3px 3px;
}
</style>
