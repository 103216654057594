<template>
  <div>
    <f-dialog
      width="350px"
      :value="value"
      :closeOnClickOutside="true"
      @input="$emit('input', $event)"
    >
      <div
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="msgAguarde"
        class="text-center"
        v-if="value"
      >
        <strong class="mb-5">Definir a empresa atual como padrão para as próximas vezes que entrar no sistema?</strong>
        <f-button
          caption="Confirmar empresa padrão"
          class="mt-5"
          size="md"
          type="success"
          @click="setOrgPadrao"
        />
      </div>
    </f-dialog>
  </div>
</template>

<script>

import Service from './UserService';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      msgAguarde: 'Aguarde...',
      service: null,
    };
  },
  created() {
    this.service = new Service();
  },
  methods: {
    setLoading(value) {
      this.loading = value;
    },
    setOrgPadrao() {
      this.setLoading(true);
      this.service.setOrgPadrao()
        .then(() => {
          this.setLoading(false);
          this.$showMessage({
            title: 'Empresa padrão',
            type: 'success',
            message: 'Empresa padrão foi definida com sucesso!',
          });
          this.$emit('input', false);
        })
        .catch((err) => {
          this.setLoading(false);
          this.$showMessage({
            title: 'Empresa padrão não foi definida', type: 'error', message: err || err.message, time: 4500,
          });
        });
    },
  },
};
</script>
