<template>
  <el-avatar :size="size" :src="url" :icon="icon" class="bg-blue-600"></el-avatar>
</template>

<script>
import { Avatar } from 'element-ui';

export default {
  name: 'fAvatar',
  components: {
    'el-avatar': Avatar,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'el-icon-user-solid',
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
};
</script>
