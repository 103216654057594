<template>
  <div>
    <f-dialog
      title="Impressão de Etiquetas"
      width="70%"
      :value="value" @input="$emit('input',$event)"
      :fullscreen="$store.getters.isMobile"
    >
      <div class="flex flex-col">
        <slot></slot>
        <hr class="mt-5">
        <div v-if="imprimindo">
          <div>
            <div class="text-center">
              <FontAwesomeIcon icon="cog" size="3x" spin v-if="imprimindo" />
            </div>
          </div>
          <p class="text-center">Imprimindo</p>
        </div>
        <div
          class="mt-4 flex flex-col w-25"
          v-loading="service.loading || imprimindo"
          :element-loading-text="service.loadingMessage || 'Aguarde...'"
          element-loading-spinner="el-icon-loading"
        >
          <f-combobox
            caption="Modelos de Etiquetas disponíveis"
            persistElement="ultimaEtiquetaEscolhida"
            v-model="etiquetaEscolhida"
            :options="etiquetasLista"
            :fieldStyle="{width: '400px'}"
            width="400px"
          />

          <f-card class="cartao-etiqueta" v-if="etiquetaSelecionada">
            <div slot="header" class="text-center" style="font-size: 11px">
              <strong>{{ etiquetaSelecionada.nome }}</strong>
            </div>
            <div class="flex flex-col align-items-center">
              <p class="font-thin text-xs">{{ etiquetaSelecionada.descricao }}</p>
              <p class="font-thin text-xs">Colunas : {{ etiquetaSelecionada.colunas }}</p>
              <f-tag
                class="mt-2"
                type="info"
                :tags="[etiquetaSelecionada.tipoImpressora ==='etiqueta' ? 'Impressora de Etiqueta':'Impressora Comum']"
              />
              <div class="mt-2">
                <f-button caption="Imprimir" @click="imprimir(etiquetaSelecionada)"></f-button>
              </div>
            </div>
          </f-card>
        </div>

      </div>
      <Linker ref="linker" />
    </f-dialog>
  </div>
</template>

<script>
import Service from './EtiquetaService';
import Linker from '../../custom/linker/Linker';

export default {
  name: 'ImpressaoEtiquetas',
  components: {
    Linker,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    modulo: {
      type: String,
      required: true,
    },
    dados: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imprimindo: false,
      service: null,
      etiquetas: [],
      etiquetasSeremImpresas: [],
      etiquetaEscolhida: '',
      etiquetaSelecionada: null,
    };
  },
  computed: {
    etiquetasLista() {
      const lista = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const etq of this.etiquetas) {
        lista.push({
          text: etq.nome,
          value: etq.nome,
        });
      }
      return lista;
    },
  },
  created() {
    this.service = new Service();
  },
  methods: {
    getEtiquetas() {
      this.service.getEtiquetasPorModulo(this.modulo)
        .then((etiquetas) => {
          this.etiquetas = etiquetas;
          this.selecionaEtiqueta(this.etiquetaEscolhida);
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err.message,
          });
        });
    },
    imprimir(etiqueta) {
      this.duplicaEtiquetasBaseadaNaQuantidade();
      if (etiqueta.tipoImpressora === 'comum') {
        this.imprimeHtml(etiqueta);
        return;
      }
      this.imprimeLinker(etiqueta);
    },
    duplicaEtiquetasBaseadaNaQuantidade() {
      this.etiquetasSeremImpresas = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const etq of this.dados) {
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= etq.QtdeEtiqueta; i++) {
          this.etiquetasSeremImpresas.push(_.clone(etq));
        }
      }
    },
    imprimeHtml(etiqueta) {
      this.service.imprimeEtiquetaHtml(etiqueta.nome, this.etiquetasSeremImpresas)
        .then((relatorio) => {
          if (relatorio) {
            this.$showReportHtml(`Impressão de Etiqueta - ${etiqueta.nome}`, relatorio);
          }
        })
        .catch((err) => {
          this.$showMessage({
            title: '', type: 'error', message: err.message,
          });
        });
      this.$emit('imprimeEtiqueta', etiqueta);
    },
    imprimeLinker(etiqueta) {
      this.imprimindo = true;
      this.$refs.linker.imprimeEtiqueta(etiqueta.template, this.etiquetasSeremImpresas)
        .finally(() => {
          this.imprimindo = false;
        });
    },
    selecionaEtiqueta(etiqueta) {
      // eslint-disable-next-line no-restricted-syntax
      for (const etq of this.etiquetas) {
        if (etq.nome === etiqueta) {
          this.etiquetaSelecionada = etq;
          return;
        }
      }
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.getEtiquetas();
      }
    },
    etiquetaEscolhida(newValue) {
      this.selecionaEtiqueta(newValue);
    },
  },
};
</script>

<style scoped>
.cartao-etiqueta {
  margin: 5px;
  width: 360px;
}

.imagem-etiqueta {
  height: 145px;
}
</style>
