<script>

export default {
  data() {
    return {
      internalDisabled: false,
    };
  },
  methods: {
    classes() {
      const classes = [];
      // eslint-disable-next-line default-case

      if (this.validationMsg.length > 0) {
        classes.push('f-invalid');
      }
      return classes;
    },
    generatedId() {
      if (this.id) {
        return this.id;
      }
      return this.caption.toLocaleLowerCase()
        .replace(/ /g, '-');
    },
    setDisable(value) {
      this.internalDisabled = value;
    },
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
