import Dataset from '../../libs/dataset';

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      {
        caption: 'Criado em',
        name: 'createdAt',
        type: 'datetime',
      },
      {
        caption: 'Ativo',
        name: 'ativo',
        type: 'boolean',
      },
      {
        caption: 'Nome',
        name: 'descricao',
      },
      {
        caption: 'Usuário',
        name: 'tokenUserName',
      },
    ];
    this.findFilters = [
      {
        label: 'Consulta por Descricao',
        name: 'main',
        type: 'textbox',
        value: '',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = this.getModel();
  }

  getFields() {
    return `
        id
        createdAt
        updatedAt
        ativo
        descricao
        token
        tokenUserId
        tokenUserName
        `;
  }

  getModel() {
    return {
      id: null,
      ativo: null,
      descricao: null,
      token: null,
      tokenUserId: null,
      tokenUserName: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;

    return `query {
              TokenList(${filter}){
                id
                createdAt
                updatedAt
                ativo
                descricao
                tokenUserName
              }
            }`;
  }

  getByIds(ids) {
    return `query {
              TokenList(ids: [${ids.join()}]) {
                ${this.getFields()}
              }
            }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation TokenCreateMutation($data: TokenInputType!) {
           TokenCreate(data: $data){
             ${this.getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation TokenUpdateMutation($id: String!, $data: TokenInputType!) {
           TokenUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation TokenDeleteMutation($id: String!){
          TokenDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(record) {
    return {
      ativo: record.ativo,
      descricao: record.descricao,
      tokenUserId: record.tokenUserId,
      tokenUserName: record.tokenUserName,
    };
  }
}

export default Service;
