<template>
  <div>
    <f-alert type="error" v-if="linkerNaoRespondeu">
      <div>
        <p>
          O programa <strong>Linker</strong> não está instalado nesse computador, para essa operação,
          é necessário instalar :
          <a
            href="https://github.com/linksoft-dev/downloads/releases/download/latest/LinkerInstalacao.exe"
            target="_blank"
          >
            <i class="fa fa-download"></i>
            <strong>CLIQUE AQUI PARA BAIXAR O Linker e em seguida instale-o nesse computador</strong>
          </a>
        </p>
      </div>
    </f-alert>
  </div>
</template>

<script>
import Service from './LinkerService';

export default {
  name: 'Sigelink',
  data() {
    return {
      service: null,
      linkerNaoRespondeu: false,
    };
  },
  created() {
    this.service = new Service();
  },
  methods: {
    imprimeEtiqueta(template, dados) {
      return new Promise((resolve, reject) => {
        this.linkerNaoRespondeu = false;
        let impressao = {};
        try {
          impressao = JSON.parse(template);
        } catch (e) {
          this.$showMessage({
            title: 'Template da Etiqueta',
            type: 'error',
            message: 'Configuração da etiqueta está incorreta',
          });
          reject();
          return;
        }
        impressao.dados = dados;

        this.service.imprimeEtiqueta(impressao)
          .then(() => {
            this.$showMessage({ title: '', type: 'success', message: 'Comando de impressão enviado com sucesso!' });
            resolve();
          })
          .catch((err) => {
            if (_.includes(err.message, 'com o servidor')) {
              // eslint-disable-next-line no-param-reassign
              this.linkerNaoRespondeu = true;
            } else {
              this.$showMessage({ title: '', type: 'error', message: err.message });
            }
            reject();
          });
      });
    },
  },
};
</script>
