<style>
.flex-container {
  display: flex;
  flex-direction: row;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 900px) {
  .flex-container {
    flex-direction: column;
  }
}
</style>
<template>
  <div class="p-0 flex-container">
    <slot></slot>
  </div>
</template>

<script>

export default {};
</script>
