<template>
  <f-crud-form
    ref=formCarteirinha
    v-if="service"
    v-on="$listeners"
    :schema="service"
    :styleCell="styleCell"
    :formsValidation="[$refs.formValidation]"
    @save="$refs.upload.reset()"
  >
    <f-row slot="detailButtons">
      <f-button caption="Imprimir" @click="imprimir">
        <FontAwesomeIcon icon="print"/>
      </f-button>
      <f-button caption="Cancelar Carteira" type="danger" @click="mostraCancelamento = true"/>
    </f-row>
    <f-row>
      <f-col col="7">
        <f-form ref="formValidation">
          <div v-show="service.record.cancelamentoMotivo">
            <b>Dados do Cancelamento</b>
            <f-form-row>
              <f-textbox
                caption='Motivo Cancelamento'
                v-model='service.record.cancelamentoMotivo'
                :disabled="true"
                :inputStyle="{color: this.$style.colorRed}"
              />
              <f-datetimebox
                caption='Cancelado em'
                v-model='service.record.cancelamentoDataHora'
                :disabled="true"
                :inputStyle="{color: this.$style.colorBlack}"
              />
              <f-textbox
                caption='Cancelado por'
                v-model='service.record.cancelamentoUsuario'
                :inputStyle="{color: this.$style.colorBlack}"
                :disabled="true"
              />
            </f-form-row>
          </div>
          <f-form-row>
            <f-textbox
              caption='Número'
              v-model='service.record.numero'
              v-show="service.record.numero"
              width="100px"
              :disabled="true"
            />
            <f-datetimebox
              caption='Criado em'
              v-model='service.record.createdAt'
              v-show="service.record.createdAt"
              :disabled="true"
            />
          </f-form-row>
          <f-form-row>
            <f-lookup
              v-model="service.record.pessoaNome"
              caption='Pessoa'
              component="Person"
              :validations="validations.pessoaNome"
              :value-id="service.record.pessoaId"
              :filters="{type: 'isCustomer'}"
              @value="setPessoa"
            />
            <f-textbox
              caption='RG'
              v-model='service.record.pessoaRg'
              v-show="service.record.pessoaRg"
              width="100px"
              :disabled="true"
            />
            <f-textbox
              caption='CPF'
              v-model='service.record.pessoaCpf'
              v-show="service.record.pessoaCpf"
              width="120px"
              :disabled="true"
            />
          </f-form-row>
          <f-form-row>
            <f-datetimebox
              caption='Validade'
              type="date"
              v-show="service.record.validade"
              v-model='service.record.validade'
              :disabled="true"
            />
            <f-lookup
              v-model="service.record.vendedor.nome"
              caption='Vendedor'
              component="Person"
              :value-id="service.record.vendedor.id"
              :filters="{type: 'isSalesMan'}"
              @value="setVendedor"
            />
          </f-form-row>
          <f-textbox
            v-model='service.record.obs'
            type="textarea"
            caption='Obs'
            :validations="validations.obs"
          />
        </f-form>
      </f-col>
      <f-col col="3">
        <h5>Foto </h5>
        <f-image
          :src="service.record.fotoUrl"
          @change="service.record.fotoBase64 = $event.base64"
        />
        <f-upload
          ref="upload"
          :maxSize="100"
          :multiple="false"
          :show-submit-button="false"
          :maxImageWidth="200"
          :maxImageHeight="200"
          @removed="service.record.fotoUrl = ''"
          @files-changed="selectedPhoto"
        >
        </f-upload>
      </f-col>
    </f-row>
    <f-dialog v-model="mostraCancelamento" width="30%">
      <Cancelamento
        v-if="mostraCancelamento"
        title="Cancelamento de Carteira"
        :function="cancela"
      />
    </f-dialog>
  </f-crud-form>
</template>

<script>
import Cancelamento from 'comps/custom/Cancelamento';
import CarteirinhaService from './carteirinhaService';

export default {
  components: {
    Cancelamento,
  },
  data() {
    return {
      service: null,
      reportService: 'carteirinha',
      mostrarBaixarFicha: false,
      mostraCancelamento: false,
      validations: {
        pessoaNome: [
          {
            required: true,
            message: 'Pessoa é obrigatório',
            trigger: 'blur',
          },
        ],
        obs: [
          {
            min: 2,
            max: 220,
            message: 'Obs deve ter entre 2 e 220 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new CarteirinhaService();
  },
  mounted() {
    // quando passa o form para o service, o component form-crud irá tentar validar antes de salvar
    this.service.form = this.$refs.form;
  },
  computed: {
    inserindo() {
      return true;
    },
  },
  methods: {
    imprimir() {
      this.$showMessage({
        title: 'Imprimindo...',
        type: 'info',
        message: 'Imprimindo...',
      });
      this.service.imprimir()
        .then((resp) => {
          if (resp.result.htmlContent) {
            this.$showReportHtml('Impressão de Carteirinha', resp.result.htmlContent);
          }
        })
        .catch((err) => {
          this.$showMessage({
            title: 'Impressão de pedido',
            type: 'error',
            message: `Pedido não impresso : ${err.message || err}`,
          });
        });
    },
    cancela(motivo) {
      this.service.record.cancelamentoMotivo = motivo;
      return new Promise((resolve, reject) => {
        this.service.cancelar(this.service.record.id)
          .then(() => {
            this.$showMessage({
              title: 'Cancelamento de Carteira',
              type: 'success',
              message: 'Carteira cancelada com sucesso!',
            });
            this.mostraCancelamento = false;
            resolve();
          })
          .catch((error) => {
            reject();
            this.$showMessage({
              title: 'Cancelamento de Carteira',
              type: 'error',
              message: `Carteira não cancelada : ${error}`,
            });
          });
      });
    },
    selectedPhoto($event) {
      this.service.record.fotoUrl = $event[0].localUrl;
    },
    setPessoa(record) {
      this.service.record.pessoaNome = record.name;
      this.service.record.pessoaId = record.id;
      this.service.record.pessoaCpf = record.cpfCnpj;
      this.service.record.pessoaRg = record.ident;
    },
    selectVendedor(records) {
      this.service.record.vendedor.id = records[0].id;
      this.service.record.vendedor.nome = records[0].name;
    },
    setVendedor(record) {
      this.service.record.vendedor.id = record.id;
      this.service.record.vendedor.nome = record.name;
      this.service.record.vendedor.apelido = record.name2;
      this.service.record.vendedor.comissao = record.commission;
    },
    styleCell(col, item) {
      if (col === 'cancelamentoMotivo') {
        if (item.cancelamentoMotivo !== '') {
          return {
            'background-color': this.$style.colorRed,
            color: 'white',
          };
        }
      }
      return {};
    },
  },
};

</script>
