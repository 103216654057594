<template>
  <div>
    <f-form ref="form">
      <f-textbox caption='Nome' v-model='service.record.name' :validations="validations.nome"/>
      <f-textbox caption='E-mail' v-model='service.record.email' :disabled="true" type="email"/>
      <div class="flex flex-col" v-if="service.record.settings">
        <h3>Preferências</h3>
        <f-checkbox
          caption="Desabilitar Auto pesquisa"
          v-model="service.record.settings.disableAutoFind"
        />
      </div>
      <div class="mt-5">
        <f-button caption="Alterar Senha" @click="mostraAlterarSenha = !mostraAlterarSenha" />
      </div>
      <div class="mt-5">
        <f-button caption="Salvar" @click="save" />
      </div>
    </f-form>
    <div>
      <f-dialog v-model="mostraAlterarSenha" width="40%" title="Alteração de senha">
        <f-form ref="form">
          <f-textbox caption='Usuario Logado:' v-model='service.record.email' :disabled=true />
          <f-textbox
            caption='Senha Atual:'
            v-model='dadosAlteraSenha.currentPassword'
            placeholder="Informe a senha atual usada para entrar no sistema."
            type="password"
            :validations="validations.password"
          />
          <f-textbox
            caption='Nova senha:'
            v-model='dadosAlteraSenha.newPassword'
            placeholder="Informe a nova senha que será utilizada para entrar no sistema."
            type="password"
            :validations="validations.password"
          />
          <f-textbox
            caption='Repetir Nova Senha:'
            v-model='dadosAlteraSenha.repeatedNewPassword'
            placeholder="Repita a nova senha."
            type="password"
            :validations="validations.password"
          />
          <div class="mt-5">
            <f-button caption="Confirmar Alteração de Senha" @click="changePassword" />
          </div>
        </f-form>
      </f-dialog>
    </div>
  </div>
</template>

<script>
import Service from './UserService';

export default {
  name: 'UserProfile',
  mounted() {
    this.getUserData();
  },
  data() {
    return {
      mostraAlterarSenha: false,
      dadosAlteraSenha: {
        currentPassword: null,
        newPassword: null,
        repeatedNewPassword: null,
      },
      service: new Service(),
      validations: {
        nome: [
          { required: true, trigger: 'blur' },
        ],
        password: [
          {
            required: true,
            message: 'Insira uma senha',
            trigger: 'blur',
          },
          {
            min: 4,
            max: 30,
            message: 'Senha deve ter entre 4 e 30 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.service.save()
        .then((response) => {
          this.$showMessage({
            title: '', type: 'success', message: 'Preferências salvas com sucesso!', time: 4500,
          });
          this.$emit('success', true);
          const { infoLogin } = this.$store.getters;
          infoLogin.userSettings = response.record.settings;
          infoLogin.name = response.record.name;
          this.$store.dispatch('setInfoLogin', infoLogin);
        });
    },
    changePassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.dadosAlteraSenha.newPassword !== this.dadosAlteraSenha.repeatedNewPassword) {
        this.$showMessage({
          title: 'Senha não alterada',
          type: 'error',
          message: 'Os campos de "Nova Senha" e "Repetir Nova Senha" estão diferentes',
        });
        return;
      }
      this.service.changePassword(this.dadosAlteraSenha)
        .then(() => {
          this.mostraAlterarSenha = false;
          this.$showMessage({
            title: 'Alteracao de senha',
            type: 'success',
            message: 'Senha alterada com sucesso',
          });
          this.dadosAlteraSenha = {
            currentPassword: null,
            newPassword: null,
            repeatedNewPassword: null,
          };
          this.$emit('success', true);
        })
        .catch((err) => {
          this.$showMessage({
            title: 'Senha não alterada',
            type: 'error',
            message: `Erro ao tentar alterar a senha: ${err}`,
          });
        });
    },
    getUserData() {
      this.service.getRecordsByIds({ ids: [this.$store.getters.infoLogin.userId] })
        .then((response) => {
          this.service.setRecord(response[0]);
        });
    },
  },
};
</script>
