import Dataset from '../../libs/dataset';

class Service extends Dataset {
  constructor(config) {
    super(config);
    this.findGridColumns = [
      { caption: 'Nome', name: 'name' },
      { caption: 'E-mail', name: 'email' },
      { caption: 'Telefone', name: 'phone' },
      { caption: 'Whatsapp', name: 'byWhatsApp' },
    ];
    this.model = this.getModel();
  }

  getModel() {
    return {
      id: null,
      name: null,
      email: null,
      phone: '',
      byWhatsApp: null,
    };
  }
}

export default Service;
