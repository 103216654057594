<template>
  <div class="row f-row">
    <slot></slot>
  </div>
</template>

<script>
require('./bootstrap-grid.min.css');
</script>

<style scoped>
.f-row {
  margin: 0;
  padding: 0;
}
</style>
