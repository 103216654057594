<template>
  <div class="flex flex-column items-center">
    <h5 class="text-white">{{data.appName}}</h5>
    <p class="text-sm text-white">Ver. {{appVersion}}</p>
  </div>
</template>

<script>

export default {
  name: 'AppSystemInfo',
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    message() {
      return this.$store.getters.getMessage;
    },
    footerStyle() {
      if (this.message.msg) {
        if (this.message.type === 'success') {
          return { 'background-color': 'green', color: 'white' };
        }
        if (this.message.type === 'error') {
          return { 'background-color': 'red', color: 'white' };
        }
      }
      return {};
    },
  },
};
</script>
