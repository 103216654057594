<template>
  <f-row>
    <f-col col="12">
      <f-dialog :value="value" @input="$emit('input', $event)" width="350px" :title="title">
        <f-row
          v-loading="service.loading"
          element-loading-text="Convidando novo usuário, aguarde..."
          element-loading-spinner="el-icon-loading"
        >
          <f-form ref="form" @submit="convidaUsuario">
            <f-textbox
              caption='Nome'
              id="auth-user-name"
              v-model='user.name'
              :validations="validations.name"
            />
            <f-textbox
              caption='E-mail'
              id="auth-user-email"
              v-model='user.email'
              type="email"
              :validations="validations.email"
            />
            <f-lookup
              v-model="group.name"
              id="auth-user-group"
              caption='Grupo'
              component="Group"
              style="width: 300px;"
              :value-id="group.id"
              @value="selectGroup"
            />
            <f-button caption="Enviar convite" @click="convidaUsuario" type="success" size="md" style="width: 300px;"/>
          </f-form>
        </f-row>
      </f-dialog>
    </f-col>
  </f-row>
</template>

<script>
export default {
  name: 'UserNew',
  data() {
    return {
      title: 'Cadastro de novo usuário',
      user: {
        name: null,
        email: null,
      },
      group: {
        id: null,
        name: null,
      },
      validations: {
        name: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Email é obrigatório',
            trigger: 'blur',
          },
          {
            min: 8,
            max: 120,
            message: 'Email deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  props: {
    service: { type: Object, required: true },
    value: { type: Boolean, default: false },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
      this.user.name = null;
      this.user.email = null;
      this.group.id = null;
      this.group.name = null;
    },
    selectGroup(record) {
      this.group.id = record.id;
      this.group.name = record.name;
    },
    async convidaUsuario() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const result = await this.$confirm('Será enviado um e-mail de confirmação a este usuario. Convidar Usuario?',
        'Convite de novo usuário', {
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          type: 'error',
        });
      if (result === 'confirm') {
        const convite = {
          user: this.user,
          group: this.group,
        };
        this.service.loadingMessage = 'Convidando novo usuário, aguarde...';
        this.service.convidaUsuario(convite)
          .then(() => {
            this.$showMessage({
              title: 'Convite de novo usuário',
              type: 'success',
              message: 'Usuario convidado! O usuario deve entrar no e-mail e clicar no link para confirmar o registro',
            });
            this.$emit('input', false);
            this.reset();
          })
          .catch((error) => {
            this.$showMessage({
              title: 'Convite de novo usuário',
              type: 'error',
              message: `Convite não realizado: ${error}`,
            });
          })
          .then(() => {
            this.service.loadingMessage = '';
          });
      }
    },
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.reset();
      }
      this.value = newValue;
    },
  },
};
</script>
