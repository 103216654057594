<template>
  <div
    class="flex"
    v-loading="loading"
    :element-loading-text="loadingMessage || 'Aguarde, importando...'"
    element-loading-spinner="el-icon-loading"
  >
    <f-form ref="form" @submit="importa">
      <div class="flex flex-col justify-center" style="width: 240px;">
        <div class="flex justify-center">
          <f-upload
            ref="upload"
            :maxSize="5048"
            :multiple="false"
            :showSubmitButton="false"
            accept=".csv"
            @removed="file = ''"
            @files-changed="selecionaArquivo"
          >
          </f-upload>
        </div>
        <slot></slot>
        <f-button
          v-if="file"
          caption="Enviar/Importar"
          class="mt-4"
          size="md"
          type="success"
          @click="importa"
        />
      </div>
    </f-form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      file: '',
      fileName: '',
      loading: false,
    };
  },
  props: {
    importCallBack: { type: Function },
    title: { type: String },
    loadingMessage: { type: String },
  },
  methods: {
    selecionaArquivo($event) {
      this.fileName = $event[0].name;
      this.$utils.File.getBase64FromUrl($event[0].localUrl, (base64) => {
        this.file = base64;
      });
    },
    importa() {
      this.loading = true;
      this.importCallBack(this.file, this.fileName)
        .then(() => {
          this.$refs.upload.reset();
          this.loading = false;
        })
        .catch(() => { this.loading = false; });
    },
  },
};
</script>
