<template>
  <f-form-row>
    <f-dialog :value="value" width="auto" @input="input">
      <div class="">
        <div><b>CALCULO DE METRO LINEAR</b></div>
        <div class="flex flex-wrap" v-cloak>
          <f-form>
            <f-form-row>
              <f-numberbox caption="Valor Unitário" :value="model.valorUnitarioInterno" :disabled="true" />
              <f-numberbox caption="Total Metro linear (ML)" :value="totalMetroLinear" :disabled="true" />
              <f-numberbox caption="Valor Calculado" :value="valorTotal" :disabled="true" />
              <f-alert>Mostrando apenas produtos que tem comprimento</f-alert>
            </f-form-row>
          </f-form>
          <f-grid
            class="grid2 f-flex-stretch"
            element-loading-text="Aguarde..."
            ref="grid"
            :columns="columns"
            :data="list"
            @change="gridChange"
          >
          </f-grid>
          <f-button caption="OK" @click="concluido" />
        </div>
      </div>
    </f-dialog>
  </f-form-row>
</template>

<script>
export default {
  name: 'CalculaMetroLenear',
  props: {
    servico: { type: Object, required: true },
    produtos: { type: Array, required: true },
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      model: {
        valorUnitarioInterno: 0,
      },
      produtosSelecionados: [],
      list: [],
      columns: [
        { title: 'Item', field: 'nome' },
        { title: 'Quantidade', field: 'quantidade' },
        { title: 'UN', field: 'un' },
        { title: 'Comprimento', field: 'comprimento', type: 'number' },
      ],
    };
  },
  watch: {
    value(v) {
      if (v) {
        this.reset();
        this.model.valorUnitarioInterno = this.servico.valorUnitario;
        Object.keys(this.produtos).forEach((key) => {
          const item = this.produtos[key];
          this.list.push({
            id: this.$utils.String.uiid(),
            nome: item.nome,
            un: item.un,
            comprimento: item.comprimento,
            quantidade: item.quantidade,
          });
        });
      }
    },
  },
  computed: {
    totalMetroLinear() {
      let soma = 0;
      Object.keys(this.produtosSelecionados).forEach((key) => {
        const item = this.produtosSelecionados[key];
        soma += (item.comprimento * item.quantidade);
      });
      return soma;
    },
    valorTotal() {
      if (this.totalMetroLinear > 0) {
        return this.totalMetroLinear * this.model.valorUnitarioInterno;
      }
      return 0;
    },
  },
  methods: {
    gridChange(selectedRecords) {
      this.produtosSelecionados = selectedRecords;
    },
    input(value) {
      this.$emit('input', value);
    },
    concluido() {
      this.model.totalMetroLinear = this.totalMetroLinear;
      this.$emit('input', false);
      this.$emit('concluido', this.model);
    },
    reset() {
      this.model = {
        comprimento: 0,
        largura: 0,
        medida: 0,
        valorUnitarioInterno: 0,
        totalMetroLinear: 0,
      };
      this.list = [];
    },
  },
};
</script>
