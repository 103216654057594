<style scoped>
>>> .lookup-input > input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
<style lang="scss">

.f-field {
  margin: 0 4px 4px 0 !important;
}

.f-invalid > input {
  border-color: #EF4444 !important;
}
</style>

<template>
  <f-row class="f-field flex justify-center flex-col w-full" :id="generatedId()" :style="internalFieldStyle">
    <f-col>
     <f-label
      :caption="caption"
      :isRequired="isRequired"
      :helpMessage="helpMessage"
    />
    </f-col>
    <f-col>
      <div class="flex">
        <el-input
          autocomplete="off"
          class="lookup-input"
          type="text"
          ref="input"
          :placeholder=getPlaceholder
          :class="classes()"
          :value="value"
          :size="size"
          :disabled="disabled || internalDisabled || (disableTextBox && value === '') || editable === false"
          :readonly="readonly"
          @input="$emit('input', caseTransform($event, caseOption))"
          @keyup.native="keyUp"
          @blur="validadeInput()"
        >
        </el-input>
        <f-button
          type="danger"
          class="rounded-none"
          v-if="!disableDeleteButton && !editable && (value !== '' && value !== null)"
          :disabled="disabled || internalDisabled"
          @click="$emit('input', '');$emit('value', {})"
        >
          <FontAwesomeIcon icon="ban" />
        </f-button>
        <f-button
          style="margin-left: 0px"
          icon="el-icon-edit-outline"
          type="primary"
          class="rounded-none"
          v-if="valueId && disableDetailPage === false"
          :disabled="disabled || internalDisabled"
          @click="openDetailPage"
        />
        <f-button
          v-if="!disableSearchButton"
          icon="el-icon-search"
          type="primary"
          class="rounded-l-none"
          style="margin: 0"
          :disabled="disabled || internalDisabled"
          @click="openPage"
        />
      </div>

      <p
        class="text-xs text-red-500"
        v-for="msg in validationMsg" :key="msg"
      >
        {{ msg }}
      </p>
      <p
        class="text-xs text-red-500"
        v-if="!valueId && value && !desableInternalValidation"
      >
        Necessario selecionar um registro atraves da lupa. Dados sem vinculo a um registro serão ignorados
      </p>
      <f-dialog
        v-model="showDialog"
        @close="showDialog = false"
        :title="pageTitle || caption"
        :fullscreen="$store.getters.isMobile"
      >
        <div style="width: 100%">
          <slot  style="width: 100%">
            <component
              style="width: 100%"
              ref="form"
              v-if="showDialog"
              :is="component"
              @dblClick="openRecord"
            />
          </slot>
        </div>
      </f-dialog>
    </f-col>
  </f-row>
</template>

<script>
import { Input } from 'element-ui';
import inputMixing from './InputMixing';

export default {
  name: 'fLookup',
  mixins: [
    inputMixing,
  ],
  components: {
    'el-input': Input,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    pageTitle: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    valueId: {
      type: String,
      default: '',
    },
    component: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      default: '',
    },
    filters: {
      type: Object,
      default: () => {
      },
    },
    width: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    caseOption: {
      type: String,
      default: 'uppercase',
    },
    openPageAutomatic: {
      type: Boolean,
      default: true,
    },
    desableInternalValidation: {
      type: Boolean,
      default: false,
    },
    disableDetailPage: { type: Boolean, default: false },
    disableTextBox: { type: Boolean, default: false },
    editable: { type: Boolean, default: true },
    disableDeleteButton: { type: Boolean, default: false },
    disableSearchButton: { type: Boolean, default: false },
  },
  data() {
    return {
      // guarda o value internamente para ser usado na consulta (pois o value sera limpo ao sair do campo)
      internalValue: null,
      showDialog: false,
      validationFieldName: 'valueId',
    };
  },
  computed: {
    getPlaceholder() {
      if (!this.editable && !this.placeholder) {
        return 'Clique na lupa para selecionar o registro';
      }
      return this.placeholder;
    },
  },
  methods: {
    // validadeInput verifica se o campo input deve ser limpo, se sim, aciona a funcao de limpeza
    validadeInput() {
      // se nao tem ID, o campo input deve ser limpo para prevenir o salvamento dessa informacao sem a ID
      if ((this.valueId === '' || this.valueId === null) && !this.desableInternalValidation) {
        this.clearInput();
      }
    },
    // clearInput limpa o input
    clearInput() {
      this.$emit('input', '');
    },
    change($event) {
      if (!this.valueId) {
        this.$emit('input', this.caseTransform($event, this.caseOption));
      }
    },
    setDisable(value) {
      this.internalDisabled = value;
    },
    openPage() {
      this.showDialog = true;
      // ao passar 'consulta', diz ao crud form que deve abrir em modo de consulta, caso contrário, abrirá em
      // modo detail
      this.$store.dispatch('setCrudFormLookup', 'consulta');
      const filters = this.$utils.Object.copyObject(this.filters);
      if (!this.valueId) {
        // o filtro usa o valor interno, pois value sera limpo para prevenir salvamento sem id
        filters.main = this.internalValue;
      }
      this.$store.dispatch('setCrudFormFindInitialFilter', filters);
      this.$emit('button-click');
    },
    // especificamente para abrir a tela ja de cadastro diretamente com o registro que ta no lookup
    openDetailPage() {
      this.showDialog = true;
      this.$store.dispatch('setCrudFormLookup', this.valueId);
      this.$emit('button-edit-click');
    },
    caseTransform(value, caseOption) {
      if (value) {
        if (caseOption === 'lowercase' || this.type === 'email') {
          return value.toLowerCase();
        }
        if (caseOption === 'uppercase') {
          return value.toUpperCase();
        }
      }
      return value;
    },
    openRecord(records) {
      // ao escolher o registro, esta saindo da consulta. Entao internalValue usado para consulta devem ser limpo
      this.internalValue = null;
      this.$emit('input', records[0][this.fieldName]);
      this.$emit('value', records[0]);
      this.showDialog = false;
    },
    keyUp(event) {
      // so deve limpar o campo se houver vinculo
      if (_.includes(['Backspace', 'Delete'], event.code) && !this.readonly && this.valueId) {
        this.$emit('input', '');
        this.$emit('value', {});
        // se esta removendo o vinculo, limpe internalValue
        this.internalValue = null;
      } else if (event.code === 'Enter' && !this.valueId && this.value) {
        if (this.openPageAutomatic) {
          this.openPage();
          // limpa input ao usar ENTER
          this.clearInput();
        }
      }
      this.$emit('keyup', event);
    },
    inputStyleInterno() {
      let style = {
        textTransform: 'uppercase',
      };
      if (this.type === 'email') {
        style.textTransform = 'lowercase';
      }

      if (this.inputStyle && this.inputStyle.textTransform) {
        style.textTransform = this.inputStyle.textTransform;
      }

      if (this.width) {
        style.width = this.width;
      }

      style = { ...this.inputStyle, ...style };
      return style;
    },
  },
  watch: {
    value(newValue) {
      // se o novo valor de value nao for nulo, armazene-o em internalValue para ser usado na consulta
      if (newValue && !this.valueId) {
        this.internalValue = newValue;
      }
    },
  },
};
</script>
