<template>
  <div>
    <div class="CreatorPreview--Wrap">
      <slot>
      </slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Preview',
  props: {
    avatar: {
      type: String,
      default: 'https://source.boringavatars.com/beam/90/',
    },
    name: {
      type: String,
      default: 'Creator',
    },
    description: {
      type: String,
      default: 'Developer, Founder',
    },
    content: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'light',
      validator(value) {
        // The value must match one of these strings
        return ['light', 'dark'].indexOf(value) !== -1;
      },
    },
  },
};
</script>
<style lang="scss">
.CreatorPreview--Wrap {
  position: relative;
  display: inline;
  text-align: left;

  &.is-dark {
    .CreatorPreview {
      background-color: rgb(32, 32, 32);

      * {
        color: white;
      }
    }
  }

  .CreatorPreview {
    width: clamp(250px, 30vw, 360px);
    opacity: 0;
    z-index: 1;
    position: fixed;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 60px 0 rgba(black, 0.15);
    will-change: auto;
    left: 0;
    bottom: 0;
    opacity: 0;
    padding: 20px;
    pointer-events: none;
    color: black;

    > * {
      will-change: auto;
    }

    &.is-visible {
      pointer-events: all;
    }

    .CreatorPreview--Person {
      display: flex;
      align-items: center;
    }

    .CreatorPreview--Name--Wrap {
      flex: 1;
    }

    .CreatorPreview--Avatar {
      border-radius: 50px;
      overflow: hidden;
      display: block;
      height: 90px;
      width: 90px;
      margin: 0 10px 0 0;
      border: solid 4px rgba(black, 0.2);
    }

    .CreatorPreview--Name {
      font-size: 1rem;
      font-weight: bold;
      grid-area: name;
      margin: 0 0 10px;
      line-height: 1.25;
    }

    .CreatorPreview--Description {
      font-size: 0.85rem;
      margin: 0;
      line-height: 1.25;
    }

    .CreatorPreview--Content {
      font-size: 0.85rem;
      margin-top: 20px;
    }

    .CreatorPreview--Link {
      text-decoration: underline;
    }
  }
}
</style>
