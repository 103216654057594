import lodash from 'lodash';

export default class ObjectUtil {
  static isObject = (value) => (
    Object.prototype.toString.call(value) === '[object Object]'
    && JSON.stringify(value) === '{}'
  );

  static isEmpty = (value) => lodash.isEmpty(value);

  static copyObject = (obj) => {
    if (obj) {
      return JSON.parse(JSON.stringify(obj));
    }
    return {};
  };

  static clone(obj) {
    if (obj == null || typeof obj !== 'object') return obj;
    const copy = new obj.constructor();
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    return copy;
  }

  static getValueFromString(obj, str) {
    // eslint-disable-next-line no-param-reassign
    str = str.split('.');
    // eslint-disable-next-line no-plusplus,no-param-reassign
    for (let i = 0; i < str.length; i++) obj = obj[str[i]];
    return obj;
  }

  static convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => ({
      ...obj,
      [item[key]]: item,
    }), initialValue);
  };
}
