<style lang="scss" scoped>

.user-info {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  .avatar {
    margin: 0 5px 0 0;
    border-radius: 50%;
  }

  .username {
    color: white;
  }
}

</style>

<template>
  <div class="user-info items-center">
    <f-avatar class="mr-3" :url="avatarLink"></f-avatar>
    <f-link @click="openUserProfile" class="text-white">{{ userName }}</f-link>
    <f-dialog
      v-model="showUserProfile"
      title="Perfil Usuário"
      :width="$store.getters.isMobile ? '100%': '45%'"
    >
      <UserProfile v-if="showUserProfile" @success="showUserProfile = false"/>
    </f-dialog>
    <f-preview v-if="false">
      <div class="bg-blue-500" style="height: 300px; width: 500px;"></div>
      <div class="bg-gray-100" style="height: 200px; width: 500px; position: relative;">
        <div class="bg-white rounded-2 text-center" style="top: 0;height: 100px;
          position: absolute; margin: 30px;">
        </div>
      </div>
    </f-preview>
    <f-link @click="logout" class="text-white">(Sair)</f-link>
  </div>
</template>

<script>
import UserProfile from '../../apps/auth/UserProfile';

export default {
  name: 'AppFooter',
  components: {
    UserProfile,
  },
  computed: {
    message() {
      return this.$store.getters.getMessage;
    },
    avatarLink() {
      return _.get(this, '$store.getters.infoLogin.avatarLink');
    },
    userName() {
      return this.$store.getters.infoLogin.name.toLowerCase().capitalize();
    },
    footerStyle() {
      if (this.message.msg) {
        if (this.message.type === 'success') {
          return {
            'background-color': 'green',
            color: 'white',
          };
        }
        if (this.message.type === 'error') {
          return {
            'background-color': 'red',
            color: 'white',
          };
        }
      }
      return {};
    },
  },
  data() {
    return {
      showUserProfile: false,
    };
  },
  methods: {
    openUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    logout() {
      window.sessionStorage.setItem('infoLogin', '');
      this.$store.dispatch('setInfoLogin', '');
      this.$router.replace({ name: 'Login' });
    },
  },
};
</script>
