import Dataset from 'comps/libs/dataset';

class Service extends Dataset {
  constructor() {
    super();
    this.findGridColumns = [
      {
        name: 'nome',
        caption: 'Nome',
      },
      {
        name: 'createdAt',
        caption: 'Criado em',
        type: 'datetime',
      },
    ];
    this.findFilters = [
      {
        type: 'textbox',
        width: '400px',
        label: 'Consulta por número da carteira ou nome do paciente',
        name: 'main',
      },
      {
        label: 'Validade (A partir) ',
        type: 'date',
        name: 'vencimentoGte',
      },
      {
        label: 'Validade (Até) ',
        type: 'date',
        name: 'vencimentoLte',
      },
    ]; // filtro para ser criado pela tela de consulta
    this.record = {
      id: null,
      createdAt: null,
      nome: null,
      htmlTemplate: null,
      stimulsoftTemplate64: null,
      dadosExemplo: null,
      imagemBackground64: null,
      imagemBackgroundUrl: null,
      ImagemBackgroundNomeArquivo: null,
      path: null,
      obs: null,
      valor: null,
    };
  }

  getModel() {
    return {
      id: null,
      createdAt: null,
      nome: null,
      imagemBackground64: null,
      imagemBackgroundUrl: null,
      obs: null,
      valor: null,
    };
  }

  getFindQuery(filters) {
    return `query {
              CarteirinhaModeloList(filter: "${filters.main || ''}") {
                id
                nome
                valor
                createdAt
              }
           }`;
  }

  getByIds(ids) {
    return `
      query {
        CarteirinhaModeloList(ids: [${ids.join()}]) {
          ${this.getFields()}
       }
      }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation CarteirinhaModeloCreateMutation($data: CarteirinhaModeloInputType!) {
           CarteirinhaModeloCreate(data: $data){
              ${this.getFields()}
           }
         }`,
      params: this.getMutationParams(this.record),
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation CarteirinhaModeloUpdateMutation($id: String!, $data: CarteirinhaModeloInputType!) {
           CarteirinhaModeloUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: this.getMutationParams(this.record),
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation CarteirinhaModeloDeleteMutation($id: String!){
          CarteirinhaModeloDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  async imprimir() {
    const query = `
          query {
            CarteirinhaModeloImprimir(ids:["${this.record.id}"]){
              id
              htmlContent
            }
          }`;
    const result = await this.gqlQuery(query);
    return result;
  }

  getFields() {
    return `id
            createdAt
            nome
            htmlTemplate
            stimulsoftTemplate64
            dadosExemplo
            imagemBackgroundUrl
            path
            obs
            valor`;
  }

  getMutationParams(model) {
    const params = {
      data: {
        nome: model.nome,
        htmlTemplate: model.htmlTemplate,
        stimulsoftTemplate64: model.stimulsoftTemplate64,
        dadosExemplo: model.dadosExemplo,
        imagemBackground64: model.imagemBackground64 || '',
        obs: model.obs,
        valor: model.valor,
      },
    };
    if (model.id) {
      params.id = model.id;
    }
    return params;
  }
}

export default Service;
