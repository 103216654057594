import Dataset from '../../libs/dataset';

class OrgService extends Dataset {
  constructor() {
    super();
    this.filtersModel = {
      main: '',
    };
    this.findGridColumns = [];
    this.findFilters = []; // filtro para ser criado pela tela de consulta
    this.model = this.getModel();
    this.record = this.model;
  }

  getModel() {
    return {
      id: null,
      name: null,
      name2: null,
      cpfCnpj: null,
    };
  }

  getFindQuery(filters) {
    const filter = `filter: "${filters.main || ''}"`;

    return `query {
              UserList(${filter}){
                id
                name
                name2
                cpfCnpj
              }
            }`;
  }

  getFields() {
    return `
       id
       name
       name2
       cpfCnpj
    `;
  }

  getByIds(ids) {
    return `query {
            OrgList(ids: [${ids.join()}]) {
             ${this.getFields()}
           }
         }`;
  }

  getMutationCreate() {
    return {
      query: `
         mutation OrgCreateMutation($data: OrgInputType!) {
           OrgCreate(data: $data){
             ${this.getFields()}
           }
         }`,
      params: { data: this.getMutationParams(this.record) },
    };
  }

  getMutationUpdate() {
    return {
      query: `
         mutation OrgUpdateMutation($id: String!, $data: UserInputType!) {
           UserUpdate(id: $id, data: $data){
             ${this.getFields()}
           }
         }`,
      params: {
        id: this.record.id,
        data: this.getMutationParams(this.record),
      },
    };
  }

  getMutationDelete() {
    return {
      query: `
         mutation OrgDeleteMutation($id: String!){
          OrgDelete(id: $id)
         }`,
      params: {
        id: this.record.id,
      },
    };
  }

  getMutationParams(model) {
    return {
      name: model.name,
      name2: model.name2,
      cpfCnpj: model.cpfCnpj,
    };
  }
}

export default OrgService;
