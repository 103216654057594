<template>
  <f-crud-form
    v-if="service"
    :schema="service"
    :formsValidations="[$refs.formValidation]"
    @newRecord="newRecord"
  >
    <f-row>
      <f-col col="7">
        <f-form ref="formValidation">
          <f-alert>
            <b>ATENÇÃO</b> Essa tela se destina a integração desse sistema com outros sistemas(Acesso Externo),
            através da criação de um Token, é possível que outra aplicação envie e receba
            os dados desse sistema. A aplicação que deseja integrar com esse sistema deve usar o
            Token gerado abaixo.
          </f-alert>

          <f-checkbox caption="Ativo" v-model="service.record.ativo"/>
          <f-textbox
            caption='Descrição'
            v-model='service.record.descricao'
            :validations="validations.descricao"
          />
          <f-lookup
            v-model="service.record.tokenUserName"
            caption='Usuário'
            component="User"
            :value-id="service.record.tokenUserId"
            :validations="validations.usuario"
            @value="setUser"
          />
          <f-textbox
            caption='Token'
            type="textarea"
            caseOption=""
            :readonly="true"
            v-model='service.record.token'
            v-if="service.record.token"
          />
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import TokenService from './TokenService';

export default {
  created() {
    this.service = new TokenService();
  },
  data() {
    return {
      service: null,
      validations: {
        descricao: [
          {
            required: true,
            message: 'Descrição é obrigatório',
            trigger: 'blur',
          },
          {
            min: 3,
            max: 120,
            message: 'Descrição deve ter entre 3 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        usuario: [
          {
            required: true,
            message: 'Usuário é obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    newRecord() {
      this.service.record.ativo = true;
    },
    setUser(data) {
      if (data.id) {
        this.service.record.tokenUserId = data.id;
        this.service.record.tokenUserName = data.name;
      }
    },
  },
};
</script>
