<template>
  <!-- eslint-disable -->
  <div>
    <span
      v-for="(filter, idx) in filters.filter(item => item.visible === undefined || item.visible === true)"
      :key="filter.name">
      <f-textbox
        v-if="filter.name ==='main'"
        v-model="filtersValues[filter.name]"
        :caption="filter.label"
        :width="filter.width"
        @keyup="keyup"
      />
      <FilterField
        v-else
        class="ml-2 p-0 "
        v-model="filtersValues[filter.name]"
        :filter="filter"
        @change="keyup"
        @lookup-value="filtersValues[`${filter.name}_lookup_value`] = $event"
      />
    </span>
    <f-button
      @click="consulta"
      caption="Consulta"
      class="mt-4"
    >
      <FontAwesomeIcon icon="search" />
    </f-button>
  </div>
</template>

<script>
import FilterField from './CrudFormFindFilters';

export default {
  components: {
    FilterField,
  },
  name: 'CrudFormFindFilters',
  data() {
    return {
      lookupValueInternal: '',
    };
  },
  mounted() {
  },
  methods: {
    keyup(event) {
      this.$emit('keyup', event);
    },
    consulta() {
      this.$emit('consulta', 'OK');
      this.$emit('success', 'OK');
    },
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
    filtersValues: {
      type: Object,
      required: true,
    },
  },
};
</script>
