<template>
  <f-crud-form
    v-if="service"
    :schema="service"
    v-on="$listeners"
    permissao="grupopess"
    :formsValidation="[formValidation]"
  >
    <f-row>
      <f-col col="12">
        <f-form @mounted="formValidation = $event">
          <f-textbox
            id="nome"
            caption='Nome'
            v-model='service.record.nome'
            :validations="validations.nome"
          />
          <f-textbox
            id="code"
            type="textarea"
            caption='Obs'
            v-model='service.record.obs'
            :validations="validations.obs"
          />
        </f-form>
      </f-col>
    </f-row>
  </f-crud-form>
</template>

<script>
import Service from './GrupoPessoaService';

export default {
  data() {
    return {
      service: null,
      formValidation: null,
      validations: {
        nome: [
          {
            required: true,
            message: 'Nome é obrigatório',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 120,
            message: 'Nome deve ter entre 2 e 120 caracteres',
            trigger: 'blur',
          },
        ],
        obs: [
          {
            min: 5,
            max: 220,
            message: 'Obs deve ter entre 5 e 220 caracteres',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.service = new Service();
  },
};
</script>
