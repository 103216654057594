<template>
  <span>
    <f-form-row>
      <f-alert type="danger" v-if="mensagem">{{ mensagem }}</f-alert>
    </f-form-row>
    <f-form-row>
       <f-textbox
        ref='input'
        caption="Cep"
        v-loading="dataset.loading"
        element-loading-text="Aguarde..."
        element-loading-spinner="el-icon-loading"
        :value="cep"
        :fieldStyle="{width: '140px'}"
        :validations="internalValidations"
        @input="$emit('cep', $event)"
        @keyup="keyup"
      />
      <f-textbox
        caption="Endereco"
        :fieldStyle="{width: '350px'}"
        :value="endereco" @input="emitEndereco" :validations="internalValidations"
      />
      <f-textbox
        caption="Número"
        v-if="showNumero"
        ref="numero"
        :value="numero"
        :validations="internalValidations"
        :fieldStyle="{width: '110px'}"
        @input="emitNumero"
      />
      <f-textbox
        caption="Bairro" :value="bairro"
        @input="emitBairro"
        :fieldStyle="{width: '200px'}"
        :validations="internalValidations"
      />
    </f-form-row>
    <f-form-row>
      <Uf :value="uf" @input="emitUf" :required="allRequired"/>
      <f-textbox
        caption="Cidade" :value="cidade" @input="emitCidade"
        :fieldStyle="{width: '200px'}" :validations="internalValidations"
      />
      <f-textbox
        caption="Cidade Código"
        :fieldStyle="{width: '110px'}"
        :value="cidadeCodigo"
        :validations="internalValidations"
        @input="emitCidadeCodigo"
      />
    </f-form-row>
  </span>
</template>

<script>
import Uf from './Uf';

export default {
  components: {
    Uf,
  },
  props: {
    cep: {
      type: String,
      default: '',
    },
    endereco: {
      type: String,
      default: '',
    },
    numero: {
      type: String,
      default: '',
    },
    bairro: {
      type: String,
      default: '',
    },
    cidade: {
      type: String,
      default: '',
    },
    cidadeCodigo: {
      type: String,
      default: '',
    },
    uf: {
      type: String,
      default: '',
    },
    showNumero: {
      type: Boolean,
      default: true,
    },
    allRequired: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataset: this.$newDataset({
        getHeaders: () => {
        },
      }),
      mensagem: '',
      timeout: null,
      validations: {
        required: [
          {
            required: true,
            message: 'Campo obrigatório',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  computed: {
    internalValidations() {
      if (!this.allRequired) {
        return [];
      }
      return this.validations.required;
    },
  },
  methods: {
    emitCep(value) {
      this.$emit('cep', value);
    },
    emitEndereco(value) {
      this.$emit('endereco', value);
    },
    emitNumero(value) {
      this.$emit('numero', value);
    },
    emitBairro(value) {
      this.$emit('bairro', value);
    },
    emitCidade(value) {
      this.$emit('cidade', value);
    },
    emitCidadeCodigo(value) {
      this.$emit('cidadeCodigo', value);
    },
    emitUf(value) {
      this.$emit('uf', value);
    },
    async consultaCEP() {
      this.mensagem = '';
      const response = await this.dataset.get(`https://viacep.com.br/ws/${this.cep}/json/`);
      if (response.status === 200) {
        this.emitEndereco(response.data.logradouro);
        this.emitBairro(response.data.bairro);
        this.emitCidade(response.data.localidade);
        this.emitUf(response.data.uf);
        this.emitCidadeCodigo(response.data.ibge);
        if (this.showNumero) {
          this.$refs.numero.focus();
        }
      } else {
        this.mensagem = 'Cep não encontrado, digite o endereço manualmente';
        setTimeout(() => {
          this.mensagem = '';
        }, 4000);
      }
    },
    keyup(event) {
      clearTimeout(this.timeout);
      if (event.code === 'enter') {
        this.consultaCEP();
        return;
      }
      if (this.$utils.String.isNumericKey(event)) {
        this.timeout = setTimeout(() => {
          if (this.cep && this.cep.length >= 8) {
            this.consultaCEP();
          }
        }, 100);
      }
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
